<template>
  <div class="trainee-list-modal-overlay" @click="closeModalOutside">
    <div class="trainee-list-modal" @click.stop>
      <div class="modal-content">
        <h2>Edit Aircraft ID: {{ newAircraft.aircraft_id }}</h2>

        <div class="add">
          <form @submit.prevent="updateAircraft">
            <div class="form-group">
              <label for="name">Name:</label>
              <input type="text" class="form-control rounded" id="name" name="name" placeholder="Enter Aircraft Name"
                v-model="newAircraft.name" />
            </div>
            <div v-if="showAlert" class="alert alert-danger error-message" role="alert">
              <div v-if="errorMessage">{{ errorMessage }}</div>
            </div>
            <div>
              <button class="btn btn-primary rounded" type="submit">Update Aircraft</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

import axios from 'axios';

export default {
  data() {
    return {
      allAircraft: [],
      aircraft: [],
      newAircraft: {
        name: '',
      },
      showAlert: false,
      errorMessage: '',
      processing: false,
      showTraineeList: false,
    };
  },
  methods: {

    async updateAircraft() {
      try {
        const aircraft_id = parseInt(localStorage.getItem('aircraft_id'));
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        const normalizedNewAircraftName = this.newAircraft.name.trim().toLowerCase();
        // Check for duplicate Aircraft name, excluding the current Aircraft being updated
        const isNameDuplicate = this.allAircraft.some(
          aircraft =>
            aircraft.name.trim().toLowerCase() === normalizedNewAircraftName &&
            aircraft.aircraft_id !== aircraft_id
        );
        if (isNameDuplicate) {
          console.error("Aircraft name already exists. Please choose a different name.");
          window.alert('Aircraft name already exists');
        } else {
        const response = await axios.put(
          `/api/aircraft/${aircraft_id}`,
          {
            name: this.newAircraft.name,
          },
          { headers }
        );

        console.log('update')
        console.log(response);

        if (response.status === 200) {
          window.alert('Aircraft updated Successfully');
          this.aircraft.push(response.data);
          this.newAircraft = {
            name: '',
          };
          this.errorMessage = '';
          this.showAlert = false;
          this.closeModalOutside();
        }
      }
      } catch (error) {
        console.error('Error adding User:', error.response);


        if (error.response && error.response.data && error.response.data.errors) {
          // Handle validation errors
          const validationErrors = error.response.data.errors;

          // Build an error message from the validation errors
          let errorMessage = 'Validation errors:';
          for (const field in validationErrors) {
            errorMessage += `\n${field}: ${validationErrors[field][0]}`;
          }

          this.errorMessage = errorMessage;
        } else {
          // Handle other errors
          this.errorMessage = 'An error occurred while updating the Aircraft.';
        }

        this.showAlert = true;
      }
    },

    async fetchAircraft() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.display('unauthorized');
          return;
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get('/api/aircraft', { headers });

        if (response.status === 200) {
          this.allAircraft = response.data;
          console.log('aircraft');
          console.log(this.allAircraft);
        }
      } catch (error) {
        console.error('Error retrieving aircraft:', error);
        // Handle error
      }
    },
    viewTraineeList() {
      this.showTraineeList = true; // Show the modal when the button is clicked
    },
    closeTraineeList() {
      this.showTraineeList = false; // Close the modal
    },
    goBack() {
      this.$router.push({ name: 'ViewAircraft' });
    },
    closeModalOutside() {
      this.$emit('close'); // Emit an event to indicate the modal should be closed
    },
    getAircraftData(aircraft_id) {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        axios.get(`/api/aircraft/${aircraft_id}`, { headers })
          .then(res => {
            this.newAircraft = res.data;
            console.log(res.data);
          });
      } catch (error) {
        console.error('An error occurred while fetching data:', error);
      }
    },
  },
  async mounted() {
    this.fetchAircraft();
    this.getAircraftData(localStorage.getItem('aircraft_id'));
  },
}
</script>
  
<style scoped>
.trainee-list-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure the modal is on top of other content */
}

h2 {
  margin-top: 30px;
  font-size: 30px;
  margin-bottom: 30px;
}

/* Style your modal content */
.trainee-list-modal {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 1000px;
  /* Limit the maximum width of the modal */
  padding: 20px;
  position: relative;
  max-height: 95%;

}


.modal-content {
  overflow-y: auto;
  /* Enable scrolling within the modal content */
}


.add button {
  width: 200px;
  height: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
}

button.btn:hover {
  background-color: #0c1a61;
  transition: transform 0.2s, box-shadow 0.2s;
}

input.form-control,
select.form-control {
  width: 70%;
  margin-left: 100px;
  display: inline-block;
  margin-bottom: 25px;
  align-items: baseline;
}

.form-group {
  margin-bottom: 15px;
}

label {
  margin-right: 5px;
  position: absolute;
  margin-top: 8px;
}

.form-control {
  border-radius: 5px;
}

.form-control#start_date {
  border-radius: 5px;
  width: 25%;
  margin-left: 100px;
  margin-right: 45%;
}

h1 {
  margin-bottom: 20px;
}

.alert-danger {
    color: #ff0019;
    background-color: #f8d7da;
    border: 1px solid #e6b0b6;
}

.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 0rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}
</style>
  