<template>
      <Header />

  <div class="calendar-container">
    <h1 class="title">Attendance Sheet - Wireframe</h1>
    <table class="calendar">
      <thead>
        <tr class="month-year">
          <th colspan="7">{{ formattedMonthYear }}</th>
        </tr>
        <tr class="days-of-week">
          <th>SUN</th>
          <th>MON</th>
          <th>TUE</th>
          <th>WED</th>
          <th>THU</th>
          <th>FRI</th>
          <th>SAT</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(week, index) in calendar" :key="index">
          <td v-for="day in week" :key="day.date" :class="{ 'today-date': day.isToday }" class="day">
            <button @click="openDialog(day.date)">
              {{ day.date.getDate() }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="showDialog" class="modal-overlay" @click.self="closeDialog">
      <div class="modal" @click.stop>
        <h3>Trainees </h3>
        <h4>{{ selectedDate.toDateString() }}</h4>
        <input type="text" v-model="searchQuery" placeholder="Search trainees..." class="search-input">
        <div class="trainee-grid">
          <div class="trainee-row" v-for="trainee in filteredTrainees" :key="trainee.id">
            <div class="trainee-name">{{ trainee.name }}</div>
            <div class="trainee-time">{{ trainee.timestamp }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format, startOfMonth, endOfMonth, eachDayOfInterval, eachWeekOfInterval, startOfWeek, endOfWeek } from 'date-fns';
import { isToday, startOfDay } from 'date-fns';
import Header from './Instructor/InstructorHeader.vue';

export default {
  name: 'MonthCalendar',
  
  components:{
    Header,
  },
  data() {
    return {
      currentMonth: new Date(),
      today: startOfDay(new Date()),
      showDialog: false,
      selectedDate: null,
      trainees: [
        { "id": 1, "name": "John Doe", "timestamp": "10:00 AM" },
        { "id": 2, "name": "Jane Smith", "timestamp": "11:30 AM" },
        { "id": 3, "name": "Joseph K", "timestamp": "10:00 AM" },
        { "id": 4, "name": "Paul N", "timestamp": "11:30 AM" },
        { "id": 5, "name": "Aravind H", "timestamp": "10:00 AM" },
        { "id": 6, "name": "Rory MCC", "timestamp": "11:30 AM" },
        { "id": 7, "name": "David Lee", "timestamp": "10:00 AM" },
        { "id": 8, "name": "Emily Brown", "timestamp": "11:30 AM" },
        { "id": 9, "name": "George Hill", "timestamp": "10:00 AM" },
        { "id": 10, "name": "Sophia Miller", "timestamp": "11:30 AM" },
        { "id": 11, "name": "Michael Clark", "timestamp": "10:00 AM" },
        { "id": 12, "name": "Lily Garcia", "timestamp": "11:30 AM" },
        { "id": 13, "name": "Daniel Allen", "timestamp": "10:00 AM" },
        { "id": 14, "name": "Olivia Martinez", "timestamp": "11:30 AM" },
        { "id": 15, "name": "William Young", "timestamp": "10:00 AM" },
        { "id": 16, "name": "Sophia Davis", "timestamp": "11:30 AM" },
        { "id": 17, "name": "James Lopez", "timestamp": "10:00 AM" },
        { "id": 18, "name": "Ava Hernandez", "timestamp": "11:30 AM" },
        { "id": 19, "name": "Benjamin Moore", "timestamp": "10:00 AM" },
        { "id": 20, "name": "Mia Thompson", "timestamp": "11:30 AM" },
        { "id": 21, "name": "Ethan Hall", "timestamp": "10:00 AM" },
        { "id": 22, "name": "Emma Wilson", "timestamp": "11:30 AM" },
        { "id": 23, "name": "Alexander Lewis", "timestamp": "10:00 AM" },
        { "id": 24, "name": "Isabella Carter", "timestamp": "11:30 AM" },
        { "id": 25, "name": "Christopher Turner", "timestamp": "10:00 AM" },
        { "id": 26, "name": "Charlotte Wood", "timestamp": "11:30 AM" },
        { "id": 27, "name": "Andrew Adams", "timestamp": "10:00 AM" },
        { "id": 28, "name": "Madison King", "timestamp": "11:30 AM" },
        { "id": 29, "name": "Josephine Rivera", "timestamp": "10:00 AM" },
        { "id": 30, "name": "Logan Baker", "timestamp": "11:30 AM" }
      ],
      searchQuery: '',
    };
  },
  computed: {
    filteredTrainees() {
      return this.trainees.filter(trainee =>
        trainee.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    formattedMonthYear() {
      return format(this.currentMonth, 'MMMM yyyy');
    },
    calendar() {
      const today = startOfDay(new Date()); // Ensure you use this correctly in your component
      const startDay = startOfWeek(startOfMonth(this.currentMonth));
      const endDay = endOfWeek(endOfMonth(this.currentMonth));
      const days = eachDayOfInterval({ start: startDay, end: endDay });

      return eachWeekOfInterval({ start: startDay, end: endDay }).map(weekStart => {
        const weekEnd = endOfWeek(weekStart);
        return days.filter(day => day >= weekStart && day <= weekEnd).map(date => ({
          date,
          isToday: isToday(date, today) // Check if it's today
        }));
      });
    },
  },
  methods: {
    viewTrainees(date) {
      alert(`Viewing trainees for ${date.toDateString()}`);
    },
    openDialog(date) {
      this.selectedDate = date;
      this.showDialog = true;
      console.log("dialog opened")
    },
    closeDialog() {
      this.showDialog = false;
    },
    isToday(date) {
      return isToday(date);
    }
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 5px;
  height: auto;
  max-width: 55%;
  display: flex;
  max-height: 60%;
  flex-direction: column;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  position: relative;
}

.trainee-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
}

.trainee-row {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ccc;
  padding: 8px;
  background-color: #f9f9f9;
}

.trainee-name,
.trainee-time {
  margin: 0 5px;
}

.close {
  align-self: flex-end;
  cursor: pointer;
  font-size: 1.5em;
}

.calendar-container {
  text-align: center;
  width: 90%;
  /* Adjust width as needed */
  margin: 0 auto;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
}

.calendar {
  width: 100%;
  border-collapse: collapse;
  /* Ensures borders between cells are shared */
}

.month-year th {
  font-size: 20px;
  padding: 10px;
  border-bottom: 2px solid #ccc;
}

.days-of-week th {
  text-align: center;
  padding: 10px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ccc;
}

.day button {
  width: 100%;
  height: 60px;
  background: white;
  border: none;
  cursor: pointer;
}

.day button:hover {
  background-color: #f0f0f0;
}

.day {
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.today-date button {
  background-color: rgb(137, 206, 140);
  /* Highlight today's date */
}

.search-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  box-sizing: border-box;
  border-radius: 5px;
}
</style>
