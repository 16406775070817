<template>
    <!DOCTYPE html>
    <html lang="en">

    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
    </head>

    <body>
        <div>
            <page-header></page-header>
            <div class="import-tasks-modal">
                <div class="container-fluid-body no-padding">
                    <div class="row">
                        <div class="col-md-12 left-align-text">
                            <h2>Import trainees from .CSV file</h2>
                            <p style="font-size:16px;">The file has to be a spreadsheet file (.csv) in the following format:
                            </p>
                            <p style="font-weight:bold;">First Name|Last Name|Email|Category|Date of Birth|Place of
                                Birth|License No|Session(s)
                            </p>
                            <p style="font-size:16px; color: rgb(196, 0, 0);"><b>Fields A-F are mandatory</b>
                            </p>
                            <div class="instructions-area">
                                <table class="inst-table">
                                    <thead>
                                        <tr>
                                            <th>Column</th>
                                            <th>Name</th>
                                            <th>Instructions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>A</td>
                                            <td>First Name</td>
                                            <td>The first name of the trainee</td>
                                        </tr>
                                        <tr>
                                            <td>B</td>
                                            <td>Last Name</td>
                                            <td>The last name of the trainee</td>
                                        </tr>
                                        <tr>
                                            <td>C</td>
                                            <td>Email</td>
                                            <td>The trainee's email (must be unique)</td>
                                        </tr>
                                        <tr>
                                            <td>D</td>
                                            <td>Category</td>
                                            <td>The Category of the Trainee</td>
                                        </tr>
                                        <tr>
                                            <td>E</td>
                                            <td>Date of Birth</td>
                                            <td>Trainee Date of Birth must be written in the form DD/MM/YYYY</td>
                                        </tr>
                                        <tr>
                                            <td>F</td>
                                            <td>Place of Birth</td>
                                            <td>The location where the trainee was born</td>
                                        </tr>
                                        <tr>
                                            <td>G</td>
                                            <td>License No</td>
                                            <td>The number associated with the trainee (must be unique)</td>
                                        </tr>
                                        <tr>
                                            <td>H</td>
                                            <td>Session(s)</td>
                                            <td>The Session ID which the trainee will be assigned to (optional). If multiple
                                                they must be seperated by "/"</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <span class="clip" role="img" aria-label="Paperclip">&#x1F4CE;</span>
                                <input type="file" @change="importFile" class="file-input" />
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div v-if="previewRows.length && !importSuccessful && !dryRunSuccessful">
                                <h4 style="text-align: left;">CSV Preview (First 5 rows):</h4>
                                <table class="preview-table">
                                    <!-- Uploaded CSV headers (dynamic) -->
                                    <thead>
                                        <tr>
                                            <th class="narrow-column">First Name</th>
                                            <th class="narrow-column">Last Name</th>
                                            <th class="narrow-column">Email</th>
                                            <th class="narrow-column">Category</th>
                                            <th class="narrow-column">Date of Birth</th>
                                            <th class="narrow-column">Place of Birth</th>
                                            <th class="narrow-column">License No</th>
                                            <th class="narrow-column">Session(s)</th>
                                        </tr>
                                    </thead>
                                    <!-- Uploaded CSV preview -->
                                    <tbody>
                                        <tr v-for="(row, index) in previewRows" :key="row.toString()"
                                            v-show="!(ignoreFirstRow && index === 0)">
                                            <td style="font-size:14px" v-for="cell in row" :key="cell">{{ cell }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="dryRunSuccessful" v-html="successMessage.replace(/\n/g, '<br>')" class="successBox">
                            </div>
                            <div v-if="importSuccessful && !dryRun" v-html="successMessage.replace(/\n/g, '<br>')"
                                class="successBox"></div>
                        </div>

                        <div class="importArea">
                            <label>
                                <input type="checkbox" v-model="ignoreFirstRow" class="ignoreCheckbox">
                                Ignore first row
                            </label>
                            <label>
                                <input type="checkbox" v-model="dryRun" class="dryRuncheckbox">
                                Dry Run
                            </label>
                            <div class="buttonContainer">
                                <button class="importButton" :disabled="processing" @click.stop.prevent="handleImport">
                                    {{ processing ? 'Importing...' : 'IMPORT' }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </body>

    </html>
</template>
  
<script>
import axios from 'axios';
import pageHeader from '@/components/Examiner/Header.vue';


export default {
    name: "importTrainees",
    data() {
        return {
            traineePasswords: [],
            previewRows: [],
            entireFileRows: [],
            ignoreFirstRow: false,
            importSuccessful: false,
            dryRunSuccessful: false,
            successMessage: '',
            dryRun: true,
            fileName: '',
            projects: [],
            emails: [],
            license_nos: [],
            existingTrainees: [],
            importedTrainees: [],
            validTrainees: [],
            processing: false,
        };
    },
    components: {
        pageHeader
    },
    watch: {
        selectedFile(newFile, oldFile) {
            if (newFile !== oldFile) {
                this.resetImportState();
            }
        }
    },
    mounted() {
        axios.get(`/api/users`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(response => {
                const existingUsers = response.data;
                this.existingTrainees = existingUsers.filter(user => user.role === 'trainee' || user.second_role === 'trainee');
            })
            .catch(error => {
                console.error('There was a problem fetching Users:', error);
            });
            axios.get(`/api/projects`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(response => {
                this.projects = response.data;
            })
            .catch(error => {
                console.error('There was a problem fetching Users:', error);
            });
            axios.get(`/api/projects`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(response => {
                this.projects = response.data;
            })
            .catch(error => {
                console.error('There was a problem fetching Users:', error);
            });
    },
    methods: {
        closeModal() {
            this.$emit('close');
            this.$emit('modal-closed');
        },
        importFile(event) {
            const file = event.target.files[0];

            // Check if a file is selected
            if (file) {
                this.fileName = file.name;

                if (file.type.includes('csv')) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        const rows = reader.result.trim().split('\n');
                        this.entireFileRows = rows.map(row => {
                            // Split using either ',' or '|'
                            return row.split(/[|]/);
                        });
                        this.previewRows = this.entireFileRows.slice(0, 5);
                    };
                    reader.readAsText(file);
                }
            } else {
                // Handle the case where no file is selected, clear any previous data
                this.fileName = '';
                this.entireFileRows = [];
                this.previewRows = [];
            }
        },
        processDataRows(dataRows) {
            const uniqueEmails = new Set();
            const uniqueLicenses = new Set();

            return dataRows
                .filter(row => Array.isArray(row) && row.length > 0)
                .map((row) => {
                    const first_name = row[0].trim();  
                    const last_name = row[1].trim();    
                    const email = row[2].trim();         // Second column: Email
                    const category = row[3].trim();      // Third column: Category
                    const date_of_birth = row[4].trim();   // Fourth column: Date of Birth
                    const place_of_birth = row[5].trim();  // Fifth column: Place of Birth
                    const license_no = row[6].trim();            // Sixth column: license_no
                    const project = row[7] ? row[7].trim() : '';

                    // Convert the date format from dd/mm/yyyy to Y-m-d
                    const dateParts = date_of_birth.split('/');
                    const day = dateParts[0];
                    const month = dateParts[1];
                    const year = dateParts[2];
                    const formattedDateOfBirth = `${year}-${month}-${day}`;

                    // Check for unique email and license_no
                    if (uniqueEmails.has(email)) {
                        // Handle duplicate email (you can throw an error or handle it as needed)
                        console.error(`Duplicate email found: ${email}`);
                    } else {
                        uniqueEmails.add(email);
                    }

                    if (uniqueLicenses.has(license_no)) {
                        // Handle duplicate license_no (you can throw an error or handle it as needed)
                        console.error(`Duplicate license_no found: ${license_no}`);
                    } else {
                        uniqueLicenses.add(license_no);
                    }

                    return {
                        first_name,
                        last_name,
                        email,
                        category,
                        date_of_birth: formattedDateOfBirth,
                        place_of_birth,
                        license_no,
                        project,
                    };
                });
        },
        handleImport() {
            this.dryRunSuccessful = false;
            this.processing = true;

            // Process the rows first
            const processedTrainees = this.processDataRows(this.entireFileRows);

            if (this.ignoreFirstRow) {
                processedTrainees.shift(); // Remove the first row if the user wants to ignore it
            }

            if (this.dryRun) {
                this.dryRunCheck(processedTrainees, 1);
                this.dryRunImport(processedTrainees);
            } else {
                this.checkImportTrainees(processedTrainees);
            }
        },
        dryRunImport(processedTrainees) {
            let dryRunSkippedTrainees = [];

            const duplicates = this.checkForDuplicates(processedTrainees);
            dryRunSkippedTrainees = duplicates.map(trainee => trainee.email);

            // Check for uniqueness of emails and license_nos during the dry run
            const uniqueEmails = new Set();
            const uniqueLicenses = new Set();

            processedTrainees.forEach(trainee => {
                // Check for unique email and license_no
                if (uniqueEmails.has(trainee.email)) {
                    console.error(`Duplicate email found during dry run: ${trainee.email}`);
                } else {
                    uniqueEmails.add(trainee.email);
                }

                if (uniqueLicenses.has(trainee.license_no)) {
                    console.error(`Duplicate license_no found during dry run: ${trainee.license_no}`);
                } else {
                    uniqueLicenses.add(trainee.license_no);
                }
            });

            console.log("Dry Run Mode: These trainees would have been imported:", processedTrainees);
            if (dryRunSkippedTrainees.length > 0) {
                console.log("Duplicate trainees that would have been skipped:", dryRunSkippedTrainees);
            } else {
                this.successMessage = `Dry Run Mode: ${processedTrainees.length} trainees would have been imported. No issues found in the file`;
            }

            this.dryRunSuccessful = true;
            this.processing = false;
        },
        async sendBatchEmailToTrainees(trainees) {
            try {
                const token = localStorage.getItem('token');
                const headers = { Authorization: `Bearer ${token}` };

                console.log('trainees');
                console.log(trainees);

                // Map trainees array to an array of data objects for the API call
                const emailDataArray = trainees.map(trainee => ({
                    first_name: trainee.first_name,
                    last_name: trainee.last_name,
                    email: trainee.email,
                    UIN: trainee.license_no,
                    // Add any additional data needed for the email
                }));

                const response = await axios.post(
                    '/api/batch-contact', // Update the API endpoint to handle batch emails
                    emailDataArray,
                    { headers }
                );

                this.traineePasswords = response.data.data;

                console.log('trainees');
                console.log(this.traineePasswords);
                if (response.status === 201) {
                    console.log('Batch emails sent successfully');
                }
            } catch (error) {
                console.error('Error sending batch emails:', error);
                // Handle error as needed
            }
        },
        async batchImportTrainees(validTrainees, skippedTrainees, processedTrainees) {
            
            try {
                const token = localStorage.getItem('token');
                const headers = { Authorization: `Bearer ${token}` };

        const validTraineesData = validTrainees.map(trainee => {
            const project_ids = [];
            if (trainee.project) {
                const projectIdsFromTrainee = trainee.project.split('/').map(id => parseInt(id));
                // Assuming projects is an array of project IDs fetched from the database
                this.projects.forEach(project => {
                    if (projectIdsFromTrainee.includes(project.project_id)) {
                        project_ids.push(project.project_id);
                    }
                });
            }
            return {
                ...trainee,
                password: 'green123',
                role: 'trainee',
                project_ids: project_ids,
            };
        });

                const response = await axios.post('/api/trainees/batch', validTraineesData, { headers });

                const responseTrainees = response.data.data;

                // Handle the responseTrainees as needed
                await this.sendBatchEmailToTrainees(responseTrainees);
                console.log('Batch Import Successful:', responseTrainees);
                console.log(skippedTrainees);
                console.log('importpasswords');
                console.log(this.traineePasswords);
                this.$store.dispatch('setTraineePasswords', this.traineePasswords);

                this.$store.dispatch('setImportedTrainees', processedTrainees);
                this.$store.dispatch('setFileName', this.fileName);
                this.$store.dispatch('setSkippedTrainees', skippedTrainees);
                this.processing = false;
                this.$router.push({ name: 'TraineeImportSuccessful'} );
            } catch (error) {
                console.error('Batch Import Error:', error);
                this.processing = false;
            }
        },
        areTraineesEqual(trainee1, trainee2) {
            for (let key in trainee1) {
                if (key !== 'user_id' && key !== 'created_at' && key !== 'updated_at' && trainee1[key] !== trainee2[key]) {
                    return false;
                }
            }
            for (let key in trainee2) {
                if (key !== 'user_id' && key !== 'created_at' && key !== 'updated_at' && trainee1[key] !== trainee2[key]) {
                    return false;
                }
            }
            return true;
        },
        checkForDuplicates(importedTrainees) {
            const duplicates = [];

            // Check for uniqueness of emails and license_nos during the dry run
            const uniqueEmails = new Set();
            const uniqueLicenses = new Set();

            for (let i = 0; i < importedTrainees.length; i++) {
                const currentTrainee = importedTrainees[i];

                // Check for duplicate with existing trainees
                let isDuplicate = this.existingTrainees.some(existingTrainee => this.areTraineesEqual(currentTrainee, existingTrainee));

                // Check for unique email and license_no
                if (uniqueEmails.has(currentTrainee.email) || uniqueLicenses.has(currentTrainee.license_no)) {
                    isDuplicate = true;
                } else {
                    uniqueEmails.add(currentTrainee.email);
                    uniqueLicenses.add(currentTrainee.license_no);
                }

                if (isDuplicate) {
                    duplicates.push({
                        trainee: currentTrainee,
                        duplicateType: uniqueEmails.has(currentTrainee.email) ? 'email' : 'license_no',
                    });
                }
            }

            return duplicates;
        },
        checkImportTrainees(importedTrainees) {
            this.dryRunCheck(importedTrainees, 0);
            this.batchImportTrainees(this.validTrainees, this.skippedTrainees, importedTrainees);
        },
        dryRunCheck(importedTrainees, isDryRun) {
            let issues = [];
            this.validTrainees = [];
            this.skippedTrainees = [];
            // Get duplicate trainees using checkForDuplicates method
            const duplicates = this.checkForDuplicates(importedTrainees);

            duplicates.forEach(duplicate => {
                issues.push({
                    message: `Trainee with ${duplicate.duplicateType === 'email' ? 'email' : 'license_no'} ${duplicate.trainee[duplicate.duplicateType]} already exists in the import file.`,
                    email: duplicate.trainee.email,
                });
            });

            // Loop through each importedtrainee
            importedTrainees.forEach(importedTrainee => {
                const emailExists = this.existingTrainees.some(existingTrainee => existingTrainee.email === importedTrainee.email);
                if (emailExists) {
                    issues.push({
                        message: `Trainee with email ${importedTrainee.email} already exists in the database.`,
                        email: importedTrainee.email
                    });
                }

                const licenseExists = this.existingTrainees.some(existingTrainee => existingTrainee.license_no === importedTrainee.license_no);
                if (licenseExists) {
                    issues.push({
                        message: `Trainee with license_no ${importedTrainee.license_no} already exists in the database.`,
                        email: importedTrainee.email
                    });
                }
                // Check for duplicates
                if (duplicates.includes(importedTrainee)) {
                    issues.push({
                        message: `Trainees already exists (Duplicate record)`,
                        email: importedTrainee.email
                    });
                }

                for (let key in importedTrainee) {
                    let value = importedTrainee[key];
                    switch (key) {
                        case "first_name":
                        case "last_name":
                            if (value === undefined || value === null || value.trim() === '') {
                                issues.push({
                                    message: `Trainee ${importedTrainee.email} - Field "${key}" is empty.`,
                                    email: importedTrainee.email
                                });
                            } else if (typeof value !== "string") {
                                issues.push({
                                    message: `Trainee ${importedTrainee.email} - Invalid data type for "${key}". Expected string, got ${typeof value}.`,
                                    email: importedTrainee.email
                                });
                            }
                            break;
                        case "email":
                            if (value === undefined || value === null || value.trim() === '') {
                                issues.push({
                                    message: `Trainee ${importedTrainee.email} - Field "${key}" is empty.`,
                                    email: importedTrainee.email
                                });
                            } else if (typeof value !== "string") {
                                issues.push({
                                    message: `Trainee ${importedTrainee.email} - Invalid data type for "${key}". Expected string, got ${typeof value}.`,
                                    email: importedTrainee.email
                                });
                            }
                            break;
                        case "category":
                            if (value === undefined || value === null || value.trim() === '') {
                                issues.push({
                                    message: `Trainee ${importedTrainee.email} - Field "${key}" is empty.`,
                                    email: importedTrainee.email
                                });
                            } else if (typeof value !== "string") {
                                issues.push({
                                    message: `Trainee ${importedTrainee.email} - Invalid data type for "${key}". Expected string, got ${typeof value}.`,
                                    email: importedTrainee.email
                                });
                            }
                            break;
                        case "date_of_birth":
                            if (value === undefined || value === null || value.trim() === '') {
                                issues.push({
                                    message: `Trainee ${importedTrainee.email} - Field "${key}" is empty.`,
                                    email: importedTrainee.email
                                });
                            } else if (typeof value !== "string") {
                                issues.push({
                                    message: `Trainee ${importedTrainee.email} - Invalid data type for "${key}". Expected string, got ${typeof value}.`,
                                    email: importedTrainee.email
                                });
                            } else {
                                // Regular expression for the "dd/mm/yyyy" format
                                const datePattern = /^\d{4}-\d{2}-\d{2}$/;

                                if (!datePattern.test(value)) {
                                    issues.push({
                                        message: `Trainee ${importedTrainee.email} - Invalid date format for "${key}". Expected "dd/mm/yyyy".`,
                                        email: importedTrainee.email
                                    });
                                }
                            }
                            break;
                        case "place_of_birth":
                            if (value === undefined || value === null || value.trim() === '') {
                                issues.push({
                                    message: `Trainee ${importedTrainee.email} - Field "${key}" is empty.`,
                                    email: importedTrainee.email
                                });
                            } else if (typeof value !== "string") {
                                issues.push({
                                    message: `Trainee ${importedTrainee.email} - Invalid data type for "${key}". Expected string, got ${typeof value}.`,
                                    email: importedTrainee.email
                                });
                            }
                            break;
                        case "license_no":
                            if (value === undefined || value === null || value === '') {
                                issues.push({
                                    message: `Field "${key}" is empty.`,
                                    email: importedTrainee.email
                                });
                            }
                            // Check for invalid data types
                            else if (isNaN(value)) {
                                issues.push({
                                    message: `Invalid data type for "${key}". Expected number, got ${typeof value}.`,
                                    email: importedTrainee.email
                                });
                            }
                            break;
                        case "project":
                            if (value !== undefined && value !== null) {
                                // Check for the format x/x/x or an empty string
                                const isValidFormat = /^(|(\d+\/)+\d+|\d+)$/.test(value);
                                if (!isValidFormat) {
                                    issues.push({
                                        message: `Trainee ${importedTrainee.email} - Invalid format for "${key}". Expected 'x/x/x' format.`,
                                        email: importedTrainee.email
                                    });
                                }
                            }
                            break;
                            default:
                            issues.push({
                                message: `Trainee ${importedTrainee.email} - Extra field "${key}" detected.`,
                                email: importedTrainee.email
                            });
                    }
                }
                                        // Check if there are issues for the current trainee
        const hasIssues = issues.some(issue => issue.email === importedTrainee.email);
        
        // Push the trainee into the appropriate array
        if (hasIssues) {
            this.skippedTrainees.push(importedTrainee);
        } else {
            this.validTrainees.push(importedTrainee);
        }
            });

            if (issues.length > 0 && isDryRun) {
                this.$store.dispatch('setIssues', issues);
                this.$store.dispatch('setImportedTrainees', importedTrainees);
                this.$router.push({ name: 'TraineeIssuesDisplay' });
                this.$store.dispatch('setFileName', this.fileName);

            }
        },

        displayissues(issues) {
            console.log("These are the issues - ", issues);
        },
        resetImportState() {
            this.successMessage = null;
            this.processedTrainees = [];
        }

    },
}
</script>
  
<style scoped>
/* Add some spacing between the two main sections */
page-header {
    position: fixed;
}

.col-md-6:first-child {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.no-padding {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
}

.left-align-text {
    text-align: left;
}

.col-md-12 h2,
.col-md-12 p {
    text-align: left;
    margin-left: 1px;
}

.import-tasks-modal {
    width: 100%;
    max-height: 90vh;
    margin-top: 0;
    padding: 10px;
    border-radius: 8px;
    position: fixed;
    display: block;
    align-items: left;
    overflow-y: auto;
    z-index: 0;
}

.import-section {
    align-items: left;
}

/* Style the Paper Clip */
.clip {
    font-size: 30px;
    border: none;
    margin-top: 10px;
}

.ignoreCheckbox,
.dryRuncheckbox {
    margin-left: 30px;
    margin-top: 10px;

}

.tasks-container-fluid .row {
    margin: 0;
    padding: 0;
}

.file-input {
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 5px;
    width: 90%;
    /* Add additional styling for file input if needed */
}

.instructions-area {
    width: 100%;
}

.inst-table {
    border-collapse: collapse;
    border: 1px solid black;
    border-radius: 5px;
    overflow: hidden;
    /* Helps with the border radius */
    margin: 20px 0;
    /* Optional: adds some spacing above and below the table */
    text-align: left;
    width: 100%;
}

/* CSS for the columns of .inst-table */
.inst-table th:nth-child(1),
.inst-table td:nth-child(1) {
    width: 5%;
}

.inst-table th:nth-child(2),
.inst-table td:nth-child(2) {
    width: 10%;
}

.inst-table th:nth-child(3),
.inst-table td:nth-child(3) {
    width: calc(85% - 15%);
    /* Taking into account the total width and subtracting the other column widths */
}

.inst-table tbody tr:nth-child(odd) {
    background-color: rgba(219, 219, 219, 0.904);
}

.inst-table tbody tr:nth-child(even) {
    background-color: white;
}

.importArea {
    margin-top: 20px;
}

.buttonContainer {
    margin-left: 30px;
    width: 20px;
    margin-top: 10px;
    margin-bottom: 15px;
}

.importButton {
    color: rgb(255, 255, 255);
    border: none;
    width: 100px;
    padding: 70 70px;
    padding-left: 10px;

    /* Add some margin between buttons */
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    align-content: center;
    background-color: #02092b;
    margin-left: 0;
}



.importButton:hover {
    background-color: #02092b;
    transform: scale(0.95);
    /* Increase the size slightly on hover */

}

.importButton:active {
    background-color: #02092b;
    transform: scale(0.95);
    /* Slightly shrink the button on click */
}

.preview-table {
    position: relative;
    width: 100%;
    border-collapse: collapse;
    text-align: left;
}

.preview-table tr[v-show="false"] {
    visibility: hidden;
    height: [height-of-the-row]px;
    /* Specify the height that the row would normally occupy */
}

.preview-table th,
.preview-table td {
    border: 1px solid #ddd;
    /* adjust color as needed */
    padding: 8px;
}

.preview-table th {
    background-color: #f2f2f2;
    /* adjust color as needed */
}

/* Styling for table headers and cells */
.narrow-column {
    width: 5%;
    border: #ccc;
    font-size: 14px;
}

.wide-column {
    width: 65%;
    border: #ccc;
    font-size: 14px;
}

.successBox {
    border: 1px solid black;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
    font-size: 20px;
}

.importButton[disabled] {
    background-color: #ccc;
    cursor: not-allowed;
    opacity: 0.9;
}
</style>
  