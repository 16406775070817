<template>
  <div class="trainee-list-modal-overlay" @click="closeModalOutside">
    <div class="trainee-list-modal" @click.stop>
      <div class="modal-content">
        <h2>Edit Session ID: {{ newProject.project_id }}</h2>

        <div class="add">
          <form @submit.prevent="updateProject">
            <!-- <div class="form-group">
              <label for="role">Practical Element:</label>
              <select class="form-control rounded" id="prod_id" name="prod_id" 
              v-model="newProject.product_id" @change="fetchProjectsByProduct">
                <option value=""></option>
                <option v-for="product in products" :key="product.prod_id" :value="product.prod_id">
                  {{ product.name }}</option>
              </select>
            </div> -->
            <div class="form-group">
              <label for="start_date">Start Date:</label>
              <input type="date" class="form-control rounded" id="start_date" name="start_date" placeholder="YYYY-MM-DD"
                v-model="newProject.start_date" />
            </div>
            <div class="form-group">
              <label for="end_date">End Date:</label>
              <input type="date" class="form-control rounded" id="end_date" name="end_date" placeholder="YYYY-MM-DD"
                v-model="newProject.end_date" />
            </div>
            <div class="form-group">
              <label for="name">Name:</label>
              <input type="text" class="form-control rounded" id="name" name="name" placeholder="Enter Project Name"
                v-model="newProject.name" />
            </div>
            <!-- <div class="form-group">
              <label for="role">Instructor:</label>
              <select class="form-control rounded" id="instructor_id" name="instructor_id" v-model="newProject.instructor_id">
                <option value=""></option>
                <option v-for="instructor in instructors" :key="instructor.instructor_id" :value="instructor.user_id">
                ID: {{ instructor.user_id }} &nbsp;&nbsp;&nbsp; -- &nbsp;&nbsp;&nbsp; {{ instructor.first_name + ',' + instructor.last_name }}</option>
              </select>
            </div>
            <div class="form-group">
  <label for="instructor2_id">Second Instructor:</label>
  <select class="form-control rounded" id="instructor2_id" name="instructor2_id" v-model="newProject.instructor2_id">
    <option value=""></option>
    <option v-for="instructor in instructors" :key="instructor.instructor2_id" :value="instructor.user_id">
      ID: {{ instructor.user_id }} &nbsp;&nbsp;&nbsp; -- &nbsp;&nbsp;&nbsp; {{ instructor.first_name + ',' + instructor.last_name }}
    </option>
  </select>
</div> -->
            <div v-if="showAlert" class="alert alert-danger error-message" role="alert">
              <div v-if="errorMessage">{{ errorMessage }}</div>
            </div>
            <div>
              <button class="btn btn-primary rounded" type="submit">Update Session</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

import axios from 'axios';

export default {
  data() {
    return {
      instructors: [],
      products: [],
      projects: [],
      newProject: {
        name: '',
        start_date: '',
        end_date: '',
        prod_id: '',
        product_name: '',
        instructor_id: '',
        instructor2_id: '',
      },
      showAlert: false,
      currentTime: '',
      errorMessages: {},
      errorMessage: '',
      productSelected: false,
      projectSelected: false,
      showTraineeList: false,
    };
  },
  methods: {

    async updateProject() {
      try {
        const project_id = localStorage.getItem('project_id');
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        console.log('prod_id')
        console.log(this.newProject);

        const response = await axios.put(
          `/api/projects/${project_id}`,
          {
            name: this.newProject.name,
            start_date: this.newProject.start_date,
            end_date: this.newProject.end_date,
            prod_id: this.newProject.prod_id,
            product_name: this.newProject.product_name,
            instructor_id: this.newProject.instructor_id,
            instructor2_id: this.newProject.instructor2_id,
          },
          { headers }
        );

        if (response.status === 200) {
          window.alert('Session updated Successfully');
          this.projects.push(response.data);
          this.newProject = {
            name: '',
            start_date: '',
            end_date: '',
            prod_id: '',
            product_name: '',
            instructor_id: '',
            instructor2_id: '',
          };
          this.errorMessage = '';
          this.showAlert = false;
        }
        await this.fetchProjects();
        window.location.reload();
      } catch (error) {
        console.error('Error adding User:', error.response);


        if (error.response && error.response.data && error.response.data.errors) {
          // Handle validation errors
          const validationErrors = error.response.data.errors;

          // Build an error message from the validation errors
          let errorMessage = 'Validation errors:';
          for (const field in validationErrors) {
            errorMessage += `\n${field}: ${validationErrors[field][0]}`;
          }

          this.errorMessage = errorMessage;
        } else {
          // Handle other errors
          this.errorMessage = 'An error occurred while adding the user.';
        }

        this.showAlert = true;
      }
    },
    async fetchProjects() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.display('unauthorized');
          return;
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get('/api/projects', { headers });
        console.log('Session response')
        console.log(response);

        if (response.status === 200) {
          this.projects = response.data;
          console.log('Sessions');
          console.log(this.projects);
        }
      } catch (error) {
        console.error('Error retrieving Sessions:', error);
        // Handle error
      }
    },
    async fetchProducts() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.display('unauthorized');
          return;
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get('/api/products', { headers });

        if (response.status === 200) {
          this.products = response.data; // Populate the products array with API data
          console.log('this products')
          console.log(this.products);
        }
      } catch (error) {
        console.error('Error retrieving Practical Elements:', error);
        // Handle error
      }
    },
    async fetchInstructors() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.display('unauthorized');
          return;
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get('/api/users', { headers });

        if (response.status === 200) {
          // Filter the response data to include only users with the role 'instructor'
          this.instructors = response.data.filter(user => user.role === 'instructor' || user.second_role === 'instructor');
          console.log(this.instructors);
        }
      } catch (error) {
        console.error('Error retrieving instructors:', error);
        // Handle error
      }
    },
    goBack() {
      this.$router.push({ name: 'ViewProjects' });
    },
    closeModalOutside() {
      this.$emit('close'); // Emit an event to indicate the modal should be closed
    },
    getProjectData(project_id) {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        axios.get(`/api/projects/${project_id}`, { headers })
          .then(res => {
            this.newProject = res.data.project;
            console.log('New Session')
            console.log(res.data);
          });
      } catch (error) {
        console.error('An error occurred while fetching data:', error);
      }
    },
  },
  async mounted() {
    this.getProjectData(localStorage.getItem('project_id'));
    await this.fetchInstructors();
    await this.fetchProjects();
    this.fetchProducts();

  },
}
</script>
  
<style scoped>
.trainee-list-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure the modal is on top of other content */
}

h2 {
  margin-top: 30px;
  font-size: 30px;
  margin-bottom: 30px;
}

/* Style your modal content */
.trainee-list-modal {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 800px;
  /* Limit the maximum width of the modal */
  padding: 20px;
  position: relative;
  max-height: 95%;
  top: 30px;
}


.modal-content {
  overflow-y: auto;
  max-height: 90vh;
  /* Enable scrolling within the modal content */
}


.add button {
  width: 200px;
  height: 40px;
  margin-top: 50px;
  margin-bottom: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
}

button.btn:hover {
  background-color: #0c1a61;
  transition: transform 0.2s, box-shadow 0.2s;
}

input.form-control,
select.form-control {
  width: 60%;
  margin-left: 170px;
  display: inline-block;
  margin-bottom: 25px;
  align-items: baseline;
}

.form-group {
  margin-bottom: 15px;
}

label {
  margin-right: 5px;
  position: absolute;
  margin-top: 8px;
}

.form-control {
  border-radius: 5px;
}

.form-control#start_date .form-control#end_date {
  border-radius: 5px;
  width: 25%;
  margin-left: 100px;
  margin-right: 45%;
}

h1 {
  margin-bottom: 20px;
}

.alert-danger {
    color: #ff0019;
    background-color: #f8d7da;
    border: 1px solid #e6b0b6;
}

.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 0rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}
</style>
  