<template>
  <head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
  </head>
  <div>
    <Header />

    <div class="filter-container">
      <label for="productFilter">Practical Element:</label>
      <select v-model="productFilter" id="productFilter" class="filter-select" @change="fetchProjectsByProduct">
        <option value=""></option>
        <option v-for="product in products" :key="product.product_id" :value="product.product_id">{{ product.name }}
        </option>
      </select><br>


      <label for="projectFilter">Session: </label>
      <select v-model="projectFilter" id="projectFilter" class="filter-select" :disabled="!productFilter">
        <option value=""></option>
        <option v-for="project in filteredProjects" :key="project.project_id" :value="project.project_id">{{
          project.name }}</option>
      </select>
      <p v-if="projectFilter === ''">Please select a Practical Element and a Session.</p>
    </div>

    <h1>Trainee Logbook Reports</h1>

    <div class="container-fluid-report" v-if="projectFilter !== ''">
      <div class="pagination-button-container" v-if="projectFilter !== ''">
        <div class="pagination-container">
          <Pagination :totalPages="totalPages" :perPage="perPage" :currentPage="currentPage"
            @page-change="onPageChange" />
        </div>
        <div class="pagination-info">
          Showing {{ startIndex + 1 }}-{{ endIndex }} of {{ this.trainees.length }} trainees
        </div>
      </div>
      <table class="data-table-trainee">
        <thead>
          <tr>
            <th @click="sortBy('trainee_id')" class="skinny-column">
              Trainee ID
              <span v-if="sortField === 'trainee_id'" v-html="getFieldArrow()"></span>
            </th>
            <th @click="sortBy('first_name')">
              First Name
              <span v-if="sortField === 'first_name'" v-html="getFieldArrow('first_name')"></span>
            </th>
            <th>Last Name</th>
            <th>Email</th>
            <th class="skinny-column">Category</th>
            <th class="skinny-column">License No</th>
            <th class="skinny-column-select">Select</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in this.trainees" :key="item.trainee_id">
            <td> {{ item.user_id }} </td>
            <td>{{ item.first_name }}</td>
            <td>{{ item.last_name }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.category }}</td>
            <td>{{ item.license_no }} </td>
            <td class="center-icons">
              <input type="radio" name="selectedTrainee" v-model="selectedTraineeId" :value="item.user_id"
                @change="selectTrainee(item)">
            </td>
          </tr>
        </tbody>
      </table>
      <div class="error-container">
        <p v-if="showSelectTraineeError" class="error-message-html">You must select a trainee.</p>
      </div>
      <span v-if="products.length === 0">No Matching Trainees</span>
    </div>

    <button class="btn btn-primary rounded" id="htmlButton" @click="goTraineeHTML()"
      v-if="projectFilter !== ''">HTML</button>
    <button class="btn btn-primary rounded" id="addproductButton" @click="generatePDF"
      v-if="projectFilter !== ''">Generate PDF</button>
    <button v-if="projectFilter !== ''" class="back-button" @click="goBack">Back</button>

  </div>
</template>
  
<script>
import Header from '../Header.vue';
import axios from 'axios';
import Pagination from '../TablePagination.vue';
import jsPDF from 'jspdf';
import 'jspdf-autotable';


export default {
  name: 'TraineeReports',

  components: {
    Pagination,
    Header,
  },

  data() {
    return {
      products: [],
      projects: [],
      tasks: [],
      trainees: [],
      sortField: 'product_id',
      sortDirection: 'asc',
      currentPage: 1,
      perPage: 10,
      totalPages: 0,
      productFilter: '',
      projectFilter: '',
      selectedTrainee: {
        trainee_id: null,
        first_name: '',
        last_name: '',
        email: '',
        category: '',
        license_no: '',
        date_of_birth: '',
        place_of_birth: '',
      },
      selectedTraineeId: '',
      filteredTraineesLength: 0,
      joinedName: '',
      showSelectTraineeError: false,
    };
  },

  watch: {
    productFilter(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when productFilter changes
        this.calculateTotalPages();
        this.fetchTasksForSelectedProduct(newValue);
      }
    },
    projectFilter(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when productFilter changes
        this.calculateTotalPages();
        this.fetchTraineesByProject(newValue);
      }
    },

  },
  async mounted() {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      let result = await axios.get('/api/products', { headers });
      this.products = result.data;

      let projectResult = await axios.get('/api/projects', { headers });
      this.projects = projectResult.data;

      let taskResult = await axios.get('/api/tasks', { headers });
      this.tasks = taskResult.data;



    } catch (error) {
      console.error('An error occurred while fetching data:', error);
    }
  },

  methods: {
    async fetchTasksForSelectedProduct(product_id) {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        let taskResult = await axios.get('/api/tasks', { headers });
        this.tasks = taskResult.data.filter(task => task.prod_id === product_id);

        console.log(this.tasks);


        const selectedProduct = this.products.find(product => product.product_id === product_id);
        this.selectedProductName = selectedProduct ? selectedProduct.name : '';

        const user_id = localStorage.getItem('user_id');

        const userResult = await axios.get(`/api/users/${user_id}`, { headers });

        const userFullName = userResult.data.first_name + ' ' + userResult.data.last_name;
        this.joinedName = userFullName;

        // Generate PDF content after fetching tasks
      } catch (error) {
        console.error('An error occurred while fetching tasks:', error);
      }
    },
    selectTrainee(trainee) {
      // Find the selected trainee by ID
      const selectedTrainee = this.trainees.find((t) => t.user_id === trainee.user_id);

      if (selectedTrainee) {
        // Populate the selectedTrainee object with the details
        this.selectedTrainee.trainee_id = selectedTrainee.user_id;
        this.selectedTrainee.name = selectedTrainee.first_name + ' ' + selectedTrainee.last_name;
        this.selectedTrainee.email = selectedTrainee.email;
        this.selectedTrainee.category = selectedTrainee.category;
        this.selectedTrainee.license_no = selectedTrainee.license_no;
        this.selectedTrainee.date_of_birth = selectedTrainee.date_of_birth;
        this.selectedTrainee.place_of_birth = selectedTrainee.place_of_birth;
      }
    },
    async fetchTraineeTasks(traineeId) {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        console.log(traineeId);

        // Fetch trainee logbooks using trainee ID
        const traineeLogbooksResponse = await axios.get(`api/trainee/${traineeId}/logbooks`, { headers });
        const traineeLogbooks = traineeLogbooksResponse.data.logbooks; // Assuming it returns an array of logbooks

        console.log(traineeLogbooks);

        // Fetch tasks in each logbook
        const tasksPromises = traineeLogbooks.map(async (logbook) => {
          const logbookId = logbook.logbook_id;
          const logbookEntryResponse = await axios.get(`api/logbookentry/${logbookId}`, { headers });
          return logbookEntryResponse.data.tasks; // Assuming it returns an array of tasks for each logbook
        });

        const tasksArray = await Promise.all(tasksPromises); // Array of arrays of tasks

        // Flatten the tasksArray to a single array
        const tasks = tasksArray.flat();

        return tasks; // Return the tasks array
      } catch (error) {
        console.error('An error occurred while fetching trainee logbooks or tasks:', error);
        throw error; // Propagate the error to the calling code if needed
      }
    },
    async fetchProjectsByProduct() {
      // Fetch projects based on the selected product
      this.onProductSelected();
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.display('unauthorized');
          return;
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get(`/api/products/${this.productFilter}/projects`, { headers });

        if (response.status === 200) {
          this.projects = response.data; // Update the projects array with the fetched projects

        }
      } catch (error) {
        console.error('Error retrieving Sessions:', error);
        // Handle error
      }
    },
    async fetchTraineesByProject(projectId) {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        console.log(projectId);

        const response = await axios.get(`/api/projects/${projectId}/users`, { headers });
        if (response.status === 200) {
          this.trainees = response.data;
          console.log(this.trainees);
        }

        this.totalPages = Math.ceil(this.trainees.length / this.perPage);

      } catch (error) {
        console.error('Error retrieving trainees:', error);
        // Handle error
      }
    },

    onProductSelected() {
      this.productSelected = !!this.productFilter;
    },
    async generateTaskPDF() {
      const pdf = new jsPDF();

      const traineeId = this.selectedTraineeId;
      const traineeTasks = await this.fetchTraineeTasks(traineeId);

      // Now you have all the tasks for the selected trainee
      console.log(traineeTasks);

      // Add the "Tasks for Product:" heading to the PDF content
      pdf.setFont('helvetica', 'bold');
      pdf.setFontSize(12); // Adjust the font size if needed
      pdf.text(`Practical Element: ${this.selectedProductName}`, 10, 15);

      // Add a horizontal line underneath the title
      pdf.line(10, 20, 200, 20);

      const currentDate = new Date();
      const day = currentDate.getDate();
      const month = currentDate.getMonth() + 1; // Months are 0-based, so add 1
      const year = currentDate.getFullYear();

      // Use padStart to add a leading zero if day or month is a single digit
      const formattedDate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;


      pdf.setFont('helvetica', 'bold');
      pdf.setFontSize(10);
      pdf.text('Name:', 10, 30);
      pdf.text('Category:', 100, 30);
      pdf.text('License No:', 10, 40);
      pdf.text('Date of Birth:', 100, 40);
      pdf.text('Date:', 10, 50);

      pdf.setFont('helvetica', 'normal'); // Reset the font weight to normal
      pdf.text(`${this.selectedTrainee.name}`, 30, 30);
      pdf.text(`${this.selectedTrainee.category}`, 135, 30);
      pdf.text(`${this.selectedTrainee.license_no}`, 30, 40);
      pdf.text(`${this.selectedTrainee.date_of_birth}`, 135, 40);
      pdf.text(`${formattedDate}`, 30, 50);

      // Add a horizontal line underneath the title
      pdf.line(10, 60, 200, 60);


      pdf.setFont('helvetica', 'bold');
      pdf.setFontSize(12); // Adjust the font size if needed
      pdf.text(`Tasks:`, 10, 70);
      pdf.setFont('helvetica', 'normal'); // Reset the font weight to normal
      const imgData = require('./astech.png');
      const imgWidth = 22; // Adjust the width of the image
      const imgHeight = 10; // Adjust the height of the image
      const imgX = pdf.internal.pageSize.width - imgWidth - 10; // Adjust the X position
      const imgY = 7.5; // Adjust the Y position
      pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth, imgHeight);


      // Define the table columns
      const columns = ['Task ID', 'Task No', 'Reference', 'Scope', 'Category', 'M/O', 'Est. Time', 'Chapter No.', 'Description'];

      // Initialize an empty array to hold the table data
      const tableData = [];

      for (const item of this.tasks) {
        const taskData = [
          item.task_id,
          item.task_no,
          item.reference,
          item.scope,
          item.category,
          item.manob,
          item.est_time,
          item.chapt_no,
          item.description,
        ];

        // Find the corresponding trainee task
        const traineeTask = traineeTasks.find(tt => tt.task_id === item.task_id);

        if (traineeTask) {
          // If trainee task data exists, add it to the table
          taskData.push(traineeTask.completed);
          taskData.push(traineeTask.ins_so);
        } else {
          // If no trainee task data, add empty values for Completed and Inst_SO
          taskData.push('');
          taskData.push('');
        }

        // Add the row to the table data
        tableData.push(taskData);
      }

      // AutoTable options
      const options = {
        startY: 75,
        margin: { horizontal: 10 },
        styles: {
          lineWidth: 0.1, // Set the border line width
          lineColor: [169, 169, 169], // Set the border color 
          fontSize: 8, // Set the font size for the table
        },
      };

      // Add the table to the PDF document
      pdf.autoTable(columns, tableData, options);


      pdf.setFontSize(11);
      pdf.text(`Signature:`, 38, 280)
      // Add a horizontal line 
      pdf.line(60, 280, 160, 280);


      // Add a horizontal line 
      pdf.line(10, 285, 200, 285);

      // Calculate the total number of pages after rendering the table
      const totalPages = pdf.internal.getNumberOfPages();
      // Add the footer to each page
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(8);
        const pageWidth = pdf.internal.pageSize.width;

        pdf.text(`Author: ${this.joinedName}`, 10, 290);

        const currentDate = new Date();
        const formattedDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`;
        // Calculate the width of the "Printed by" text and the formatted date
        const text = `Printed by: ${this.joinedName} on ${formattedDate}`;
        const textWidth = (pdf.getStringUnitWidth(text) * 8) / 3;

        // Calculate the X position to center the text
        const x = (pageWidth - textWidth) / 2;

        pdf.text(text, x, 290);

        pdf.text(`Page ${i} of ${totalPages}`, pdf.internal.pageSize.width - 25, 290); // Adjust the coordinates as needed
      }


      const blob = pdf.output('blob');
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    },
    generatePDF() {
      if (!this.selectedTraineeId) {
        this.showSelectTraineeError = true; // Show the error message
        console.log(`No trainee selected ${this.showSelectTraineeError}`)
      } else {
        this.showSelectTraineeError = false;
        this.generateTaskPDF();
      }
    },
    goTraineeHTML() {
      if (!this.selectedTraineeId) {
        this.showSelectTraineeError = true; // Show the error message
        console.log(`No trainee selected ${this.showSelectTraineeError}`)
      } else {
        this.showSelectTraineeError = false; // Hide the error message
        const trainee_id = this.selectedTraineeId;
        localStorage.setItem('project_id', this.projectFilter);
        this.$router.push({ name: 'TraineeReportsHTML', params: { trainee_id: trainee_id } });
      }
    },
    getProjectCount(productId) {
      return this.projects.filter(project => project.prod_id === productId).length;
    },
    getTaskCount(productId) {
      return this.tasks.filter(task => task.prod_id === productId).length;
    },
    getFieldArrow(field = 'product_id') {
      if (this.sortField === field) {
        return this.sortDirection === 'asc' ? '▲' : '▼';
      } else {
        return '';
      }
    },
    onPageChange(page) {
      this.currentPage = page;

      this.calculateTotalPages();
    },
    calculateTotalPages() {
      //this.computeFilteredTrainees();
      this.totalPages = Math.ceil(this.trainees.length / this.perPage);
    },
    // computeFilteredTrainees() {
    //   const startIndex = (this.currentPage - 1) * this.perPage;

    //   // Filter trainees based on the selected product and project
    //   const filteredTrainees = this.trainees.filter((trainee) => {
    //     const projectMatch = this.projectFilter === '' || trainee.project_id === this.projectFilter;
    //     return projectMatch;
    //   });

    //   this.filteredTraineesLength = filteredTrainees.length;

    //   return filteredTrainees.slice(startIndex, startIndex + this.perPage);
    // },
  },

  computed: {
    startIndex() {
      return (this.currentPage - 1) * this.perPage;
    },
    endIndex() {
      const end = this.startIndex + this.perPage;
      return end < this.trainees.length ? end : this.trainees.length;
    },
    filteredProjects() {
      if (this.productFilter === '') {
        // If no product is selected, return all projects
        return this.projects;
      } else {
        // Filter projects based on the selected prod_id
        return this.projects.filter(project => project.prod_id === this.productFilter);
      }
    },
    // filteredTrainees() {
    //   return this.computeFilteredTrainees();
    // },
  },

}
</script>
  
  
<style scoped>
span:hover {
  cursor: pointer;
  color: blue;
}

.container-fluid-report {
  padding-top: 190px;
  margin-left: 1%;
  margin-right: 1%;
}

button.btn#addproductButton {
  width: 200px;
  height: 40px;
  margin-right: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  float: right;
}

button.btn#addproductButton:hover {
  background-color: #0f57b4;
  transition: transform 0.2s, box-shadow 0.2s;
}

input[type="text"] {
  width: 100%;
  height: 35px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  margin-bottom: 1px;
}



h1 {
  padding-top: 30px;
  margin-bottom: 110px;
  font-size: 2rem;
  position: absolute;
  text-align: left;
  left: 3%
}

.filter-container {
  margin: 20px 0;
  position: absolute;
  width: 50%;
  top: 100px;
  left: 45%;
  padding: 30px;
  padding-bottom: 10px;
  border: 1px solid grey;
  border-radius: 5px;
  margin-bottom: 40px;
}

label {
  padding: 5px;
  font-size: 16px;

}
.pagination-button-container[data-v-7ff806f6] {
  margin-bottom: 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.filter-select#productFilter {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 80%;
}

.filter-select#projectFilter {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 80%;
}


.data-table-trainee {
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-collapse: collapse;
  margin-left: 0;
  align-items: left;
}

.data-table-trainee th,
.data-table-trainee td {
  text-align: left;
  /* Align content to the left */
  padding-left: 10px;
}

.data-table-trainee td.center-icons {
  text-align: center;
}

.pagination-container {
  margin-bottom: 0;
  display: flex;
  margin-left: 3%;
}

.error-container {
  text-align: center;
  background-color: rgba(255, 109, 109, 0.534);
  margin-right: 5%;
  margin-left: 5%;
  border-radius: 2px;
}

.error-message-html {
  color: rgb(231, 3, 3);
  font-size: 14px;
}


.skinny-column {
  width: 10%;
}

.data-table-trainee th.skinny-column-select {
  width: 7%;
  text-align: center;
}

.pagination-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.pagination-info {
  font-size: 14px;
  color: #666;
  /* Adjust the color as needed */
  position: absolute;
  top: 310px;
  left: 50%;
  transform: translateX(-50%);
}

.filter-select#projectFilter:disabled {
  cursor: not-allowed;
}

button.btn#htmlButton {
  width: 100px;
  height: 40px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  margin-right: 20px;
  float: right;
}

button.btn#htmlButton:hover {
  background-color: #0f57b4;
  transition: transform 0.2s, box-shadow 0.2s;
}

.back-button {
  /* Adjust the right position as needed */
  width: 100px;
  border-radius: 5px;
  background-color: #c1e0bf;
  float: left;
  margin-left: 30px;
  border: 2px solid black;
  padding: 3px;
}

.back-button:hover {
  background-color: #abc7a9;
  cursor: pointer;
}
</style>
  