<template>
  <div>
    <page-header></page-header>
    <div class="content">
      <div v-if="viewMode === 'projects'">
        <h1 style="text-align: center;">Your Projects:</h1>
        <div v-if="projects.length === 0" class="no-projects-message">
          <p style="color: rgb(216, 4, 4)">You have no assigned projects.</p>
        </div>
        <div v-else class="project-container">
          <div v-for="project in projects" :key="project.project_id" class="project-box"
            @click="selectProject(project)">
            <div class="project-card">
              <div>
                <h2 style="font-size: 18px;"><b>ID: {{ project.project_id }} </b> {{ project.name }} </h2>
              </div>
              <div>
                <p style="margin-top: 10px"><b>Start Date:</b> {{ project.start_date }}</p>
                <p style="margin-top: 10px"><b>End Date:</b> {{ project.end_date }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="viewMode === 'logbooks'">
        <button @click="multiSignOff" style="font-size: 18px;" class="multiSignOffButton">Multi Sign Off</button>
        <button @click="multiEnable" style="font-size: 18px;" class="multiEnableButton">Multi Enable</button>
        <button @click="clearLogbooks" class="back-button">Back to Projects</button>
        <h1>Logbooks for Project: {{ selectedProject.project_id }}</h1>
        <div v-if="logbooks.length === 0" class="no-logbooks-message">
          <p style="color: rgb(216, 4, 4)">There are no logbooks assigned to this project.</p>
        </div>
        <div v-else class="product-container">
          <div v-for="logbook in logbooks" :key="logbook.logbook_id" class="logbook-box"
            @click="openLogbook(logbook.logbook_id, logbook.project_id, logbook.product_id, logbook.trainee_id)">
            <div class="product-card">
              <h2 style="font-size: 18px;"><b>ID: {{ logbook.logbook_id }} </b> {{ logbook.name }} </h2>
              <span class="product-name">{{ logbook.productName }}</span>
              <div class="task-summary">
                <p>Total Tasks: {{ logbook.totalTasks }}</p>
                <p>Enabled Tasks: {{ logbook.enabledTasks }}</p>
                <p>Incomplete Tasks: {{ logbook.incompleteTasks }}</p>
              </div>
              <div class="emails">
                <p class="trainee-email">Trainee: {{ logbook.traineeName }}</p>
                <p class="trainee-email">Email: {{ logbook.traineeEmail }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from './InstructorHeader.vue';
import axios from 'axios';

export default {
  name: 'InstructorHome',
  data() {
    return {
      viewMode: 'projects',
      projects: [],
      logbooks: [],
      currentTime: '',
      loggedInUser: '',
      showModal: false,
      logbook: {
        totalTasks: 0,
        incompleteTasks: 0,
        enabledTasks: 0,
      },
    };
  },
  components: {
    PageHeader,
  },
  async mounted() {
    try {
      const instructorId = localStorage.getItem('user_id');
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      // Fetch list of projects associated with the instructor
      const result = await axios.get(`/api/users/${instructorId}/projects`, { headers });
      this.projects = result.data;

      console.log('projects', this.projects);

      const loggedInUser = localStorage.getItem('username');
      this.loggedInUser = loggedInUser;

    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Unauthorized, redirect to login page
        this.$router.push({ name: 'LoginPage' });
      }
    }
  },
  methods: {
    clearLogbooks() {
      this.viewMode = 'projects';
      this.logbooks = [];
    },
    async selectProject(project) {
      this.selectedProject = project;
      this.viewMode = 'logbooks';

      // Fetch logbooks for the selected project
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      try {
        const result = await axios.get(`/api/projects/${project.project_id}/logbooks`, { headers });
        this.logbooks = result.data;

        // Enhance logbook data
        const productResponse = await axios.get(`/api/products`, { headers });
        const traineeResponse = await axios.get(`/api/users`, { headers });
        const tasksResult = await axios.get(`/api/tasks`, { headers });
        const logbookEntriesResult = await axios.get(`/api/logbookentry`, { headers });

        this.logbooks.forEach(logbook => {
          const product = productResponse.data.find(product => product.product_id === logbook.product_id);
          const trainee = traineeResponse.data.find(trainee => trainee.user_id === logbook.trainee_id);
          const tasks = tasksResult.data.filter(task => task.prod_id === logbook.product_id);
          const entries = logbookEntriesResult.data.filter(entries => entries.logbook_id === logbook.logbook_id);

          if (entries && entries.length > 0) {
            logbook.incompleteTasks = entries.filter(entries => entries.completed == null).length;
            logbook.enabledTasks = entries.length;
          } else {
            logbook.incompleteTasks = 0;
            logbook.enabledTasks = 0;
          }
          if (tasks && tasks.length > 0) {
            logbook.tasks = tasks;
            logbook.totalTasks = tasks.length;
          }
          if (trainee) {
            logbook.traineeName = trainee.first_name + ' ' + trainee.last_name;
            logbook.traineeEmail = trainee.email;
          }
          if (product) {
            logbook.productName = product.name;
          }
        });
      } catch (error) {
        console.error('Error fetching logbooks:', error);
      }
    },
    async openLogbook(logbookId, projectId, productId, traineeId) {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      localStorage.setItem('logbook_id', logbookId);
      localStorage.setItem('project_id', projectId);
      localStorage.setItem('productId', productId);
      localStorage.setItem('trainee_id', traineeId);
      if (!token) {
        this.$router.push({ name: 'examinerLogin' });
      }
      try {
        // Fetch trainee name
        const response = await axios.get(`/api/users/${traineeId}`, { headers });
        const trainee_name = response.data.first_name + ' ' + response.data.last_name;
        localStorage.setItem('traineeName', trainee_name);

        const usersResponse = await axios.get(`/api/logbooks/${logbookId}/users`, { headers });
        const instructors = usersResponse.data.filter(user => user.role === 'instructor');
        const instructorIds = instructors.map(instructor => instructor.user_id);
        localStorage.setItem('instructorIds', JSON.stringify(instructorIds));

        this.$router.push({ name: 'InstructorLogbook', params: { logbookId, productId, traineeId } });
      } catch (error) {
        console.error('Error fetching logbook or trainee data:', error);
      }
    },
    async multiSignOff() {
      this.$router.push({
        name: 'MultiSignOff',
        params: { projectId: this.selectedProject.project_id }
      });
    },
    async multiEnable() {
      this.$router.push({
        name: 'MultiEnable',
        params: { projectId: this.selectedProject.project_id }
      });
    }
  },
};
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  /* Add space from the top */
}

.product-name {
  font-size: 1rem;
  /* Set the font size for the product name */
  color: grey;
  /* Set the text color to grey */
  margin-top: 40px;
  /* Add some space between the logbook name and product name */
  position: absolute;
  margin-left: 1%;
  border: none;
}

.trainee-email,
.instructor-email {
  font-size: 0.9rem;
  /* Set the font size for the emails */
  color: grey;
  /* Set the text color to grey */
  margin: 0;
  margin-top: 8px;
  /* Reset margin to remove any default spacing */
}

.product-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 0;
  width: 90%;
}

h2 {
  font-size: 1.3rem;
  margin-bottom: 25px;
}

h1 {
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 20px;
  padding-top: 0px;
  top: 7px;
}

.product-box {
  margin: 10px;
  width: 100%;
  height: 80px;
}

.viewbutton-container {
  display: flex;
  justify-content: flex-start;
  /* Align the button to the left within the container */
  width: 100%;
  /* Make the container take up the full width */
  margin-top: 0;
}

.product-card {
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2),
    /* Right and bottom */
    -3px -3px 3px rgba(0, 0, 0, 0.2),
    /* Left and top */
    0 0 3px rgba(0, 0, 0, 0.2);
  /* Inner shadow */
  transition: transform 0.2s ease-in-out;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90vw;
  height: 95px;
  margin-bottom: 2px;
  /* Center content horizontally within the card */
}

.product-card h2 {
  margin-left: 0;
}

.product-card:hover {
  transform: scale(1.03);
}

.view-button {
  /* Rounded corners for the button */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Shadow effect */
  background-color: #0a407a;
  /* Button background color */
  color: #ffffff;
  /* Button text color */
  padding: 10px 15px;
  width: 20%;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: auto;
  margin-bottom: 1px;
  /* Add spacing between the text and the button */
}

.task-summary {
  margin-top: 15px;
  text-align: center;
}

.task-summary p {
  margin-bottom: 3px;
  /* Adjust as needed to reduce the gap */
}

.project-container {
  margin-top: 0;
  width: 95%;
}

.project-card {
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2),
    /* Right and bottom */
    -3px -3px 3px rgba(0, 0, 0, 0.2),
    /* Left and top */
    0 0 3px rgba(0, 0, 0, 0.2);
  /* Inner shadow */
  transition: transform 0.2s ease-in-out;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90vw;
  height: 85px;
  margin-bottom: 2px;
  /* Center content horizontally within the card */
}

.project-card h2 {
  margin-left: 0;
  margin-top: 0px;
  margin-bottom: 0px;
}

.project-card:hover {
  transform: scale(1.03);
}

p {
  margin-top: 0px;
  margin-bottom: 0px;
}

.back-button {
  position: absolute;
  /* Adjust the top position as needed */
  top: 105px;
  right: 30px;
  /* Adjust the right position as needed */
  width: 150px;
  border-radius: 5px;
  background-color: #c1e0bf;
  font-size: 14px;
  border: 2px solid black;
  padding: 3px;
}

.back-button:hover {
  background-color: #abc7a9;
  cursor: pointer;
}

.multiSignOffButton {
  margin-top: 0px;
  position: absolute;
  border-radius: 5px;
  width: 200px;
  height: 45px;
  background-color: #003f86;
  color: white;
  margin-left: 10px;
  right: 200px;
  border: 1px solid #01162e;
}

.multiSignOffButton:hover {
  background-color: #012c5e;
}

.multiEnableButton {
  margin-top: 0px;
  position: absolute;
  border-radius: 5px;
  width: 200px;
  height: 45px;
  background-color: #003f86;
  color: white;
  margin-left: 10px;
  right: 425px;
  border: 1px solid #01162e;
}

.multiEnableButton:hover {
  background-color: #012c5e;
}

h1 {
  font-size: 1.75rem;
  margin-top: 0;
  margin-bottom: 20px;
  padding-top: 10px;
  top: 7px;
  text-align: left;
}

.disabled-checkbox {
  cursor: not-allowed;
  opacity: 0.6;
}
</style>
