<template>
  <div>
    <Header />
    <div v-if="isOffline" class="offline-box">
      <p>Offline Mode</p>
    </div>
    <div v-if="isSyncing" class="syncing-box">
      <p>You're online! Syncing data...</p>
    </div>
    <div class="table-section-instructor">
      <div class="logbook-name">
        <table class="logbook-name-table">
          <tr>
            <td class="InslogbookName">{{ logbookName }}</td>
            <td style="border:none; width: 160px;"><button class="inspdfButton"
                @click="openSignatureModal">Signature</button></td>
            <td style="width: 160px;"><button @click="showPDFReport" class="inspdfButton">Generate PDF</button></td>
            <td style="width: 160px;"><button @click="openTraineeProgressModal" class="inspdfButton">Progress</button>
            </td>
          </tr>
        </table>
      </div>
      <div class="ins-pagination-button-container">
        <div class="ins-pagination-container">
          <Pagination :total-pages="totalPages" :per-page="perPage" :current-page="currentPage"
            @page-change="onPageChange" />
        </div>
        <div class="ins-pagination-info">
          Showing {{ startIndex + 1 }}-{{ endIndex }} of {{ filteredTasks.length }} Tasks
        </div>
      </div>
      <div class="signoff-buttons">
        <button @click="toggleMultiSignOffMode" class="multi-sign-off-button" id="select-all">
          {{ multiSignOffMode ? 'De-select All' : 'Select All' }}
        </button>
        <button @click="signOffAll" class="multi-sign-off-button" id="sign-off-all">Sign Off All</button>
      </div>
      <table class="data-table-instructor-logbook">
        <thead>
          <tr>
            <th class="task-no">Task No</th>
            <th class="scope">Scope
              <select v-model="selectedscope">
                <option value="All">All</option>
                <option v-for="scope in uniquescopes" :key="scope" :value="scope">{{ scope }}</option>
              </select>
            </th>
            <!-- <th class="category">Category
              <select v-model="selectedCategory">
                <option value="All">All</option>
                <option v-for="category in uniquecategories" :key="category" :value="category">{{ category }}</option>
              </select>
            </th> -->
            <th class="MO">Manob <br>
              <select v-model="selectedMO">
                <option value="All">All</option>
                <option v-for="mo in uniqueMOs" :key="mo" :value="mo">{{ mo }}</option>
              </select>
            </th>
            <!--<th class="sect-id">Sect ID</th>-->
            <th class="chapt-id">Chapt No
              <select v-model="selectedChapter">
                <option value="All">All</option>
                <option v-for="chapter in uniqueChapters" :key="chapter" :value="chapter">{{ chapter }}</option>
              </select>
            </th>
            <!-- <th v-if="isAssessorSelected" class="is_exam">Being Assessed</th> -->
            <th v-if="!isAssessorSelected" class="enable">Enable Tasks</th>
            <th v-if="isAssessorSelected" class="enable">Assessment Complete</th>
            <th class="description">Description</th>
            <th class="completed">Completed
              <select id="completed-filter" v-model="selectedCompleted" style="width:100%">
                <option value="All tasks">All tasks</option>
                <option value="completed tasks" style="width: 8%">Completed tasks</option>
                <option value="not completed tasks">Incomplete tasks</option>
                <option value="enabled tasks">Enabled tasks</option>
                <option id="examinable" value="examinable tasks">Examinable tasks</option>
              </select>
            </th>
            <th class="inssign" style="width: 8%">Inst. Sign Off
              <select v-model="selectedSignOff" style="width:100%">
                <option value="All tasks">All tasks</option>
                <option value="signed off tasks">Signed Off</option>
                <option value="not signed off tasks">Not Signed Off</option>
              </select>
            </th>
            <th v-if="!isAssessorSelected" class="publicnotes">Public Notes</th>
            <th v-if="!isAssessorSelected" class="privatenotes">Private Notes</th>
            <!-- <th class="publicnotes">Assess</th> -->
          </tr>
        </thead>

        <!-- TABLE BODY -->
        <tbody>
          <template v-for="(group, chaptNo) in paginatedTasks" :key="chaptNo">
    <!-- Row for the Chapter Name -->
    <tr class="chapter-row">
      <td colspan="100%" class="chapter-name-cell">{{ group.name }}</td>
    </tr>
          <tr v-for="(task, index) in group.tasks" :key="index"
            :style="{ background: task.P1 === 1 ? customColor : 'inherit' }">
            <td @click="openInsTaskLongFormModal(task)" style="color: blue; text-decoration: underline;">{{ task.task_no
              }}
            </td>
            <td>{{ task.scope }}</td>
            <!-- <td>{{ task.category }}</td> -->
            <td>{{ task.manob }}</td>
            <td>{{ task.chapt_no }}</td>
            <!-- <td>
              <input type="checkbox" v-model="task.is_exam" @change="toggleIsExam(task)" class="custom-checkbox"
                id="center-icons">
            </td> -->
            <!-- <td v-if="isAssessorSelected">
          <input type="checkbox" v-model="task.being_assessed" @change="toggleBeingAssessed(task)" :disabled="task.is_assessed">
        </td> -->
            <td v-if="!isAssessorSelected">
              <input type="checkbox" v-model="task.enabled" @change="toggleEnabled(task)" :disabled="task.completed">
              <!-- <input type="checkbox" v-model="task.enabled" @change="addNewTaskToEnable(task)" :disabled="task.completed"> -->
            </td>
            <td v-else>
              <input type="checkbox" v-model="task.is_assessed" @change="toggleIsAssessed(task)">
            </td>
            <!-- <td @click="openTaskDescriptionModal(task)"
              :class="{ 'Description-data': task.is_exam, 'Other-description': !(task.is_exam) }">
              {{ task.description }}
            </td> -->
            <td v-if="!task.is_exam">
              <div :style="{ color: task.is_submitted === 3 ? 'green' : 'blue' }">
                {{ task.description }}
              </div>
            </td>
            <td id="description" v-else @click="openTaskAssessmentModal(task)">
              <div :style="{ color: task.is_submitted === 3 ? 'green' : 'blue' }">
                {{ task.description }}
              </div>
              <div :style="{ color: task.is_submitted === 3 ? 'green' : 'red' }">
                <b>
                  {{ task.is_submitted === 3 ? '*Assessed' :
                    task.is_submitted === 2 ? '*Trainee Signature Required' :
                      (task.is_selected ? '*Selected for Assessment' : '*Examinable') }}
                </b>
              </div>
            </td>
            <td v-if="task.completed">
              <div>{{ task.completed }}</div>
              <div v-if="task.t_signature_id" class="signature-container" style="max-height: 40px;">
                <img :src="findSignatureUrl(task.t_signature_id)" class="signature-image" alt="Signature">
              </div>
            </td>
            <td v-else>
              <div>{{ task.completed }}</div>
            </td>
            <td v-if="multiSignOffMode && !task.ins_so">
              <button class="instructor_signoff" :class="{ 'disabled-signoff': !task.completed }"
                :disabled="!task.completed" @click.prevent="confirmSignOff(task)">SIGN OFF</button>
              <input type="checkbox" v-if="task.completed && !task.ins_so" v-model="task.selectedForSignOff"
                @change="toggleSignOff(task)" />
            </td>
            <td v-else>
              <div v-if="task.ins_so">
                {{ task.ins_so }}
                <div v-if="task.ins_signature_id" class="signature-container" style="max-height: 40px;">
                  <img :src="findInstSignatureUrl(task.ins_signature_id)" class="signature-image" alt="Signature">
                </div>
              </div>
              <div v-else>
                <button v-if="!isAssessorSelected" class="instructor_signoff"
                  :class="{ 'disabled-signoff': !task.completed }" :disabled="!task.completed"
                  @click.prevent="confirmSignOff(task)">SIGN OFF</button>
              </div>
            </td>

            <td v-if="!isAssessorSelected">
              <button class="notesButton" @click="openEditor(task.task_id, true)">
                <i :class="{
      'fas fa-sticky-note': true,
      'icon-green': publicNotes[task.task_id] && publicNotes[task.task_id].trim() !== '',
      'icon-grey': !publicNotes[task.task_id] || publicNotes[task.task_id].trim() === ''
    }"></i>
              </button>
            </td>
            <td v-if="!isAssessorSelected">
              <button class="notesButton" @click="openEditor(task.task_id)">
                <i :class="{
      'fas fa-sticky-note': true,
      'icon-green': privateNotes[task.task_id] && privateNotes[task.task_id].trim() !== '',
      'icon-grey': !privateNotes[task.task_id] || privateNotes[task.task_id].trim() === ''
    }"></i>
              </button>
            </td>
            <!-- <td>
  <div v-if="task.is_assessed">
    <a style="text-decoration: underline; color: blue; cursor: pointer;">View Results</a>
  </div>
  <div v-if="task.being_assessed && !task.is_assessed">
    <a style="text-decoration: underline; color: blue; cursor: pointer;">Assess Trainee</a>
  </div>
</td> -->
          </tr>
        </template>
        </tbody>
      </table>
      <div class="evaluationSection">
        <button class="evaluationButton" @click="openEvaluationModal()">Evaluation Sheet</button>
      </div>
      <QuillEditor v-if="showQuillEditor" :task-id="currentTaskId" :initialContent="getCurrentTaskNotes"
        :displayTaskId="currentTaskId" @saveNotes="closeQuillEditor">
      </QuillEditor>
      <instroubleshootModal v-if="showTroubleshootModal" :task-id="currentTaskId" :logbook-id="logbookId"
        :troubleshoot="currentTroubleshoot" :task-explanation="currentTaskExplanation" :chapt-no="currentChaptNo"
        @close="closeTroubleshootModal">
      </instroubleshootModal>
      <TaskDescriptionModal v-if="showTaskDescriptionModal" :task-id="currentTaskId" :logbook-id="logbookId"
        :task-explanation="currentTaskExplanation" @close="closeTaskDescriptionModal"></TaskDescriptionModal>
      <task-assessment-modal v-if="showTaskAssessmentModal" :trainee="trainee" :tasks-count="MTCount"
        :completed-tasks="completedMTCount || 0" :taskAssessmentId="taskAssessmentId" :logbook-id="logbookId"
        :fetched-data="fetchedTaskAssessmentData" :attemptNo="attemptNo" :selectedTask="selectedTask"
        @close="closeTaskAssessmentModal"></task-assessment-modal>
      <InsTaskLongFormModal v-if="showInsTaskLongFormModal" :task="currentTask" :logbook-id="logbookId"
        @close="closeInsTaskLongFormModal"></InsTaskLongFormModal>
      <TraineeProgressModal v-if="showTraineeProgressModal" :logbook-id="logbookId" :merged-tasks="mergedTasks"
        @close="closeTraineeProgressModal"></TraineeProgressModal>
      <evaluation-form-modal v-if="showEvaluationFormModal" :tasks-count="tasksCount" :completed-tasks="tasksCompleted"
        :evaluation-id="evaluationId" :logbook-id="logbookId" :fetched-data="fetchedEvaluationData"
        @close="closeEvaluationModal"></evaluation-form-modal>
      <signature-modal v-if="showSignatureModal" @close="closeSignatureModal"></signature-modal>
      <logbook-report v-if="displayLogbookReport" :filteredTasks="mergedTasks" :logbookName="logbookName"
        :logbookId="logbookId" :selectedTrainee="trainee" />
    </div>
  </div>
</template>

<script>
import Header from './InstructorHeader.vue';
import axios from 'axios';
import './InstructorLogbook.css';
import Pagination from '@/components/Examiner/TablePagination.vue';
import QuillEditor from '@/components/notes/quill.vue';
import LogbookReport from './InstructorLogbookReport.vue';
import instroubleshootModal from '@/components/Instructor/InsTroubleshootModal.vue';
import TaskDescriptionModal from '@/components/Trainee/TaskDescriptionModal.vue';
import TaskAssessmentModal from '@/components/Instructor/TaskAssessmentModal.vue';
import InsTaskLongFormModal from '@/components/Instructor/InsTaskLongFormModal.vue';
import TraineeProgressModal from '@/components/Trainee/TraineeProgressModal.vue';
import EvaluationFormModal from '@/components/Instructor/EvaluationFormModal.vue';
import SignatureModal from '@/components/Examiner/SignatureModal.vue';
import Localbase from 'localbase';
let db = new Localbase('myDatabase');

export default {
  name: 'InstructorLogbook',
  components: {
    Pagination,
    Header,
    QuillEditor,
    instroubleshootModal,
    TaskDescriptionModal,
    InsTaskLongFormModal,
    TraineeProgressModal,
    TaskAssessmentModal,
    EvaluationFormModal,
    SignatureModal,
    LogbookReport,
  },
  data() {
    return {
      logbooks: [],
      tasks: [],
      trainee: [],
      logbookName: '',
      productId: localStorage.getItem('productId') || null,
      logbookId: localStorage.getItem('logbook_id'),
      mergedTasks: [],
      editorContent: '',
      showQuillEditor: false,
      showTroubleshootModal: false,
      showTaskDescriptionModal: false,
      showInsTaskLongFormModal: false,
      showTraineeProgressModal: false,
      showTaskAssessmentModal: false,
      showEvaluationFormModal: false,
      showSignatureModal: false,
      displayLogbookReport: false,
      fetchedEvaluationData: null,
      tasksCount: 0,
      privateNotes: {},
      publicNotes: {},
      notesContent: '',
      showDropdown: false,
      selectedMO: 'All',
      selectedCompleted: 'All tasks',
      selectedLevel: 'All',
      selectedscope: 'All',
      selectedisexam: 'All',
      selectedCategory: 'All',
      selectedChapter: 'All',
      selectedSignOff: 'All tasks',
      isSyncing: false,
      isOffline: !navigator.onLine,
      selectedTasks: [],
      multiSignOffMode: false,
      filteredTasksLength: 0,
      currentPage: 1,
      perPage: 10,
      customColor: 'rgb(218, 248, 252, 0.7)',
      MTCount: '',
      completedMTCount: '',
      newTasksToEnable: [],
      oldTasksToEnable: [],
      updateTimer: null, // Timer for batching updates
      updateDelay: 1000,
      signatureId: '',
      instSignatureId: '',
      user_id: null,
      instructorSignatures: {},
      traineeSignatureUrl: null,
      signatureUrl: null,
      signatures: [],
      currentTask: null,
      fetchedTaskAssessmentData: [],
      taskAssessmentId: null,
      selectedTrainee: {},
      task_assessments: [],
      chapters: [],
    };
  },
  computed: {
    getCurrentTaskNotes() {
      const noteSource = this.isNotePublic ? this.publicNotes : this.privateNotes;
      return noteSource[this.currentTaskId] || '';
    },
    filteredTasks() {
      return this.mergedTasks.filter(task => {
        this.currentPage = 1;
        const moMatches = this.selectedMO === 'All' || task.manob === this.selectedMO;
        const completedMatches =
          this.selectedCompleted === 'All tasks' ||
          (this.selectedCompleted === 'completed tasks' && task.completed) ||
          (this.selectedCompleted === 'not completed tasks' && !task.completed) ||
          (this.selectedCompleted === 'enabled tasks' && task.enabled) ||
          (this.selectedCompleted === 'examinable tasks' && task.is_exam);
        const SignedOffMatches =
          this.selectedSignOff === 'All tasks' ||
          (this.selectedSignOff === 'signed off tasks' && task.ins_so) ||
          (this.selectedSignOff === 'not signed off tasks' && !task.ins_so);
        //const levelMatches = this.selectedLevel === 'All' || task.level === parseInt(this.selectedLevel);
        const scopeMatches = this.selectedscope === 'All' || task.scope === this.selectedscope;
        const chapterMatches = this.selectedChapter === 'All' || task.chapt_no === this.selectedChapter;
        const categoryMatches = this.selectedCategory === 'All' || task.category === this.selectedCategory;
        const isexamMatches = this.selectedisexam === 'All' || task.is_exam === this.selectedisexam;

        return moMatches && completedMatches && SignedOffMatches && scopeMatches && chapterMatches && categoryMatches && isexamMatches;
      });
    },
    groupedTasks() {
  return Object.entries(
    this.filteredTasks.reduce((groups, task) => {
      const chapterName = this.getChapterName(task.chapt_no);
      if (!groups[task.chapt_no]) {
        groups[task.chapt_no] = { name: chapterName, tasks: [] };
      }
      groups[task.chapt_no].tasks.push(task);
      return groups;
    }, {})
  )
    .map(([chaptNo, group]) => ({
      chaptNo,
      ...group,
    }))
    .sort((a, b) => parseInt(a.chaptNo, 10) - parseInt(b.chaptNo, 10)); // Sort numerically by chapter number
},

paginatedTasks() {
  // Paginate the filtered tasks before grouping them by chapter
  const start = this.startIndex;
  const end = this.endIndex;

  // Get the paginated filtered tasks
  const paginatedFilteredTasks = this.filteredTasks.slice(start, end);

  // Now, group the tasks by chapter
  return Object.entries(
    paginatedFilteredTasks.reduce((groups, task) => {
      const chapterName = this.getChapterName(task.chapt_no);
      if (!groups[task.chapt_no]) {
        groups[task.chapt_no] = { name: chapterName, tasks: [] };
      }
      groups[task.chapt_no].tasks.push(task);
      return groups;
    }, {})
  )
    .map(([chaptNo, group]) => ({
      chaptNo,
      ...group,
    }))
    .sort((a, b) => parseInt(a.chaptNo, 10) - parseInt(b.chaptNo, 10)); // Sort numerically by chapter number
},
    uniqueChapters() {
      const chapters = this.tasks.map(task => task.chapt_no);
      return [...new Set(chapters)]; // This will remove any duplicates and return unique values.
    },
    uniqueMOs() {
      const MOs = this.tasks.map(task => task.manob);
      return [...new Set(MOs)];
    },
    uniqueLevels() {
      const levels = this.tasks.map(task => task.level);
      return [...new Set(levels)];
    },
    uniquescopes() {
      const scopes = this.tasks.map(task => task.scope);
      return [...new Set(scopes)];
    },
    uniquecategories() {
      const categories = this.tasks.map(task => task.category);
      return [...new Set(categories)];
    },
    totalPages() {
      return Math.ceil(this.filteredTasks.length / this.perPage);
    },
    startIndex() {
      return (this.currentPage - 1) * this.perPage;
    },
    endIndex() {
      const end = this.startIndex + this.perPage;
      return end < this.filteredTasks.length ? end : this.filteredTasks.length;
    },
    localIsAssessorSelected: {
      get() {
        return this.isAssessorSelected;
      },
      set(newValue) {
        this.$emit('update:isAssessorSelected', newValue);
      }
    }
  },

  methods: {
    getChapterName(chapt_no) {
      const chapter = this.chapters.find((ch) => ch.chapt_no === chapt_no);
      return chapter ? `Chapter ${chapt_no}: ${chapter.name}` : `Chapter ${chapt_no}`;
    },
    async addSigToTasks() {
  try {
    const logbookId = this.logbookId;
    const tasksToUpdate = [];

    // Filter tasks that need signature
    const filteredSigTasks = this.mergedTasks.filter(task => {
      return task.completed !== null && task.ins_signature_id === null;
    });

    // Create an array of tasks to update
    for (const task of filteredSigTasks) {
      tasksToUpdate.push({
        logbook_id: parseInt(logbookId),
        task_id: parseInt(task.task_id),
        ins_signature_id: parseInt(localStorage.getItem('inst_sign_id'))
      });
    }

    console.log('inst_sign_id');
    console.log(parseInt(localStorage.getItem('inst_sign_id')));

    if (tasksToUpdate.length === 0) {
      console.log('No tasks require signature.');
      return;
    }

    const token = localStorage.getItem('token');
    const headers = { Authorization: `Bearer ${token}` };

    // Make an Axios POST request to the batchUpdate API endpoint
    const response = await axios.post('/api/logbookentry/batch-update', {
      logbook_entries: tasksToUpdate
    }, { headers });

    console.log("Success in updating logbook entries:", response);

    // Fetch updated data after successful batch update
    this.allSignatures();
    this.fetchData();

  } catch (error) {
    console.error('Error updating logbook entries:', error);
  }
},
    findSignatureUrl(signatureId) {
    const signature = this.signatures.find(sig => sig.id === signatureId);
    if (signature && signature.user_image) {
      return `data:image/jpeg;base64,${signature.user_image}`;
    } else {
      return null;
    }
  },
  findInstSignatureUrl(instSignatureId) {
    const signature = this.signatures.find(sig => sig.id === instSignatureId);
    if (signature && signature.user_image) {
      return `data:image/jpeg;base64,${signature.user_image}`;
    } else {
      return null;
    }
  },
    hasNotes(taskId) {
      return this.publicNotes[taskId] || this.privateNotes[taskId];
    },
    onPageChange(newPage) {
      this.currentPage = newPage;
    },
    addNewTaskToEnable(task) {
    this.newTasksToEnable.push(task);
    // If timer already exists, clear it

    console.log(this.newTasksToEnable);
    if (this.updateTimer) {
      clearTimeout(this.updateTimer);
    }
        // Start a new timer to batch the updates
        this.updateTimer = setTimeout(() => {
      this.toggleEnabled(task);
    }, this.updateDelay);
  },
//   async toggleEnabled(task) {
//   const logbookId = localStorage.getItem('logbook_id');
//   const token = localStorage.getItem('token');
//   const headers = { Authorization: `Bearer ${token}` };

//   if (!task.enabled) {
//     const confirmation = window.confirm('Are you sure you want to disable this task?');
//     if (!confirmation) {
//       task.enabled = true;
//       return;
//     }
//   }
//   try {
//     if (!task.enabled) {
//       // Disable the task
//       await axios.delete(`/api/logbookentry/delete/${logbookId}/${task.task_id}`, { headers });
//     } else {
//       // Enable the task or create a new record if it doesn't exist
//       const tasksData = this.newTasksToEnable.map(task => ({
//         logbook_id: logbookId,
//         task_id: task.task_id,
//         enabled: task.enabled, // or whatever property you need to update
//         is_submitted: false,
//       }));

//         console.log(tasksData);

//         await axios.post(`/api/logbookentry/batch`, {tasks: tasksData}, { headers });

//         this.newTasksToEnable.forEach(task => {
//         task.enabled = true; // Update local state
//               // Clear tasksToUpdate array
//       this.newTasksToEnable = [];
//       });
//     }
//   } catch (error) {
//     console.error("Error toggling task:", error);
//   }
// },
    async toggleEnabled(task) {
  const logbookId = localStorage.getItem('logbook_id');
  const token = localStorage.getItem('token');
  const headers = { Authorization: `Bearer ${token}` };

  if (!task.enabled) {
    const confirmation = window.confirm('Are you sure you want to disable this task?');
    if (!confirmation) {
      task.enabled = true;
      return;
    }
  }
  try {
    if (!task.enabled) {
      // Disable the task
      await axios.delete(`/api/logbookentry/delete/${logbookId}/${task.task_id}`, { headers });
    } else {
      // Enable the task or create a new record if it doesn't exist
      await axios.post(`/api/logbookentry`, {
        logbook_id: logbookId,
        task_id: task.task_id,
        enabled: true,
        is_submitted: false,
        is_selected: 0,
      }, { headers });
    }
  } catch (error) {
    console.error("Error toggling task:", error);
  }
},
    async toggleIsExam(task) {
      if (!task.is_exam) {
        const confirmation = window.confirm('Are you sure you want to disable this task?');
        if (!confirmation) {
          task.is_exam = true; // If the user cancels the confirmation, keep the checkbox checked
          return;
        }
      }
      // Toggle the is_exam value
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        await axios.put(`/api/tasks/${task.task_id}`, {
          is_exam: task.is_exam // Send the value to the backend
        }, { headers });


        // No need to manually update the local state because Vue's reactivity system will do it
      } catch (error) {
        console.error("Error updating 'is_exam' property:", error);
        // If the update fails, revert the checkbox state
        this.$set(task, 'is_exam', !task.is_exam);
      }
    },

    updateCurrentTime() {
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');
      this.currentTime = `${hours}:${minutes}:${seconds}`;
    },
    async setTimestamp(task) {
      const currentDate = new Date();
      const formattedDate = this.formatDate(currentDate);
      task.ins_so = formattedDate;
      task.isReadOnly = true;

      const logbookId = localStorage.getItem('logbook_id');
      const userId = localStorage.getItem('user_id');  // Retrieve user_id from localStorage

      if (!navigator.onLine) {
        // Save to Localbase when offline
        db.collection('InsCompletedTasks').doc(String(task.task_id)).set({
          taskId: String(task.task_id),  // Convert task_id to string
          ins_so: formattedDate,
          logbookId: logbookId,
          userId: userId  // Also save the user_id offline
        }).then(() => {
          console.log('Task saved offline');
        });
      } else {
        try {
          const token = localStorage.getItem('token');
          const headers = { Authorization: `Bearer ${token}` };
          const response = await axios.put(`/api/logbookentry/${logbookId}/${task.task_id}`, {
            ins_so: formattedDate,
            instructor_id: userId,
            ins_signature_id: parseInt(localStorage.getItem('inst_sign_id')),
          }, { headers });
          console.log("Success in updating logbook entry:", response);
          // Handle the response if needed (e.g. check if the update was successful)
          if (parseInt(localStorage.getItem('inst_sign_id'))) {
          this.allSignatures();
          const entryResult = await axios.get(`/api/logbookentry/${logbookId}`, { headers });
          this.logbookEntries = entryResult.data.tasks; 
          this.mergeData();
          }
        } catch (error) {
          console.error("Error updating logbook entry:", error);
        }
      }
    },
    async signOffAll() {
      if (this.selectedTasks.length === 0) {
        alert('No tasks selected for sign-off.');
        return;
      }

      if (confirm('Are you sure you want to sign off on ' + this.selectedTasks.length + ' selected tasks?')) {
        try {
          const currentDate = new Date();
          const formattedDate = this.formatDate(currentDate);
          const logbookId = localStorage.getItem('logbook_id');

          const tasksToUpdate = this.selectedTasks.map(task => {
            return {
              logbook_id: parseInt(logbookId),
              task_id: parseInt(task.task_id),
              ins_so: formattedDate,
              instructor_id: parseInt(localStorage.getItem('user_id')),
              ins_signature_id: parseInt(localStorage.getItem('inst_sign_id')),
            };
          });

          const token = localStorage.getItem('token');
          const headers = { Authorization: `Bearer ${token}` };

          // Make an Axios POST request to the batchUpdate API endpoint
          await axios.post('/api/logbookentry/batch-update', {
            logbook_entries: tasksToUpdate
          }, { headers });

          // Clear the selectedTasks array after successful sign-off
          this.selectedTasks = [];
          window.location.reload();
        } catch (error) {
          console.error('Error signing off tasks:', error.response.data.message);
          // You can handle the error here, e.g., show an error message to the user
          alert('Error signing off tasks: ' + error.response.data.message);
        }
      }
    },
    async syncData() {
      // Check if there's an internet connection
      if (navigator.onLine) {
        this.isSyncing = true;

        const tasksToSync = await db.collection('InsCompletedTasks').get();
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        if (tasksToSync.length === 0) {
          this.isSyncing = false;
          return; // exit function if there are no tasks to sync
        }

        let allTasksSynced = true;

        // Synchronize each task's completed status with the backend
        for (const task of tasksToSync) {
          try {
            const response = await axios.put(`/api/logbookentry/${task.logbookId}/${task.taskId}`, {
              ins_so: task.ins_so
            }, { headers });

            console.log("success in syncing task:", response);

            // If sync is successful, remove task from Localbase
            await db.collection('InsCompletedTasks').doc(task.taskId).delete();

          } catch (error) {
            console.error('Error syncing task:', error);
            allTasksSynced = false;
          }
        }

        this.isSyncing = false;

        if (allTasksSynced) {
          location.reload();
        }
      } else {
        console.log("No internet connection. Data sync postponed.");
      }
    },
    formatDate(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    async fetchData() {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        const logbookId = localStorage.getItem('logbook_id');

        const [logbookResponse, inslogbookEntryResponse, tasksResponse, taskAssessmentResponse] = await Promise.all([
          axios.get(`/api/logbooks/${logbookId}`, { headers }),
          axios.get(`/api/logbookentry/${logbookId}`, { headers }),
          axios.get(`/api/${logbookId}/tasks`, { headers }),
          axios.get(`/api/task_assessments/logbooks/${logbookId}`, { headers })
        ]);

        this.logbookName = logbookResponse.data.name;
        this.tasks = tasksResponse.data.tasks;
        this.logbookEntries = inslogbookEntryResponse.data.tasks;
        this.MTCount = this.tasks.filter(task => task.manob === 'MT').length;
        this.task_assessments = taskAssessmentResponse.data;

        this.mergeData(); // Prepare mergedTasks for display
      } catch (error) {
        console.error('An error occurred while fetching data:', error);
      }
    },

    mergeData() {
      this.mergedTasks = this.tasks.map(task => {
        const matchingEntry = this.logbookEntries.find(entry => entry.task_id === task.task_id);
        const matchingAssessment = this.task_assessments.find(assessment => assessment.task_id === task.task_id);
        return {
          ...task,
          completed: matchingEntry ? matchingEntry.completed : null,
          ins_so: matchingEntry ? matchingEntry.ins_so : null,
          enabled: matchingEntry ? matchingEntry.enabled === 1 : 0,
          troubleshoot: matchingEntry ? matchingEntry.troubleshoot : null,
          instructor_id: matchingEntry ? matchingEntry.instructor_id : null,
          is_selected: matchingEntry ? matchingEntry.is_selected : null,
          is_submitted: matchingAssessment ? matchingAssessment.is_submitted : null,
          t_signature_id: matchingEntry ? matchingEntry.t_signature_id : null,
          ins_signature_id: matchingEntry ? matchingEntry.ins_signature_id : null,
        };
      });
      this.completedMTCount = this.mergedTasks.filter(task => task.manob === 'MT' && task.completed).length;
      console.log('merged', this.mergedTasks);
    },

    async fetchAllSignatures() {
      const idsRaw = localStorage.getItem('instructorIds');
      console.log("Raw instructor IDs from localStorage:", idsRaw);

      let instructorIds;
      try {
        instructorIds = JSON.parse(idsRaw);
      } catch (error) {
        console.error("Error parsing instructorIds from localStorage:", error);
        instructorIds = [];
      }

      if (!Array.isArray(instructorIds)) {
        console.error("Parsed instructorIds is not an array:", instructorIds);
        instructorIds = [];
      }

      console.log("Parsed instructor IDs:", instructorIds);
      instructorIds.forEach(instructorId => {
        if (instructorId === undefined) {
          console.error("Skipping fetch for undefined instructorId");
        } else {
          this.fetchSignature(instructorId);
        }
      });
    },

    async fetchSignature(instructorId) {
      // if (!this.user_id || this.instructorSignatures[instructorId]) {
      //   return; // Exit if no user_id or signature already fetched
      // }
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      try {
        const sigResponse = await axios.get(`/api/store_image/fetch_image/${instructorId}`, {
          responseType: 'blob',
          headers: headers
        });
        const url = window.URL.createObjectURL(new Blob([sigResponse.data]));
        this.instructorSignatures[instructorId] = url;
      } catch (error) {
        console.error(`Error fetching signature for instructor ${instructorId}:`, error);
        this.instructorSignatures[instructorId] = null;
      }
    },

    shouldShowSignOffButton(task) {
      return (
        !task.complete && // Check if complete is empty (not signed off)
        task.work_order_no !== null &&
        task.work_order_no.toString().trim() !== '' &&
        task.task_detail !== null &&
        task.task_detail.toString().trim() !== '' &&
        task.category !== null &&
        task.category.toString().trim() !== '' &&
        task.ATA !== null &&
        task.ATA.toString().trim() !== ''
      );
    },
    async fetchPublicNotesForTask(taskId) {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      const endpoint = `/api/instructorPublicNotes/${taskId}`;

      axios.get(endpoint, { headers })
        .then(response => {
          if (response.data && response.data.content) {
            this.publicNotes[taskId] = response.data.content;
          } else {
            this.publicNotes[taskId] = '';
          }
        })
        .catch(error => {
          console.error(`Error fetching public notes for task ${taskId}:`, error);
        });
    },

    async fetchPrivateNotesForTask(taskId) {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      const endpoint = `/api/instructorPrivateNotes/${taskId}`;

      axios.get(endpoint, { headers })
        .then(response => {
          if (response.data && response.data.content) {
            this.privateNotes[taskId] = response.data.content;
          } else {
            this.privateNotes[taskId] = '';
          }
        })
        .catch(error => {
          console.error(`Error fetching private notes for task ${taskId}:`, error);
        });
    },

    async savePublicNotes(taskId, content) {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      };
      const postEndpoint = '/api/instructor-public-notes';
      const putEndpoint = `/api/instructorPublicNotes/${taskId}`;

      if (this.publicNotes[taskId]) {
        axios.put(putEndpoint, { content }, { headers })
          .then(response => {
            this.publicNotes[taskId] = response.data.content;
          })
          .catch(error => {
            console.error('Error updating public note:', error);
          });
      } else {
        const userId = localStorage.getItem('instructor_id');
        axios.post(postEndpoint, { task_id: taskId, content, user_id: userId }, { headers })
          .then(response => {
            this.publicNotes[taskId] = response.data.content;
          })
          .catch(error => {
            console.error('Error creating public note:', error);
          });
      }
    },

    async savePrivateNotes(taskId, content) {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      };
      const postEndpoint = '/api/instructor-private-notes';
      const putEndpoint = `/api/instructorPrivateNotes/${taskId}`;

      if (this.privateNotes[taskId]) {
        axios.put(putEndpoint, { content }, { headers })
          .then(response => {
            this.privateNotes[taskId] = response.data.content;
          })
          .catch(error => {
            console.error('Error updating private note:', error);
          });
      } else {
        const userId = localStorage.getItem('instructor_id');
        axios.post(postEndpoint, { task_id: taskId, content, user_id: userId }, { headers })
          .then(response => {
            this.privateNotes[taskId] = response.data.content;
          })
          .catch(error => {
            console.error('Error creating private note:', error);
          });
      }
    },

    async openEditor(taskId, isPublic = false) {
      // Clear the note for the current task ID first
      if (isPublic) {
        if (!this.publicNotes) this.publicNotes = {};
        this.publicNotes[this.currentTaskId] = '';  // Clear the previous public note
      } else {
        if (!this.privateNotes) this.privateNotes = {};
        this.privateNotes[this.currentTaskId] = '';  // Clear the previous private note
      }

      // Update the current task ID
      this.currentTaskId = taskId;
      this.isNotePublic = isPublic;  // Set the note type
      // Fetch the appropriate note for the new task ID
      if (isPublic) {
        await this.fetchPublicNotesForTask(taskId);
      } else {
        await this.fetchPrivateNotesForTask(taskId);
      }

      this.showQuillEditor = true;
    },

    closeQuillEditor(payload) {
      if (this.isNotePublic) {
        this.savePublicNotes(payload.taskId, payload.content);
        this.fetchAllPublicNotes();
        this.publicNotes[this.currentTaskId] = '';  // Clear the public note
      } else {
        this.savePrivateNotes(payload.taskId, payload.content);
        this.fetchAllPrivateNotes();
        this.privateNotes[this.currentTaskId] = '';  // Clear the private note
      }
      this.showQuillEditor = false;
    },
    async fetchAllPublicNotes() {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      axios.get(`/api/instructor-public-notes`, { headers })
        .then(response => {
          console.log('Fetched all notes:', response.data);

          // Assuming response.data is an array of notes
          response.data.forEach(note => {
            this.publicNotes[note.task_id] = note.content;
          });
        })
        .catch(error => {
          console.error("Error fetching all public notes:", error);
        });
    },
    async fetchAllPrivateNotes() {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      axios.get(`/api/instructor-private-notes`, { headers })
        .then(response => {
          console.log('Fetched all notes:', response.data);

          // Assuming response.data is an array of notes
          response.data.forEach(note => {
            this.privateNotes[note.task_id] = note.content;
          });
        })
        .catch(error => {
          console.error("Error fetching all private notes:", error);
        });
    },
    isTaskVisible(task) {
      if (this.taskFilter === 'all') return true;
      if (this.taskFilter === 'completed' && task.completed) return true;
      if (this.taskFilter === 'uncompleted' && !task.completed) return true;
      return false;
    },
    goHome() {
      this.$router.push({ name: 'InstructorHome' }) // Go back to the home page
    },
    confirmSignOff(task) {
      if (confirm('Are you sure you want to sign off?')) {
        this.setTimestamp(task);
      }
    },
    fetchTraineeSignatureOld() {
      const traineeId = localStorage.getItem('trainee_id');
      if (!traineeId) {
        console.error("trainee_id is undefined when fetching signature.");
        return;
      }

      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      axios.get(`/api/store_image/fetch_image/${traineeId}`, {
        responseType: 'blob',
        headers: headers
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          this.traineeSignatureUrl = url; // You'll need to add this new data property
        })
        .catch(error => {
          // Handle errors as above
          console.error('Error fetching trainee signature:', error);
        });
    },
    toggleMultiSignOffMode() {
      if (this.multiSignOffMode) {
        // If currently in Multi-Sign Off mode, exit it
        this.multiSignOffMode = false;
        this.selectedTasks = [];
      } else {
        // If not in Multi-Sign Off mode, switch to Select All mode
        this.multiSignOffMode = true;
        // Perform the "Select All" action here
        this.toggleAllTasksSelection();
      }
    },
    toggleSignOff(task) {
      if (task.selectedForSignOff) {
        this.selectedTasks.push(task);
      } else {
        const index = this.selectedTasks.findIndex((selectedTask) => selectedTask.task_id === task.task_id);
        if (index !== -1) {
          this.selectedTasks.splice(index, 1);
        }
      }
    },
    toggleAllTasksSelection() {
      this.selectedTasks = [];

      this.filteredTasks.forEach(task => {
        if (task.completed && !task.ins_so) {
          task.selectedForSignOff = true;
          this.selectedTasks.push(task);
        }
      });

      if (this.selectedTasks.length === 0) {
        alert("All tasks have been already signed off.");
        this.multiSignOffMode = false;
      }
    },
    async createPDFReport() {
      const logbookName = this.logbookName;
      LogbookReport.methods.generatePDF(this.filteredTasks, logbookName);
    },
    updateOnlineStatus() {
      this.isOffline = !navigator.onLine;
    },
    openTroubleshootModal(task) {
      this.showTroubleshootModal = true;
      const entry = this.logbookEntries.find(entry => entry.task_id === task.task_id);
      this.currentTaskExplanation = task.task_explanation;
      this.currentChaptNo = task.chapt_no;
      if (entry) {
        this.currentTaskId = task.task_id;  // Make sure 'task_id' is the correct property name
        this.currentTroubleshoot = entry.troubleshoot ? entry.troubleshoot.replace(/<br\s*\/?>/gi, '\n') : '';
      } else {
        console.error('No logbook entry found for task id:', task.task_id);
        this.currentTroubleshoot = '';  // Set to empty string if not found
      }
    },
    closeTroubleshootModal() {
      this.showTroubleshootModal = false;
    },
    openInsTaskLongFormModal(task) {
      this.currentTask = task;
      console.log('this.currentTask');
      console.log(this.currentTask);
      this.showInsTaskLongFormModal = true;
    },
    closeInsTaskLongFormModal() {
      this.showInsTaskLongFormModal = false;
    },
    openTraineeProgressModal() {
      this.showTraineeProgressModal = true;
    },
    closeTraineeProgressModal() {
      this.showTraineeProgressModal = false;
    },
    openTaskDescriptionModal(task) {
      if (task.is_exam) {
        this.openTroubleshootModal(task);
      } else {
        this.currentTaskId = task.task_id;
        this.currentTaskExplanation = task.task_explanation;
        this.showTaskDescriptionModal = true;
      }
    },
    closeTaskDescriptionModal() {
      this.showTaskDescriptionModal = false;
    },
    openSignatureModal() {
      this.showSignatureModal = true;
    },
    closeSignatureModal() {
      this.showSignatureModal = false;
      this.addSigToTasks();
    },
    async showPDFReport() {
      this.displayLogbookReport = true;

      setTimeout(() => {
        this.displayLogbookReport = false;
      }, 100);
    },
    fetchTaskAssessmentData() {
  const token = localStorage.getItem('token');
  const headers = { Authorization: `Bearer ${token}` };
  const logbookId = parseInt(this.logbookId);

  axios.get(`/api/task_assessments/logbooks/${logbookId}`, { headers })
    .then(response => {
      this.task_assessments = response.data;
      console.log(this.task_assessments);
    })
    .catch(error => {
      console.error('There was an error fetching the task assessment:', error);
    });
},
    fetchTraineeTaskAssessment(task) {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      const logbookId = this.logbookId;

      axios.get(`/api/task_assessments/${logbookId}/${task.task_id}`, { headers })
        .then(response => {
          const task_assessment = response.data;
          console.log('task_assessment');
          console.log(task_assessment);
          if (task_assessment) {
            this.taskAssessmentId = task_assessment.id;
            console.log('this.taskAssessmentId');
            console.log(this.taskAssessmentId);
            this.fetchedTaskAssessmentData = task_assessment;
          }
        })
        .catch(error => {
          console.error('There was an error fetching the task assessment:', error);
        });
    },
    async openTaskAssessmentModal(task) {
      if (!task.completed) {
        alert('The trainee must sign off on the task before they can be assessed')
        return;
      }
      if (task.is_selected === 0) {
        if (confirm('Do you want to select this task to be assessed?')) {
          const token = localStorage.getItem('token');
          const headers = { Authorization: `Bearer ${token}` };
          const logbookId = localStorage.getItem('logbook_id');

          const response = await axios.put(`/api/logbookentry/${logbookId}/${task.task_id}`, {
            is_selected: 1
          }, { headers });
          console.log("Success in updating logbook entry:", response);
          this.fetchData();
          return;
        } else {
          return;
        }
      }
      this.fetchedTaskAssessmentData = null;
      this.tasksCompleted = this.completedMTCount || 0;
      this.selectedTask = task;
      console.log('Selected Task:', this.selectedTask);
      this.fetchTraineeTaskAssessment(task);
      this.showTaskAssessmentModal = true;
    },
    closeTaskAssessmentModal() {
      this.showTaskAssessmentModal = false;
      this.fetchTaskAssessmentData();
    },
    fetchEvaluationData() {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      const logbookId = this.logbookId;

      axios.get(`/api/evaluations?logbook_id=${logbookId}`, { headers })
        .then(response => {
          const evaluation = response.data[0];
          if (evaluation) {
            this.evaluationId = evaluation.id;
            this.fetchedEvaluationData = evaluation;
          }
        })
        .catch(error => {
          console.error('There was an error fetching the evaluation:', error);
        });
    },

    openEvaluationModal() {
      this.showEvaluationFormModal = true;
      this.tasksCount = this.MTCount;
      this.tasksCompleted = this.completedMTCount || 0;
      this.fetchEvaluationData();
    },
    closeEvaluationModal() {
      this.showEvaluationFormModal = false;
    },
    updateIsAssessorSelected(newValue) {
      this.$emit('update:isAssessorSelected', newValue);
    },
    async loadTasks() {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        const logbookId = localStorage.getItem('logbook_id');
        const response = await axios.get(`/api/${logbookId}/tasks`, { headers });
        this.tasks = response.data.tasks.map(task => ({
          ...task,
          enabled: true
        }));
        this.mergeData();
        await this.initializeTaskEnabledState();
      } catch (error) {
        console.error('An error occurred while fetching tasks:', error);
      }
    },
    async initializeTaskEnabledState() {
      const headers = { Authorization: `Bearer ${this.token}` };
      const logbookId = localStorage.getItem('logbook_id');
      const tasks = this.tasks.map(task => ({
        task_id: task.task_id,
        enabled: true,
        is_submitted: false
      }));
      const chunkSize = 15;
      const taskPromises = [];
      for (let i = 0; i < tasks.length; i += chunkSize) {
        const batch = tasks.slice(i, i + chunkSize);
        taskPromises.push(
          axios.post('/api/logbookentries/batch-update', {
            logbook_id: logbookId,
            tasks: batch
          }, { headers })
            .then(() => console.log("Batch update successful for batch starting at index " + i))
            .catch(error => console.error("Error during batch update for batch starting at index " + i + ":", error))
        );
      }
      await Promise.all(taskPromises);
      console.log("All batch updates completed.");
    },
    async createTaskLogbookEntry(task, headers) {
      await axios.post(`/api/logbookentry/`, {
        logbook_id: this.logbookId,
        task_id: task.task_id,
        enabled: true,
        is_submitted: false
      }, { headers });
    },
    allSignatures() {
  const token = localStorage.getItem('token');
  const headers = { Authorization: `Bearer ${token}` };

  axios.get(`/api/books`, { headers })
    .then(response => {
      // Access the data from the response
      const responseData = response.data;
      
      // Do something with the data, for example, assign it to a variable or log it
      this.signatures = responseData;
      console.log('all');
      console.log(responseData);
    })
    .catch(error => {
      // Handle any errors that occurred during the request
      console.error('Error fetching signatures:', error);
    });
    this.fetchCurrentSignatureId();
},
async fetchCurrentSignatureId() {
  try {
  const token = localStorage.getItem('token');
  const headers = { Authorization: `Bearer ${token}` };

  const response = await axios.get(`/api/current-signature/${this.user_id}`, { headers })

  localStorage.setItem('inst_sign_id', response.data.id);
  this.signatureId = localStorage.getItem('inst_sign_id');
  } catch (error) {
        console.error('An error occurred while fetching signature id:', error);
}
},
fetchTraineeSignature(id) {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      axios.get(`/api/signatures/${id}`, {
        responseType: 'blob',
        headers: headers
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          this.signatureUrl = url;
        })
        .catch(error => {
          if (error.response) {
            console.error('Error status:', error.response.status);
            console.error('Error data:', error.response.data);
          } else if (error.request) {
            console.error('Error request:', error.request);
          } else {
            console.error('Error message:', error.message);
          }
        });
    },
    async fetchTrainee () {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      const traineeId = localStorage.getItem('trainee_id');

      try {
        const traineeResponse = await axios.get(`/api/users/${traineeId}`, { headers });
        this.trainee = traineeResponse.data;
        console.log('trainee', this.trainee);
      } catch (error) {
        console.error(`Error fetching data for trainee ${traineeId}:`, error);
      }
    },
    async fetchChapters () {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      try {
        const chapterResponse = await axios.get(`/api/chapters`, { headers });
        this.chapters = chapterResponse.data;
        console.log('chapters', this.chapters);
      } catch (error) {
        console.error(`Error fetching data for chapters:`, error);
      }
    },

  },
  async mounted() {
    this.user_id = localStorage.getItem('user_id');  // Retrieve and store user_id
    if (!this.user_id) {
      console.error("user_id not found");
      return;  // Handle the absence of user_id appropriately
    }

    const isAssessor = localStorage.getItem('isAssessor');
    const isAssessorSelected = JSON.parse(isAssessor);
    this.isAssessorSelected = isAssessorSelected;
    this.$route.params.logbookId;
    this.$route.params.productId;
    this.$route.params.traineeId;
    window.addEventListener('online', this.syncData);
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    this.fetchData();
    this.fetchAllSignatures();
    this.allSignatures();
    this.fetchTrainee();
    this.fetchChapters();
      await this.$nextTick();
    if (isAssessorSelected) {
      this.selectedCompleted = 'completed tasks';
      this.selectedisexam = true;
    }
  },
  beforeMount() {
    window.removeEventListener('online', this.syncData);
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
}
</script>

<style scoped>
img {
  width: 100%;
  height: 30%;
  border: 1px solid lightgray;
}

.signatureButton {
  margin-right: 20px;
  float: right;
}

.icon-green {
  color: rgb(21, 185, 21);
  fill: green
}

.signature-container,
.ins-signature-container {
  width: 100px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
}

.ins-signature-image,
.signature-image {
  width: 100%;
  height: 100%;
}

.inspdfButton {
  border-radius: 5px;
  width: 150px;
  background-color: #a52c0d;
  color:white;
  padding:5px;
  font-size: 16px;
  border: 1px solid #491204;
  float: right;
}
.inspdfButton:hover {
  background-color: #791e08;
}

.chapter-name-cell {
  background-color: rgba(38, 65, 116, 0.884);
  color: white;
  text-align: center;
  
}
</style>