<template>
    <!DOCTYPE html>
    <html lang="en">

    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
    </head>

    <body>
        <page-header></page-header>
        <div>
            <div class="import-tasks-modal">
                <div class="tasks-container-fluid no-padding">
                    <div class="row">
                        <div class="col-md-12 left-align-text">
                            <h2>Import tasks from .CSV file</h2>
                            <p style="font-size:16px;">The file has to be a spreadsheet file (.csv) in the following format:
                            </p>
                            <p style="font-weight:bold;">Chapter No|Task No|Manob|Logbook Entry|Flight Deck|Observation|Extra Info|CMCS Fault|Questions
                            </p>
                            <p style="font-size:16px; color: rgb(196, 0, 0);"><b>All fields are mandatory.</b></p>
                            <div class="instructions-area">
                                <table class="inst-table">
                                    <thead>
                                        <tr>
                                            <th>Column</th>
                                            <th>Name</th>
                                            <th>Instructions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>A</td>
                                            <td>Chapter No</td>
                                            <td>A valid ATA chapter Number</td>
                                        </tr>
                                        <tr>
                                            <td>B</td>
                                            <td>Task No</td>
                                            <td>A unique number to identify the tasks</td>
                                        </tr>
                                        <tr>
                                            <td>C</td>
                                            <td>Manob</td>
                                            <td>The Task Categories. The values should be MT or AT</td>
                                        </tr>
                                        <tr>
                                            <td>D</td>
                                            <td>Logbook Entry</td>
                                            <td>Value either 1 or 0 indicating if there is a logbook entry or not.</td>
                                        </tr>
                                        <tr>
                                            <td>E</td>
                                            <td>Flight Deck</td>
                                            <td>Value either 1 or 0 indicating if there is a Flight Deck Effect or not.</td>
                                        </tr>
                                        <tr>
                                            <td>F</td>
                                            <td>Observation</td>
                                            <td>Value either 1 or 0 indicating if Crew/Maintenance observe the task or not.</td>
                                        </tr>
                                        <tr>
                                            <td>G</td>
                                            <td>Extra Info</td>
                                            <td>Any additional info regarding the Flight Deck Effect.</td>
                                        </tr>
                                        <tr>
                                            <td>H</td>
                                            <td>CMCS Fault</td>
                                            <td>Any maintenance messages/faults relevant to the task.</td>
                                        </tr>
                                        <tr>
                                            <td>I</td>
                                            <td>Questions</td>
                                            <td>A list of questions the trainee must answer.</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <span class="clip" role="img" aria-label="Paperclip">&#x1F4CE;</span>
                                <input type="file" @change="importFile" class="file-input" />
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div v-if="previewRows.length && !importSuccessful && !dryRunSuccessful">
                                <h4 style="text-align: left;">CSV Preview (First 5 rows):</h4>
                                <table class="preview-table">
                                    <!-- Uploaded CSV headers (dynamic) -->
                                    <thead>
                                        <tr>
                                            <th class="narrow-column">Chapt No</th>
                                            <th class="narrow-column">Task No</th>
                                            <th class="narrow-column">Manob</th>
                                            <th class="narrow-column">Logbook Entry</th>
                                            <th class="narrow-column">Flight Deck</th>
                                            <th class="narrow-column">Observation</th>
                                            <th class="skill1-column">Extra Info</th> 
                                            <th class="skill-column">CMCS Fault</th> 
                                            <th class="wide-column">Questions</th> 
                                        </tr>
                                    </thead>
                                    <!-- Uploaded CSV preview -->
                                    <tbody>
                                        <tr v-for="(row, index) in previewRows" :key="row.toString()"
                                            v-show="!(ignoreFirstRow && index === 0)">
                                            <td style="font-size:14px" v-for="cell in row" :key="cell">{{ cell }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="dryRunSuccessful" v-html="successMessage.replace(/\n/g, '<br>')" class="successBox">
                            </div>
                            <div v-if="importSuccessful && !dryRun" v-html="successMessage.replace(/\n/g, '<br>')"
                                class="successBox"></div>
                        </div>

                        <div class="importArea">
                            <label>
                                <input type="checkbox" v-model="ignoreFirstRow" class="ignoreCheckbox">
                                Ignore first row
                            </label>
                            <label>
                                <input type="checkbox" v-model="dryRun" class="dryRuncheckbox">
                                Dry Run
                            </label>
                            <div class="buttonContainer">
                                <button class="importButton" :disabled="processing" @click.stop.prevent="handleImport">
                                    {{ processing ? 'Importing...' : 'IMPORT' }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </body>

    </html>
</template>

<script>
import axios from 'axios';
import pageHeader from '@/components/Examiner/Header.vue';
import './importTasks.css';
export default {
    name: "importExaminableTasks",
    data() {
        return {
            productId: this.$route.params.productId,
            previewRows: [],
            entireFileRows: [],
            ignoreFirstRow: false,
            importSuccessful: false,
            dryRunSuccessful: false,
            successMessage: '',
            dryRun: true,
            importedTasks: [],
            existingTasks: [],
            fileName: '',
            sectionIds: [],
            productIds: [],
            skippedTasks: [],
            validTasks: [],
            processing: false,
        };
    },
    components: {
        pageHeader
    },
    watch: {
        selectedFile(newFile, oldFile) {
            if (newFile !== oldFile) {
                this.resetImportState();
            }
        }
    },
    mounted() {
        const productId = localStorage.getItem('product_id');
        axios.get(`/api/exam-tasks/products/${productId}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(response => {
                this.existingTasks = response.data;
            });
    },
    methods: {
        closeModal() {
            this.$emit('close');
            this.$emit('modal-closed');
        },
        importFile(event) {
            const file = event.target.files[0];
            if (file) {
                this.fileName = file.name;
                if (file.type.includes('csv')) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        const rows = reader.result.trim().split('\n');
                        this.entireFileRows = rows.map(row => row.split('|'));
                        this.previewRows = this.entireFileRows.slice(0, 5);
                    };
                    reader.readAsText(file);
                }
            } else {
                this.fileName = '';
                this.entireFileRows = [];
                this.previewRows = [];
            }
        },
        processDataRows(dataRows) {
            return dataRows
                .filter(row => Array.isArray(row) && row.length > 0)
                .map((row) => {
                    const chapt_no = row[0].trim();
                    const task_no = isNaN(parseInt(row[1], 10)) ? row[1] : parseInt(row[1], 10);
                    const manob = row[2].trim();
                    const logbook_entry = row[3].trim() === "1" ? 1 : 0;
                    const flight_deck = row[4].trim() === "1" ? 1 : 0;
                    const observation = row[5].trim() === "1" ? 1 : 0;
                    const extra_info = row[6].trim();
                    const CMCS_fault = row[7].trim();
                    const questions = row[8].trim();
                    const productId = parseInt(localStorage.getItem('product_id'), 10);
                    return {
                        chapt_no,
                        task_no,
                        manob, 
                        logbook_entry,
                        flight_deck,
                        observation,
                        extra_info,
                        CMCS_fault,
                        questions,
                        prod_id: productId,
                    };
                })
        },
        handleImport() {
            //eslint-disable-next-line
            debugger;
            this.dryRunSuccessful = false;
            this.processing = true;
            // Process the rows first
            const processedTasks = this.processDataRows(this.entireFileRows);
            if (this.ignoreFirstRow) {
                processedTasks.shift(); // Remove the first row if the user wants to ignore it
            }
            if (this.dryRun) {
                this.checkTasks(processedTasks);
                this.dryRunImport(processedTasks);
            } else {
                this.checkImportTasks(processedTasks);
                
            }
        },
        dryRunImport(processedTasks) {
            let dryRunSkippedTasks = [];
            const duplicates = this.checkForDuplicates(processedTasks);
            dryRunSkippedTasks = duplicates.map(task => task.task_no);
            console.log("Dry Run Mode: These tasks would have been imported:", processedTasks);
            if (dryRunSkippedTasks.length > 0) {
                console.log("Duplicate tasks that would have been skipped:", dryRunSkippedTasks);
            } else {
                this.successMessage = `Dry Run Mode: ${processedTasks.length} tasks would have been imported. No issues found in the file.`;
                console.log(this.successMessage);
            }
            this.dryRunSuccessful = true;
            this.processing = false;
        },
        async batchImportTasks(validTasks, skippedTasks, processedTasks) {
            try {
                const token = localStorage.getItem('token');
                const headers = { Authorization: `Bearer ${token}` };
                const response = await axios.post('/api/exam-tasks/batch', validTasks, { headers });
                const responseTasks = response.data.tasks;
                // Handle the responseTasks as needed
                console.log('Batch Import Successful:', responseTasks);
                console.log(skippedTasks);
                this.$store.dispatch('setImportedTasks', processedTasks);
                this.$store.dispatch('setFileName', this.fileName);
                this.$store.dispatch('setSkippedTasks', skippedTasks);
                this.processing = false;
                this.$router.push({ name: 'importSuccessful' });
            } catch (error) {
                console.error('Batch Import Error:', error.response ? error.response.data : error.message);
                this.processing = false;
            }
        },
        areTasksEqual(task1, task2) {
            const keysToCheck = ['chapt_no', 'task_no', 'manob', 'logbook_entry', 'flight_deck', 'observation', 'extra_info', 'questions'];

for (let key of keysToCheck) {
    if (task1[key] !== task2[key]) {
        return false;
    }
}

return true;
},
checkForDuplicates(importedTasks) {
    const duplicates = [];

    for (let i = 0; i < importedTasks.length; i++) {
        for (let j = 0; j < this.existingTasks.length; j++) {
            if (this.areTasksEqual(importedTasks[i], this.existingTasks[j])) {
                duplicates.push(importedTasks[i]);
                break;  // No need to check further for this importedTask if a duplicate is found
            }
        }
    }

    return duplicates;
},
        checkTasks(importedTasks, isDryRun = true) {
            //eslint-disable-next-line
            debugger;
            const issues = [];
            this.skippedTasks = [];
            this.validTasks = [];
            const duplicates = this.checkForDuplicates(importedTasks);
            importedTasks.forEach(task => {
                this.validateTask(task, issues, duplicates);
            });
            this.handleIssues(issues, importedTasks, isDryRun);
        },
        checkImportTasks(importedTasks) {
            //eslint-disable-next-line
            debugger;
            const issues = [];
            this.skippedTasks = [];
            this.validTasks = [];
            const duplicates = this.checkForDuplicates(importedTasks);
            importedTasks.forEach(task => {
                this.validateTask(task, issues, duplicates);
            });
            console.log(this.skippedTasks);
            console.log(this.validTasks);
            this.batchImportTasks(this.validTasks, this.skippedTasks, importedTasks);
        },
        validateTask(importedTask, issues, duplicates) {
            if (importedTask.task_no === undefined || importedTask.task_no === null ||importedTask.task_no === '') {
        issues.push({
            message: 'Field "task_no" is empty or undefined.',
            task_no: null,
            chapt_no: importedTask.chapt_no 
        });
        this.skippedTasks.push(importedTask); // Add the task to skippedTasks
        return; // Exit the method as there's no task_no to validate further
    }
        // Validate chapt_no
        if (importedTask.chapt_no === undefined || importedTask.chapt_no === null || importedTask.chapt_no === '') {
        issues.push({
            message: 'Field "chapt_no" is empty or undefined.',
            task_no: importedTask.task_no, // Include task_no in the issues
            chapt_no: null // You can set it to some default value or null based on your requirements
        });
        this.skippedTasks.push(importedTask); // Add the task to skippedTasks
        return; // Exit the method as there's no chapt_no to validate further
    }
    for (let key in importedTask) {
                let value = importedTask[key];
                if (key === "prod_id") continue
                switch (key) {
                    case "task_no":
                        if (value === undefined || value === null || value === '') {
                            issues.push({
                                message: `Field "${key}" is empty.`,
                                task_no: importedTask.task_no,
                                chapt_no: importedTask.chapt_no
                            });
                        }
                        // Check for invalid data types
                        else if (isNaN(value)) {
                            issues.push({
                                message: `Invalid data type for "${key}". Expected number, got ${typeof value}.`,
                                task_no: importedTask.task_no,
                                chapt_no: importedTask.chapt_no
                            });
                        }
                        break;
                    case "logbook_entry":
                    case "flight_deck":
                    case "observation":
                        if (value === undefined || value === null || value === '' || isNaN(value)) {
                            issues.push({
                                message: `Field "${key}" is empty.`,
                                task_no: importedTask.task_no,
                                chapt_no: importedTask.chapt_no
                            });
                        }
                        else if (![true, false, "true", "false", 1, 0, "1", "0"].includes(value)) {
                            issues.push({
                                message: `Invalid data for "${key}". Expected boolean (true/false or 1/0), got ${value}.`,
                                task_no: importedTask.task_no,
                                chapt_no: importedTask.chapt_no
                            });
                        }
                        break;
                    case "chapt_no":
                    case "extra_info":
                    case "CMCS_fault":
                    case "questions":
                        if (value === undefined || value === null || value.trim() === '') {
                            issues.push({
                                message: `Field "${key}" is empty.`,
                                task_no: importedTask.task_no,
                                chapt_no: importedTask.chapt_no
                            });
                        } else if (typeof value !== "string") {
                            issues.push({
                                message: `Invalid data type for "${key}". Expected string, got ${typeof value}.`,
                                task_no: importedTask.task_no,
                                chapt_no: importedTask.chapt_no
                            });
                        }
                        break;
                    case "manob":
                        if (value === undefined || value === null || value.trim() === '') {
                            issues.push({
                                message: `Field "${key}" is empty.`,
                                task_no: importedTask.task_no,
                                chapt_no: importedTask.chapt_no
                            });
                        } else if (typeof value !== "string") {
                            issues.push({
                                message: `Invalid data type for "${key}". Expected string, got ${typeof value}.`,
                                task_no: importedTask.task_no,
                                chapt_no: importedTask.chapt_no
                            });
                        } else if (!['MT', 'AT'].includes(value.trim())) {
                            issues.push({
                                message: `Invalid value for "${key}". Accepted values are 'MT' or 'AT'.`,
                                task_no: importedTask.task_no,
                                chapt_no: importedTask.chapt_no
                            });
                        }
                        break;
                    default:
                        issues.push({
                            message: `Extra field "${key}" detected.`,
                            task_no: importedTask.task_no,
                            chapt_no: importedTask.chapt_no
                        });
                }
            }
            const validators = {
                task_no: (val, task) => {
                    const matchingExistingTask = this.existingTasks.find(
                        existingTask => existingTask.task_no === val,
                    );
                    if (duplicates.includes(task)) return 'Task already exists in the Practical Element (Duplicate record)';
                    if (matchingExistingTask && this.areTasksEqual(matchingExistingTask, task)) return `Task with Task No ${val} already exists. Consider adding a new unique number.`;
                },
            };
            Object.entries(importedTask).forEach(([key, value]) => {
                const validationError = validators[key]?.(value, importedTask);
                if (validationError) {
                    console.log(importedTask.chapt_no)
                    issues.push({ message: `${validationError}`, task_no: importedTask.task_no, chapt_no: importedTask.chapt_no, });
                }
            });
            const hasIssues = issues.some(issue => issue.task_no === importedTask.task_no);
            if (hasIssues) {
                this.skippedTasks.push(importedTask);
            } else {
                this.validTasks.push(importedTask);
            }
        },
        handleIssues(issues, importedTasks) {
            if (issues.length) {
                this.$store.dispatch('setIssues', issues);
                this.$store.dispatch('setImportedTasks', importedTasks);
                this.$store.dispatch('setFileName', this.fileName);
                this.$router.push({ name: 'ExaminableIssuesDisplay', params: { productId: this.productId } });
            }
        },
        resetImportState() {
            this.successMessage = null;
            this.processedTasks = [];
        },
    },
}
</script>

<style scoped>
.importButton[disabled] {
    background-color: #ccc; 
    cursor: not-allowed;
    opacity: 0.9;
}

</style>
  