<template>
  <div class="modal-overlay" @click="closeModal">
    <div class="products-modal" @click.stop>
      <div class="modal-content" @click.stop>
        <div id="app" class="bg-secondary">
          <div class="row bg-secondary" style="margin-right: 0px; margin-left: 0px">
            <div class="col-md bg-secondary">
              <div class="card">
                <div class="card-body" style="background: darkgray">

                  <!-- this is form to insert new Signature -->
                  <form action="" class="card-img-top" @submit.prevent="sign" method="POST" autocomplete="off">
                    <!-- we used vueSignature, so write in form vueSignature -->
                    <vueSignature ref="signature" id="sig" :sigOption="option" :disabled="disabled">
                    </vueSignature>
                  </form>

                  <!-- Buttons for saving, clearing, and closing -->
                  <div class="btn-container mt-3">
                    <button @click.prevent="sign" class="btn btn-success m-1">Save</button>
                    <button @click.prevent="clear" class="btn btn-info m-1">Clear</button>
                    <button @click.prevent="closeModal" class="btn btn-secondary m-1">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import vueSignature from "vue-signature";
import axios from "axios";

export default {
  components: {
    vueSignature,
  },
  data() {
    return {
      books: [],
      png: null,
      user_image: null,
      error: "",
      option: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "#FAF9F6",
      },
      sign_id: null,
      user_id: null,
      disabled: false,
      dataUrl: "",
      signatureUrl: null,
    };
  },

  mounted() {
    this.user_id = parseInt(localStorage.getItem('user_id'));  // Retrieve user_id when component mounts
    console.log("user_id in sign modal", this.user_id);
    this.getPosts();  // Assuming you might want to use user_id in getPosts as well
  },
  methods: {
    closeModal() {
      this.$emit('close'); // Emit an event to indicate the modal should be closed
    },
    async getPosts() {  // Revised to use user_id from data
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      axios.get(`/api/store_image/fetch_image/${this.user_id}`, { headers })
        .then((response) => {
          this.books = response.data;
        })
        .catch((error) => {
          console.error("Error fetching posts:", error);
        });
    },
    async sign() {
    const token = localStorage.getItem('token');
    const headers = { Authorization: `Bearer ${token}` };
    var png = this.$refs.signature.save();
    try {
      const result = await axios.get(`/api/users/${this.user_id}`, { headers });
      if (result.data.role === 'instructor' || result.data.second_role === 'instructor') {
        await this.submitSignature(png, headers, true);
      } else {
        await this.submitSignature(png, headers, false);
      }
    } catch (error) {
      console.error('Error checking user role:', error);
    }
  },
  async submitSignature(png, headers, isInstructor) {
    try {
      const response = await axios.post('/api/sign', {
        user_image: png,
        user_id: this.user_id
      }, { headers });
      console.log(response.data);
      this.sign_id = response.data.id;
      localStorage.setItem(isInstructor ? 'inst_sign_id' : 'sign_id', this.sign_id);
      this.getPosts();
      setTimeout(() => {
        this.$refs.signature.clear();
        this.$emit('close');
      }, 500); // Adjust the time delay as needed 
    } catch (error) {
      console.error('Error submitting signature:', error);
    }
  },
    clear() {
      this.$refs.signature.clear();
    },
    async deleteBook(id) {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      axios.post(`/api/store_image/delete/${id}`, { headers })
        .then((response) => {
          let index = this.books.findIndex((item) => item.id === id);
          if (index !== -1) {
            this.books.splice(index, 1);
          }
          console.log(response);
        })
        .catch((error) => {
          console.error("Error deleting book:", error);
        });
    },
  },
};
</script>


<style scoped>
ul {
  list-style: none;
}

.modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.products-modal {
  background-color: #fff;
  width: 60%;
  max-width: 900px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1001;
  display: block;
}

.card {
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-body {
  padding: 20px;
}

#sig {
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  margin-top: 10px;
}

.btn-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-left: 20%;
  margin-right: 20%;
}

.btn {
  padding: 10px 20px;
  border-radius: 6px;
  transition: background-color 0.3s ease;
  min-width: 100px;
}

.btn-info {
  border: 1px solid rgb(1, 112, 146);
}

.btn-success {
  border: 1px solid rgb(16, 94, 26);
}

.btn-secondary {
  border: 1px solid rgb(59, 59, 59);
}

button.btn-info:hover {
  background-color: rgba(0, 123, 255, 0.9);
}

button.btn-success:hover {
  background-color: rgba(21, 122, 35, 0.9);
}

button.btn-secondary:hover {
  background-color: rgba(77, 75, 75, 0.9);
}
</style>