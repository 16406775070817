<head>  
    <meta name="viewport" content="width=device-width, initial-scale=1">
  </head>
<template>
  <div class="tr-Evaluation-modal-overlay">
    <div class="tr-Evaluation-modal">
      <h3 class="tr-Evaluation-modal-title">CONTINUOUS EVALUATION SHEET</h3>
      <table class="tr-assessment-table">
        <thead>
          <tr>
            <th rowspan="2">Competence Criteria</th>
            <th colspan="2" style="text-align: center;">Performance result</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in criteriaResults" :key="index">
            <td>{{ criteria[index] }}</td>
            <td
              :class="{ 'satisfactory': item.result === 'satisfactory', 'unsatisfactory': item.result === 'unsatisfactory' }"
              style="text-align: center;">
              {{ item.result ? item.result.charAt(0).toUpperCase() + item.result.slice(1) : '-' }}
            </td>
          </tr>
        </tbody>
      </table>
      <div style="background-color:#f2f2f2">
        <div class="tr-assessment-result">
          <table>
            <td style="width:50%; font-weight: bold;">
              RESULT OF THE ASSESSMENT
            </td>
            <td style="border:none;" :style="assessmentResultStyle">
              <span v-if="assessmentResult" style="border:none">{{ assessmentResult.toUpperCase() }}</span>
              <span v-else style="border:none">-</span>
            </td>
          </table>
        </div>
        <div class="tr-assessment-container">
          <table class="tr-task-performance">
            <tr>
              <th style="font-size:14px">Number of Main Tasks in this practical training element</th>
              <td style="font-weight: bold; text-align: center; width:10%;">{{ tasksCount }}</td>
            </tr>
            <tr>
              <td style="width:40%; font-size:14px">Number of Main Tasks performed by the Trainee (mini {{ tasksCount/2 }} to pass)</td>
              <td style="text-align: center;">{{ completedTasks }}</td>
            </tr>
          </table>
          <div class="tr-signature-block">
            <table class="tr-signature-table" style="margin-left: 30px;">
              <tr class="tr-signature">
                <td style="width:50%; font-weight:bold">TRAINEE SIGNATURE:</td>
                <td>
                  <canvas ref="traineeSignaturePadCanvas" id="trainee-signature-pad" class="signature-pad" width="220"
                    height="60"></canvas>
                </td>
              </tr>
              <tr class="assessor-signature">
                <td style="width:50%; font-weight:bold;">ASSESSOR SIGNATURE:</td>
                <td>
                  <canvas ref="assessorSignaturePadCanvas" id="assessor-signature-pad" class="signature-pad" width="250"
                    height="60"></canvas>
                </td>
              </tr>
              <tr class="assessor-signature">
                <td style="width:50%; font-weight:bold;">DATE:</td>
                <td>
                  <span v-if="date" style="border:none">{{ date }}</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <!-- ... other signature inputs ... -->
      </div>
      <div class="buttons-container">
        <button class="tr-close-button" @click="close">Close</button>
      <div class="right">
      <button class="tr-clear-button" @click="clearSignature" :disabled="isSubmitted > 1">Clear Signature</button>
      <button class="tr-submit-button" @click="updateTraineeSignature" :disabled="isSubmitted > 1">Submit</button>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import SignaturePad from 'signature_pad';

export default {
  props: {
    taskId: {
      type: [String, Number],
      required: true
    },
    tasksCount: {
      type: Number,
      required: true
    },
    completedTasks: {
      type: Number,
    },
    fetchedData: Object,
    evaluationId: Number,
  },
  watch: {
    fetchedData(newVal) {
      console.log('Received fetched data:', newVal);
      this.populateEvaluationData(newVal);
    },
    criteriaResults: {
      immediate: true,
      handler() {
        const result = this.assessmentResult;
        this.successfulChecked = result === 'successful';
        this.remedialChecked = result === 'remedial';
      }
    },
    showEvaluationFormModal(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.initializeSignaturePad();
        });
      } else {
        this.destroySignaturePad();
      }
    },
  },
  data() {
    return {
      criteriaResults: new Array(9).fill(null).map((_, index) => {
        return { criterionId: index + 1, result: null };
      }),
      isSubmitted: false,
      tasksPerformed: '',
      traineeSignature: '',
      assessorSignature: '',
      successfulChecked: false,
      remedialChecked: false,
      date: '',
      traineeId: localStorage.getItem('user_id'),
      logbookId: localStorage.getItem('logbook_id'),
      instructorId: localStorage.getItem('instructor_id'),
      evallogbookId: null,
      traineeSignaturePad: null,
      assessorSignaturePad: null,
      criteria: [
        'Consults the correct documentation',
        'Scans the environment before starting and during the task to ensure safety',
        'Reads/interprets the safety warning correctly',
        'Informs/communicates with appropriate people correctly',
        'Informs/communicates with appropriate people correctly',
        'Interprets the reports, indications and documentation correctly',
        'Follows the procedure steps',
        'Reacts properly to changes during task performance to ensure safety',
        'Takes into account interactions between systems',
        'Restores the aircraft back to initial condition (or appropriate condition depending on the circumstances)'
      ],
    }
  },
  computed: {
    assessmentResult() {
    // Check if all criteria are marked as 'satisfactory'
    const allSatisfactory = this.criteriaResults.every(item => item.result === 'satisfactory');

    // Check if any criterion is marked as 'unsatisfactory'
    const anyUnsatisfactory = this.criteriaResults.some(item => item.result === 'unsatisfactory');

    if (allSatisfactory) {
      return 'successful';
    } else if (anyUnsatisfactory) {
      return 'remedial';
    }
    return null;
  },
    assessmentResultStyle() {
      switch (this.assessmentResult) {
        case 'successful':
          return { color: 'green', fontWeight: 'bold', border: 'none' };
        case 'remedial':
          return { color: 'red', fontWeight: 'bold', border: 'none' };
        default:
          return {};
      }
    },
    isReadOnly() {
      return this.fetchedData && this.fetchedData.is_submitted;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    clearSignature() {
      if (this.isSubmitted === 1 && this.traineeSignaturePad) {
        this.traineeSignaturePad.clear();
      }
    },
    initializeSignaturePad() {
            // Initialize the trainee's signature pad
            const traineeCanvas = this.$refs.traineeSignaturePadCanvas;      
        if (traineeCanvas) {
          this.traineeSignaturePad = new SignaturePad(traineeCanvas, {
            backgroundColor: 'rgb(255, 255, 255)',
            minWidth: 0.5, // Adjust this value for thinner lines
      maxWidth: 1.5, // Adjust this value for thinner lines
          });
          if (this.traineeSignature) {
          this.traineeSignaturePad.fromDataURL(this.traineeSignature);
        }
              // Check if is_submitted is 2, then disable the canvas
    if (this.isSubmitted === 2) {
      this.traineeSignaturePad.off();
      traineeCanvas.style.opacity = '0.5'; // Change opacity to indicate it's disabled
    }
        }
      const assessorCanvas = this.$refs.assessorSignaturePadCanvas;
      if (assessorCanvas) {
        this.assessorSignaturePad = new SignaturePad(assessorCanvas, {
          backgroundColor: 'rgb(255, 255, 255)',
          minWidth: 0.5, // Adjust this value for thinner lines
      maxWidth: 1.5, // Adjust this value for thinner lines
        });

        if (this.assessorSignature) {
          this.assessorSignaturePad.fromDataURL(this.assessorSignature);
        }

        if (this.isSubmitted > 0) {
          this.assessorSignaturePad.off();
          assessorCanvas.style.opacity = '0.5';
        } 
      }
    },
    destroySignaturePad() {
      if (this.signaturePad) {
        this.signaturePad.off();  // Turn off the signature pad
        this.signaturePad = null; // Clean up the reference
      }
    },
    toggleAllSatisfactory(event) {
      const isSatisfactory = event.target.checked;
      this.criteriaResults.forEach((criteria) => {
        criteria.result = isSatisfactory ? 'satisfactory' : null;
      });
    },

    populateEvaluationData(evaluationData) {
      if (!evaluationData) {
        console.error('No evaluation data found');
        return;
      }

      // Convert criteria object to an array if necessary
      if (evaluationData.criteria && typeof evaluationData.criteria === 'object') {
        // Object.entries will give you an array of [key, value] pairs
        this.criteriaResults = Object.entries(evaluationData.criteria).map(([criterionId, result]) => ({
          criterionId: parseInt(criterionId), // Make sure to parse the criterionId to number if necessary
          result: result
        }));
      }

      // Check if this.signaturePad exists before using it
      if (this.assessorSignaturePad && evaluationData.assessor_signature) {
        const image = new Image();
        image.onload = () => {
          if (this.assessorSignaturePad) {
            this.assessorSignaturePad.fromDataURL(evaluationData.assessor_signature, {width: 270, height: 60});
          }
        };
        image.src = evaluationData.assessor_signature;
      } else if (this.signaturePad) {
        this.signaturePad.clear();
      }

      if (this.traineeSignaturePad) {
        const traineeImage = new Image();
        traineeImage.onload = () => {
          if (this.traineeSignaturePad) {
            this.traineeSignaturePad.fromDataURL(evaluationData.trainee_signature, {width: 220, height: 60});
          }
        };
        traineeImage.src = evaluationData.trainee_signature;
      }

      // Assign other values
      this.successfulChecked = evaluationData.result === 'successful';
      this.remedialChecked = evaluationData.result === 'remedial';
      this.traineeSignature = evaluationData.trainee_signature;
      this.assessorSignature = evaluationData.assessor_signature;
      this.date = evaluationData.date;
      this.isSubmitted = evaluationData.is_submitted;
    },
    updateTraineeSignature() {
  // Confirm submission
  const confirmSubmission = confirm("Once submitted, the form cannot be edited. Are you sure you want to submit?");
  if (confirmSubmission) {
    const evaluationUpdateData = {
      logbook_id: Number(this.logbookId),
      trainee_id: Number(this.traineeId),
      is_submitted: 2,
      trainee_signature: this.traineeSignaturePad.toDataURL() // Corrected property name
    };
    console.log(evaluationUpdateData);
    // Make sure to include the header with the authorization token
    const token = localStorage.getItem('token');
    const headers = { Authorization: `Bearer ${token}` };

    axios.put(`/api/evaluations/${this.evaluationId}`, evaluationUpdateData, { headers })
      .then(response => {
        alert('Signature updated successfully.');
        this.populateEvaluationData(response.data);
        this.isSubmitted = status;
        this.close();
      })
      .catch(error => {
        console.error('There was an error updating the signature:', error);
        alert('Failed to update the signature.');
      });
  }
}

  },
  mounted() {
    if (this.fetchedData) {
      this.populateEvaluationData(this.fetchedData);
    }
    this.$nextTick(() => {
      this.initializeSignaturePad();
    });
  },
  beforeUnmount() { // or beforeDestroy() for Vue 2
    this.destroySignaturePad();
  },
}
</script>


<style scoped>
.tr-Evaluation-modal-overlay {
  position: absolute;
  left: 0;
  background-color: rgba(97, 94, 94, 0.5);
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: auto;
}

.tr-Evaluation-modal {
  background-color: #ffffff;
  overflow-y: auto;
  /* Enables vertical scrolling if needed */
  margin-top: 2px;
  max-height: 85vh;
  max-width: 90vw;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  position: fixed;
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
  padding: 20px;
}

.tr-modal-title {
  text-align: center;
  margin-bottom: 20px;
}

.tr-assessment-table {
  width: 100%;
  border-collapse: collapse;
  border: none;
  margin-bottom: 20px;
  align-items: center;
}

.tr-assessment-table th,
.tr-assessment-table td {
  border: none;
  padding: 8px;
  text-align: left;
}

.tr-assessment-table thead th {
  background-color: #f2f2f2;
}

.tr-signature-section {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.tr-signature-item label {
  width: 40%;
  display: block;
  margin-bottom: 5px;
}

.tr-signature-placeholder,
.tr-date-placeholder {
  border: 1px solid #ddd;
  padding: 5px;
  min-height: 30px;
  /* Adjust as needed */
}

.tr-close-button {
  background-color: #6c757d;
border-color: #6c757d;
color:white;
padding: 5px 20px;
border-radius: 5px;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  /* Aligns items to the right */
  align-items: center;
  margin-top: 20px;
  /* Adjust as needed */
}


.tr-submit-button {
  background-color: brown;
color: white;
border: none;
cursor: pointer;
border-radius: 5px;
padding: 5px 10px;
margin-left: 20px;
min-width: 100px;
max-width: 120px;
}

.tr-clear-button {
  background-color: #f0ac9c;
color:white;
padding: 5px 10px;
border-radius: 5px;
margin-right: 0;
width: 100%;
min-width: 140px;
max-width: 150px;
}

.tr-submit-button:disabled,
.tr-clear-button:disabled {
  background-color: rgb(216, 216, 216);
  cursor: not-allowed;
  color: grey
}

.tr-assessment-result {
  margin-left: 25%;
}

.tr-task-performance {
  width: 50%;
  border-collapse: collapse;
  text-align: left;
  background-color: #f2f2f2;
  margin-left: 20px;
}

.tr-task-performance th,
.tr-task-performance td {
  border: 1px solid black;

}

.tr-task-performance th {
  /* Adjust the color to match your header background */
  font-weight: bold;
}


.tr-task-performance input[type="text"] {
  width: 100%;
  border: none;
  padding: 4px;
  text-align: center;
  background-color: #f2f2f2;
}

.tr-assessment-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
}


.tr-signature-block {
  display: flex;
  flex-direction: column;
  margin-right: 70px;
}


.tr-signature-table {
  width: 100%;
}

.tr-signature-table td {
  padding: 4px;
  /* Adjust padding as needed */
  vertical-align: middle;
  /* Aligns content of <td> vertically in the middle */
}

.tr-signature-table .tr-signature {
  text-align: left;
  /* Aligns the text to the left */
}

.assessor-signature {
  text-align: left;
}

.tr-signature-table input[type="text"],
.tr-signature-table input[type="date"] {
  width: 100%;
  border: none;
  border-radius: 0px;
  background-color: transparent;
  outline: none;
  height: 20px;
}

@media (max-width: 768px) {

  /* Adjust the max-width as needed for iPad or other tablet sizes */
  .Evaluation-modal {
    padding: 5px;
    font-size: 0.9em;
    /* Reduce font size */
  }

  /* Further adjustments for other elements */
}


#selectAllSatisfactory {
  margin-left: 5px;
  border: 1px solid rgb(206, 13, 13);
}

.right {
    display: flex;
  justify-content: flex-end;
}
</style>
  