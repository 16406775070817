<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12 header-box" >
        <img class="astech-logo float-md-left" src="./astech.png" alt="">
        <img class="af-logo float-md-right" src="./Airfrance_KLM.png" alt="">
        <!-- <img class="bluesky-logo float-md-right" src="./bluesky_client_logo.png" alt=""> -->
      </div>
    </div>
    <div class="row md-4">
      <div class="col">
        <h1 class="welcome-title text-center">Welcome to cloud trial of e-Syllabus <br>for Type Practical Training</h1>
      </div>
    </div>
    <RoleSelectModal 
      v-if="showRoleModal" 
      :primaryRole="primaryRole" 
      :secondaryRole="secondaryRole" 
      @role-selected="selectRole" 
      @close="closeRoleModal()"
    />
    <div class="row md-4">
      <div class="col">
        <h5 class="welcome-title text-center" style="color: grey;">This app has been customised to broadly conform with the sample provided by Air France & KLM</h5>
        <!-- <h5 class="welcome-title text-center" style="color: grey;">This app has been customised to conform with the requirements of type practical training</h5> -->
      </div>
    </div>
  <div class="login-box">
    <div class="login-header">
      <h2 id="login-title">Login</h2>
    </div>
    <div class="login">
      <div class="input-container">
        <div class="space"></div>
        <input id="email" type="text" v-model="username" placeholder="Enter Email" />
      </div>
      <div class="input-container">
        <input id="password" type="password" v-model="password" placeholder="Enter Password" @keyup.enter="performLogin" />
      </div>
      <div class="input-container">
        <button id="user-login" type="login" v-on:click="performLogin">Login</button>
      </div>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </div>
    <div class="info-box" style="margin-top: 10%;">
      <h5 style="margin-top: 2%;"><b>For more information contact:</b></h5>
      <p style="font-size: 18px; color: grey">paul.nolan@astech.ie <br>
    rory.mccarthy@astech.ie <br>
  aravind.hota@astech.ie</p>
    </div>
  </div>
  </div>
</template>

<script>
import axios from 'axios';
import RoleSelectModal from './RoleSelectModal.vue';

export default {
  components: {
    RoleSelectModal,
  },
  data() {
    return {
      username: '',
      password: '',
      errorMessage: '',
      showRoleModal: false, // Flag for showing the modal
      primaryRole: null,    // User's primary role
      secondaryRole: null,  // User's secondary role
    };
  },
  methods: {
  async performLogin() {
    try {
      this.errorMessage = '';
      const response = await axios.post('/api/login', {
        email: this.username,
        password: this.password,
      });

      if (response.data.data.status === true) {
        const { role, second_role, token, username, user_id } = response.data.data;
        localStorage.setItem('token', token);
        localStorage.setItem('username', username);
        localStorage.setItem('user_id', user_id);

        if (second_role) {
          // Store roles temporarily and show the modal
          this.primaryRole = role;
          this.secondaryRole = second_role;
          this.showRoleModal = true;
        } else {
          this.redirectUser(role);
        }
      } else {
        this.errorMessage = response.data.message;
      }
    } catch (error) {
      console.error('Login error:', error);
      if (error.response && error.response.data) {
        this.errorMessage = `An error occurred during login: ${error.response.data.message}`;
      } else {
        this.errorMessage = 'An error occurred during login.';
      }
    }
  },
  redirectUser(role) {
    localStorage.setItem('role', role);
    switch (role) {
      case 'practical_manager':
      case 'session_manager':
      case 'examiner':
        this.$router.push('/dashboard');
        break;
      case 'trainee':
        this.$router.push('/trainee-home');
        break;
      case 'instructor':
        this.$router.push('/instructor-home');
        localStorage.setItem('isAssessor', false);
        break;
      case 'assessor':
        localStorage.setItem('isAssessor', true);
        this.$router.push('/instructor-home');
        break;
      case 'admin':
        this.$router.push('/add-user');
        break;
      default:
        console.error('Unknown user role:', role);
    }
  },
  selectRole(role) {
    this.showRoleModal = false; // Close the modal
    this.redirectUser(role);
  },
  closeRoleModal() {
    this.showRoleModal = false; 
  },
},
};
</script>

<style scoped>
.welcome-title {
  margin: 10px 0;
  /* Adjust the top and bottom margin as needed */
}

.login-box {
  background-color: #fff;
  /* Background color of the box */
  border-radius: 8px;
  /* Rounded corners for the box */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Shadow effect */
  padding: 10px;
  /* Padding inside the box */
  width: 700px;
  /* Adjust the width as needed */
  height: 320px;
  margin: 10px auto 0 auto;
  /* Center the box horizontally */
}

.logo {
  margin: 20px auto 0 auto;
  /* Center the box horizontally */

}

.login-header {
  background-color: #b3c5f7;
  text-align: left;
  height: 70px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  justify-content: left;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
}

.input-container {
  margin-bottom: 20px;
  /* Spacing between input elements */
  font-family: Helvetica;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  height: 40px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button[type="login"] {
  border-radius: 80px;
  /* Rounded corners for the box */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Shadow effect */
  background-color: #0a407a;
  /* Button background color */
  color: #fff;
  /* Button text color */
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #042344;
  /* Hover color for the button */
}

h1 {

  font-family: Helvetica, Arial, sans-serif;
  /* You can add more fallback fonts after Helvetica */
}

.error-message {
  position: absolute;
  color: #FF0000;
  /* Error message color */
  font-size: 14px;
  width: 80%;
  bottom: -60px; /* Adjust this value as needed */
  margin-bottom: 0px;
  margin-top: 0px;
}

.space {
  height: 20px;
  /* Adjust the spacing as needed */
}

.astech-logo {
  margin-top: 22px;
  margin-bottom: 10px;
  margin-left: 6%;
}

.af-logo {
  margin-top: 45px;
  margin-bottom: 10px;
  max-width: 400px;
  margin-right: 5%;

}

.bluesky-logo {
  margin-top: 20px;
  min-width: 110px;
  margin-right: 5%;
}

.container {
  max-width: 100%;
}

.info-box {
    border: 1px solid #ccc;
    padding: 20px;
    margin-top: 20px;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .header-box {
    border-bottom: 1px solid #ccc;
  }

  .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  position: fixed;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.role-btn {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.role-btn:hover {
  background-color: #0056b3;
}

</style>