<template>
  <Header />
  <h1>Aircraft</h1>

  <div class="current-time-container">
    <div class="filter-container">
      <input type="text" v-model="nameSearchQuery" placeholder="Search by Aircraft Name" />

      <button class="clear-filter-button" @click="clearFilters">Clear Filters</button>
    </div>
  </div>

  <div class="pagination-button-container">
    <div class="pagination-container">
      <Pagination :totalPages="totalPages" :perPage="perPage" :currentPage="currentPage" @page-change="onPageChange" />
    </div>
    <div class="pagination-info">
      Showing {{ startIndex + 1 }}-{{ endIndex }} of {{ filteredAircraftLength }} aircraft
    </div>
  </div>

  <edit-aircraft-modal v-if="showEditAircraft" @close="closeEditAircraft"></edit-aircraft-modal>
  <add-aircraft-modal v-if="showAddAircraft" @close="closeAddAircraft" :showViewAircraftButton="false"></add-aircraft-modal>

  <div class="container-fluid-trainee">
    <table class="data-table-trainee">
      <thead>
        <tr>
          <th class="id-column" @click="sortBy('aircraft_id')">
            ID
            <span v-if="sortField === 'aircraft_id'" v-html="getFieldArrow()"></span>
          </th>
          <th @click="sortBy('name')">
            Name
            <span v-if="sortField === 'name'" v-html="getFieldArrow('first_name')"></span>
          </th>
          <th class="id-column">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in filteredAircraft" :key="item.aircraft_id">
          <td>{{ item.aircraft_id }}</td>
          <td>{{ item.name }}</td>
          <td class="center-icons">
            <i class="fas fa-edit edit-icon" @click="openEditAircraftModal(item.aircraft_id)"></i>
            <i class="fas fa-trash-alt delete-icon" @click="deleteAircraft(item.aircraft_id)"></i>
          </td>
        </tr>
        <tr v-if="filteredAircraft.length === 0">
          <td colspan="100" style="text-align: center;">No Matching Aircraft</td>
        </tr>
      </tbody>
    </table>
  </div>
<div>
  <button class="back-button" @click="goBack">Back</button>
    <button class="btn btn-primary rounded" id=addlogbookButton @click="openAddAircraftModal">Add Aircraft</button>
  </div>
</template>
    
<script>
import Header from '../Header.vue';
import axios from 'axios';
import Pagination from '../TablePagination.vue';
import EditAircraftModal from './EditAircraftModal.vue';
import addAircraftModal from './addAircraftModal.vue';

export default {
  name: 'ViewAircraft',
  components: {
    Pagination,
    Header,
    EditAircraftModal,
    addAircraftModal,
  },

  data() {
    return {
      aircraft: [],
      sortField: 'aircraft_id',
      sortDirection: 'asc',
      nameSearchQuery: '',
      currentPage: 1,
      perPage: 10,
      totalPages: 0,
      showEditAircraft: false,
      showAddAircraft: false,
      filteredAircraftLength: 0,
    };
  },
  watch: {
    nameSearchQuery(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
  },

  async mounted() {
    this.fetchAircraft();
  },
  methods: {
    async fetchAircraft() {
      try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      // Fetch all Aircraft
      let result = await axios.get('/api/aircraft', { headers });
        this.aircraft = result.data;

      this.totalPages = Math.ceil(this.aircraft.length / this.perPage);

    } catch (error) {
      console.error('An error occurred while fetching data:', error);
    }
    },
    openEditAircraftModal(aircraft_id) {
      localStorage.setItem('aircraft_id', aircraft_id);
    this.showEditAircraft = true;
  },
  openAddAircraftModal() {
    this.showAddAircraft = true;
  },
  closeEditAircraft() {
      this.showEditAircraft = false; // Close the modal
      this.fetchAircraft();
    },
    closeAddAircraft() {
      this.showAddAircraft = false; // Close the modal
      this.fetchAircraft();
    },
    deleteAircraft(aircraft_id) {
  if (confirm("Are you sure you want to delete this aircraft?")) {
    if (confirm("This action will PERMANENTLY delete the aircraft from the database. Do you wish to continue?")) {
      // If the user confirms the deletion, send a request to delete the aircraft.
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      axios
        .delete(`/api/aircraft/${aircraft_id}`, { headers })
        .then(response => {
          if (response.status === 200) {
            // Remove the deleted aircraft from the local data
            this.aircraft = this.aircraft.filter(aircraft => aircraft.aircraft_id !== aircraft_id);

            window.alert('Aircraft deleted Successfully');
          }
        })
        .catch(error => {
          // Check if the error is due to a foreign key constraint
          if (error.response && error.response.status === 500) {
            const errorMessage = error.response.data.message;
            const isForeignKeyConstraintError = errorMessage.includes('SQLSTATE[23000]') && errorMessage.includes('Integrity constraint violation: 1451');

            if (isForeignKeyConstraintError) {
              window.alert('This Aircraft cannot be deleted because it is in one or more Practical Elements.');
            } else {
              window.alert('An error occurred while deleting the aircraft. Please try again.');
            }
          } else {
            console.error('An unexpected error occurred:', error);
            window.alert('An unexpected error occurred. Please try again.');
          }
        });
    }
  }
},
    sortBy(field) {
      if (this.sortField === field) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortField = field;
        this.sortDirection = 'asc';
      }

      this.aircraft.sort((a, b) => {
        const valueA = a[field];
        const valueB = b[field];

        if (typeof valueA === 'number' && typeof valueB === 'number') {
          return (this.sortDirection === 'asc' ? 1 : -1) * (valueA - valueB);
        } else if (typeof valueA === 'string' && typeof valueB === 'string') {
          return (this.sortDirection === 'asc' ? 1 : -1) * valueA.localeCompare(valueB);
        } else {
          return 0;
        }
      });
    },
    getFieldArrow(field = 'aircraft_id') {
      if (this.sortField === field) {
        return this.sortDirection === 'asc' ? '▲' : '▼';
      } else {
        return '';
      }
    },
    onPageChange(page) {
      this.currentPage = page;
      this.calculateTotalPages();
    },
    onNameSearch() {
      this.currentPage = 1;
    },
    calculateTotalPages() {
      this.computeFilteredAircraft();
      this.totalPages = Math.ceil(this.filteredAircraftLength / this.perPage);
    },
    computeFilteredAircraft() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;

      const filteredAircraft = this.aircraft.filter((aircraft) => {

        const name = (aircraft.name || "").toLowerCase(); // Fallback to empty string if null or undefined
        const nameMatch = name.includes(this.nameSearchQuery.toLowerCase()) || this.nameSearchQuery === '';

        return nameMatch;
      });
      console.log(filteredAircraft);
      this.filteredAircraftLength = filteredAircraft.length;
      console.log(this.filteredAircraftLength);
      return filteredAircraft.slice(startIndex, endIndex);
    },
    clearFilters() {
      // Clear all filter-related data properties
      this.nameSearchQuery = '',

        // Recalculate the total pages and update the data
        this.calculateTotalPages();
    },
    goBack() {
      this.$router.push({ name: 'products' });
    },
  },
  computed: {
    startIndex() {
      return (this.currentPage - 1) * this.perPage;
    },
    endIndex() {
      const end = this.startIndex + this.perPage;
      return end < this.filteredAircraftLength ? end : this.filteredAircraftLength;
    },
    filteredAircraft() {
      return this.computeFilteredAircraft();
    },
  },
};
</script>
    
    
<style scoped>
span:hover {
  cursor: pointer;
  color: blue;
}

.container-fluid-trainee {
  padding-top: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 100%; /* Ensure the container takes up full width */
  display: flex; /* Use flexbox for centering */
  justify-content: center; /* Center the content horizontally */
}

button.btn#addlogbookButton {
  padding: 10px 10px;
  font-size: 16px;
  width: 180px;
  height: 40px;
  margin-top: 0;
  margin-bottom: 15px;
  margin-right: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  float: right;
}

button.btn#addlogbookButton:hover {
  background-color: #0c1a61;
  transition: transform 0.2s, box-shadow 0.2s;
}


input[type="text"] {
  width: 100%;
  height: 35px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  margin-bottom: 1px;
  margin-right: 20px;
}

input[type="text"]#productFilter {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
  position: absolute;
  right: 210px;
  top: 0px;
}

input[type="text"]#projectFilter {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
  position: absolute;
  right: 210px;
  top: 41px;
}




h1 {
  padding-top: 30px;
  margin-right: 100%;
  padding-left: 3%;
}

.filter-container {
  margin: 20px 0;
  position: absolute;
  width: 200px;
  top: 0px;
  right: 20px;
}

label {
  margin-top: 8px;
}

.current-time-container {
  position: absolute;
  top: 80px;
  right: 10px;

}

.data-table-trainee {
  width: 95%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-collapse: collapse;
  margin: 0 auto; /* Center the table within its container */
  align-items: center;
  margin-bottom: 30px;
}

.data-table-trainee th,
.data-table-trainee td {
  text-align: left;
  /* Align content to the left */
  padding-left: 10px;
}

.data-table-trainee td.center-icons {
  text-align: center;
}

.pagination-container {
  margin-top: 40px;
  margin-bottom: 0;
  display: flex;
  margin-left: 3%;
}

.edit-icon {
  font-size: 16px;
  /* Adjust the size as needed */
  color: #615f5f;
  /* Change the color to your preference */
  cursor: pointer;
  transition: color 0.2s, transform 0.2s, box-shadow 0.2s;
}

.edit-icon:hover {
  color: #2b2a2a;
  /* Change the color to your preference */
  cursor: pointer;
  transform: scale(1.1);
  /* Example transform for scaling on hover */

}

.delete-icon {
  font-size: 16px;
  color: #FF0000;
  /* Red color */
  cursor: pointer;
  transition: color 0.2s, transform 0.2s, box-shadow 0.2s;
  padding-left: 15px;
}

.delete-icon:hover {
  color: #a82222;
  /* Lighter red color on hover */
  cursor: pointer;
  transform: scale(1.1);
  /* Scale the icon on hover */
  /* You can also add a box-shadow or any other styles you prefer */
}

.clear-filter-button {
  position: absolute;
  right: 50px;
  top: 112px;
  width: 120px;
  border-radius: 5px;
  background-color: #dee2e6;
  font-size: 14px;
  height: 30px;
  border: 2px solid black;
  line-height: 0px;
}

.clear-filter-button:hover {
  background-color: #cecece;
  cursor: pointer;
}

.pagination-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}


.pagination-info {
  font-size: 14px;
  color: #666;
  /* Adjust the color as needed */
  position: absolute;
  top:250px;
  left: 50%;
  /* Center horizontally */
  transform: translateX(-50%);
  /* Center horizontally */
}

.wider-column {
  width: 42%;
}

.td-logbook {
  font-size: 13px;
}


.id-column {
  width: 8%;
}

.skinny-column {
  width: 7%;
}

.back-button {
  float: left;
  width: 100px;
  margin-left: 40px;
  border-radius: 5px;
  background-color: #c1e0bf;
  font-size: 14px;
  border: 2px solid black;
  padding: 3px;
}

.back-button:hover {
  background-color: #abc7a9;
  cursor: pointer;
}
</style>