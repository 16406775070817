<template>
    <div class="Trainee-TS-modal-overlay">
        <div class="Trainee-TS-modal-dialog" @click.stop>
            <div class="Trainee-TS-modal-content">
                <div class="Trainee-TS-modal-header">
                    <h5 class="Trainee-TS-modal-title" style="padding-top: 15px;">Text to HTML</h5>
                </div>
                <div class="container-fluid" v-show="currentTab === 'troubleshoot'">
                    <div class="trainee-answer-section" style="padding-left: 15px; padding-right: 15px;">
                        <div class="artificial-fieldset">
                            <label class="artificial-fieldset-legend">Enter Text</label>
                            <div ref="editor" class="quill-editor" :class="{ 'read-only': readOnly }"></div>
                        </div>
                    </div>
                    <div class="plain-html-section" v-if="plainHTML">
                        <div class="artificial-fieldset">
                            <label class="artificial-fieldset-legend">Plain HTML</label>
                            <!-- Display plainHTML here -->
                            <div style="text-align: left;">{{ plainHTML }}</div>
                        </div>
                    </div>
                    <div class="converted-html-section" v-if="convertedHTML">
                        <div class="artificial-fieldset">
                            <label class="artificial-fieldset-legend">Rendered HTML</label>
                            <div v-html="formatHtml(convertedHTML)" style="text-align: left;"></div>
                        </div>
                    </div>
                    <div class="Trainee-modal-footer">
                        <button @click="close" class="btn btn-secondary" style="margin-top: 15px; margin-bottom: 10px;">Close</button>
                        <div class="right">
                            <button @click="saveAnswers" class="btn btn-primary">Convert</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

export default {
    name: 'ExaminerHTMLModal',
    data() {
        return {
            showTroubleshootModal: true,
            currentQuestionIndex: 0,
            showExaminerHTML: true,
            currentTab: 'troubleshoot',
            quill: null,
            convertedHTML: '',
            plainHTML: '', 
        };
    },
    watch: {
        showTroubleshootModal(newValue) {
            if (newValue && this.quill) {
                // Wait for the next tick to ensure the Quill editor is mounted and visible
                this.$nextTick(() => {
                    this.quill.root.innerHTML = this.editableTroubleshoot || '';
                });
            }
        },
        editableTroubleshoot(newVal) {
            if (newVal !== this.quill.root.innerHTML) {
                this.quill.root.innerHTML = newVal;
            }
        },
        currentTab(newVal, oldVal) {
            if (newVal === 'troubleshoot' && oldVal === 'description') {
                this.initializeQuillEditor();
            }
        },
    },
    mounted() {
        this.initializeQuillEditor();
    },
    methods: {
        initializeQuillEditor() {
        if (!this.quill) {
            this.quill = new Quill(this.$refs.editor, {
                theme: 'snow',
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline'],
                        ['blockquote', 'code-block'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    ],
                    keyboard: {
                        bindings: {
                            shift_enter: {
    key: 13,
    shiftKey: true,
    handler: (range) => {
        const lineBreakIndex = range.index;
        const lineBreakLength = 1;
        const newParagraphIndex = lineBreakIndex + lineBreakLength;
        const p = document.createElement('p');
        const nextNode = this.quill.root.childNodes[newParagraphIndex];
        const shouldContinueList = nextNode && nextNode.nodeName === 'LI';

        if (shouldContinueList) {
            // If next node is a list item, insert a line break after it
            this.quill.insertText(lineBreakIndex, '\n', Quill.sources.USER);
        } else {
            // If next node is not a list item, insert a new paragraph
            this.quill.root.insertBefore(p, nextNode);
            this.quill.setSelection(newParagraphIndex, Quill.sources.SILENT);
        }
    }
},

                        }
                    },
                }
            });

            this.quill.on('text-change', () => {
                this.editableTroubleshoot = this.quill.root.innerHTML;
            });

            this.quill.root.innerHTML = this.editableTroubleshoot || '';
            this.adjustEditorHeight();
        }
    },
    formatHtml(html) {
        const pattern = /<li class="ql-indent-1">/g;
        const formattedHtml = html.replace(pattern, '<ul></ul>');
    return formattedHtml;
  },
        adjustEditorHeight() {
            const editorElement = this.$refs.editor.querySelector('.ql-editor');
            const toolbar = this.$refs.editor.querySelector('.ql-toolbar');
            const toolbarButtons = this.$refs.editor.querySelectorAll('.ql-toolbar .ql-formats');

            if (editorElement) {
                editorElement.style.height = '260px';
                editorElement.style.fontFamily = 'monospace';
            }
            if (toolbar) {
                toolbar.style.height = '40px';
                toolbar.style.minHeight = '30px';
            }
            if (toolbarButtons) {
                toolbarButtons.forEach(btnGroup => {
                    btnGroup.style.margin = '0 2px';
                });
            }
        },
        close() {
            this.$emit('close');
        },
        saveAnswers() {
            this.convertedHTML = this.quill.root.innerHTML;
            this.plainHTML = this.quill.root.innerHTML; // Set plainHTML to Quill's HTML content
        },
        makeEditorReadOnly() {
            if (this.quill) {
                this.quill.enable(false); // This disables editing
                this.quill.getModule('toolbar').container.style.display = 'none'; // This hides the toolbar
            }
        },
    }
}
</script>

 


<style scoped>
.question p {
    font-size: 14px;
    /* Adjust the font size as desired */
    font-weight: lighter;
    /* Adjust the font weight as desired */
}

table.trainee-info-table {
    font-family: Arial, sans-serif;
    border-collapse: collapse;
    width: 95%;
    margin-top: 10px;
    margin-left: 18px;
}

.trainee-info-table th,
.trainee-info-table td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 6px;
    font-size: 14px;
}

.trainee-info-table td {
    font-weight: 100;
}

.trainee-info-table th {
    background-color: #f2f2f2;
}

.Trainee-TS-modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(97, 94, 94, 0.5);
    z-index: 1000;
    overflow-y: auto;
    min-height: calc(100vh);
}

.Trainee-TS-modal-body h2 {
    list-style-type: none;
    margin-top: 10px;
    padding: 0;
    margin-right: 1%;
    text-align: center;
    overflow-y: auto;

}

.Trainee-TS-modal-body ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: left;
    margin-left: 5%;
}

.Trainee-TS-modal-body p.heading {
    list-style-type: none;
    margin-top: 20px;
    text-align: left;
    margin-left: 1%;
}

.assessment-criteria-box {
    border: 1px solid #b1b1b1;
    /* You can adjust the border color and thickness */
    padding: 10px;
    /* Optional: Add padding for better appearance */
    margin: 10px 0;
    /* Optional: Add margin for spacing */
}


.Trainee-TS-modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: min(900px, 90%);
    width: 100%;
    margin: auto;
    padding: 0;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    overflow-y: auto;
    max-height: 85vh;
    margin-top: 40px;
}

.Troubleshoot-modal-dialog {
    display: flex;
    flex-direction: row;
    /* Arrange children (questions and answers) in a row */
}

.Trainee-TS-modal-dialog {
    max-height: 90vh;
    overflow-y: auto;
}

.Troubleshoot-modal-body {
    display: flex;

}

.question-item {
    margin: 10px 0;
    text-align: left;
    font-weight: bold;
}

.Trainee-questions-container>ol>li>ul {
    list-style-type: disc;
    /* Adds a bullet point (dot) */
    padding-left: 40px;
    /* Indent subpoints further than the main list */
}

.Trainee-questions-container ul li {
    margin: 5px 0;
}

.Trainee-questions-container {
    text-align: left;
}

button {
    width: 100px;
    padding: 10px;
    cursor: pointer;
}

.btn-primary {
    margin-bottom: 0px;
}

.questions-list {
    text-align: left;
}

.Trainee-questions-container h6,
.Trainee-answers-container h6 {
    width: 100%;
    background-color: rgb(216, 216, 216);
    /* Light grey background */
    /* Border for the header */
    text-align: center;

}


.list-group-item {
    background-color: transparent;
    /* Remove default background */
}

.list-group-numbered {
    counter-reset: list-group-counter;
    text-align: left;
}

.list-group-numbered>.list-group-item:before {
    content: counter(list-group-counter) ". ";
    counter-increment: list-group-counter;
}

/* Style for the nested ul */
.Trainee-questions-container ul {
    list-style-type: none;
    /* Removes bullet points */
    padding-left: 20px;
    /* Indent subpoints */
}

.Trainee-answers-container {
    outline: none;
    z-index: 1000;
    width: 60%;
}

.Trainee-modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.Trainee-modal-footer .right {
    margin-top: 1%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    /* Adjust the gap between buttons */
}

.nav-link {
    cursor: pointer;
}

.artificial-fieldset {
    border: 1px solid rgba(44, 62, 80, 0.658);
    padding: 30px;
    margin-top: 20px;
    position: relative;

}

.artificial-fieldset-legend {
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: #fff;
    padding: 0 10px;
    font-weight: bold;
}


.question {
    text-align: left;
    font-weight: bold;
}

.navigation-buttons {
    margin-top: 5px;
}

.nav-button {
    padding: 5px 15px;
    /* Adjust padding to change button size */
    margin: 0 5px;
    /* Space between buttons */

}


.ql-toolbar {
    height: 40px;
    min-height: 40px;
}


.ql-toolbar .ql-formats {
    margin: 0 2px;
}

.ql-container .ql-editor {
    height: 200px;
    min-height: 200px;
}

h4 {
    font-size: 1.5rem;
    margin-top: 30px;
    margin-bottom: 30px;
}</style>