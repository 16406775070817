<template>
  <div>
    <Header />
    <div class="container card">
      <h1>Add Session</h1>
      <div class="add">
        <form @submit.prevent="addProject">
          <div class="form-group">
            <label for="role">Practical Element:</label>
            <select class="form-control rounded" id="prod_id" name="prod_id" v-model="newProject.prod_id">
              <option value=""></option>
              <option v-for="product in products" :key="product.product_id" :value="product.product_id">{{ product.name }}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="start_date">Start Date:</label>
            <input type="date" class="form-control rounded" id="start_date" name="start_date" placeholder="YYYY-MM-DD" v-model="newProject.start_date" />
          </div>


          <div class="form-group">
            <label for="end_date">End Date:</label>
            <input type="date" class="form-control rounded" id="end_date" name="end_date" placeholder="YYYY-MM-DD" v-model="newProject.end_date" />
          </div>


          <div class="form-group">
            <label for="name">Name:</label>
            <input type="text" class="form-control rounded" id="name" name="name" placeholder="Enter Session Name"
              v-model="newProject.name" />
          </div>
          <div v-if="showAlert" class="alert alert-danger error-message" role="alert">
            <div v-if="errorMessage">{{ errorMessage }}</div>
          </div>
          <div>
            <button class="btn btn-primary rounded" type="submit">Add Session</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
  
  
  
<script>
import axios from 'axios';
import Header from '../Header.vue';

export default {
  name: 'AddProject',
  components: {
    Header
  },
  data() {
    return {
      instructors: [],
      products: [],
      projects: [],
      newProject: {
        name: '',
        start_date: '',
        end_date: '',
        prod_id: '',
        product_name: '', 
      },
      showAlert: false,
      currentTime: '',
      errorMessages: {},
      errorMessage: '',
      loggedInUser: '',
      selectedInstructors: [null, null],
    };
  },
  watch: {
        'newProject.prod_id': function (newProductId) {
            if (newProductId && this.products) {
                const selectedProduct = this.products.find(product => product.product_id === newProductId);
                if (selectedProduct) {
                    this.newProject.name = selectedProduct.name;
                }
            }
        }
    },
  methods: {
    updateCurrentTime() {
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');
      this.currentTime = `${hours}:${minutes}:${seconds}`;
    },
    async addProject() {
  try {
    // Check if end date is before start date
    if (this.newProject.end_date && this.newProject.start_date && this.newProject.end_date < this.newProject.start_date) {
      this.errorMessage = 'End date cannot be before start date.';
      this.showAlert = true;
      return;
    }

    const token = localStorage.getItem('token');
    const headers = { Authorization: `Bearer ${token}` };

    const response = await axios.post(
      '/api/projects',
      {
        name: this.newProject.name,
        start_date: this.newProject.start_date,
        end_date: this.newProject.end_date,
        prod_id: this.newProject.prod_id,
        product_name: this.newProject.product_name,
      },
      { headers }
    );

    if (response.status === 201) {
      window.alert('Session added Successfully');
      this.newProject = {
        name: '',
        start_date: '',
        end_date: '',
        prod_id: '',
        product_name: '',
      };
      this.errorMessage = '';
      this.showAlert = false;
    }
    await this.fetchProjects();
    this.$router.push({ name: 'ViewProjects' });
  } catch (error) {
    console.error('Error adding Session:', error);

    if (error.response && error.response.data && error.response.data.errors) {
      // Handle validation errors
      const validationErrors = error.response.data.errors;

      // Build an error message from the validation errors
      let errorMessage = 'Validation errors:';
      for (const field in validationErrors) {
        errorMessage += `\n${field}: ${validationErrors[field][0]}`;
      }

      this.errorMessage = errorMessage;
    } else {
      // Handle other errors

      this.errorMessage = 'An error occurred while adding the user.';
    }

    this.showAlert = true;
  }
},
    async fetchProjects() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.display('unauthorized');
          return;
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get('/api/projects', { headers });

        if (response.status === 200) {
          this.projects = response.data.projects;
        }
      } catch (error) {
        console.error('Error retrieving Sessions:', error);
        // Handle error
      }
    },
  async fetchProducts() {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.display('unauthorized');
        return;
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get('/api/products', { headers });

      if (response.status === 200) {
        this.products = response.data; // Populate the products array with API data
      }
    } catch (error) {
      console.error('Error retrieving Practical Elements:', error);
      // Handle error
    }
  },
  async fetchInstructors() {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.display('unauthorized');
        return;
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get('/api/users', { headers });

      if (response.status === 200) {
      // Filter the response data to include only users with the role 'instructor'
      this.instructors = response.data.filter(user => user.role === 'instructor' || user.second_role === 'instructor');
      }
    } catch (error) {
      console.error('Error retrieving instructors:', error);
      // Handle error
    }
  },

  },
  async mounted() {
    await this.fetchInstructors();
    await this.fetchProjects();
    this.fetchProducts();
    
  },
};
</script>
  
<style scoped>
.container.card {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  margin-top: 10px;
  min-width: 90%;
  min-height: 90%;
}

.alert-danger {
    color: #ff0019;
    background-color: #f8d7da;
    border: 1px solid #e6b0b6;
}

.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 0rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}

.add button {
  width: 200px;
  height: 40px;
  margin-bottom: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
}

button.btn:hover {
  background-color: #0c1a61;
  transition: transform 0.2s, box-shadow 0.2s;
}

input.form-control,
select.form-control {
  width: 70%;
  margin-left: 200px;
  display: inline-block;
  margin-bottom: 35px;
  align-items: baseline;
}

.form-group {
  margin-bottom: 15px;
}

label {
  margin-right: 5px;
  position: absolute;
  margin-top: 8px;
  font-size: 18px;

}

.form-control {
  border-radius: 5px;
}

.form-control#start_date, .form-control#end_date {
  border-radius: 5px;
  width: 25%;
  margin-left: 200px;
  margin-right: 45%;
}

h1 {
  margin-bottom: 50px;
}

.alert-danger {
    color: #ff0019;
    background-color: #f8d7da;
    border: 1px solid #e6b0b6;
}

.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 0rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}
</style>