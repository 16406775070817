<template>
  <div>
    <page-header></page-header>
    <h1>Multi Sign Off:</h1>
    <!-- <div class="select-project-container">
  <label for="selectedProject" style="margin-right: 20px;">Select Project:</label>
  <select v-model="selectedProject" @change="onProjectSelectionChange">
    <option value="">All Sessions</option>
    <option v-for="project in projects" :key="project.project_id" :value="project.project_id">
      {{ project.name }}
    </option>
  </select> 
</div> -->
    <!-- <div class="select-task-container">
      <label for="selectedTask" style="margin-right: 20px;">Select Task to Sign Off:</label>
      <select v-model="selectedTask" @change="onTaskSelectionChange">
        <option value="">All Tasks</option>
        <option v-for="task in tasks" :key="task.task_id" :value="task.task_id">
          {{ task.description }}
        </option>
      </select>
    </div> -->
    <div class="signoff-buttons">
      <button @click="toggleSelectAllTasks" class="multi-sign-off-button">
          {{ isAllTasksSelected ? 'Deselect All' : 'Select All' }}
        </button>
        <button @click="signOffAll" class="multi-sign-off-button">Sign Off All</button>
      </div>
    <div class="container-fluid-trainee">
      <table class="data-table-trainee">
        <thead>
          <tr>
            <th>Task</th>
            <th v-for="logbook in filteredLogbooks" :key="logbook.logbook_id">
              <span v-if="logbook.trainee">{{ logbook.trainee.first_name + ' ' + logbook.trainee.last_name }}</span>
            </th>
          </tr>
        </thead>
        <tbody>
<tr v-for="task in tasks" :key="task.task_id" v-show="shouldShowTask(task)">
  <td>{{ `${task.task_no}, ${task.description}` }}</td>
  <td v-for="logbook in filteredLogbooks" :key="logbook.logbook_id" :class="{ 'completed-task': getEntry(task, logbook).completed }">
    <!-- Find the corresponding entry for the current task and logbook -->
    <div>
      <span v-if="getEntry(task, logbook).ins_so">{{ getEntry(task, logbook).ins_so }}</span>
      <input v-else type="checkbox" :checked="isTaskCompleted(task, logbook)" @change="toggleTaskCompletion(task, logbook)"/>
    </div>
  </td>
</tr>

        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import PageHeader from './InstructorHeader.vue';
import axios from 'axios';

export default {
  name: 'MultiSignOff',
  data() {
    return {
      loggedInUser: '',
      logbooks: [],
      tasks: [],
      projects: [],
      traineeNames: [],
      entries: [],
      selectedTasks: [],
      isAllTasksSelected: false,
      selectedTask: '',
      selectedProject: '',
      trainees: [],
    };
  },
  components: {
    PageHeader,
  },
  async mounted() {
    const instructorId = localStorage.getItem('user_id');
    const token = localStorage.getItem('token');
    const headers = { Authorization: `Bearer ${token}` };
    // Include the trainee_id parameter in the URL
    const result = await axios.get(`/api/instructor/${instructorId}/logbooks`, { headers });

    //console.log(result);
    this.logbooks = result.data.logbooks;
    console.log(this.logbooks);


    const projectResult = await axios.get(`/api/users/${instructorId}/projects`, { headers });
    this.projects = projectResult.data;
    console.log(this.projects);

    const taskResult = await axios.get(`/api/tasks`, { headers });
    this.tasks = taskResult.data;
    console.log(this.tasks);


    const entryResult = await axios.get(`/api/logbookentry`, { headers });
    this.entries = entryResult.data;

      // Filter tasks that have at least one entry
  this.tasks = this.tasks.filter(task =>
    this.entries.some(entry => entry.task_id === task.task_id)
  );

  const traineeResult = await axios.get(`/api/users`, { headers });
this.trainees = traineeResult.data.filter(user => user.role === 'trainee' || user.second_role === 'trainee');

console.log(this.trainees);


  // Map logbooks to trainees
  this.logbooks = this.logbooks.map(logbook => {
    // Find the trainee with the matching user_id
    const trainee = this.trainees.find(trainee => trainee.user_id === logbook.trainee_id);

    // If a matching trainee is found, add it to the logbook
    if (trainee) {
      logbook.trainee = trainee; // Add trainee information to the logbook
    }

    return logbook;
  });

  console.log(this.logbooks);

    // // Extract trainee names
    // this.traineeNames = this.logbooks.map(logbook => {
    //   // Split the logbook name based on the hyphen
    //   const parts = logbook.name.split('-');

    //   // Get the last part which should be the trainee name
    //   const traineeName = parts[parts.length - 1].trim();

    //   return traineeName;
    // });

  },
  computed: {
    filteredLogbooks() {
      return this.logbooks.filter(logbook =>
        this.tasks.some(task => this.getEntry(task, logbook).completed)
      );
    }
  },
  methods: {
    onProjectSelectionChange() {
    // Reset other selections when project is changed
    this.selectedTasks = [];
    this.isAllTasksSelected = false;
  },

    onTaskSelectionChange() {
      // Reset other selections when task is changed
      this.selectedTasks = [];
      this.isAllTasksSelected = false;
    },

    // New method to determine whether to show a task based on the selected task
    shouldShowTask(task) {
      return !this.selectedTask || task.task_id === this.selectedTask;
    },
    getEntry(task, logbook) {
      return this.entries.find(entry => entry.task_id === task.task_id && entry.logbook_id === logbook.logbook_id) || {};
    },
    isTaskCompleted(task, logbook) {
      return this.selectedTasks.some(selectedTask => selectedTask.task.task_id === task.task_id && selectedTask.logbook.logbook_id === logbook.logbook_id);
    },

    toggleSelectAllTasks() {
      if (this.isAllTasksSelected) {
        // Deselect all tasks
        this.selectedTasks = [];
      } else {
        // Select all completed tasks
        this.selectedTasks = [];

        this.tasks.forEach(task => {
          this.filteredLogbooks.forEach(logbook => {
            if (this.getEntry(task, logbook).completed) {
              this.selectedTasks.push({ task, logbook });
            }
          });
        });
      }

      // Toggle the state
      this.isAllTasksSelected = !this.isAllTasksSelected;
    },

    toggleTaskCompletion(task, logbook) {
      const index = this.selectedTasks.findIndex(selectedTask => selectedTask.task.task_id === task.task_id && selectedTask.logbook.logbook_id === logbook.logbook_id);

      if (index === -1) {
        // Task is not in the selectedTasks array, add it
        this.selectedTasks.push({ task, logbook });
      } else {
        // Task is already in the selectedTasks array, remove it
        this.selectedTasks.splice(index, 1);
      }
    },

    selectAllTasks() {
      this.selectedTasks = [];

      this.tasks.forEach(task => {
        this.logbooks.forEach(logbook => {
          if (this.getEntry(task, logbook).completed) {
            this.selectedTasks.push({ task, logbook });
          }
        });
      });
    },
    formatDate(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    async setTimestamp(task, logbook) {
  const currentDate = new Date();
  const formattedDate = this.formatDate(currentDate);

  const entry = this.getEntry(task, logbook);

  // Check if the entry exists before updating the timestamp
  if (entry && !entry.ins_so && entry.completed) {
    entry.ins_so = formattedDate;
    entry.isReadOnly = true;
    try {
          const token = localStorage.getItem('token');
          const headers = { Authorization: `Bearer ${token}` };
          const response = await axios.put(`/api/logbookentry/${logbook.logbook_id}/${task.task_id}`, {
            ins_so: formattedDate
          }, { headers });
          console.log("success in updating logbook entry:", response);
          // Handle the response if needed (e.g. check if the update was successful)
        } catch (error) {
          console.error("Error updating logbook entry:", error);
        }
  } else {
    // Handle the case where the entry is not found (optional)
    console.error(`Entry not found for task_id: ${task.task_id} and logbook_id: ${logbook.logbook_id}`);
  }
},

//     signOffAll() {
//       if (this.selectedTasks.length === 0) {
//         alert('No tasks selected for sign-off.');
//         return;
//       }
//       if (confirm('Are you sure you want to sign off all selected tasks?')) {
//         this.selectedTasks.forEach(task => {
//           this.setTimestamp(task);
//         });

//       }
//       this.selectedTasks = [];
//     },
// setTimestamp(task, logbook) {
//       const currentDate = new Date();
//       const formattedDate = currentDate.toISOString(); // Use ISO format for timestamp

//       const entry = this.getEntry(task, logbook);

//       // Check if the entry exists before updating the timestamp
//       if (entry) {
//         entry.ins_so = formattedDate;
//         entry.isReadOnly = true;
//       } else {
//         // Handle the case where the entry is not found (optional)
//         console.error(`Entry not found for task_id: ${task.task_id} and logbook_id: ${logbook.logbook_id}`);
//       }
//     },

    signOffAll() {
      if (this.selectedTasks.length === 0) {
        alert('No tasks selected for sign-off.');
        return;
      }

      if (confirm('Are you sure you want to sign off all selected tasks?')) {
        this.selectedTasks.forEach(task => {
          this.setTimestamp(task.task, task.logbook);
        });
      }

      this.selectedTasks = [];
    },
  },

}

</script>

<style scoped>
.select-project-container {
  float: left; /* Adjust this margin as needed */
  margin-left: 50px;
}

.select-project-container select {
  max-width: 250px;
}
span {
  border: 0px;
}
.container-fluid-trainee {
  padding-top: 30px;
  margin-left: 2%;
  margin-right: 2%;
}

h2 {
  font-size: 1.3rem;
  margin-bottom: 25px;
}

h1 {
  font-size: 2rem;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-top: 0px;
  top: 15px;
}

.completed-task {
  background-color: #a2f5a1;
  /* or any other color you want for completed tasks */
}

.signoff-buttons{
  display: flex;
  justify-content: flex-end;
  margin-top: -50px;
  margin-right: 10px;
}

.select-task-container {
  float: left; /* Adjust this margin as needed */
  margin-left: 50px;
}

.select-task-container select {
  max-width: 250px;
}
</style>
