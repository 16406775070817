<template>
    <div class="ins-TS-modal-overlay">
        <div class="Trainee-TS-modal-dialog" @click.stop>
            <div class="Trainee-TS-modal-content">
                <div class="Trainee-TS-modal-header">
                    <h5 class="Trainee-TS-modal-title" style="padding-top: 15px;">Examinable Task</h5>
                    <h5 class="Trainee-TS-modal-title" style="padding-top: 5px;"><b>Chapter {{ chaptNo }}: {{ chapterName }}</b></h5>
                </div>
                <div class="container-fluid" v-show="currentTab === 'troubleshoot'">
                        <div class="question">
                            <div>
        <div class="artificial-fieldset">
            <label class="artificial-fieldset-legend">FLIGHT DECK EFFECT</label>
                            <table class="trainee-info-table">
                                <tr>
                                <td style="width:60%; text-align: left;">Logbook entry</td>
                                <td><input type="checkbox" v-model="filteredTask.logbook_entry" style="transform: scale(1.3);" disabled/></td>
                            </tr>
                            <tr>
                                <td style="width:60%; text-align: left;">Flight deck effect</td>
                                <td><input type="checkbox" v-model="filteredTask.flight_deck" style="transform: scale(1.3);" disabled/></td>
                            </tr>
                            <tr>
                                <td style="width:60%; text-align: left;">Crew/Maintenance observation</td>
                                <td><input type="checkbox" v-model="filteredTask.observation"  style="transform: scale(1.3);" disabled/></td>
                            </tr>
                            <tr>
                                <td style="width:100%; text-align: left; border:none" v-html="extraInfo"></td>
                            </tr>
                            </table>
                        </div>
                        </div>
                        <div class="artificial-fieldset">
                            <label class="artificial-fieldset-legend">CMCS FAULT</label>
                            <p style="margin-top: 20px; margin-left: 20px;">{{ filteredTask.CMCS_fault }}</p>
            </div>
        </div>
                        <div class="artificial-fieldset">
                            <label class="artificial-fieldset-legend">QUESTIONS</label>
                            <div class="questions-section" v-html="formattedQuestions" style="text-align: left; margin-top: 20px; font-size: 14px; margin-left: 30px;"></div>
                    </div>
                </div>
                    <div class="trainee-answer-section" style="padding-left: 15px; padding-right: 15px;">
                        <div class="artificial-fieldset">
                            <label class="artificial-fieldset-legend">ANSWERS</label>
                            <div ref="editor" class="quill-editor" :class="{ 'read-only': readOnly }"></div>
                        </div>
                    </div>
                    <div class="Trainee-modal-footer">
                        <button @click="close" class="btn btn-secondary"
                            style="margin-top: 15px; margin-bottom: 10px;">Close</button>
                    </div>
                </div>
            </div>
        </div>
</template>
  

<script>
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import axios from 'axios';

export default {
    name: 'troubleshootModal',
    props: {
        taskId: {
            type: [String, Number],
            required: true
        },
        logbookId: {
            type: [String, Number],
            required: true
        },
        troubleshoot: String,
        taskExplanation: String,
        chaptNo: String
    },
    data() {
        return {
            isTableDisabled: true,
            currentQuestionIndex: 0,
            showTroubleshootModal: true,
            editableTroubleshoot: this.troubleshoot,
            currentTab: 'troubleshoot',
            quill: null,
            chapterName: null,
            exam_tasks: [],
            filteredTask: {
                CMCS_fault: '',
                logbook_entry: false, 
                flight_deck: false,   
                observation: false    
            },
        };
    },
    watch: {
        filteredTask: {
            handler(newVal) {
                // Check if filteredTask contains questions and format them
                if (newVal.questions) {
                    const pattern = /<li class="ql-indent-1">/g;
                    const formattedHtml = newVal.questions.replace(pattern, '<ul></ul>');

                    this.formattedQuestions = formattedHtml;
                    
                } else {
                    this.formattedQuestions = ''; // Clear formattedQuestions if questions are not available
                }
            // Check if filteredTask contains extra_info and assign it
            if (newVal.extra_info) {
                this.extraInfo = newVal.extra_info;
            } else {
                this.extraInfo = ''; // Clear extraInfo if it's not available
            }
            },
            deep: true, // Watch for nested changes
            immediate: true, // Trigger on component mount
        },
        showTroubleshootModal(newValue) {
            if (newValue && this.quill) {
                // Wait for the next tick to ensure the Quill editor is mounted and visible
                this.$nextTick(() => {
                    this.quill.root.innerHTML = this.editableTroubleshoot || '';
                });
            }
        },
        currentTab(newVal, oldVal) {
            if (newVal === 'troubleshoot' && oldVal === 'description') {
                this.initializeQuillEditor();
            }
        },
    },

    mounted() {
        this.loadChapterName();
        this.initializeQuillEditor();
        this.loadExamData();
    },
    methods: {
        initializeQuillEditor() {
            if (!this.quill) {
                this.quill = new Quill(this.$refs.editor, {
                    theme: 'snow',
                    modules: {
                        toolbar: false
                    },
                    readOnly:true
                });

                this.quill.root.innerHTML = this.editableTroubleshoot || '';
                this.adjustEditorHeight();
            }
        },
        loadExamData() {
    const token = localStorage.getItem('token');
    const headers = { Authorization: `Bearer ${token}` };
    const productId = localStorage.getItem('productId');

    axios.get(`/api/exam-tasks/products/${productId}`, { headers })
        .then(response => {
            this.exam_tasks = response.data;
            
            // Filter out the exam_task that matches taskNo and chaptNo
            const filteredTasks = this.exam_tasks.filter(task => {
                return task.chapt_no === this.chaptNo;
            });
            console.log(this.chaptNo);

            this.filteredTask = filteredTasks[0];

            this.filteredTask.logbook_entry = filteredTasks[0].logbook_entry === 1 ? true : false;
            this.filteredTask.flight_deck = filteredTasks[0].flight_deck === 1 ? true : false;
            this.filteredTask.observation = filteredTasks[0].observation === 1 ? true : false;
            this.filteredTask.CMCS_fault = filteredTasks[0].CMCS_fault;
            this.filteredTask.extra_info = filteredTasks[0].extra_info;

            console.log(this.filteredTask.logbook_entry);

            console.log(response.data);
            console.log(this.filteredTask);

        })
        .catch(error => {
            console.error('Failed to load answers', error);
            // Handle the error, show a message, etc.
        });
},
        loadChapterName() {
  const token = localStorage.getItem('token');
  const headers = { Authorization: `Bearer ${token}` };

  // Assuming you have an API endpoint to get chapter information
  axios.get(`/api/chapters`, { headers })
    .then(response => {
      const foundChapter = response.data.find(chapter => chapter.chapt_no === this.chaptNo);

      console.log(response.data);
      console.log(this.chaptNo);
      console.log(foundChapter);

      if (foundChapter) {
        this.chapterName = foundChapter.name;
      } else {
        console.error('Chapter not found for chaptNo:', this.chaptNo);
        // Handle the case where the chapter is not found
      }
    })
    .catch(error => {
      console.error('Failed to load chapter name', error);
      // Handle the error, show a message, etc.
    });
},
        adjustEditorHeight() {
            const editorElement = this.$refs.editor.querySelector('.ql-editor');
            const toolbar = this.$refs.editor.querySelector('.ql-toolbar');
            const toolbarButtons = this.$refs.editor.querySelectorAll('.ql-toolbar .ql-formats');

            if (editorElement) {
                editorElement.style.height = '360px';
                editorElement.style.fontFamily = 'monospace';
            }
            if (toolbar) {
                toolbar.style.height = '40px';
                toolbar.style.minHeight = '30px';
            }
            if (toolbarButtons) {
                toolbarButtons.forEach(btnGroup => {
                    btnGroup.style.margin = '0 2px';
                });
            }
        },
        close() {
            this.$emit('close');
        },
        prevQuestion() {
            if (this.currentQuestionIndex > 0) {
                this.currentQuestionIndex--;
                // The computed property 'currentQuestion' will automatically update the question displayed
            }

        },
        nextQuestion() {
            if (this.currentQuestionIndex < this.questions.length - 1) {
                this.currentQuestionIndex++;
            }
        }
    }
}


</script>

<style scoped>
.question p {
    font-size: 14px;
    font-weight: lighter;
    cursor: not-allowed;
}

.ins-TS-modal-overlay {
    position: absolute;
    top: 0px;
    left: 0;
    background-color: rgba(97, 94, 94, 0.5);
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.ins-TS-modal-content {
    background-color: #ffffff;
    max-height: 84vh;
    width: 1000px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-wrap: break-word;
    overflow: auto;
}

button {
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100px;
    background-color: #616161;
    border: none;
    cursor: pointer;
}

.ins-modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ins-modal-footer .right {
    margin-top: 1%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    /* Adjust the gap between buttons */
}


.artificial-fieldset {
    border: 1px solid #777777;
    padding: 10px;
    margin-top: 20px;
    position: relative;
}

.artificial-fieldset-legend {
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: #fff;
    padding: 0 10px;
    font-weight: bold;
}

.question {
    text-align: left;
    font-weight: bold;
}


.disabled-table input {
  cursor: not-allowed;
}
</style>