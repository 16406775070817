<template>
  <div>

    <head>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
    </head>
    <page-header></page-header>

    <div class="content">
      <div class="row">
        <div class="product-container">
          <div class="product-table-heading">
            <table>
              <td style="width:83%; font-size: 34px;">
                Practical Elements
              </td>
              <td>
                <button class="addchapter-button" @click="viewChapters">Add/View Chapters</button>
              </td>
              <td>
                <button class="addaircraft-button" @click="viewAircraft">Add/View Aircraft</button>
              </td>
              <td>
                <button class="addspecific-button" @click="viewSpecifics">Add/View Specifics</button>
              </td>
              <td>
                <button class="addproduct-button" @click="addProductsModal()">Add Practical Element</button>
              </td>
            </table>
          </div>
          <table v-if="products.length > 0" class="product-box">
            <tr v-for="product in products" :key="product.product_id">
                <div class="product-card">
                  <div class="product-table">
                    <table style="width: 100%;">
                      <td style="width: 40%; text-align: left;">{{ product.name }}</td>
                      <td style="width: 18%;"><button class="view-button" @click="viewProduct(product)">View Tasks</button>
                      </td>
                      <td style="width: 12%;">
                        <!-- <button class="viewProjects-button" @click="openProjectsModal(product)">View Sessions</button> -->
                      </td>
                      <td class="createdat" style="width: 20%;">Created at: {{ formatDate(product.created_at) }}</td>
                      <td style="width: 20%;">
                        <i class="fas fa-edit edit-icon" @click="viewEditProduct(product.product_id)"></i>
                        <i class="fas fa-trash-alt delete-icon" @click="deleteProduct(product.product_id)"></i>
                      </td>
                    </table>
                  </div>
                </div>
            </tr>
          </table>
          <div v-else class="no-products">
            <p style="color: red; margin-top: 50px;">There are no practical elements. Click on <strong>Add Practical Element</strong> to add practical elements.</p>
          </div>

          <edit-product-modal v-if="showEditProduct" @close="closeEditProduct"></edit-product-modal>
          <add-products-modal v-if="showProductsModal" @close="closeProductsModal" />
          <add-specifics-modal v-if="showSpecificsModal" @close="closeSpecificsModal" />
          <add-aircraft-modal v-if="showAircraftModal" @close="closeAircraftModal" :showViewAircraftButton="true"/>
          <view-projects-modal v-if="showProjectsModal" :show-projects-modal="showProjectsModal"
            :selectedProductName="selectedProductName" :selectedProductId="selectedProductId"
            @close="closeProjectsModal" />
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
import PageHeader from '@/components/Examiner/Header';
import ViewProjectsModal from './ViewProjectsModal.vue';
import AddProductsModal from './addProductsModal.vue';
import AddSpecificsModal from './addSpecificsModal.vue';
import AddAircraftModal from './addAircraftModal.vue';
import EditProductModal from './EditProductModal.vue';

export default {
  name: 'viewProducts',
  components: {
    PageHeader, // Register the PageHeader component
    ViewProjectsModal,
    AddProductsModal,
    AddSpecificsModal,
    AddAircraftModal,
    EditProductModal,
  },
  data() {
    return {
      username: '',
      products: [],
      showProjectsModal: false,
      showProductsModal: false,
      showEditProduct: false,
      showSpecificsModal: false,
      showAircraftModal: false,
      projects: [],
      selectedproductId: null,
      selectedproductName: null,

    };
  },
  async mounted() {
    // Check the user's role before accessing this page
    const role = localStorage.getItem('role');
    if (role !== 'practical_manager' && role !== 'examiner') {
      // Redirect to the login page if the user is not an examiner
      this.$router.push({ name: 'LoginPage' });
    }
    this.fetchProducts();
    window.onload = function () {
      document.body.classList.add('page-loaded');
    };
    if (this.$route.query.showModal === 'true') {
      this.showProductsModal = true;
    }

  },
  methods: {
    async fetchProducts() {
      try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      let result = await axios.get('/api/products', { headers });
      this.products = result.data;
      this.username = localStorage.getItem('username');
    }
    catch (error) {
      console.error('Error fetching Practical Elements:', error);
    }
    },
    logout() {
      localStorage.clear();
      window.addEventListener('popstate', () => {
        location.reload();
      });
      this.$router.push({ name: 'LoginPage' });
    },
    openProjectsModal(product) {
      this.selectedProductName = product.name;
      this.selectedProductId = product.product_id;
      console.log("id", this.selectedProductId);
      console.log(product);
      this.showProjectsModal = true;
    },
    closeProjectsModal() {
      this.showProjectsModal = false;
    },
    addProductsModal() {
      this.showProductsModal = true;
    },
    addAircraftModal() {
      this.showAircraftModal = true;
    },
    addSpecificsModal() {
      this.showSpecificsModal = true;
    },
    viewChapters () {
            this.$router.push({name: 'ViewChapters'});
        },
        viewAircraft () {
            this.$router.push({name: 'ViewAircraft'});
        },
        viewSpecifics () {
            this.$router.push({name: 'ViewSpecifics'});
        },
    closeProductsModal() {
      this.showProductsModal = false;
    },
    closeAircraftModal() {
      this.showAircraftModal = false;
    },
    closeSpecificsModal() {
      this.showSpecificsModal = false;
    },
    viewEditProduct(product_id) {
      console.log(product_id)
      localStorage.setItem('product_id', product_id);
      this.showEditProduct = true; //Show the modal when the button is clicked
    },
    closeEditProduct() {
      this.showEditProduct = false; // Close the modal
      this.fetchProducts();
    },
    viewProduct(product) {
      const productId = parseInt(product.product_id, 10);
      this.$store.commit('SET_SELECTED_PRODUCT', product);
      localStorage.setItem('product_id', productId);
      this.$router.push({
        name: 'productPage', params: { productId: product.product_id }
      });
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
  deleteProduct(product_id) {
  if (confirm("Are you sure you want to delete this Practical Element?")) {
    if (confirm("This action will PERMANENTLY delete the Practical Element from the database. Do you wish to continue?")) {
      // If the user confirms the deletion, send a request to delete the Practical Element.
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      axios
        .delete(`/api/products/${product_id}`, { headers })
        .then(response => {
          if (response.status === 200) {
            // Remove the deleted product from the local data
            this.products = this.products.filter(product => product.product_id !== product_id);

            window.alert('Practical Element deleted Successfully');
          }
        })
        .catch(error => {
          // Check if the error is due to a foreign key constraint
          if (error.response && error.response.status === 500) {
            const errorMessage = error.response.data.message;
            const isForeignKeyConstraintError = errorMessage.includes('SQLSTATE[23000]') && errorMessage.includes('Integrity constraint violation: 1451');

            if (isForeignKeyConstraintError) {
              window.alert('This Practical Element cannot be deleted because it contains one or more Sessions.');
            } else {
              window.alert('An error occurred while deleting the Practical Element. Please try again.');
            }
          } else {
            console.error('An unexpected error occurred:', error);
            window.alert('An unexpected error occurred. Please try again.');
          }
        });
    }
  }
},
  },
};
</script>
  
<style scoped>
.content {
  display: flex;
  flex-direction: column;
  padding-top: 1%;
  margin-left: 5%;
  margin-right: 5%;
  /* Add space from the top */
}

h1 {
  padding-top: 0px;
}

.product-container {
  background-color: rgb(255, 255, 255);
  width: 100%;
  position: relative;
  flex-wrap: wrap;
  margin-top: 5px;
  margin-left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-box {
  margin: 10px;
  width: 99%;
  height: 10px;
}

.viewbutton-container {
  display: flex;
  justify-content: flex-start;
  /* Align the button to the left within the container */
  width: 100%;
  /* Make the container take up the full width */
  margin-top: 0;
}

.product-table-heading {
  width: 100%;
  text-align: left;
}

.product-card {
  position: relative;
  background-color: #ffffff;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2),
    /* Right and bottom */
    -3px -3px 3px rgba(0, 0, 0, 0.2),
    /* Left and top */
    0 0 3px rgba(0, 0, 0, 0.2);
  /* Inner shadow */
  transition: transform 0.2s ease-in-out;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin-bottom:2px;
}

.product-card:hover {
  transform: scale(1.03);
}

.product-card h2 {
  text-align: left;
  margin-left: 5px;
  font-size: 15px;
}


.product-header h2,
.product-header button,
.product-header div {
  margin: 10px;
  /* Remove any default margins */
}

.view-button {
  background-color: #ffffff;
  color: #0a407a;
  padding: 10px;
  width: 140px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: auto;
  margin-left: 0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: left;
}

.viewProjects-button {
  background-color: #ffffff;
  color: #0a407a;
  padding: 10px 15px;
  width: 150px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: auto;
  margin-left: 0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.product-table,
.product-table tr,
.product-table td {
  border: none;
  border-collapse: collapse;
}

.product-table {
  width: 100%;
  border: none;
}

.createdat {
  text-align: right;
  margin-right: 0;
  padding-right: 0;
}

.addproduct-button {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #0a407a;
  color: #ffffff;
  padding: 10px 15px;
  position: absolute;
  float: right;
  right: 10px;
  border: none;
  border-radius: 4px;
  min-width: 220px;
  cursor: pointer;
  margin-bottom: auto;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  transition: transform 0.2s ease-in-out;
}

.addproduct-button:hover {
  background-color: #08325f;
  cursor: pointer;
  transform: scale(1.02);
  border: 1px solid #062444;
}

.addaircraft-button {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #5a5f64;
  color: #ffffff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center; 
  position: absolute;
  float: right;
  right: 470px;
  top: 8px;
  border: none;
  border-radius: 4px;
  min-width: 180px;
  height: 35px;
  cursor: pointer;
  margin-bottom: auto;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  transition: transform 0.2s ease-in-out;
}

.addaircraft-button:hover {
  background-color: #3e4246;
  cursor: pointer;
  transform: scale(1.02);
  border: 1px solid #062444;
}

.addspecific-button {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #5a5f64;
  color: #ffffff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center; 
  position: absolute;
  float: right;
  top: 8px;
  right: 270px;
  border: none;
  border-radius: 4px;
  min-width: 180px;
  height: 35px;
  cursor: pointer;
  margin-bottom: auto;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  transition: transform 0.2s ease-in-out;
}

.addspecific-button:hover {
  background-color: #3e4246;
  cursor: pointer;
  transform: scale(1.02);
  border: 1px solid #062444;
}

.addchapter-button {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #5a5f64;
  color: #ffffff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center; 
  position: absolute;
  float: right;
  top: 8px;
  right: 670px;
  border: none;
  border-radius: 4px;
  min-width: 180px;
  height: 35px;
  cursor: pointer;
  margin-bottom: auto;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  transition: transform 0.2s ease-in-out;
}

.addchapter-button:hover {
  background-color: #3e4246;
  cursor: pointer;
  transform: scale(1.02);
  border: 1px solid #062444;
}

.edit-icon {
  font-size: 16px;
  color: #615f5f;
  cursor: pointer;
  transition: color 0.2s, transform 0.2s, box-shadow 0.2s;
  margin-left: 30px;
}

.edit-icon:hover {
  color: #2b2a2a;
  cursor: pointer;
  transform: scale(1.1);
}


.delete-icon {
  font-size: 16px;
  color: #FF0000;
  /* Red color */
  cursor: pointer;
  transition: color 0.2s, transform 0.2s, box-shadow 0.2s;
  margin-left: 15px;
}

.delete-icon:hover {
  color: #a82222;
  cursor: pointer;
  transform: scale(1.1);
}
</style>
  