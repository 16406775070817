<template>
    <Header />
    <div class="container">
        <div class="logged-in-label">Logged in as: </div>
        <div class="logged-in-info"> {{ loggedInUser }}</div>
    </div>
    <h1>Tasks</h1>

    <div class="current-time-container">
        <button class="btn btn-primary mr-3" id=homeButton @click="goHome">Back to Home</button>
        <div class="time-label">Time:</div>
        <div class="current-time">{{ currentTime }}</div>
    </div>

    <div class="search-container">
        <input type="text" v-model="logbookSearchQuery" placeholder="Search by Logbook ID">
        <input type="text" v-model="traineeSearchQuery" placeholder="Search by Trainee ID">
        <button @click="clearSearch">Clear</button>
    </div>

    <div class="pagination-container">
        <Pagination :totalPages="totalPages" :perPage="perPage" :currentPage="currentPage" @page-change="onPageChange" />
    </div>


    <div class="container-fluid">
        <table class="table custom-table">
            <thead>
                <tr>
                    <th @click="sortBy('task_id')" class="skinny-column">
                        Task ID
                        <span v-if="sortField === 'task_id'" v-html="getFieldArrow()"></span>
                    </th>
                    <th>Trainee ID</th>
                    <th>Logbook ID</th>
                    <th>Work Order No</th>
                    <th class="wider-column">Task Detail</th>
                    <th>Category</th>
                    <th @click="sortBy('ATA')">
                        ATA
                        <span v-if="sortField === 'ATA'" v-html="getFieldArrow('ATA')"></span>
                    </th>
                    <th>Trainee SignOff</th>
                    <th>Instructor SignOff</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in filteredTasks" :key="item.task_id">
                    <td>{{ item.task_id }}</td>
                    <td>{{ item.trainee_id }}</td>
                    <td>{{ item.logbook_id }}</td>
                    <td>{{ item.work_order_no }} </td>
                    <td>{{ item.task_detail }} </td>
                    <td>{{ item.category }} </td>
                    <td>{{ item.ATA }} </td>
                    <td>{{ item.TEE_SO }} </td>
                    <td>{{ item.INS_SO }} </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import Header from './Header.vue';
import axios from 'axios';
import Pagination from './TablePagination.vue';

export default {
    name: 'ViewTasks',
    data() {
        return {
            tasks: [],
            currentTime: '',
            sortField: 'task_id',
            sortDirection: 'asc',
            searchQuery: '',
            loggedInUser: '',
            currentPage: 1,
            perPage: 5,
            totalPages: 0,
            logbookSearchQuery: '',
            traineeSearchQuery: '',
        }
    },
    watch: {
        traineeSearchQuery(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
            }
        },
        logbookSearchQuery(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
            }
        },

    },
    components: {
        Pagination,
        Header
    },
    async mounted() {
        this.updateCurrentTime(); // Update current time immediately
        setInterval(this.updateCurrentTime, 1000); // Update current time every second

        this.fetchLoggedInUser();

        try {
            const token = localStorage.getItem('examiner-token');
            const headers = { Authorization: `Bearer ${token}` };

            let result = await axios.get('http://46.101.94.150/api/tasks', { headers });
            this.tasks = result.data;

            this.totalPages = Math.ceil(this.tasks.length / this.perPage);

        } catch (error) {
            console.error('An error occurred while fetching data:', error);
        }
    },
    methods: {
        updateCurrentTime() {
            const now = new Date();
            const hours = now.getHours().toString().padStart(2, '0');
            const minutes = now.getMinutes().toString().padStart(2, '0');
            const seconds = now.getSeconds().toString().padStart(2, '0');
            this.currentTime = `${hours}:${minutes}:${seconds}`;
        },
        sortBy(field) {
            if (this.sortField === field) {
                this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
            } else {
                this.sortField = field;
                this.sortDirection = 'asc';
            }

            this.tasks.sort((a, b) => {
                const valueA = a[field];
                const valueB = b[field];

                if (typeof valueA === 'number' && typeof valueB === 'number') {
                    return (this.sortDirection === 'asc' ? 1 : -1) * (valueA - valueB);
                } else if (typeof valueA === 'string' && typeof valueB === 'string') {
                    return (this.sortDirection === 'asc' ? 1 : -1) * valueA.localeCompare(valueB);
                } else {
                    return 0;
                }
            });
        },
        getFieldArrow(field = 'task_id') {
            if (this.sortField === field) {
                return this.sortDirection === 'asc' ? '▲' : '▼';
            } else {
                return '';
            }
        },
        onPageChange(page) {
            this.currentPage = page;

            this.calculateTotalPages();
        },
        async fetchLoggedInUser() {
            try {
                const token = localStorage.getItem('examiner-token');
                const headers = { Authorization: `Bearer ${token}` };

                const response = await axios.get('http://46.101.94.150/api/user', { headers });
                this.loggedInUser = response.data.username;
            } catch (error) {
                console.error('An error occurred while fetching the logged-in user:', error);
            }
        },
        goHome() {
            this.$router.push({ name: 'examinerHome' })
        },
        clearSearch() {
            this.logbookSearchQuery = '';
            this.traineeSearchQuery = '';
        },


    },
    computed: {
        filteredTasks() {
            const startIndex = (this.currentPage - 1) * this.perPage;
            const endIndex = startIndex + this.perPage;

            return this.tasks.filter((item) => {
                const logbookMatch =
                    item.logbook_id.toString().includes(this.logbookSearchQuery) ||
                    this.logbookSearchQuery === '';

                const traineeMatch =
                    item.trainee_id.toString().includes(this.traineeSearchQuery) ||
                    this.traineeSearchQuery === '';

                return logbookMatch && traineeMatch;
            }).slice(startIndex, endIndex);
        },
    },
}

</script>

<style scoped>
h2 .trainees-header,
.instructors-header {
    margin-left: 5%;
    text-align: left;
}

.wider-column {
    width: 40%;
}

.search-container input[type="text"] {
    padding: 10px;
    margin-left: 20px;
    font-size: 16px;
    width: 30%;
}

span:hover {
    cursor: pointer;
    color: blue;
}

.search-container button {
    padding: 10px 20px;
    font-size: 16px;
    margin: 5px;
}
</style>