<template>
    <div class="Trainee-TS-modal-overlay">
        <div class="Trainee-TS-modal-dialog" @click.stop>
            <div class="Trainee-TS-modal-content">
                <div class="Trainee-TS-modal-header">
                    <h5 class="Trainee-TS-modal-title" style="padding-top: 15px;">Examinable Task</h5>
                    <h5 class="Trainee-TS-modal-title" style="padding-top: 5px;"><b>Chapter {{ chaptNo }}: {{ chapterName }}</b></h5>
                </div>
                <div class="container-fluid" v-show="currentTab === 'troubleshoot'">
                        <div class="question">
                            <div>
        <div class="artificial-fieldset">
            <label class="artificial-fieldset-legend">FLIGHT DECK EFFECT</label>
                            <table class="trainee-info-table">
                                <tr>
                                <td style="width:60%; text-align: left;">Logbook entry</td>
                                <td><input type="checkbox" v-model="filteredTask.logbook_entry" style="transform: scale(1.3);" disabled/></td>
                            </tr>
                            <tr>
                                <td style="width:60%; text-align: left;">Flight deck effect</td>
                                <td><input type="checkbox" v-model="filteredTask.flight_deck" style="transform: scale(1.3);" disabled/></td>
                            </tr>
                            <tr>
                                <td style="width:60%; text-align: left;">Crew/Maintenance observation</td>
                                <td><input type="checkbox" v-model="filteredTask.observation" style="transform: scale(1.3);" disabled/></td>
                            </tr>
                            <tr>
                                <td style="width:100%; text-align: left; border:none" v-html="extraInfo"></td>
                            </tr>
                            </table>
                        </div>
                        </div>
                        <div class="artificial-fieldset">
                                    <label class="artificial-fieldset-legend">CMCS FAULT</label>
                <p style="margin-top: 20px; margin-left: 20px;">{{ filteredTask.CMCS_fault }}</p>
            </div>
        </div>
                        <div class="artificial-fieldset">
                            <label class="artificial-fieldset-legend">QUESTIONS</label>
                        <!-- <h6 class="questions" style="margin-right: 850px;"><b>QUESTIONS</b></h6> -->
                        <div class="questions-section" v-html="formattedQuestions" style="text-align: left; margin-top: 20px; font-size: 14px; margin-left: 20px;"></div>
                    </div>
                </div>
                    <div class="trainee-answer-section" style="padding-left: 15px; padding-right: 15px;">
                        <div class="artificial-fieldset">
                            <label class="artificial-fieldset-legend">ANSWERS</label>
                            <div ref="editor" class="quill-editor" :class="{ 'read-only': readOnly }"></div>
                        </div>
                    </div>
                    <div class="Trainee-modal-footer">
                        <button id="close" @click="close" class="btn btn-secondary"
                            style="margin-top: 15px; margin-bottom: 10px;">Close</button>
                        <div class="right">
                            <button id="save" @click="saveAnswers" class="btn btn-primary" :disabled="is_submitted === 1">Save</button>
                            <button id="submit" @click="submitAnswers" class="btn btn-primary"
                                style="background-color: brown; border: 1px solid rgb(112, 28, 28);" :disabled="is_submitted === 1">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>


<script>
import axios from 'axios';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

export default {
    name: 'troubleshootModal',
    props: {
        taskId: {
            type: [String, Number],
            required: true
        },
        logbookId: {
            type: [String, Number],
            required: true
        },
        troubleshoot: String,
        taskExplanation: String,
        chaptNo: String
    },
    data() {
        return {
            currentQuestionIndex: 0,
            showTroubleshootModal: true,
            editableTroubleshoot: this.troubleshoot,
            currentTab: 'troubleshoot',
            quill: null,
            chapterName: null,
            exam_tasks: [],
            filteredTask: {
                CMCS_fault: '',
                logbook_entry: false,
                flight_deck: false,  
                observation: false    
            },
            formattedQuestions: '',
            extraInfo: '',
        };

    },
    watch: {
        filteredTask: {
            handler(newVal) {
                // Check if filteredTask contains questions and format them
                if (newVal.questions) {
        const pattern = /<li class="ql-indent-1">/g;
        const formattedHtml = newVal.questions.replace(pattern, '<ul></ul>');

                    this.formattedQuestions = formattedHtml;
                } else {
                    this.formattedQuestions = ''; // Clear formattedQuestions if questions are not available
                }
            // Check if filteredTask contains extra_info and assign it
            if (newVal.extra_info) {
                this.extraInfo = newVal.extra_info;
            } else {
                this.extraInfo = ''; // Clear extraInfo if it's not available
            }
            },
            deep: true, // Watch for nested changes
            immediate: true, // Trigger on component mount
        },
        showTroubleshootModal(newValue) {
            if (newValue && this.quill) {
                // Wait for the next tick to ensure the Quill editor is mounted and visible
                this.$nextTick(() => {
                    this.quill.root.innerHTML = this.editableTroubleshoot || '';
                });
            }
        },
        editableTroubleshoot(newVal) {
            if (newVal !== this.quill.root.innerHTML) {
                this.quill.root.innerHTML = newVal;
            }
        },
        currentTab(newVal, oldVal) {
            if (newVal === 'troubleshoot' && oldVal === 'description') {
                this.initializeQuillEditor();
            }
        },
    },

    mounted() {
        this.loadChapterName();
        this.initializeQuillEditor();
        this.loadAnswers();
        this.loadExamData();
    },
    methods: {
        initializeQuillEditor() {
            if (!this.quill) {
                this.quill = new Quill(this.$refs.editor, {
                    theme: 'snow',
                    modules: {
                        toolbar: [
                            ['bold', 'italic', 'underline'],
                            ['blockquote', 'code-block'],
                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        ]
                    }
                });


                this.quill.on('text-change', () => {
                    this.editableTroubleshoot = this.quill.root.innerHTML;
                });

                this.quill.root.innerHTML = this.editableTroubleshoot || '';
                this.adjustEditorHeight();
            }
        },
        loadAnswers() {
            const token = localStorage.getItem('token');
            const headers = { Authorization: `Bearer ${token}` };

            axios.get(`/api/logbookentry/${this.logbookId}/${this.taskId}`, { headers })
                .then(response => {
                    const storedAnswer = response.data.task.troubleshoot; // Adjust based on your API response structure
                    console.log(response.data);
                    this.editableTroubleshoot = storedAnswer;
                    this.is_submitted = response.data.task.is_submitted;
                    console.log(this.is_submitted);
                    

                    // After loading the answer, update the Quill editor content
                    if (this.quill) {
                        this.quill.root.innerHTML = storedAnswer || '';
                    }
                    if (this.is_submitted === 1) {
                        this.makeEditorReadOnly(); // Apply the read-only state if needed
                    }
                })
                .catch(error => {
                    console.error('Failed to load answers', error);
                    // Handle the error, show a message, etc.
                });
        },
        loadExamData() {
    const token = localStorage.getItem('token');
    const headers = { Authorization: `Bearer ${token}` };
    const productId = localStorage.getItem('productId');

    axios.get(`/api/exam-tasks/products/${productId}`, { headers })
        .then(response => {
            this.exam_tasks = response.data;
            
            // Filter out the exam_task that matches taskNo and chaptNo
            const filteredTasks = this.exam_tasks.filter(task => {
                return task.chapt_no === this.chaptNo;
            });
            console.log(this.chaptNo);

            this.filteredTask = filteredTasks[0];

            this.filteredTask.logbook_entry = filteredTasks[0].logbook_entry === 1 ? true : false;
            this.filteredTask.flight_deck = filteredTasks[0].flight_deck === 1 ? true : false;
            this.filteredTask.observation = filteredTasks[0].observation === 1 ? true : false;
            this.filteredTask.CMCS_fault = filteredTasks[0].CMCS_fault;
            this.filteredTask.extra_info = filteredTasks[0].extra_info;

            console.log(this.filteredTask.logbook_entry);

            console.log('response');
            console.log(response.data);
            console.log(this.filteredTask);

        })
        .catch(error => {
            console.error('Failed to load answers', error);
            // Handle the error, show a message, etc.
        });
},

        loadChapterName() {
  const token = localStorage.getItem('token');
  const headers = { Authorization: `Bearer ${token}` };

  // Assuming you have an API endpoint to get chapter information
  axios.get(`/api/chapters`, { headers })
    .then(response => {
      const foundChapter = response.data.find(chapter => chapter.chapt_no === this.chaptNo);

      console.log(response.data);
      console.log(this.chaptNo);
      console.log(foundChapter);

      if (foundChapter) {
        this.chapterName = foundChapter.name;
      } else {
        console.error('Chapter not found for chaptNo:', this.chaptNo);
        // Handle the case where the chapter is not found
      }
    })
    .catch(error => {
      console.error('Failed to load chapter name', error);
      // Handle the error, show a message, etc.
    });
},

        adjustEditorHeight() {
            const editorElement = this.$refs.editor.querySelector('.ql-editor');
            const toolbar = this.$refs.editor.querySelector('.ql-toolbar');
            const toolbarButtons = this.$refs.editor.querySelectorAll('.ql-toolbar .ql-formats');

            if (editorElement) {
                editorElement.style.height = '260px';
                editorElement.style.fontFamily = 'monospace';
            }
            if (toolbar) {
                toolbar.style.height = '40px';
                toolbar.style.minHeight = '30px';
            }
            if (toolbarButtons) {
                toolbarButtons.forEach(btnGroup => {
                    btnGroup.style.margin = '0 2px';
                });
            }
        },
        close() {
            this.$emit('close');
        },
        saveAnswers() {
            this.saveOrUpdateAnswers(false);
        },
        submitAnswers() {
            if (window.confirm('Once you submit, you cannot edit the answers. Are you sure you want to continue?')) {
                this.saveOrUpdateAnswers(true);
            }
        },
        saveOrUpdateAnswers(isSubmitting) {
            const formattedTroubleshoot = this.quill.root.innerHTML;
            const url = `/api/logbookentry/${this.logbookId}/${this.taskId}`;
            const token = localStorage.getItem('token');
            const headers = { Authorization: `Bearer ${token}` };

            // Prepare the data to be sent in the request
            const requestData = { troubleshoot: formattedTroubleshoot };

            // If submitting, include the is_submitted value in the request data
            if (isSubmitting) {
                requestData.is_submitted = true;
            }

            axios.put(url, requestData, { headers })
                .then(response => {
                    console.log(response.data);
                    alert('Answer saved successfully.');
                    this.editableTroubleshoot = formattedTroubleshoot;

                    if (isSubmitting) {
                        this.makeEditorReadOnly();
                    }
                    this.loadAnswers();
                    this.$emit('close');
                })
                .catch(error => {
                    console.error('There was an error!', error);
                    alert('Failed to save the answer.');
                });
        },
        makeEditorReadOnly() {
            if (this.quill) {
                this.quill.enable(false); // This disables editing
                this.quill.getModule('toolbar').container.style.display = 'none'; // This hides the toolbar
            }
        },

        prevQuestion() {
            if (this.currentQuestionIndex > 0) {
                this.currentQuestionIndex--;
                // The computed property 'currentQuestion' will automatically update the question displayed
            }

        },
        nextQuestion() {
            if (this.currentQuestionIndex < this.questions.length - 1) {
                this.currentQuestionIndex++;
            }
        }
    }
}
</script>
 


<style>
.question p {
    font-size: 14px;
    /* Adjust the font size as desired */
    font-weight: lighter;
    /* Adjust the font weight as desired */
}

table.trainee-info-table {
    font-family: Arial, sans-serif;
    border-collapse: collapse;
    width: 95%;
    margin-top: 10px;
    margin-left: 18px;
}

.trainee-info-table th,
.trainee-info-table td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 6px;
    font-size: 14px;
}

.trainee-info-table td {
    font-weight: 100;
}

.trainee-info-table th {
    background-color: #f2f2f2;
}

.Trainee-TS-modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(97, 94, 94, 0.5);
    z-index: 1000;
    JUSTIFY-CONTENT: CENTER;
    overflow-y: auto;
}

.Trainee-TS-modal-body h2 {
    list-style-type: none;
    margin-top: 10px;
    padding: 0;
    margin-right: 1%;
    text-align: center;
    overflow-y: auto;

}

.Trainee-TS-modal-body ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: left;
    margin-left: 5%;
}

.Trainee-TS-modal-body p.heading {
    list-style-type: none;
    margin-top: 20px;
    text-align: left;
    margin-left: 1%;
}

.assessment-criteria-box {
    border: 1px solid #b1b1b1;
    /* You can adjust the border color and thickness */
    padding: 10px;
    /* Optional: Add padding for better appearance */
    margin: 10px 0;
    /* Optional: Add margin for spacing */
}


.Trainee-TS-modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: min(900px, 90%);
    width: 100%;
    margin: auto;
    padding: 0;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    overflow-y: auto;
    max-height: 85vh;
    margin-top: 40px;
}

.Troubleshoot-modal-dialog {
    display: flex;
    flex-direction: row;
    /* Arrange children (questions and answers) in a row */
}

.Trainee-TS-modal-dialog {
    max-height: 90vh;
    overflow-y: auto;
}

.Troubleshoot-modal-body {
    display: flex;

}

.question-item {
    margin: 10px 0;
    text-align: left;
    font-weight: bold;
}

.Trainee-questions-container>ol>li>ul {
    list-style-type: disc;
    /* Adds a bullet point (dot) */
    padding-left: 40px;
    /* Indent subpoints further than the main list */
}

.Trainee-questions-container ul li {
    margin: 5px 0;
}

.Trainee-questions-container {
    text-align: left;
}

button {
    margin-top: 20px;
    /* Adjust as needed */
    width: 100px;
    /* Adjust as needed */
    padding: 10px;
    /* Adjust as needed */
    background-color: #f2f2f2;
    /* Style as needed */
    border: none;
    /* Style as needed */
    cursor: pointer;
    /* Style as needed */
    /* Add more styling as needed */
}

.btn-primary {
    margin-bottom: 0px;
}

.questions-list {
    text-align: left;
}

.Trainee-questions-container h6,
.Trainee-answers-container h6 {
    width: 100%;
    background-color: rgb(216, 216, 216);
    /* Light grey background */
    /* Border for the header */
    text-align: center;

}


.list-group-item {
    background-color: transparent;
    /* Remove default background */
}

.list-group-numbered {
    counter-reset: list-group-counter;
    text-align: left;
}

.list-group-numbered>.list-group-item:before {
    content: counter(list-group-counter) ". ";
    counter-increment: list-group-counter;
}

/* Style for the nested ul */
.Trainee-questions-container ul {
    list-style-type: none;
    /* Removes bullet points */
    padding-left: 20px;
    /* Indent subpoints */
}

.Trainee-answers-container {
    outline: none;
    z-index: 1000;
    width: 60%;
}

.Trainee-modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.Trainee-modal-footer .right {
    margin-top: 1%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    /* Adjust the gap between buttons */
}

.nav-link {
    cursor: pointer;
}

.artificial-fieldset {
    border: 1px solid rgba(44, 62, 80, 0.658);
    padding: 10px;
    margin-top: 20px;
    position: relative;
}

.artificial-fieldset-legend {
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: #fff;
    padding: 0 10px;
    font-weight: bold;
}


.question {
    text-align: left;
    font-weight: bold;
}

.navigation-buttons {
    margin-top: 5px;
}

.nav-button {
    padding: 5px 15px;
    /* Adjust padding to change button size */
    margin: 0 5px;
    /* Space between buttons */

}


.ql-toolbar {
    height: 40px;
    min-height: 40px;
}


.ql-toolbar .ql-formats {
    margin: 0 2px;
}

.ql-container .ql-editor {
    height: 200px;
    min-height: 200px;
}

h4 {
    font-size: 1.5rem;
    margin-top: 30px;
    margin-bottom: 30px;
}

:disabled {
    cursor: not-allowed;
}

</style>