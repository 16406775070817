<template>
    <div>

    </div>
    <signature-modal v-if="showSignature" @close="closeSignature"></signature-modal>
</template>  
<script>
import axios from 'axios';
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import SignatureModal from '../SignatureModal.vue';

export default {
    components: {
        SignatureModal,
    },
    data() {
    return {
      tasks: [],
      showSignature: false,
    };
  },
  async mounted() {
    this.fetchTasksForSelectedProduct();
  },
    methods: {
        openSignatureModal() {
    this.showSignature = true;
  },
  closeSignature() {
      this.showSignature = false; // Close the modal
    },
        generateTaskPDF() {
      // Create a new instance of jsPDF
      const pdf = new jsPDF();

      if (!this.tasks || this.tasks.length === 0) {
    console.error('Tasks not available');
    return;
  }

      // Add the "Tasks for Product:" heading to the PDF content
      pdf.setFont('helvetica', 'bold');
      pdf.setFontSize(12); // Adjust the font size if needed
      pdf.text(`Practical Element: ${this.selectedProductName}`, 10, 15);

      // Add a horizontal line underneath the title
      pdf.line(10, 20, 200, 20);

      pdf.text(`Tasks:`, 10, 35);
      pdf.setFont('helvetica', 'normal'); // Reset the font weight to normal
      const imgData = require('./astech.png');
      const imgWidth = 22; // Adjust the width of the image
      const imgHeight = 10; // Adjust the height of the image
      const imgX = pdf.internal.pageSize.width - imgWidth - 10; // Adjust the X position
      const imgY = 7.5; // Adjust the Y position
      pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth, imgHeight);

      // Define the table columns and rows
      //const columns = ['Task ID', 'Task No', 'Sect ID', 'Chapt. No', 'TType', 'Manob', 'Level', 'Description'];
      const columns = ['Task ID', 'Task No', 'Reference', 'Scope', 'Category', 'M/O', 'Est. Time', 'Chapter No.', 'Description'];
      const data = this.tasks.map(item => [
        item.task_id,
        item.task_no,
        item.reference,
        item.scope,
        item.category,
        item.manob,
        item.est_time,
        item.chapt_no,
        item.description,
      ]);

      // AutoTable options
      const options = {
        startY: 40,
        margin: { horizontal: 10 },
        styles: {
          lineWidth: 0.1, // Set the border line width
          lineColor: [169, 169, 169], // Set the border color (black)
        },
      };

      // Add the table to the PDF document
      pdf.autoTable(columns, data, options);


      pdf.setFontSize(11);
      pdf.text(`Signature:`, 38, 280)

pdf.setDrawColor(0, 0, 255);
pdf.setLineWidth(0.1);

  // Draw clickable area as a rectangle
  const clickableArea = {
    x: 60,
    y: 258,
    width: 100,
    height: 20,
  };
  pdf.rect(clickableArea.x, clickableArea.y, clickableArea.width, clickableArea.height);

  // Add the click event to the rectangle
  pdf.link(
    clickableArea.x,
    clickableArea.y,
    clickableArea.width,
    clickableArea.height,
  );

pdf.setDrawColor(0, 0, 0);
pdf.setLineWidth(0.1);
      // Add a horizontal line 
      pdf.line(60, 280, 160, 280);


      // Add a horizontal line 
      pdf.line(10, 285, 200, 285);

      // Calculate the total number of pages after rendering the table
      const totalPages = pdf.internal.getNumberOfPages();
      // Add the footer to each page
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(8);
        const pageWidth = pdf.internal.pageSize.width;

        pdf.text(`Author: ${this.joinedName}`, 10, 290);

        const currentDate = new Date();
        const formattedDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`;
        // Calculate the width of the "Printed by" text and the formatted date
        const text = `Printed by: ${this.joinedName} on ${formattedDate}`;
        const textWidth = (pdf.getStringUnitWidth(text) * 8) / 3 ;

        // Calculate the X position to center the text
        const x = (pageWidth - textWidth) / 2;

        pdf.text(text, x, 290);

        pdf.text(`Page ${i} of ${totalPages}`, pdf.internal.pageSize.width - 25, 290); // Adjust the coordinates as needed
      }


      const blob = pdf.output('blob');
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    },
    async fetchTasksForSelectedProduct() {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        const product_id = localStorage.getItem('product_id');

        let taskResult = await axios.get('/api/tasks', { headers });
        this.tasks = taskResult.data.filter(task => task.prod_id === product_id);

        console.log('tasks');
        console.log(this.tasks);

        this.totalPages = Math.ceil(this.tasks.length / this.perPage);

        // const selectedProduct = this.products.find(product => product.product_id === product_id);
        // this.selectedProductName = selectedProduct ? selectedProduct.name : '';

        const user_id = localStorage.getItem('user_id');

        const userResult = await axios.get(`/api/users/${user_id}`, { headers });

        const userFullName = userResult.data.first_name + ' ' + userResult.data.last_name;

        this.joinedName = userFullName;

        // Generate PDF content after fetching tasks
      } catch (error) {
        console.error('An error occurred while fetching tasks:', error);
      }
    },
    }
}
</script>

  
  
<style scoped>
h1.page-title {
    text-align: left;
    padding-top: 100px;
    font-size: 22px;
    margin-left: 3%;
    padding-bottom: 10px;
}
</style>
