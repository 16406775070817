<head>
    <meta name="viewport" content="width=device-width, initial-scale=1">
  </head>
  <template>
  <div class="Evaluation-modal-overlay">
    <div class="Evaluation-modal">
      <h3 class="Evaluation-modal-title" style="color: #474747; font-size: 1.5rem;">Candidate {{ trainee.license_no }} | Task Assessment | {{ trainee.last_name }}, {{ trainee.first_name }} ({{ trainee.place_of_birth }}, {{ trainee.date_of_birth }}) </h3>
      <h3 class="Evaluation-modal-title" style="color: #474747; font-size: 1.5rem;">Chapter No: {{ selectedTask.chapt_no }} | Task No: {{ selectedTask.task_no }}</h3>
      <div>
        <p style="text-align: left; font-size: 12px; color: #474747"><br>
          <strong>Note:</strong> The final practical assessment should not be conducted before all the mandatory practical tasks have been completed. The
  Assessor Notes should give a detailed assessment of the candidate in the Assessors own words.
        </p>
      </div>
          <table class="assessment-table" style="margin-top: 5px; margin-bottom: 10px;">
            <tbody style="border: 2px solid black;">
              <tr style="border-top: 2px solid black;">
                <td style="text-align: center; border-bottom: 1px solid grey;">
                  <strong>Assessor Notes on Candidate During Final Assessment:</strong>
                </td>
              </tr>
              <tr>
                <td style="border-top: 1px solid black;">
                  <textarea v-model="assessorNotes" style="width: 100%; height: 90px;" :disabled="isSubmitted > 1"></textarea>
                </td>
              </tr>
            </tbody>
          </table>
      <table class="assessment-table" style="border: 2px solid black;">
        <thead style="border-bottom: 2px solid black;">
          <tr>
            <th rowspan="2" style="width: 78%;">Assessment Checks</th>
            <th colspan="3">Performance result</th>
          </tr>
          <tr>
            <th style="width: 11%;">
              Yes
              <div style="border:1px solid grey; font-weight:100;border-radius: 5px;">
                <input type="checkbox" id="selectAllYes" @change="toggleAllYes"
                  :disabled="isCheckboxDisabled">Select All
              </div>
            </th>
            <th style="width: 10%;">No</th>
          </tr>
        </thead>
        <tbody>
          <!-- Inside the <tbody> tag -->
          <tr v-for="(item) in criteriaResults" :key="item.criterionId">
            <td>{{ criteria[item.criterionId - 1] }}</td>
            <td>
              <input type="radio" :id="`yes${item.criterionId}`" :name="`result${item.criterionId}`"
                value="yes" v-model="item.result" :disabled="isSubmitted > 1">
              <label :for="`yes${item.criterionId}`"></label>
            </td>
            <td>
              <input type="radio" :id="`no${item.criterionId}`" :name="`result${item.criterionId}`"
                value="no" v-model="item.result" :disabled="isSubmitted > 1">
              <label :for="`no${item.criterionId}`"></label>
            </td>
          </tr>
          <tr>
            <td colspan="4" style="border-top: 2px solid black;">
              <b>Note:</b> If the assessor has marked any part of the assessment as NO the candidate has not passed and must carry out another assessment.
            </td>
          </tr>
        </tbody>
      </table>
      <div style="background-color:#f2f2f2; padding: 20px; border: 1px solid #ccc;">
        <div class="assessment-result">
          <table>
            <td style="width:50%; font-weight: bold;">
              RESULT OF THE ASSESSMENT
            </td>
            <td style="width:20%; border:none;" :style="assessmentResultStyle">
              <span v-if="assessmentResult" style="border:none">{{ assessmentResult.toUpperCase() }}</span>
              <span v-else style="border:none">-</span>
            </td>
          </table>
        </div>
        <div class="assessment-container">
          <div class="signature-block">
            <table class="signature-table" style="margin-left: 30px;">
              <tr class="trainee-signature">
                  <td style="font-weight:bold;">TRAINEE SIGNATURE:</td>
                  <td style="cursor: not-allowed;">
                    <canvas ref="traineeSignaturePadCanvas" id="trainee-signature-pad" class="signature-pad" width="270"
                      height="60"></canvas>
                  </td>
                  <td>
                    <button class="tee-clear-button" @click="clearTraineeSignature" disabled>Clear</button>
                  </td>
                </tr>
              <tr class="signature">
                <td style="font-weight:bold">INSTRUCTOR SIGNATURE:</td>
                <td>
                  <canvas ref="assessorSignaturePadCanvas" id="assessor-signature-pad" class="signature-pad" width="270"
                    height="60"></canvas>
                </td>
                <td>
                  <button class="ins-clear-button" @click="clearAssessorSignature" :disabled="isSubmitted > 1">Clear</button>
                </td>
              </tr>
              <tr class="signature">
                <td style="font-weight:bold">DATE:</td>
                <td><input type="date" id="date" name="date" v-model="date" :disabled="isSubmitted > 1" /></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <table class="assessment-table" style="border: 2px solid black; margin-top: 20px;">
    <thead style="border-bottom: 2px solid black;">
      <tr>
        <th style="width: 78%;">Trainee Confirmations</th>
        <th style="width: 11%;">
          Yes
          <div style="border:1px solid grey; font-weight:100;border-radius: 5px;">
            <input type="checkbox" id="selectAllYes" @change="toggleAllTraineeConfirmationsYes"
              disabled>Select All
          </div>
        </th>
        <th style="width: 10%;">No</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item) in traineeConfirmations" :key="item.traineeConfirmationId">
        <td>{{ trainee_confirmations[item.traineeConfirmationId - 1 ] }}</td>
        <td>
          <input type="radio" :id="`traineeYesConfirmation${item.traineeConfirmationId}`" :name="`traineeConfirmationResult${item.traineeConfirmationId}`"
            value="yes" v-model="item.result" disabled>
          <label :for="`traineeYesConfirmation${item.traineeConfirmationId}`"></label>
        </td>
        <td>
          <input type="radio" :id="`traineeNoConfirmation${item.traineeConfirmationId}`" :name="`traineeConfirmationResult${item.traineeConfirmationId}`"
            value="no" v-model="item.result" disabled>
          <label :for="`traineeNoConfirmation${item.traineeConfirmationId}`"></label>
        </td>
      </tr>
    </tbody>
  </table>
      <table class="assessment-table" style="border: 2px solid black; margin-top: 0px;">
    <thead style="border-bottom: 2px solid black;">
      <tr>
        <th style="width: 78%;">Assessor Confirmations</th>
        <th style="width: 11%;">
          Yes
          <div style="border:1px solid grey; font-weight:100;border-radius: 5px;">
            <input type="checkbox" id="selectAllYes" @change="toggleAllConfirmationsYes"
              :disabled="isCheckboxDisabled">Select All
          </div>
        </th>
        <th style="width: 10%;">No</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item) in assessorConfirmations" :key="item.confirmationId">
        <td>{{ confirmations[item.confirmationId - 1 ] }}</td>
        <td>
          <input type="radio" :id="`yesConfirmation${item.confirmationId}`" :name="`confirmationResult${item.confirmationId}`"
            value="yes" v-model="item.result" :disabled="isSubmitted > 1">
          <label :for="`yesConfirmation${item.confirmationId}`"></label>
        </td>
        <td>
          <input type="radio" :id="`noConfirmation${item.confirmationId}`" :name="`confirmationResult${item.confirmationId}`"
            value="no" v-model="item.result" :disabled="isSubmitted > 1">
          <label :for="`noConfirmation${item.confirmationId}`"></label>
        </td>
      </tr>
    </tbody>
  </table>
      <div class="button-container">
        <button class="ins-close-button" @click="close">Close</button>
        <div class="right">
        <button class="ins-save-button" @click="save(1)" :disabled="isSubmitted > 1">Save</button>
        <button class="ins-submit-button" @click="submitTaskAssessment" :disabled="isSubmitted > 1">Submit</button>
      </div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import axios from 'axios';
  import SignaturePad from 'signature_pad';
  
  export default {
    props: {
      trainee: {
        type: Object,
        required: true
      },
    tasksCount: {
      type: Number,
      required: true
    },
    completedTasks: {
      type: Number,
    },
    fetchedData: Object,
    attemptNo: Number,
    selectedTask: Object,
  },
  watch: {
    fetchedData(newVal) {
      console.log('Received fetched data:', newVal);
      this.populateTaskAssessmentData(newVal);
    },
    criteriaResults: {
      immediate: true,
      handler() {
        const result = this.assessmentResult;
        this.successfulChecked = result === 'pass';
        this.remedialChecked = result === 'fail';
      }
    },
    isSubmitted(newValue, oldValue) {
      if (newValue > 1) {
        // Disable the signature pad
        if (this.assessorSignaturePad) {
          this.assessorSignaturePad.off();
        }
        if (this.traineeSignaturePad) {
          this.traineeSignaturePad.off();
        }
        this.$refs.assessorSignaturePadCanvas.style.opacity = '0.5';
        this.$refs.traineeSignaturePadCanvas.style.opacity = '0.5';
      } else if (oldValue > 1 && newValue === 1) {
        // If going from disabled to enabled
        this.initializeAssesorSignaturePad();
        this.initializeTraineeSignaturePad();
      }
    },
  },
  data() {
    return {
      currentCriterionId: null,
      currentConfirmationId: null,
      criteriaResults: new Array(9).fill(null).map((_, index) => {
          return { criterionId: index + 1, result: null};
        }),
      assessorConfirmations: new Array(3).fill(null).map((_, index) => {
          return { confirmationId: index + 1, result: null};
        }),
        traineeConfirmations: new Array(1).fill(null).map((_, index) => {
          return { traineeConfirmationId: index + 1, result: null};
        }),
      isSubmitted: 0,
      taskAssessmentId: '',
      taskDetails: '',
      assessorNotes: '',
      tasksPerformed: '',
      assessorSignature: '',
      traineeSignature: '',
      successfulChecked: false,
      remedialChecked: false,
      date: '',
      traineeId: localStorage.getItem('trainee_id'),
      logbookId: localStorage.getItem('logbook_id'),
      instructorId: localStorage.getItem('user_id'),
      evallogbookId: null,
      assessorSignaturePad: null,
      traineeSignaturePad: null,
      tableType: '',
      criteria: [
        'Does the candidate show good working environment awareness (act safely, apply safety precautions and prevent dangerous situations)',
        'Does the candidate show good and correct communication with other team members (verbal, written, informing of status changes, safety)',
        'Does the candidate show correct reading and interpretation of the nominated task given for the assessment',
        'Does the candidate show good use of relevant aircraft documentation: AMM, IPC, MEL (identify aircraft documentation, obey the maintenance procedures etc)',
        'Does the candidate complete aircraft documentation correctly (entries in tech log, tool control)',
        'Does the candidate show good systems knowledge (demonstrate understanding of aircraft systems interaction - identify, describe)',
        'Does the candidate react well to changes environmental/work changes during the task (other engineers interrupting, informing of other maintenance, safety)',
        'Does the candidate correctly analysis the consequences to associated systems and other personnel',
        'Does the candidate correctly return the aircraft to service (close up, documentation)',
      ],
      confirmations: [
        'I confirm that all marked mandatory tasks have been completed and applicable to the candidate and 50% of all task have been completed.',
        'I confirm that the candidate was assessed as meeting all the requirements as specified in the assessment checks.',
        'I confirm that the candidate has attained the required standard to pass the practical training element.',  
      ],
      trainee_confirmations: [
        'I confirm that I am the named candidate for this assessment and I accept the result of the assessment as determined by the assigned Assessor.',
      ],
    }
  },
  computed: {
    assessmentResult() {
      // Check if all criteria are marked as 'Yes'
      const allYes = this.criteriaResults.every(item => item.result === 'yes');
  
      // Check if any criterion is marked as 'No'
      const anyNo = this.criteriaResults.some(item => item.result === 'no');
  
      if (allYes) {
        return 'pass';
      } else if (anyNo) {
        return 'fail';
      }
      return null;
    },
    assessmentResultStyle() {
      switch (this.assessmentResult) {
        case 'pass':
          return { color: 'green', fontWeight: 'bold', border: 'none' };
        case 'fail':
          return { color: 'red', fontWeight: 'bold', border: 'none' };
        default:
          return {};
      }
    },
    isReadOnly() {
      return this.fetchedData && this.fetchedData.is_submitted;
    },
    isCheckboxDisabled() {
      return this.isSubmitted > 1;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    clearAssessorSignature() {
      if (this.isSubmitted <= 1 && this.assessorSignaturePad) {
        this.assessorSignaturePad.clear();
      }
    },
    clearTraineeSignature() {
      if (this.isSubmitted <= 1 && this.traineeSignaturePad) {
        this.traineeSignaturePad.clear();
      }
    },
    initializeAssessorSignaturePad() {
      const canvas = this.$refs.assessorSignaturePadCanvas;
      if (canvas) {
        this.assessorSignaturePad = new SignaturePad(canvas, {
          backgroundColor: 'rgb(255, 255, 255)',
          minWidth: 0.5, // Adjust this value for thinner lines
        maxWidth: 1.5, // Adjust this value for thinner lines
        });
  
          if (this.assessorSignature) {
            this.assessorSignaturePad.fromDataURL(this.assessorSignature);
          }
  
        if (this.isSubmitted > 1) {
          this.assessorSignaturePad.off();
          canvas.style.opacity = '0.5';
        } else {
          canvas.style.opacity = '1';
        }
      }
    },
    initializeTraineeSignaturePad() {
      const canvas = this.$refs.traineeSignaturePadCanvas;
      if (canvas) {
        this.traineeSignaturePad = new SignaturePad(canvas, {
          backgroundColor: 'rgb(255, 255, 255)',
          minWidth: 0.5, // Adjust this value for thinner lines
        maxWidth: 1.5, // Adjust this value for thinner lines
        });
  
          if (this.traineeSignature) {
            this.traineeSignaturePad.fromDataURL(this.traineeSignature);
          }
          this.traineeSignaturePad.off();
          canvas.style.opacity = '0.5';
      }
    },
    destroySignaturePad() {
      if (this.assessorSignaturePad) {
        this.assessorSignaturePad.off();  // Turn off the signature pad
        this.assessorSignaturePad = null; // Clean up the reference
      }
      if (this.traineeSignaturePad) {
        this.traineeSignaturePad.off();  // Turn off the signature pad
        this.traineeSignaturePad = null; // Clean up the reference
      }
    },
  
    toggleAllYes(event) {
      const isYes = event.target.checked;
      this.criteriaResults.forEach((criteria) => {
        criteria.result = isYes ? 'yes' : null;
      });
    },
    toggleAllConfirmationsYes(event) {
      const isYes = event.target.checked;
      this.assessorConfirmations.forEach((confirmation) => {
        confirmation.result = isYes ? 'yes' : null;
      });
    },
    toggleAllTraineeConfirmationsYes(event) {
      const isYes = event.target.checked;
      this.traineeConfirmations.forEach((traineeConfirmation) => {
        traineeConfirmation.result = isYes ? 'yes' : null;
      });
    },
    submitTaskAssessment() {
      const assessorSign = this.assessorSignaturePad.toDataURL();
        // Check if the trainee signature is empty

    if (this.assessorSignaturePad.isEmpty()) {
      alert("Please provide a signature for the instructor before submitting.");
      return;
    }
      const isComplete = this.criteriaResults.every(criterion => criterion.result) && this.assessorConfirmations.every(criterion => criterion.result) &&
        assessorSign && this.date && this.assessmentResult && this.assessorNotes;
  
        if (!isComplete) {
          alert("Please fill all the fields before submitting.");
          return;
        }
  
      // Confirm submission
      const confirmSubmission = confirm("Once submitted, the form cannot be edited. Are you sure you want to submit?");
      if (confirmSubmission && this.isSubmitted <= 1) {
        this.isSubmitted = 2; // Set form to readonly
        this.save(2); // Pass true to indicate submission
      }
    },
    save(isSubmitting = 0) {
      //eslint-disable-next-line
      debugger;
      const taskAssessmentData = {
        logbook_id: Number(this.logbookId),
        trainee_id: this.trainee.user_id,
        instructor_id: Number(this.instructorId),
        task_id: this.selectedTask.task_id || null,
        notes: this.assessorNotes || null,
        criteria: this.criteriaResults.map(result => ({
          criterionId: result.criterionId || null,
          result: result.result || null,
        })),
        result: this.assessmentResult,
        date: this.date || null,
        assessor_signature: this.assessorSignaturePad.isEmpty() ? null : this.assessorSignaturePad.toDataURL(),
        trainee_signature: this.traineeSignaturePad.isEmpty() ? null : this.traineeSignaturePad.toDataURL(),
        attempt_no: this.attemptNo,
        assessor_confirmations: this.assessorConfirmations.map(confirmation => ({
          confirmationId: confirmation.confirmationId || null,
          result: confirmation.result || null,
        })),
        trainee_confirmations: this.traineeConfirmations.map(traineeConfirmation => ({
          traineeConfirmationId: traineeConfirmation.traineeConfirmationId || null,
          result: traineeConfirmation.result || null,
        })),
        is_submitted: Number(isSubmitting),
      };
      console.log('taskassess')
      console.log(taskAssessmentData);
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
  
      console.log('this.taskAssessmentId');
      console.log(this.taskAssessmentId);
  
      if (this.taskAssessmentId) {
        axios.put(`/api/task_assessments/${this.taskAssessmentId}`, taskAssessmentData, { headers })
          .then(response => {
            alert('Task Assessment updated successfully.', response);
            console.log(response);
            console.log("Saving Task Assessment Data:", taskAssessmentData);
            this.close();
            //this.populateTaskAssessmentData(response.data);
          })
          .catch(error => {
            console.error('There was an error updating the Task Assessment:', error);
            alert('Failed to update the Task Assessment.');
          });
      } else {
        // POST request if this is a new Task Assessment
        axios.post('/api/task_assessments', taskAssessmentData, { headers })
          .then(response => {
            alert('Task Assessment saved successfully.', response);
            console.log(response);
            this.taskAssessmentId = response.data.id;
            console.log("Saving Task Assessment Data:", taskAssessmentData);
            this.close();
          })
          .catch(error => {
            console.error('There was an error saving the Task Assessment:', error);
            alert('Failed to save the Task Assessment.');
          });
      }
    },
    populateTaskAssessmentData(taskAssessmentData) {
    if (!taskAssessmentData) {
      console.error('No Task Assessment data found');
      return;
    }
  
    // Check if the trainee and logbook IDs match
    if ((taskAssessmentData.trainee_id !== this.trainee.user_id) || (taskAssessmentData.logbook_id !== parseInt(this.logbookId))) {
      console.warn('Trainee or Logbook ID does not match. Data will not be populated.');
      return;
    }
  
    this.criteriaResults = Object.entries(taskAssessmentData.criteria).map(([criterionId, value]) => ({
    criterionId: parseInt(criterionId),
    result: value.result,
  }));
  
  this.assessorConfirmations = Object.entries(taskAssessmentData.assessor_confirmations).map(([confirmationId, value]) => ({
    confirmationId: parseInt(confirmationId),
    result: value.result,
  }));
  
  this.traineeConfirmations = Object.entries(taskAssessmentData.trainee_confirmations).map(([traineeConfirmationId, value]) => ({
    traineeConfirmationId: parseInt(traineeConfirmationId),
    result: value.result,
  }));
  
    // Populate the signature if available
    if (this.assessorSignaturePad && taskAssessmentData.assessor_signature) {
      const assessorImage = new Image();
      assessorImage.onload = () => {
        this.assessorSignaturePad.fromDataURL(taskAssessmentData.assessor_signature, { width: 270, height: 60 });
      };
      assessorImage.src = taskAssessmentData.assessor_signature;
    } else if (this.assessorSignaturePad) {
      this.assessorSignaturePad.clear();
    }
  
      // Populate the signature if available
      if (this.traineeSignaturePad && taskAssessmentData.trainee_signature) {
      const traineeImage = new Image();
      traineeImage.onload = () => {
        this.traineeSignaturePad.fromDataURL(taskAssessmentData.trainee_signature, { width: 270, height: 60 });
      };
      traineeImage.src = taskAssessmentData.trainee_signature;
    } else if (this.traineeSignaturePad) {
      this.traineeSignaturePad.clear();
    }
  
    // Assign other values
    this.taskAssessmentId = taskAssessmentData.id;
    this.taskDetails = taskAssessmentData.task;
    this.assessorNotes = taskAssessmentData.notes;
    this.successfulChecked = taskAssessmentData.result === 'pass';
    this.remedialChecked = taskAssessmentData.result === 'fail';
    this.date = taskAssessmentData.date;
    this.isSubmitted = taskAssessmentData.is_submitted;
  },
      formatDate(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
  
        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
      },
  },
  mounted() {
    this.$nextTick(() => {
      this.initializeAssessorSignaturePad();
      this.initializeTraineeSignaturePad();
    });
  
    if (this.fetchedData) {
      this.populateTaskAssessmentData(this.fetchedData);
    }
    console.log(this.selectedTask);
  },
  beforeUnmount() { // or beforeDestroy() for Vue 2
    this.destroySignaturePad();
  }
  
  }
  </script>
  
  
  <style scoped>
  .Evaluation-modal-overlay {
    position: absolute;
    top: 0px;
    left: 0;
    background-color: rgba(97, 94, 94, 0.5);
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }
  
  .Evaluation-modal {
  background-color: #ffffff;
  overflow-y: auto;
  max-height: 89vh;
  max-width: 90vw;
  min-width: 70vw;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  position: fixed;
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
  padding: 20px;
  }
  
  .modal-title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .assessment-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    align-items: center;
  }
  
  .assessment-table th,
  .assessment-table td {
  border: 1px solid #c7c7c7;
  padding: 6px;
  text-align: left;
  font-size: 14px;
  }
  
  .assessment-table thead th {
    background-color: #f2f2f2;
    font-size: 16px;
  }
  
  .signature-section {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  
  .signature-item label {
    width: 40%;
    display: block;
    margin-bottom: 5px;
  }
  
  .signature-placeholder,
  .date-placeholder {
    border: 1px solid #ddd;
    padding: 5px;
    min-height: 30px;
    /* Adjust as needed */
  }
  
  .button-container {
    display: flex;
    justify-content: space-between;
    /* Aligns items to the right */
    align-items: center;
    margin-top: 20px;
    /* Adjust as needed */
  }
  
  .ins-save-button {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 10px;
  margin-left: 20px;
  width: 100%;
  min-width: 90px;
  max-width: 100px;
  }
  
  .ins-save-button:hover {
  background-color: #0462c7;
  }
  
  .ins-submit-button {
    background-color: brown;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    padding: 5px 10px;
    margin-left: 20px;
    min-width: 100px;
    max-width: 120px;
  }
  
  .ins-submit-button:hover {
    background-color: rgb(129, 33, 33);
  }
  
  
  .ins-signoff-all-button {
    border: 1px solid rgb(46, 73, 54);
    border-radius: 2px;
    background-color: #5d975e; /* Green */
    color: white;
    padding: 5px 10px;
    text-align: center;
  }
  
  .ins-signoff-all-button:hover {
    background-color: #3e743f;
  }
  
  .ins-close-button {
  background-color: #6c757d;
  border: 1px solid #4d5358;
  color:white;
  padding: 5px 20px;
  border-radius: 5px;
  }
  
  .ins-close-button:hover {
  background-color: #545a5f;
  }
  
  .ins-clear-button {
    background-color: #ee9581;
  color:white;
  font-size: 14px;
  border-radius: 5px;
  margin-left: 20px;
  width: 100%;
  min-width: 60px;
  max-width: 100px;
  border: 1px solid #c7715e;
  height: 30px;
  padding: 0px;
  }
  
  .ins-clear-button:hover {
    background-color: #e47a62;
  }
  
  .tee-clear-button {
  background-color: #ee9581;
  color:white;
  font-size: 14px;
  border-radius: 5px;
  margin-left: 20px;
  width: 100%;
  min-width: 60px;
  max-width: 100px;
  border: 1px solid #c7715e;
  height: 30px;
  padding: 0px;
  }
  
  .tee-clear-button:hover {
  background-color: #e47a62;
  }
  
  .ins-save-button:disabled,
  .ins-submit-button:disabled,
  .ins-clear-button:disabled, 
  .tee-clear-button:disabled {
  background-color: rgb(216, 216, 216);
  cursor: not-allowed;
  color:grey;
  border: 1px solid rgb(92, 92, 92);
  }
  
  .ins-save-button:disabled:hover,
  .ins-submit-button:disabled:hover,
  .ins-clear-button:disabled:hover, 
  .tee-clear-button:disabled:hover {
  background-color: rgb(216, 216, 216);
  cursor: not-allowed;
  color:grey;
  border: 1px solid rgb(92, 92, 92);
  }
  
  .assessment-result {
    margin-left: 25%;
  }
  
  .task-performance {
    width: 50%;
    border-collapse: collapse;
    text-align: left;
    background-color: #f2f2f2;
    margin-left: 20px;
  }
  
  .task-performance th {
    /* Adjust the color to match your header background */
    font-weight: bold;
  }
  
  
  .task-performance input[type="text"] {
    width: 100%;
    border: none;
    padding: 4px;
    text-align: center;
    background-color: #f2f2f2;
  }
  
  .assessment-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 20px;
  }
  
  
  .signature-block {
    display: flex;
    flex-direction: column;
    margin-right: 70px;
  }
  
  
  .signature-table {
    width: 100%;
  }
  
  .signature-table td {
    padding: 4px;
    /* Adjust padding as needed */
    vertical-align: middle;
    /* Aligns content of <td> vertically in the middle */
  }
  
  .signature-table .signature {
    text-align: left;
    /* Aligns the text to the left */
  }
  
  .trainee-signature {
    text-align: left;
  }
  
  .signature-table input[type="text"],
  .signature-table input[type="date"] {
    width: 100%;
    border: none;
    border-bottom: 1px solid black;
    border-radius: 0px;
    background-color: transparent;
    outline: none;
    height: 20px;
  }
  
  @media (max-width: 768px) {
  
    /* Adjust the max-width as needed for iPad or other tablet sizes */
    .Evaluation-modal {
      padding: 5px;
      font-size: 0.9em;
      /* Reduce font size */
    }
  
    /* Further adjustments for other elements */
  }
  
  
  #selectAllYes {
  margin-left: 5px;
  border: 1px solid rgb(206, 13, 13);
  }
  
  .signature-pad {
    border-bottom: 1px solid #636363;
    border-radius: 5px;
  }
  
  .right {
    display: flex;
    justify-content: flex-end;
  }
  
  /* Add this CSS to your stylesheet */
  .signature-image-wrapper {
    position: relative;
    display: inline-block;
  }
  
  .signature-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    pointer-events: none;
    /* Prevents interaction with the overlay */
  }
  
  .disabled {
    opacity: 0.5;
    /* Optionally reduce opacity to indicate disabled state */
  }
  
  .artificial-fieldset {
    border: 1px solid #8b8b8b;
    padding: 10px;
    margin-top: 20px;
    position: relative;
    margin-bottom: 20px;
  }
  
  .artificial-fieldset-legend {
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: #e4e4e4;
    padding: 0 10px;
    font-weight: bold;
    border: 1px solid #8b8b8b;
    border-radius: 3px;
  }
  
  .additional-info-table {
    text-align: left;
    margin-top: 10px;
    border: 1px solid #d1d1d1;
    border-radius: 3px;
    margin-bottom: 10px;
    padding: 5px;
  }
  
  .additional-info-table td:nth-child(1) {
    width: 10%;
    border: 1px solid #d1d1d1;
    border-radius: 3px;
  }
  
  .additional-info-table td:nth-child(2) {
    width: 28%;
  }
  
  .additional-info-table td:nth-child(3) {
    width: 29%;
    padding-left: 10%;
  }
  
  .additional-info-table td:nth-child(4) {
    width: 30%;
  }
  
  .additional-info-table input {
    height: 25px;
  }
  
  label {
    margin-bottom: 0px;
  }
  
  .additional-info-table td {
    padding: 5px;
  }
  
  .instructor_signoff {
    border: none;
    color: #3e9651 !important;
    background: none;
    font-weight: bold;
    padding: 0px;
    width: 80px;
  }
  
  .instructor_signoff:hover {
    background-color: white !important;
    font-weight: bold;
  }
  
  .instructor_signoff:disabled {
    border: none;
    color: rgb(97, 97, 97) !important;
    background: none;
    font-weight: 400;
    cursor: not-allowed;
  }
  </style>