<template>
    <!DOCTYPE html>
    <html lang="en">

    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
    </head>

    <body>
        <page-header></page-header>
        <div>
            <div class="import-tasks-modal">
                <div class="tasks-container-fluid no-padding">
                    <div class="row">
                        <div class="col-md-12 left-align-text">
                            <h2>Import tasks from .CSV file</h2>
                            <p style="font-size:16px;">The file has to be a spreadsheet file (.csv) in the following
                                format:
                            </p>
                            <p style="font-weight:bold;">ATA|Task
                                No|Manob|Scope|Description|Reference|Skill|P1|P2|Category
                            </p>
                            <p style="font-size:16px; color: rgb(196, 0, 0);"><b>All fields are mandatory.</b></p>
                            <div class="instructions-area">
                                <table class="inst-table">
                                    <thead>
                                        <tr>
                                            <th>Column</th>
                                            <th>Name</th>
                                            <th>Instructions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>A</td>
                                            <td>ATA</td>
                                            <td>A valid ATA chapter Number</td>
                                        </tr>
                                        <tr>
                                            <td>B</td>
                                            <td>Task No</td>
                                            <td>A unique number to identify the tasks</td>
                                        </tr>
                                        <tr>
                                            <td>C</td>
                                            <td>Manob</td>
                                            <td>The Task Categories. The values should be MT or AT</td>
                                        </tr>
                                        <tr>
                                            <td>D</td>
                                            <td>Scope</td>
                                            <td>Task Codes such as: LOC/SGH/TS/FOT/MEL.</td>
                                        </tr>
                                        <tr>
                                            <td>E</td>
                                            <td>Description</td>
                                            <td>Task Subject/Description</td>
                                        </tr>
                                        <tr>
                                            <td>F</td>
                                            <td>Reference</td>
                                            <td>Manual Reference of the Task. Can contain the chapter #, topic #,
                                                section #</td>
                                        </tr>
                                        <tr>
                                            <td>G</td>
                                            <td>Skill</td>
                                            <td>The required skill of the trainee to perform the task.</td>
                                        </tr>
                                        <tr>
                                            <td>H</td>
                                            <td>P1</td>
                                            <td>Put an "x" in the field where this applies</td>
                                        </tr>
                                        <tr>
                                            <td>I</td>
                                            <td>P2</td>
                                            <td>Put an "x" in the field where this applies</td>
                                        </tr>
                                        <tr>
                                            <td>J</td>
                                            <td>Category</td>
                                            <td>Category of the task.</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <span class="clip" role="img" aria-label="Paperclip">&#x1F4CE;</span>
                                <input type="file" @change="importFile" class="file-input" />
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div v-if="previewRows.length && !importSuccessful && !dryRunSuccessful">
                                <h4 style="text-align: left;">CSV Preview (First 5 rows):</h4>
                                <table class="preview-table">
                                    <!-- Uploaded CSV headers (dynamic) -->
                                    <thead>
                                        <tr>
                                            <th class="narrow-column">ATA</th>
                                            <th class="narrow-column">Task No</th>
                                            <th class="narrow-column">Manob</th>
                                            <th class="narrow-column">Scope</th>
                                            <th class="wide-column">Description</th>
                                            <th class="skill-column">Reference</th>
                                            <th class="skill-column">Skill</th>
                                            <th class="narrow-column">P1</th>
                                            <th class="narrow-column">P2</th>
                                            <th class="narrow-column">Category</th>
                                        </tr>
                                    </thead>
                                    <!-- Uploaded CSV preview -->
                                    <tbody>
                                        <tr v-for="(row, index) in previewRows" :key="row.toString()"
                                            v-show="!(ignoreFirstRow && index === 0)">
                                            <td style="font-size:14px" v-for="cell in row" :key="cell">{{ cell }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="dryRunSuccessful" v-html="successMessage.replace(/\n/g, '<br>')"
                                class="successBox">
                            </div>
                            <div v-if="importSuccessful && !dryRun" v-html="successMessage.replace(/\n/g, '<br>')"
                                class="successBox"></div>
                        </div>
                        <div v-if="showAlert" class="alert alert-danger error-message" role="alert">
                            <div v-if="errorMessage">{{ errorMessage }}</div>
                        </div>
                        <div class="importArea">
                            <label>
                                <input type="checkbox" v-model="ignoreFirstRow" class="ignoreCheckbox">
                                Ignore first row
                            </label>
                            <label>
                                <input type="checkbox" v-model="dryRun" class="dryRuncheckbox">
                                Dry Run
                            </label>
                            <div class="buttonContainer">
                                <button class="importButton" :disabled="processing" @click.stop.prevent="handleImport">
                                    {{ processing ? 'Importing...' : 'IMPORT' }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </body>

    </html>
</template>

<script>
import axios from 'axios';
import pageHeader from '@/components/Examiner/Header.vue';
import './importTasks.css';
export default {
    name: "importTasks",
    data() {
        return {
            productId: this.$route.params.productId,
            previewRows: [],
            entireFileRows: [],
            ignoreFirstRow: false,
            importSuccessful: false,
            dryRunSuccessful: false,
            successMessage: '',
            dryRun: true,
            importedTasks: [],
            existingTasks: [],
            fileName: '',
            sectionIds: [],
            productIds: [],
            skippedTasks: [],
            validTasks: [],
            issues: [],
            processing: false,
            showAlert: false,
            errorMessage: '',
        };
    },
    components: {
        pageHeader
    },
    watch: {
        selectedFile(newFile, oldFile) {
            if (newFile !== oldFile) {
                this.resetImportState();
            }
        }
    },
    mounted() {
        const productId = localStorage.getItem('product_id');
        axios.get(`/api/tasks/products/${productId}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(response => {
                this.existingTasks = response.data;
            });
    },
    methods: {
        handleError(error, customMessage = '') {
            console.error('Error:', error);
            if (error.response && error.response.data && error.response.data.message) {
                this.errorMessage = `${customMessage} ${error.response.data.message}`;
            } else if (error.message) {
                this.errorMessage = `${customMessage} ${error.message}`;
            } else {
                this.errorMessage = `${customMessage} An unknown error occurred.`;
            }
            this.showAlert = true;
            this.processing = false;
        },
        closeModal() {
            this.$emit('close');
            this.$emit('modal-closed');
        },
        importFile(event) {
            const file = event.target.files[0];
            if (file) {
                this.fileName = file.name;
                if (file.type.includes('csv')) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        const rows = reader.result.trim().split('\n');
                        this.entireFileRows = rows.map(row => row.split('|'));
                        this.previewRows = this.entireFileRows.slice(0, 5);
                    };
                    reader.readAsText(file);
                }
            } else {
                this.fileName = '';
                this.entireFileRows = [];
                this.previewRows = [];
            }
        },
        handleImport() {
            this.dryRunSuccessful = false;
            this.processing = true;
            const processedTasks = this.processDataRows(this.entireFileRows);
            console.log('processedTasks');
            console.log(processedTasks);

            if (this.ignoreFirstRow) {
                processedTasks.shift();
            }
            if (this.dryRun) {
                this.checkTasks(processedTasks);
                this.dryRunImport(processedTasks);
            } else {
                this.checkImportTasks(processedTasks);
            }

        },
        processDataRows(dataRows) {
            const rowsToProcess = this.ignoreFirstRow ? dataRows.slice(1) : dataRows;

            const processedTasks = rowsToProcess
                .filter(row => Array.isArray(row) && row.length > 0)
                .map((row, index) => {
                    if (index === 0 && this.isHeaderRow(row)) {
                        if (!this.ignoreFirstRow) {
                            this.issues.push({
                                message: `Row ${index + 1} appears to be a header row. Please check your file or enable "Ignore First Row".`,
                                row,
                                index,
                            });
                        }
                        // Skip processing this row but keep it for issues reporting
                        return null;
                    }
                    try {
                        const chapt_no = row[0].trim();
                        const task_no = isNaN(parseInt(row[1], 10)) ? row[1] : parseInt(row[1], 10);
                        const manob = row[2].trim();
                        const scope = row[3].trim();
                        const description = row[4].trim();
                        const reference = row[5].trim();
                        const skill = row[6].trim();
                        const P1 = row[7].trim() === '' ? 0 : row[7].trim();
                        const P2 = row[8].trim() === '' ? 0 : row[8].trim();
                        const category = row[9].trim();
                        const productId = parseInt(localStorage.getItem('product_id'), 10);

                        const task = {
                            task_no,
                            prod_id: productId,
                            description,
                            reference,
                            scope,
                            skill,
                            P1,
                            P2,
                            category,
                            manob,
                            chapt_no,
                        };

                        // Pass index as a second argument
                        this.validateTask(task, index, []);

                        return task;
                    } catch (error) {
                        console.error(`Error processing row ${index + 1}:`, row, error);
                        this.issues.push({ message: `Error processing row ${index + 1}: ${error.message}`, row, index });
                        return { error: true, row, message: error.message };
                    }
                });

            console.log('dryRun', this.dryRun);

            if (this.issues.length > 0) {
                this.handleIssues(this.issues, processedTasks);
            }

            return processedTasks.filter(task => task !== null);
        },
        isHeaderRow(row) {
            const headerKeywords = ["Task No", "Task Categories", "Subject", "Manual Reference", "Skill"];
            const headerMatch = headerKeywords.some(keyword =>
                row.some(cell => cell.trim().toLowerCase() === keyword.toLowerCase())
            );

            return headerMatch;
        },
        handleIssues(issues, processedTasks) {
            const uniqueIssues = [];

            console.log('processed', processedTasks);
            console.log('valid', this.validTasks);
            console.log('skipped', this.skippedTasks);
            // Filter out duplicate issues based on the message
            issues.forEach(issue => {
                const isDuplicate = uniqueIssues.some(uniqueIssue =>
                    uniqueIssue.message === issue.message &&
                    uniqueIssue.task_no === issue.task_no &&
                    uniqueIssue.chapt_no === issue.chapt_no
                );

                if (!isDuplicate) {
                    uniqueIssues.push(issue);
                }
            });

            console.log('Filtered Issues before dispatching:', uniqueIssues);
            if (uniqueIssues.length) {
                this.$store.dispatch('setIssues', uniqueIssues);
                this.$store.dispatch('setImportedTasks', processedTasks);
                this.$store.dispatch('setFileName', this.fileName);
                if (this.dryRun) {
                    this.$router.push({ name: 'IssuesDisplay', params: { productId: this.productId } });
                }
            }
        },
        dryRunImport(processedTasks) {
            let dryRunSkippedTasks = [];
            const duplicates = this.checkForDuplicates(processedTasks);
            dryRunSkippedTasks = duplicates.map(task => task.task_no);
            console.log("Dry Run Mode: These tasks would have been imported:", processedTasks);
            if (dryRunSkippedTasks.length > 0) {
                console.log("Duplicate tasks that would have been skipped:", dryRunSkippedTasks);
            } else {
                this.successMessage = `Dry Run Mode: ${processedTasks.length} tasks would have been imported. No issues found in the file.`;
                console.log(this.successMessage);
            }
            this.dryRunSuccessful = true;
            this.processing = false;
        },
        async batchImportTasks(validTasks, skippedTasks, processedTasks) {
            try {
                console.log('batchValid');
                console.log(validTasks);
                console.log('batchSkipped');
                console.log(skippedTasks);
                const token = localStorage.getItem('token');
                const headers = { Authorization: `Bearer ${token}` };
                const response = await axios.post('/api/tasks/batch', validTasks, { headers });
                const responseTasks = response.data.tasks;

                console.log('Batch Import Successful:', responseTasks);
                console.log(skippedTasks);

                this.$store.dispatch('setImportedTasks', processedTasks);
                this.$store.dispatch('setFileName', this.fileName);
                this.$store.dispatch('setSkippedTasks', skippedTasks);

                this.processing = false;
                this.errorMessage = '';
                this.showAlert = false;
                this.$router.push({ name: 'importSuccessful' });
            } catch (error) {
                this.handleError(error, 'Failed to import tasks.');
            }
        },
        areTasksEqual(task1, task2) {
            const keysToCheck = ['task_no', 'reference', 'scope', 'manob', 'chapt_no'];

            for (let key of keysToCheck) {
                if (task1[key] !== task2[key]) {
                    return false;
                }
            }

            return true;
        },
        checkForDuplicates(importedTasks) {
            const duplicates = [];
            console.log('duplicateImported')
            console.log(importedTasks)

            console.log('existingTasks');
            console.log(this.existingTasks);

            for (let i = 0; i < importedTasks.length; i++) {
                for (let j = 0; j < this.existingTasks.length; j++) {
                    if (this.areTasksEqual(importedTasks[i], this.existingTasks[j])) {
                        duplicates.push(importedTasks[i]);
                        break;  // No need to check further for this importedTask if a duplicate is found
                    }
                }
            }

            return duplicates;
        },
        checkTasks(importedTasks, isDryRun = true) {
            // eslint-disable-next-line
            debugger;
            this.skippedTasks = [];
            this.validTasks = [];
            console.log('importedTasks');
            console.log(importedTasks);
            const duplicates = this.checkForDuplicates(importedTasks);

            importedTasks.forEach((task, index) => {
                // If task.error exists, skip the validateTask call
                if (!task.error) {
                    // Pass the row number (index + 1) to validateTask
                    this.validateTask(task, index + 1, duplicates);
                } else {
                    // Handle tasks with errors if needed (e.g., add to skippedTasks)
                    this.skippedTasks.push(task);
                }
            });

            this.handleIssues(this.issues, importedTasks, isDryRun);
        },
        checkImportTasks(importedTasks) {
            //eslint-disable-next-line
            debugger;
            this.skippedTasks = [];
            this.validTasks = [];
            const duplicates = this.checkForDuplicates(importedTasks);

            importedTasks.forEach((task, index) => {
                // Pass the row number (index + 1) to validateTask
                this.validateTask(task, index + 1, duplicates,);
            });

            console.log(this.skippedTasks);
            console.log(this.validTasks);
            this.batchImportTasks(this.validTasks, this.skippedTasks, importedTasks);
        },
        validateTask(importedTask, index, duplicates) {
            try {
                //         if (!importedTask.task_no) {
                //     this.issues.push({ message: `Row ${index + 1}: Task No is required.`, task_no: importedTask.task_no, index });
                //     this.skippedTasks.push(importedTask);
                //     return;
                // }
                // if (!importedTask.chapt_no) {
                //     this.issues.push({ message: `Row ${index + 1}: Chapter No is required.`, task_no: importedTask.task_no, index });
                //     this.skippedTasks.push(importedTask);
                //     return;
                // }
                for (let key in importedTask) {
                    const scopePattern = 'LOC|SGH|TS|FOT|MEL|R\\/I'; // Define the pattern here, escape the forward slash
                    let value = importedTask[key];
                    if (key === "prod_id") continue
                    if (key === "task_explanation") continue

                    //if(key === "is_exam") continue
                    switch (key) {
                        case "task_no":
                            if (value === undefined || value === null || value === '') {
                                this.issues.push({
                                    message: `Field "${key}" is empty.`,
                                    task_no: importedTask.task_no,
                                    chapt_no: importedTask.chapt_no,
                                    index
                                });
                            }
                            // Check for invalid data types
                            else if (isNaN(value)) {
                                this.issues.push({
                                    message: `Invalid data type for "${key}". Expected number, got ${typeof value}.`,
                                    task_no: importedTask.task_no,
                                    chapt_no: importedTask.chapt_no,
                                    index
                                });
                            }
                            break;
                        case "description":
                        case "reference":
                        case "chapt_no":
                        case "skill":
                        case "category":
                            if (value === undefined || value === null || value.trim() === '') {
                                this.issues.push({
                                    message: `Field "${key}" is empty.`,
                                    task_no: importedTask.task_no,
                                    chapt_no: importedTask.chapt_no,
                                    index
                                });
                            } else if (typeof value !== "string") {
                                this.issues.push({
                                    message: `Invalid data type for "${key}". Expected string, got ${typeof value}.`,
                                    task_no: importedTask.task_no,
                                    chapt_no: importedTask.chapt_no,
                                    index
                                });
                            }
                            break;
                        case "scope":
                            if (value === undefined || value === null || value.trim() === '') {
                                this.issues.push({
                                    message: `Field "${key}" is empty.`,
                                    task_no: importedTask.task_no,
                                    chapt_no: importedTask.chapt_no,
                                    index
                                });
                            } else if (typeof value !== "string") {
                                this.issues.push({
                                    message: `Invalid data type for "${key}". Expected string, got ${typeof value}.`,
                                    task_no: importedTask.task_no,
                                    chapt_no: importedTask.chapt_no,
                                    index
                                });
                            } else {
                                // Create a regex pattern that matches the combined scope values
                                let scopeRegex = new RegExp('^(' + scopePattern + ')(\\|(' + scopePattern + '))*$');
                                if (!scopeRegex.test(value)) {
                                    this.issues.push({
                                        message: `Invalid value for "${key}". Accepted values are 'LOC', 'SGH', 'TS', 'FOT', 'MEL', 'R/I'.`,
                                        task_no: importedTask.task_no,
                                        chapt_no: importedTask.chapt_no,
                                        index
                                    });
                                }
                            }
                            break;
                        case "manob":
                            if (value === undefined || value === null || value.trim() === '') {
                                this.issues.push({
                                    message: `Field "${key}" is empty.`,
                                    task_no: importedTask.task_no,
                                    chapt_no: importedTask.chapt_no,
                                    index
                                });
                            } else if (typeof value !== "string") {
                                this.issues.push({
                                    message: `Invalid data type for "${key}". Expected string, got ${typeof value}.`,
                                    task_no: importedTask.task_no,
                                    chapt_no: importedTask.chapt_no,
                                    index
                                });
                            } else if (!['MT', 'AT'].includes(value)) {
                                this.issues.push({
                                    message: `Invalid value for "${key}". Accepted values are 'MT' or 'AT'.`,
                                    task_no: importedTask.task_no,
                                    chapt_no: importedTask.chapt_no,
                                    index
                                });
                            }
                            break;
                        case "P1":
                        case "P2":
                            // Ensure value is a string before using trim()
                            value = value ? String(value) : '';  // Convert value to a string if it's not already

                            if (value.trim() === '') {
                                // If empty, set to 0
                                importedTask[key] = 0;
                            } else if (['1', '0', 'x', 'X'].includes(value.trim())) {
                                // If 'X' or 'x', set to 1
                                importedTask[key] = 1;
                            } else {
                                // If anything else, log an error
                                this.issues.push({
                                    message: `Invalid value for "${key}". Accepted values are '1', '0', 'x', or empty.`,
                                    task_no: importedTask.task_no,
                                    chapt_no: importedTask.chapt_no,
                                    index
                                });
                            }
                            break;
                        default:
                            this.issues.push({
                                message: `Extra field "${key}" detected.`,
                                task_no: importedTask.task_no,
                                chapt_no: importedTask.chapt_no,
                                index
                            });
                    }
                }
                const validators = {
                    task_no: (val, task) => {
                        const matchingExistingTask = this.existingTasks.find(
                            existingTask => existingTask.task_no === val,
                        );
                        if (duplicates.includes(task)) return `Row ${index + 1}: Task already exists in the Practical Element (Duplicate record)`;
                        if (matchingExistingTask && this.areTasksEqual(matchingExistingTask, task)) return `Row ${index + 1}: Task with Task No ${val} already exists. Consider adding a new unique number.`;
                    },
                };
                Object.entries(importedTask).forEach(([key, value]) => {
                    const validationError = validators[key]?.(value, importedTask);
                    if (validationError) {
                        this.issues.push({ message: `${validationError}`, task_no: importedTask.task_no, chapt_no: importedTask.chapt_no, index });
                    }
                });
                const hasIssues = this.issues.some(issue => issue.task_no === importedTask.task_no && issue.index === index);
                if (!hasIssues) {
                    this.validTasks.push(importedTask);
                } else {
                    this.skippedTasks.push(importedTask);
                }
            } catch (error) {
                this.handleError(error, `Validation failed for Task No: ${importedTask.task_no}.`);
            }
        },
        resetImportState() {
            this.successMessage = null;
            this.previewRows = [];
            this.entireFileRows = [];
            this.processing = false;
            this.errorMessage = '';
            this.showAlert = false;
            this.fileName = '';
            this.processedTasks = [];
        }
    },
}
</script>

<style scoped>
.importButton[disabled] {
    background-color: #ccc;
    cursor: not-allowed;
    opacity: 0.9;
}

.alert-danger {
    color: #ff0019;
    background-color: #f8d7da;
    border: 1px solid #e6b0b6;
}

.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 0rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}
</style>