<template>
  <div class="trainee-list-modal-overlay" @click="closeModalOutside">
    <div class="trainee-list-modal" @click.stop>
      <div class="modal-content">
        <h2>Select Session</h2>

        <div class="add">
          <form @submit.prevent="goToProject">
            <div class="form-group">
              <label for="role">Practical Element:</label>
              <select class="form-control rounded" id="product_id" name="product_id" 
              v-model="this.product_id" @change="fetchProjectsByProduct">
                <option value=""></option>
                <option v-for="product in products" :key="product.product_id" :value="product.product_id">
                  {{ product.name }}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="role">Session:</label>
              <select class="form-control rounded" id="project_id" name="project_id" 
              v-model="this.project_id"
                >
                <option value=""></option>
                <option v-for="project in projects" :key="project.project_id" :value="project.project_id">
                  {{ project.name }}</option>
              </select>
            </div>
           
            <div v-if="showAlert" class="alert alert-danger error-message" role="alert">
              <div v-if="errorMessage">{{ errorMessage }}</div>
            </div>
            <div>
              <button class="btn btn-primary rounded" type="submit">Go To Session</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

import axios from 'axios';

export default {
  data() {
    return {
      products: [],
      projects: [],
      trainees: [],
      showAlert: false,
      currentTime: '',
      errorMessage: '',
      processing: false,
      productSelected: false,
      projectSelected: false,
      showTraineeList: false,
    };
  },
  methods: {

    async goToProject() {
      const project_id = this.project_id;
      console.log(project_id);
      this.$router.push({ name: 'ViewTrainees', params: { project_id } });
    },

    async fetchProjects() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.display('unauthorized');
          return;
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get('/api/projects', { headers });
        console.log('Session response')
        console.log(response);

        if (response.status === 200) {
          this.projects = response.data;
          console.log('Sessions');
          console.log(this.projects);
        }
      } catch (error) {
        console.error('Error retrieving Sessions:', error);
        // Handle error
      }
    },
    async fetchProducts() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.display('unauthorized');
          return;
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get('/api/products', { headers });

        if (response.status === 200) {
          this.products = response.data; // Populate the products array with API data
          console.log('this products')
          console.log(this.products);
        }
      } catch (error) {
        console.error('Error retrieving Practical Elements:', error);
        // Handle error
      }
    },
    async fetchProjectsByProduct() {
            // Fetch projects based on the selected product
            this.onProductSelected();
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.display('unauthorized');
                    return;
                }

                const headers = {
                    Authorization: `Bearer ${token}`,
                };
                const response = await axios.get(`/api/products/${this.product_id}/projects`, { headers });

                if (response.status === 200) {
                    this.projects = response.data;
                    console.log('projects');
                    console.log(response);
                }
            } catch (error) {
                console.error('Error retrieving Sessions:', error);
                // Handle error
            }
        },
        onProductSelected() {
            this.productSelected = !!this.product_id;
            console.log(this.product_id);
        },
    viewTraineeList() {
      this.showTraineeList = true; // Show the modal when the button is clicked
    },
    closeTraineeList() {
      this.showTraineeList = false; // Close the modal
    },
    goBack() {
      this.$router.push({ name: 'ViewTraineeTable' });
    },
    closeModalOutside() {
      this.$emit('close'); // Emit an event to indicate the modal should be closed
    },
  },
  async mounted() {
    await this.fetchProjects();
    this.fetchProducts();

  },
}
</script>
  
<style scoped>
.trainee-list-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure the modal is on top of other content */
}

h2 {
  margin-top: 30px;
  font-size: 30px;
  margin-bottom: 30px;
}

/* Style your modal content */
.trainee-list-modal {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 800px;
  /* Limit the maximum width of the modal */
  padding: 20px;
  position: relative;
  max-height: 95%;
}


.modal-content {
  overflow-y: auto;
  /* Enable scrolling within the modal content */
}


.add button {
  width: 200px;
  height: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
}

button.btn:hover {
  background-color: #0c1a61;
  transition: transform 0.2s, box-shadow 0.2s;
}

input.form-control,
select.form-control {
  width: 70%;
  margin-left: 150px;
  display: inline-block;
  margin-bottom: 25px;
  align-items: baseline;
}

.form-group {
  margin-bottom: 15px;
}

label {
  margin-right: 5px;
  position: absolute;
  margin-top: 8px;
}

.form-control {
  border-radius: 5px;
}

.form-control#start_date {
  border-radius: 5px;
  width: 25%;
  margin-left: 100px;
  margin-right: 45%;
}

h1 {
  margin-bottom: 20px;
}

.alert-danger {
    color: #ff0019;
    background-color: #f8d7da;
    border: 1px solid #e6b0b6;
}

.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 0rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}
</style>
  