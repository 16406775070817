<template>
  <Header />
  <h1>Users</h1>

  <div class="current-time-container">
    <div class="filter-container">
      <input type="text" v-model="searchQuery" placeholder="Search by Name" />
      <input type="text" id="productFilter" v-model="categorySearchQuery" placeholder="Search by Category" />
      <input type="text" id="projectFilter" v-model="licenseSearchQuery" placeholder="Search by License No" />
      <input type="text" v-model="roleSearchQuery" placeholder="Search by Role" />
      <input type="text" v-model="departmentSearchQuery" placeholder="Search by Department" />
      <input type="text" id="dobFilter" v-model="dobSearchQuery" placeholder="Search by Date of Birth" />
      <input type="text" id="pobirthFilter" v-model="pobirthSearchQuery" placeholder="Search by Place of Birth" />


      <button class="clear-filter-button" @click="clearFilters">Clear Filters</button>
    </div>
  </div>

  <div class="pagination-button-container">
    <div class="pagination-container">
      <Pagination :totalPages="totalPages" :perPage="perPage" :currentPage="currentPage" @page-change="onPageChange" />
    </div>
    <div class="pagination-info">
      Showing {{ startIndex + 1 }}-{{ endIndex }} of {{ filteredUsersLength }} users
    </div>
  </div>

  <edit-user-modal v-if="showEditUser" @close="closeEditUser"></edit-user-modal>

  <div class="container-fluid-trainee">
    <table class="data-table-trainee">
      <thead>
        <tr>
          <th class="id-column" @click="sortBy('user_id')">
            ID
            <span v-if="sortField === 'user_id'" v-html="getFieldArrow()"></span>
          </th>
          <th class="name-column" @click="sortBy('first_name')">
            First Name
            <span v-if="sortField === 'first_name'" v-html="getFieldArrow('first_name')"></span>
          </th>
          <th class="name-column">Last Name</th>
          <th>Email</th>
          <th class="skinny-column">Role</th>
          <th class="skinny-column">2nd Role</th>
          <th class="skinny-column">Category</th>
          <th class="skinny-column">License No</th>
          <th class="skinny-column">Department</th>
          <th class="date-column">Date of Birth</th>
          <th class="date-column">Place of Birth</th>
          <th class="skinny-column">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in filteredUsers" :key="item.user_id">
          <td>{{ item.user_id }}</td>
          <td>{{ item.first_name }}</td>
          <td>{{ item.last_name }}</td>
          <td>{{ item.email }} </td>
          <td>{{ item.role }} </td>
          <td>{{ item.second_role }} </td>
          <td>{{ item.category }} </td>
          <td>{{ item.license_no }} </td>
          <td>{{ item.department }} </td>
          <td>{{ item.date_of_birth }} </td>
          <td>{{ item.place_of_birth }} </td>
          <td class="center-icons">
            <i class="fas fa-edit edit-icon" @click="viewEditUser(item.user_id)"></i>
            <i class="fas fa-trash-alt delete-icon" @click="deleteUser(item.user_id)"></i>
          </td>
        </tr>
        <tr v-if="filteredUsers.length === 0">
          <td colspan="100" style="text-align: center;">No Matching Users</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div>
    <button class="btn btn-primary rounded" id=addlogbookButton @click="goToAddUser">Add User</button>
  </div>
</template>

<script>
import Header from './AdminHeader.vue';
import axios from 'axios';
import Pagination from './TablePagination.vue';
import EditUserModal from './EditUserModal.vue';

export default {
  name: 'ViewUsers',
  components: {
    Pagination,
    Header,
    EditUserModal,
  },

  data() {
    return {
      users: [],
      sortField: 'user_id',
      sortDirection: 'asc',
      searchQuery: '',
      categorySearchQuery: '',
      licenseSearchQuery: '',
      roleSearchQuery: '',
      departmentSearchQuery: '',
      dobSearchQuery: '',
      pobirthSearchQuery: '',
      currentPage: 1,
      perPage: 10,
      totalPages: 0,
      showEditUser: false,
      filteredUsersLength: 0,
    };
  },
  watch: {
    searchQuery(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    categorySearchQuery(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    licenseSearchQuery(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    roleSearchQuery(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    departmentSearchQuery(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    dobSearchQuery(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    pobirthSearchQuery(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
  },

  async mounted() {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      // Fetch all Users
      let result = await axios.get('/api/users', { headers });

      this.users = result.data;

      console.log(this.users);

      this.totalPages = Math.ceil(this.users.length / this.perPage);

    } catch (error) {
      console.error('An error occurred while fetching data:', error);
    }
  },


  methods: {
    deleteUser(user_id) {
      if (confirm("Are you sure you want to delete this user?")) {
        if (confirm("This action will PERMANENTLY delete the user from the database. Do you wish to continue?")) {
          // If the user confirms the deletion, send a request to delete the user.
          const token = localStorage.getItem('token');
          const headers = { Authorization: `Bearer ${token}` };

          axios
            .delete(`/api/users/${user_id}`, { headers })
            .then(response => {
              if (response.status === 200) {
                // Remove the deleted user from the local data
                this.users = this.users.filter(user => user.user_id !== user_id);

                window.alert('User deleted Successfully');
              }
            })
            .catch(error => {
              console.error('An error occurred while deleting the user:', error);
            });
        }
      }
    },
    sortBy(field) {
      if (this.sortField === field) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortField = field;
        this.sortDirection = 'asc';
      }

      this.users.sort((a, b) => {
        const valueA = a[field];
        const valueB = b[field];

        if (typeof valueA === 'number' && typeof valueB === 'number') {
          return (this.sortDirection === 'asc' ? 1 : -1) * (valueA - valueB);
        } else if (typeof valueA === 'string' && typeof valueB === 'string') {
          return (this.sortDirection === 'asc' ? 1 : -1) * valueA.localeCompare(valueB);
        } else {
          return 0;
        }
      });
    },
    getFieldArrow(field = 'user_id') {
      if (this.sortField === field) {
        return this.sortDirection === 'asc' ? '▲' : '▼';
      } else {
        return '';
      }
    },
    onPageChange(page) {
      this.currentPage = page;
      this.calculateTotalPages();
    },
    goToAddUser() {
      this.$router.push({ name: 'AddUser' });
    },
    onUserSearch() {
      this.currentPage = 1;
    },
    onCategorySearch() {
      this.currentPage = 1;
    },
    onLicenseSearch() {
      this.currentPage = 1;
    },
    onDepartmentSearch() {
      this.currentPage = 1;
    },
    onRoleSearch() {
      this.currentPage = 1;
    },
    ondobSearch() {
      this.currentPage = 1;
    },
    onpobirthSearch() {
      this.currentPage = 1;
    },
    viewEditUser(user_id) {
      localStorage.setItem('user_id', user_id);
      this.showEditUser = true; // Show the modal when the button is clicked
    },
    closeEditUser() {
      this.showEditUser = false; // Close the modal
    },
    calculateTotalPages() {
      this.computeFilteredUsers();
      this.totalPages = Math.ceil(this.filteredUsersLength / this.perPage);
    },
    computeFilteredUsers() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;

      const filteredUsers = this.users.filter((user) => {
        const fullName = (user.first_name + ' ' + user.last_name || "").toLowerCase(); // Fallback to empty string if null or undefined
        const userMatch = fullName.includes(this.searchQuery.toLowerCase()) || this.searchQuery === '';

        const category = (user.category || "").toLowerCase(); // Fallback to empty string if null or undefined
        const categoryMatch = category.includes(this.categorySearchQuery.toLowerCase()) || this.categorySearchQuery === '';

        const license_no = (user.license_no || "").toString(); // Fallback to empty string if null or undefined
        const licenseMatch = license_no.includes(this.licenseSearchQuery.toLowerCase()) || this.licenseSearchQuery === '';

        const role = (user.role || "").toString(); // Fallback to empty string if null or undefined
        const secondRole = (user.second_role || "").toString(); // Fallback to empty string if null or undefined
        const roleMatch =
          role.includes(this.roleSearchQuery.toLowerCase()) ||
          secondRole.includes(this.roleSearchQuery.toLowerCase()) ||
          this.roleSearchQuery === '';

        const department = (user.department || "").toString(); // Fallback to empty string if null or undefined
        const departmentMatch = department.toLowerCase().startsWith(this.departmentSearchQuery.toLowerCase()) || department.includes(this.departmentSearchQuery.toLowerCase()) || this.departmentSearchQuery === '';

        const date_of_birth = (user.date_of_birth || "").toString(); // Fallback to empty string if null or undefined
        const dobMatch = date_of_birth.includes(this.dobSearchQuery.toLowerCase()) || this.dobSearchQuery === '';

        const place_of_birth = (user.place_of_birth || "").toString(); // Fallback to empty string if null or undefined
        const pobirthMatch = place_of_birth.toLowerCase().startsWith(this.pobirthSearchQuery.toLowerCase()) || place_of_birth.includes(this.pobirthSearchQuery.toLowerCase()) || this.pobirthSearchQuery === '';

        return userMatch && categoryMatch && licenseMatch && roleMatch && departmentMatch && dobMatch && pobirthMatch;
      });
      console.log(filteredUsers);
      this.filteredUsersLength = filteredUsers.length;
      console.log(this.filteredUsersLength);
      return filteredUsers.slice(startIndex, endIndex);
    },
    clearFilters() {
      // Clear all filter-related data properties
      this.searchQuery = '',
        this.categorySearchQuery = '',
        this.licenseSearchQuery = '',
        this.roleSearchQuery = '',
        this.departmentSearchQuery = '',
        this.dobSearchQuery = '',
        this.pobirthSearchQuery = '',

        // Recalculate the total pages and update the data
        this.calculateTotalPages();
    },
  },
  computed: {
    startIndex() {
      return (this.currentPage - 1) * this.perPage;
    },
    endIndex() {
      const end = this.startIndex + this.perPage;
      return end < this.filteredUsersLength ? end : this.filteredUsersLength;
    },
    filteredUsers() {
      return this.computeFilteredUsers();
    },
  },
};
</script>


<style scoped>
span:hover {
  cursor: pointer;
  color: blue;
}

.container-fluid-trainee {
  padding-top: 30px;
  margin-left: 1%;
  margin-right: 1%;
}

button.btn#addlogbookButton {
  padding: 10px 10px;
  font-size: 16px;
  width: 180px;
  height: 40px;
  margin-top: 0;
  margin-bottom: 15px;
  margin-right: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  float: right;
}

button.btn#addlogbookButton:hover {
  background-color: #0c1a61;
  transition: transform 0.2s, box-shadow 0.2s;
}

button.btn#assignTraineeButton {
  padding: 10px 10px;
  font-size: 16px;
  width: 200px;
  height: 40px;
  margin-top: 0;
  margin-bottom: 15px;
  margin-right: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  float: right;
}

button.btn#assignTraineeButton:hover {
  background-color: #0c1a61;
  transition: transform 0.2s, box-shadow 0.2s;
}

button.btn#importTraineeButton {
  padding: 10px 10px;
  font-size: 16px;
  width: 180px;
  height: 40px;
  margin-top: 0;
  margin-bottom: 15px;
  margin-right: 20px;
  border: 1px solid rgb(17, 80, 20);
  background: #2c752c;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  float: right;
}

button.btn#importTraineeButton:hover {
  background-color: #184218;
  transition: transform 0.2s, box-shadow 0.2s;
}

input[type="text"] {
  width: 100%;
  height: 35px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  margin-bottom: 1px;
  margin-right: 20px;
}

input[type="text"]#productFilter {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
  position: absolute;
  right: 210px;
  top: 0px;
}

input[type="text"]#projectFilter {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
  position: absolute;
  right: 210px;
  top: 41px;
}

input[type="text"]#dobFilter {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
  position: absolute;
  right: 210px;
  top: 82px;
}

input[type="text"]#pobirthFilter {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
  position: absolute;
  right: 210px;
  top: 123px;
}



h1 {
  padding-top: 30px;
  margin-right: 100%;
  padding-left: 3%;
}

.filter-container {
  margin: 20px 0;
  position: absolute;
  width: 200px;
  top: 0px;
  right: 20px;
}

label {
  margin-top: 8px;
}

.current-time-container {
  position: absolute;
  top: 80px;
  right: 10px;

}

.data-table-trainee {
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-collapse: collapse;
  margin-left: 0;
  align-items: left;
  margin-bottom: 30px;
}

.data-table-trainee th,
.data-table-trainee td {
  text-align: left;
  /* Align content to the left */
  padding-left: 10px;
}

.data-table-trainee td.center-icons {
  text-align: center;
}

.pagination-container {
  margin-top: 40px;
  margin-bottom: 0;
  display: flex;
  margin-left: 3%;
}

.edit-icon {
  font-size: 16px;
  /* Adjust the size as needed */
  color: #615f5f;
  /* Change the color to your preference */
  cursor: pointer;
  transition: color 0.2s, transform 0.2s, box-shadow 0.2s;
}

.edit-icon:hover {
  color: #2b2a2a;
  /* Change the color to your preference */
  cursor: pointer;
  transform: scale(1.1);
  /* Example transform for scaling on hover */

}

.delete-icon {
  font-size: 16px;
  color: #FF0000;
  /* Red color */
  cursor: pointer;
  transition: color 0.2s, transform 0.2s, box-shadow 0.2s;
  padding-left: 15px;
}

.delete-icon:hover {
  color: #a82222;
  /* Lighter red color on hover */
  cursor: pointer;
  transform: scale(1.1);
  /* Scale the icon on hover */
  /* You can also add a box-shadow or any other styles you prefer */
}

.clear-filter-button {
  position: absolute;
  right: 50px;
  top: 137px;
  width: 120px;
  border-radius: 5px;
  background-color: #dee2e6;
  font-size: 14px;
  height: 30px;
  border: 2px solid black;
  line-height: 0px;
}

.clear-filter-button:hover {
  background-color: #cecece;
  cursor: pointer;
}

.pagination-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}


.pagination-info {
  font-size: 14px;
  color: #666;
  /* Adjust the color as needed */
  position: absolute;
  top: 250px;
  left: 50%;
  /* Center horizontally */
  transform: translateX(-50%);
  /* Center horizontally */
}

.wider-column {
  width: 42%;
}

.td-logbook {
  font-size: 13px;
}


.id-column {
  width: 5%;
}

.skinny-column {
  width: 7%;
}

.name-column {
  width: 9%;
}

.date-column {
  width: 9%;
}
</style>