<template>
  <div>
    <Header />
    <div class="container card">
      <button class="back-button" @click="goBack">Back</button>
      <h1>Add Trainee</h1>
      <!-- <div>
        <button class="view-trainees-button" @click="viewTraineeList">View Trainee List</button>
      </div> -->
      <trainee-list-modal v-if="showTraineeList" :trainees="trainees" @close="closeTraineeList"></trainee-list-modal>
      <div class="add">
        <form @submit.prevent="addUser">
          <div class="form-group">
            <label for="name">First Name:</label>
            <input type="text" class="form-control rounded" id="name" name="name" placeholder="Enter First Name"
              v-model="newUser.first_name" />
          </div>
          <div class="form-group">
            <label for="name">Last Name:</label>
            <input type="text" class="form-control rounded" id="name" name="name" placeholder="Enter Last Name"
              v-model="newUser.last_name" />
          </div>
          <div class="form-group">
            <label for="email">Email:</label>
            <input type="text" class="form-control rounded" id="email" name="email" placeholder="Enter Email Address"
              v-model="newUser.email" />
          </div>
          <div class="form-group">
            <label for="license_no">License No:</label>
            <input type="text" class="form-control rounded" id="license_no" name="license_no" placeholder="License Number"
       v-model="newUser.license_no" />
          </div>
          <div class="form-group">
            <label for="category">Category:</label>
            <input type="text" class="form-control rounded" id="category" name="category" placeholder="Enter the Category"
              v-model="newUser.category" />
          </div>
          <div class="form-group">
            <label for="password">Password:</label>
            <input type="password" class="form-control rounded" id="password" name="password"
              placeholder="Create Password" v-model="newUser.password" />
          </div>
          <div v-if="showAlert" class="alert alert-danger error-message" role="alert">
            <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
          </div>
          <div>
            <button class="btn btn-primary rounded" type="submit" id="addTraineeButton">Add Trainee</button>
          </div>

        </form>
      </div>
    </div>
  </div>
</template>



<script>
import axios from 'axios';
import Header from '../Header.vue';
import TraineeListModal from './TraineeListModal.vue';

export default {
  name: 'AddTrainee',
  components: {
    Header,
    TraineeListModal,
  },
  data() {
    return {
      users: [],
      newUser: {
        first_name: '',
        last_name: '',
        role: 'trainee',
        password: '',
        email: '',
        license_no:'',
        category:'',
      },
      showAlert: false,
      currentTime: '',
      errorMessages: {},
      errorMessage: '',
      loggedInUser: '',
      showTraineeList: false,
    };
  },
  methods: {

    async addUser() {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.post(
          '/api/users',
          {
            first_name: this.newUser.first_name,
            last_name: this.newUser.last_name,
            role: 'trainee',
            email: this.newUser.email,
            license_no: this.newUser.license_no,
            category:this.newUser.category,
            password: this.newUser.password,
          },
          { headers }
        );

        if (response.status === 200) {
          window.alert('User added Successfully');
          this.newUser = {
            first_name: '',
            last_name: '',
            role: '',
            email: '',
            license_no:'',
            category:'',
            password: '',
          };
          this.errorMessage = '';
          this.showAlert = false;
        }
        this.$router.push({ name: 'ViewTraineeTable' });
      } catch (error) {
        console.error('Error adding User:', error.response);


        if (error.response && error.response.data && error.response.data.errors) {
          // Handle validation errors
          const validationErrors = error.response.data.errors;

          // Build an error message from the validation errors
          let errorMessage = 'Validation errors:';
          for (const field in validationErrors) {
            errorMessage += `\n${field}: ${validationErrors[field][0]}`;
          }

          this.errorMessage = errorMessage;
        } else {
          // Handle other errors
          this.errorMessage = 'An error occurred while adding the user.';
        }

        this.showAlert = true;
      }
    },

    async fetchUsers() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.display('unauthorized');
          return;
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get('/api/users', { headers });
        console.log(response);

        if (response.status === 200) {
          this.users = response.data.users;
        }
      } catch (error) {
        console.error('Error retrieving users:', error);
        // Handle error
      }
    },
    async fetchLoggedInUser() {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.get('/api/users', { headers });
        this.loggedInUser = response.data.username;
      } catch (error) {
        console.error('An error occurred while fetching the logged-in user:', error);
      }
    },
    viewTraineeList() {
      this.showTraineeList = true; // Show the modal when the button is clicked
    },
    closeTraineeList() {
      this.showTraineeList = false; // Close the modal
    },
    goBack() {
      this.$router.push({ name: 'ViewTraineeTable' });
    },
  },
  async mounted() {
    await this.fetchUsers();
  },
};
</script>

<style scoped>
.container.card {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  margin-top: 10px;
  min-width: 90%;
  min-height: 90%;
}

.add button {
  width: 200px;
  height: 40px;
  margin-top: 50px;
  margin-bottom: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
}

button.btn:hover {
  background-color: #0c1a61;
  transition: transform 0.2s, box-shadow 0.2s;
}

input.form-control,
select.form-control {
  width: 70%;
  margin-left: 200px;
  display: inline-block;
  margin-bottom: 35px;
  align-items: baseline;
}

.form-group {
  margin-bottom: 15px;
}

label {
  margin-right: 5px;
  position: absolute;
  margin-top: 8px;
  font-size: 18px;

}

.form-control {
  border-radius: 5px;
  
  cursor: text;
}

.form-control:hover{
  cursor: text;
}

.form-control#start_date {
  border-radius: 5px;
  width: 25%;
  margin-left: 100px;
  margin-right: 45%;
}

h1 {
  margin-bottom: 50px;
}

.view-trainees-button {
  background-color: #e7e7e7;
  /* Change the background color to your desired color */
  color: #000000;
  position: absolute;
  left: 40px;
  bottom: 10%;
  font-size: 14px;
  width: 140px;
  border-radius: 5px;
}

.view-trainees-button:hover {
  background-color: #cecece;
  cursor: pointer;
}

.back-button {
  position: absolute;
  bottom: 40px;
  /* Adjust the top position as needed */
  left: 60px;
  /* Adjust the right position as needed */
  width: 100px;
  border-radius: 5px;
  background-color: #c1e0bf;
  font-size: 14px;
  border: 2px solid black;
  padding: 3px;
}

.back-button:hover {
  background-color: #abc7a9;
  cursor: pointer;
}
</style>