<template>
  <Header />
  <div class="heading">
    <h1>Assign Trainees to Session ID: {{ $route.params.project_id }}</h1>
  </div>

  <div class="search-container">
    <input type="text" v-model="searchQuery" placeholder="Search by Name" />
  </div>

  <div class="pagination-button-container">
    <div class="pagination-container">
      <Pagination :totalPages="totalPages" :perPage="perPage" :currentPage="currentPage" @page-change="onPageChange" />
    </div>
    <div class="pagination-info">
      Showing {{ startIndex + 1 }}-{{ endIndex }} of {{ filteredTraineesLength }} trainees
    </div>
  </div>

  <div class="container-fluid">
    <table class="data-table-trainee">
      <thead>
        <tr>
          <th @click="sortBy('user_id')">
          ID
            <span v-if="sortField === 'user_id'" v-html="getFieldArrow()"></span>
          </th>
          <th @click="sortBy('first_name')">
            First Name
            <span v-if="sortField === 'first_name'" v-html="getFieldArrow('first_name')"></span>
          </th>
          <th>Last Name</th>
          <th>Email</th>
          <th>Assign Trainee</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in filteredTrainees" :key="item.user_id">
          <td>{{ item.user_id }}</td>
          <td>{{ item.first_name }}</td>
          <td>{{ item.last_name }}</td>
          <td>{{ item.email }} </td>
          <td>
            <input type="checkbox" v-model="selectedTrainees" :value="item.user_id">
          </td>
        </tr>
        <tr v-if="filteredTrainees.length === 0">
          <td colspan="100" style="text-align: center;">No Matching Trainees</td>
        </tr>
      </tbody>
    </table>
  </div>
    <button class="back-button" @click="goBack">Back</button>
    <button class="btn btn-primary rounded" @click="assignSelectedTrainees">Assign Trainees</button>
    <button class="btn btn-primary rounded" @click="goUnAssign">Un-Assign Trainees</button>

</template>
    
<script>
import Header from '../Header.vue';
import axios from 'axios';
import Pagination from '../TablePagination.vue';

export default {
  name: 'AssignTrainees',
  data() {
    return {
      trainees: [],
      selectedTrainees: [],
      sortField: 'user_id',
      sortDirection: 'asc',
      searchQuery: '',
      projectSearchQuery: '',
      currentPage: 1,
      perPage: 10,
      totalPages: 0,
      newProject: {
        name: '',
        course_id: '',
        course_name: '',
        user_id: '',
      },
      filteredTraineesLength: 0,
    };
  },
  watch: {
    searchQuery(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
      }
    },
  },
  components: {
    Pagination,
    Header
  },
  async mounted() {
  try {
    const token = localStorage.getItem('token');
    const headers = { Authorization: `Bearer ${token}` };
    const project_id = this.$route.params.project_id;

    // Fetch the trainees assigned to the project
    const response = await axios.get(`/api/projects/${project_id}/users`, { headers });
    const assignedTrainees = response.data;

    // Now, you have the assigned trainees in the 'assignedTrainees' variable.
    console.log(assignedTrainees);

    // Fetch all trainees (including those assigned to the project)
    const result = await axios.get('/api/users', { headers });

    if (result.status === 200) {
      // Filter the response data to include only users with the role 'trainee'
      this.trainees = result.data.filter(user => user.role === 'trainee' || user.second_role === 'trainee');

            // Check the checkboxes of Trainees already assigned to the project
            this.trainees.forEach(trainee => {
        if (assignedTrainees.some(assignedTrainee => assignedTrainee.user_id === trainee.user_id)) {
          this.selectedTrainees.push(trainee.user_id);
        }
      });
    }

    this.totalPages = Math.ceil(this.trainees.length / this.perPage);

  } catch (error) {
    console.error('An error occurred while fetching data:', error);
  }
},
  methods: {
    editTrainee(user_id) {
      window.location.href = `/trainees/${user_id}`;
    },
    sortBy(field) {
      if (this.sortField === field) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortField = field;
        this.sortDirection = 'asc';
      }

      this.trainees.sort((a, b) => {
        const valueA = a[field];
        const valueB = b[field];

        if (typeof valueA === 'number' && typeof valueB === 'number') {
          return (this.sortDirection === 'asc' ? 1 : -1) * (valueA - valueB);
        } else if (typeof valueA === 'string' && typeof valueB === 'string') {
          return (this.sortDirection === 'asc' ? 1 : -1) * valueA.localeCompare(valueB);
        } else {
          return 0;
        }
      });
    },
    getFieldArrow(field = 'user_id') {
      if (this.sortField === field) {
        return this.sortDirection === 'asc' ? '▲' : '▼';
      } else {
        return '';
      }
    },
    onPageChange(page) {
      this.currentPage = page;
    },
    goUnAssign() {
      this.$router.push({ name: 'ViewTrainees'});
    },
    assignSelectedTrainees() {
      if (this.selectedTrainees.length === 0) {
        return;
      }

      const project_id = this.$route.params.project_id;
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

  // Fetch the current trainees assigned to the course
  axios.get(`/api/projects/${project_id}/users`, { headers })
    .then(response => {
      const existingTrainees = response.data.map(trainee => trainee.user_id);

          // Filter out trainees already assigned to the course
          const newTrainees = this.selectedTrainees.filter(traineeId => !existingTrainees.includes(traineeId));


          console.log(newTrainees);

if (newTrainees.length === 0) {
  window.alert('All selected trainees are already in the Session.');
  this.selectedTrainees = [];
  return;
}
      // Send a POST request to assign the remaining trainees to the course
      const data = {
        user_ids: newTrainees,
        role: 'trainee'
      };

      axios.post(`/api/projects/${project_id}/add-users`, data, { headers })
        .then(response => {
          console.log(response);
          window.alert('Trainees added successfully');
          this.selectedTrainees = [];
          this.$router.push({ name: 'ViewTrainees'});
        })
        .catch(error => {
          console.error('Error assigning trainees:', error);
          // Handle errors, e.g., display an error message.
        });
    })
    .catch(error => {
      console.error('Error fetching current trainees:', error);
      // Handle errors, e.g., display an error message.
    });
},
goBack() {
      this.$router.push({ name: 'ViewProjects'});
    },
    calculateTotalPages() {
      this.computeFilteredTrainees();
      this.totalPages = Math.ceil(this.filteredTraineesLength / this.perPage);
    },
    computeFilteredTrainees() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;

      const filteredTrainees = this.trainees.filter((trainee) => {
        const fullName = (trainee.first_name + trainee.last_name || "").toLowerCase(); // Fallback to empty string if null or undefined
        const traineeMatch = fullName.includes(this.searchQuery.toLowerCase()) || this.searchQuery === '';

        return traineeMatch
      });

      console.log(filteredTrainees);
      this.filteredTraineesLength = filteredTrainees.length;
      console.log(this.filteredTraineesLength);
      return filteredTrainees.slice(startIndex, endIndex);
  },
},
  computed: {
    startIndex() {
      return (this.currentPage - 1) * this.perPage;
    },
    endIndex() {
      const end = this.startIndex + this.perPage;
      return end < this.filteredTraineesLength ? end : this.filteredTraineesLength;
    },
    filteredTrainees() {
      return this.computeFilteredTrainees();
    },
  },
};
</script>
    
    
<style scoped>
span:hover {
  cursor: pointer;
  color: blue;
}

.pagination-container {
    margin-top: 55px;
    display: flex;
    margin-left: 3%;
}

.container-fluid {
  padding-top: 25px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.search-container input[type="text"] {
  margin-left: 40px;
  padding: 10px;
  font-size: 16px;
  width: 30%;
}

button.btn {
  padding: 10px 10px;
  font-size: 16px;
  width: 180px;
  height: 40px;
  margin-top: 0%;
  margin-bottom: 15px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  float: right;
  margin-right: 30px;
}

button.btn:hover {
  background-color: #0c1a61;
  transition: transform 0.2s, box-shadow 0.2s;
}

.heading h1 {
  padding-top: 30px;
  padding-bottom: 10px;
  text-align: left;
  padding-left: 3%;

}

.back-button {
  /* Adjust the right position as needed */
  width: 100px;
  border-radius: 5px;
  background-color: #c1e0bf;
  float: left;
  margin-left: 30px;
  border: 2px solid black;
  padding: 3px;
}

button:hover {
  background-color: #7c927a;
  transition: transform 0.2s, box-shadow 0.2s;
}
.back-button:hover {
  background-color: #abc7a9;
  cursor: pointer;
}

.pagination-info {
  font-size: 14px;
  color: #666;
  /* Adjust the color as needed */
  position: absolute;
  top: 300px;
  left: 50%;
  /* Center horizontally */
  transform: translateX(-50%);
  /* Center horizontally */
}
</style>