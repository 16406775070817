<template>
  <head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
  </head>
  <div>
    <Header />

    <div class="current-time-container">
      <div class="filter-container">
        <select v-model="productFilter" id="productFilter" class="filter-select" @change="fetchProjectsByProduct">
          <option value="">All Practical Elements</option>
          <option v-for="product in products" :key="product.product_id" :value="product.product_id">{{ product.name }}
          </option>
        </select>


        <select v-model="projectFilter" id="projectFilter" class="filter-select" :disabled="!productFilter">
          <option value="">All Sessions</option>
          <option v-for="project in filteredProjects" :key="project.project_id" :value="project.project_id">{{
            project.name }}</option>
        </select>


        <input v-model="logbookSearch" type="text" placeholder="Search Logbook Name" />
        <input v-model="traineeSearch" type="text" placeholder="Search Trainee ID" />
        <input v-model="instructorSearch" type="text" placeholder="Search Instructor Name" />

        <button class="clear-filter-button" @click="clearFilters">Clear Filters</button>

      </div>
    </div>

    <h1>Logbooks</h1>

    <div class="pagination-button-container">
      <div class="pagination-container">
        <Pagination :totalPages="totalPages" :perPage="perPage" :currentPage="currentPage" @page-change="onPageChange" />
      </div>
      <div class="pagination-info">
        Showing {{ startIndex + 1 }}-{{ endIndex }} of {{ filteredLogbooksLength }} logbooks
      </div>
    </div>

    <edit-logbook-modal v-if="showEditLogbook" @close="closeEditLogbook"></edit-logbook-modal>

    <div class="container-fluid-logbook">
      <table class="data-table-trainee">
        <thead>
          <tr>
            <th @click="sortBy('logbook_id')" class="id-column">
              ID
              <span v-if="sortField === 'logbook_id'" v-html="getFieldArrow()"></span>
            </th>
            <th @click="sortBy('name')" class="wider-column">
              Logbook Name
              <span v-if="sortField === 'name'" v-html="getFieldArrow('name')"></span>
            </th>
            <th class="id-column">P.E. ID</th>
            <th class="id-column">Session ID</th>
            <th class="id-column">Trainee ID</th>
            <th>Instructor(s)</th>
            <th class="skinny-column-select">Actions</th>
            <!-- <th class="skinny-column">Delete Logbook</th>  -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in filteredLogbooks" :key="item.logbook_id">
            <td>{{ item.logbook_id }} </td>
            <td>{{ item.name }}</td>
            <td>{{ item.product_id }} </td>
            <td>{{ item.project_id }} </td>
            <td>{{ item.trainee_id }} </td>
            <td>
  <!-- Iterate over instructors for this logbook -->
  <span v-for="(instructor, index) in instructors" :key="index">
    <!-- Check if the instructor is associated with this logbook -->
    <span v-if="instructor.logbook_id === item.logbook_id">
      <!-- Display instructor's name -->
      {{ instructor.last_name }}, {{ instructor.first_name }} <br>
    </span>
  </span>
</td>
            <td class="center-icons">
              <i class="fas fa-edit edit-icon" @click="viewEditLogbook(item.logbook_id)"></i>
              <i class="fas fa-trash-alt delete-icon" @click="deleteLogbook(item.logbook_id)"></i>
              <!-- <button id="generatePDFButton" @click="selectTrainee(item.trainee_id, item.product_id)">PDF</button> -->
            </td>
            <!-- <td>
                <button class="delete-button" @click="deleteLogbook(item.logbook_id)">Delete</button>
              </td>  -->
          </tr>
          <tr v-if="logbooks.length === 0">
          <td colspan="100" style="text-align: center;">No Matching Logbooks</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div>
    <button class="btn btn-primary rounded" id=addlogbookButton @click="goAddLogbook">Create New Logbook</button>
  </div>
</template>
  
<script>
import Header from '../Header.vue';
import axios from 'axios';
import Pagination from '../TablePagination.vue';
import EditLogbookModal from './EditLogbookModal.vue';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {
  name: 'ViewLogbooks',

  components: {
    Pagination,
    Header,
    EditLogbookModal,
  },

  data() {
    return {
      logbooks: [],
      sortField: 'logbook_id',
      sortDirection: 'asc',
      currentPage: 1,
      perPage: 10,
      totalPages: 0,
      productFilter: '',
      projectFilter: '',
      traineeSearch: '',
      instructorSearch: '',
      logbookSearch: '',
      products: [],
      projects: [],
      instructors: [],
      tasks: [],
      trainees: [],
      selectedProductId: '',
      selectedTraineeId: '',
      showEditLogbook: false,
      instructorNames: {},
      filteredLogbooksLength: 0,
      joinedName: '',
      selectedTrainee: {
        trainee_id: null,
        first_name: '',
        last_name: '',
        email: '',
        category: '',
        license_no: '',
        date_of_birth: '',
        place_of_birth: '',
      },
    };
  },

  watch: {
    traineeSearch(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    instructorSearch(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    logbookSearch(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    productFilter(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when productFilter changes
        this.fetchProjectsByProduct(); // Fetch projects based on the selected product
        this.calculateTotalPages();
      }
    },
    projectFilter(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when productFilter changes
        this.calculateTotalPages();
      }
    },
  },
  async mounted() {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      let result = await axios.get('/api/logbooks', { headers });
      this.logbooks = result.data;

      // Fetch all products from the server
      const productResponse = await axios.get('/api/products', { headers });
      this.products = productResponse.data; // Assign the entire array of products to this.products

      let resultInstructors = await axios.get('/api/user/logbooks', { headers });
      this.instructors = resultInstructors.data.user_logbooks;
      // Filter only the instructors
      this.instructors = this.instructors.filter(user => user.role === 'instructor' || user.second_role === 'instructor');
      console.log(this.instructors);   

      // let instructorProject = await axios.get('/api/projects/{project_id}/users', { headers });

      this.totalPages = Math.ceil(this.logbooks.length / this.perPage);

    } catch (error) {
      console.error('An error occurred while fetching data:', error);
    }
  },

  methods: {
    getInstructorsForLogbook(logbookId) {
    // Filter the instructors array based on the logbook_id
    return this.instructors.filter(instructor => instructor.logbook_id === logbookId);
  },
  deleteLogbook(logbook_id) {
  if (confirm("Are you sure you want to delete this logbook?")) {
    if (confirm("This action will PERMANENTLY delete the logbook from the database. Do you wish to continue?")) {
      // If the user confirms the deletion, send a request to delete the logbook.
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      axios
        .delete(`/api/logbooks/${logbook_id}`, { headers })
        .then(response => {
          if (response.status === 200) {
            // Remove the deleted logbook from the local data
            this.logbooks = this.logbooks.filter(logbook => logbook.logbook_id !== logbook_id);

            window.alert('Logbook deleted Successfully');
          }
        })
        .catch(error => {
          // Check if the error is due to a foreign key constraint
          if (error.response && error.response.status === 500) {
            const errorMessage = error.response.data.message;
            const isForeignKeyConstraintError = errorMessage.includes('SQLSTATE[23000]') && errorMessage.includes('Integrity constraint violation: 1451');

            if (isForeignKeyConstraintError) {
              window.alert('This Logbook cannot be deleted because it contains one or more entries.');
            } else {
              window.alert('An error occurred while deleting the Logbook. Please try again.');
            }
          } else {
            console.error('An unexpected error occurred:', error);
            window.alert('An unexpected error occurred. Please try again.');
          }
        });
    }
  }
},
    async selectTrainee(trainee_id, product_id) {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        // Find the selected trainee by ID
        const response = await axios.get(`/api/users/${trainee_id}`, { headers });
        const selectedTrainee = response.data;

        if (selectedTrainee) {
          // Populate the selectedTrainee object with the details
          this.selectedTrainee.trainee_id = selectedTrainee.user_id;
          this.selectedTrainee.first_name = selectedTrainee.first_name;
          this.selectedTrainee.last_name = selectedTrainee.last_name;
          this.selectedTrainee.email = selectedTrainee.email;
          this.selectedTrainee.category = selectedTrainee.category;
          this.selectedTrainee.license_no = selectedTrainee.license_no;
          this.selectedTrainee.date_of_birth = selectedTrainee.date_of_birth;
          this.selectedTrainee.place_of_birth = selectedTrainee.place_of_birth;
        }
        let taskResult = await axios.get('/api/tasks', { headers });
        this.tasks = taskResult.data.filter(task => task.prod_id === product_id);

        console.log(this.tasks);


        const selectedProduct = this.products.find(product => product.product_id === product_id);
        this.selectedProductName = selectedProduct ? selectedProduct.name : '';

        const user_id = localStorage.getItem('user_id');

        const userResult = await axios.get(`/api/users/${user_id}`, { headers });

        const userFullName = userResult.data.first_name + ' ' + userResult.data.last_name;
        this.joinedName = `${userFullName}`


        console.log(selectedTrainee);
        this.generatePDF();
      } catch (error) {
        console.error('An error occurred:', error);
      }
    },

    async fetchProjectsByProduct() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.display('unauthorized');
          return;
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        // Fetch projects based on the selected product (this.productFilter, which stores the product ID)
        const response = await axios.get(`/api/projects?product=${this.productFilter}`, { headers });

        if (response.status === 200) {
          this.projects = response.data;
        }

        // Enable or disable the project filter based on the product selection
        this.$nextTick(() => {
          const projectFilterSelect = document.getElementById('projectFilter');
          projectFilterSelect.disabled = !this.productFilter;
        });
      } catch (error) {
        console.error('Error retrieving Sessions:', error);
        // Handle error
      }
    },
    sortBy(field) {
      if (this.sortField === field) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortField = field;
        this.sortDirection = 'asc';
      }

      this.logbooks.sort((a, b) => {
        const valueA = a[field];
        const valueB = b[field];

        if (typeof valueA === 'number' && typeof valueB === 'number') {
          return (this.sortDirection === 'asc' ? 1 : -1) * (valueA - valueB);
        } else if (typeof valueA === 'string' && typeof valueB === 'string') {
          return (this.sortDirection === 'asc' ? 1 : -1) * valueA.localeCompare(valueB);
        } else {
          return 0;
        }
      });
    },
    async fetchTraineeTasks(traineeId) {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        console.log(traineeId);

        // Fetch trainee logbooks using trainee ID
        const traineeLogbooksResponse = await axios.get(`api/trainee/${traineeId}/logbooks`, { headers });
        const traineeLogbooks = traineeLogbooksResponse.data.logbooks; // Assuming it returns an array of logbooks

        console.log(traineeLogbooks);

        // Fetch tasks in each logbook
        const tasksPromises = traineeLogbooks.map(async (logbook) => {
          const logbookId = logbook.logbook_id;
          const logbookEntryResponse = await axios.get(`api/logbookentry/${logbookId}`, { headers });
          return logbookEntryResponse.data.tasks; // Assuming it returns an array of tasks for each logbook
        });

        const tasksArray = await Promise.all(tasksPromises); // Array of arrays of tasks

        // Flatten the tasksArray to a single array
        const tasks = tasksArray.flat();


        return tasks; // Return the tasks array
      } catch (error) {
        console.error('An error occurred while fetching trainee logbooks or tasks:', error);
        throw error; // Propagate the error to the calling code if needed
      }
    },
    async generatePDF() {
      const pdf = new jsPDF();

      const traineeId = this.selectedTrainee.trainee_id;
      const traineeTasks = await this.fetchTraineeTasks(traineeId);

      // Now you have all the tasks for the selected trainee
      console.log(traineeTasks);

      // Add the "Tasks for Product:" heading to the PDF content
      pdf.setFont('helvetica', 'bold');
      pdf.setFontSize(12); // Adjust the font size if needed
      pdf.text(`Practical Element: ${this.selectedProductName}`, 10, 15);

      // Add a horizontal line underneath the title
      pdf.line(10, 20, 200, 20);

      const currentDate = new Date();
      const day = currentDate.getDate();
      const month = currentDate.getMonth() + 1; // Months are 0-based, so add 1
      const year = currentDate.getFullYear();

      // Use padStart to add a leading zero if day or month is a single digit
      const formattedDate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;


      pdf.setFont('helvetica', 'bold');
      pdf.setFontSize(10);
      pdf.text('Name:', 10, 30);
      pdf.text('Category:', 100, 30);
      pdf.text('License No:', 10, 40);
      pdf.text('Date of Birth:', 100, 40);
      pdf.text('Date:', 10, 50);

      pdf.setFont('helvetica', 'normal'); // Reset the font weight to normal
      pdf.text(`${this.selectedTrainee.first_name} ${this.selectedTrainee.last_name}`, 30, 30);
      pdf.text(`${this.selectedTrainee.category}`, 135, 30);
      pdf.text(`${this.selectedTrainee.license_no}`, 30, 40);
      pdf.text(`${this.selectedTrainee.date_of_birth}`, 135, 40);
      pdf.text(`${formattedDate}`, 30, 50);

      // Add a horizontal line underneath the title
      pdf.line(10, 60, 200, 60);


      pdf.setFont('helvetica', 'bold');
      pdf.setFontSize(12); // Adjust the font size if needed
      pdf.text(`Tasks:`, 10, 70);
      pdf.setFont('helvetica', 'normal'); // Reset the font weight to normal
      const imgData = require('../reports/astech.png');
      const imgWidth = 22; // Adjust the width of the image
      const imgHeight = 10; // Adjust the height of the image
      const imgX = pdf.internal.pageSize.width - imgWidth - 10; // Adjust the X position
      const imgY = 7.5; // Adjust the Y position
      pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth, imgHeight);


      // Define the table columns
      const columns = ['Task ID', 'Task No', 'TType', 'Manob', 'Level', 'Description', 'Completed', 'Instructor SO'];

      // Initialize an empty array to hold the table data
      const tableData = [];

      for (const task of this.tasks) {
        const taskData = [
          task.task_id,
          task.task_no,
          task.ttype,
          task.manob,
          task.level,
          task.description,
        ];

        // Find the corresponding trainee task
        const traineeTask = traineeTasks.find(tt => tt.task_id === task.task_id);

        if (traineeTask) {
          // If trainee task data exists, add it to the table
          taskData.push(traineeTask.completed);
          taskData.push(traineeTask.ins_so);
        } else {
          // If no trainee task data, add empty values for Completed and Inst_SO
          taskData.push('');
          taskData.push('');
        }

        // Add the row to the table data
        tableData.push(taskData);
      }

    // Add the table to the PDF document
    pdf.autoTable({
        head: [columns], 
        body: tableData,
        startY: 75,
        margin: { horizontal: 10 },
        styles: {
            lineWidth: 0.1, // Set the border line width
            lineColor: [169, 169, 169], // Set the border color 
            fontSize: 8, // Set the font size for the table
        }
    });
    
      pdf.setFontSize(16);
      pdf.setFont('helvetica', 'bold');

      pdf.text(`Declarations`, 105, 90, 'center');



pdf.setFontSize(11);

const text = `I acknowledge that I have successfully completed the assigned tasks in accordance with the provided instructions on the dates and times indicated in the logbook. I have made a diligent effort to meet the objectives and standards set for each task and acknowledge that each task is completed through my own work alone.`;
pdf.setFont('helvetica', 'bold');
pdf.text(`Trainee Declaration:`, 20, 100);

pdf.setFont('helvetica', 'normal');
pdf.text(text, 20, 106, { maxWidth: 165 });

const text_inst = `I hereby confirm that I have overseen the progress of the trainee and have personally reviewed and signed off on every task completed on the dates and times indicated. I ensured that each task was completed up to the required standards and in accordance with the provided instructions. If there were any discrepancies or issues, they were promptly addressed and resolved. The trainee has demonstrated competence and diligence throughout the training period.`;
pdf.setFont('helvetica', 'bold');
pdf.text(`Instructor Declaration:`, 20, 135);

pdf.setFont('helvetica', 'normal');
pdf.text(text_inst, 20, 141, { maxWidth: 160 });


// const text_assess = `I hereby acknowledge that I have thoroughly assessed the tasks assigned to the trainee. After careful evaluation, I confirm that the trainee has successfully completed the assessment tasks up to the required standards and expectations outlined for each. The trainee demonstrated competence, understanding, and proficiency in the assigned responsibilities.`;
// pdf.setFont('helvetica', 'bold');
// pdf.text(`Assessor Declaration:`, 20, 180);

// pdf.setFont('helvetica', 'normal');
// pdf.text(text_assess, 20, 186, { maxWidth: 160 });


      // Add Trainee Signature
pdf.text(`Trainee Signature:`, 15, 265);
pdf.line(47, 265, 125, 265); // Horizontal line for Trainee Signature

pdf.text(`Date:`, 150, 265)
      // Add a horizontal line 
      pdf.line(160, 265, 195, 265);

// Add Instructor Signature
pdf.text(`Instructor Signature:`, 15, 280); // Adjust the vertical position as needed
pdf.line(50, 280, 125, 280);

pdf.text(`Date:`, 150, 280)
      // Add a horizontal line 
      pdf.line(160, 280, 195, 280);

      // pdf.text(`Assessor Signature:`, 15, 280)
      // // Add a horizontal line 
      // pdf.line(50, 280, 125, 280);

      // pdf.text(`Date:`, 150, 280)
      // // Add a horizontal line 
      // pdf.line(160, 280, 195, 280);

      // Add a horizontal line 
      pdf.line(10, 285, 200, 285);

      // Calculate the total number of pages after rendering the table
      const totalPages = pdf.internal.getNumberOfPages();
      // Add the footer to each page
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(8);
        const pageWidth = pdf.internal.pageSize.width;

        pdf.text(`Author: ${this.joinedName}`, 10, 290);

        const currentDate = new Date();
        const formattedDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`;
        // Calculate the width of the "Printed by" text and the formatted date
        const text = `Printed by: ${this.joinedName} on ${formattedDate}`;
        const textWidth = (pdf.getStringUnitWidth(text) * 8) / 3;

        // Calculate the X position to center the text
        const x = (pageWidth - textWidth) / 2;

        pdf.text(text, x, 290);

        pdf.text(`Page ${i} of ${totalPages}`, pdf.internal.pageSize.width - 25, 290); // Adjust the coordinates as needed
      }


      const blob = pdf.output('blob');
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    },
    goAddLogbook() {
      this.$router.push({ name: 'AddLogbook' })
    },
    getFieldArrow(field = 'logbook_id') {
      if (this.sortField === field) {
        return this.sortDirection === 'asc' ? '▲' : '▼';
      } else {
        return '';
      }
    },
    onPageChange(page) {
      this.currentPage = page;

      this.calculateTotalPages();
    },
    // Add a method to handle changes in the search input field
    onTraineeSearch() {
      this.currentPage = 1; // Reset currentPage to 1 when search query changes
    },
    onLogbookSearch() {
      this.currentPage = 1; // Reset currentPage to 1 when search query changes
    },
    onInstructorSearch() {
      this.currentPage = 1; // Reset currentPage to 1 when search query changes
    },
    viewEditLogbook(logbook_id) {
      localStorage.setItem('logbook_id', logbook_id);
      this.showEditLogbook = true; // Show the modal when the button is clicked
    },
    closeEditLogbook() {
      this.showEditLogbook = false; // Close the modal
    },
    calculateTotalPages() {
      this.computeFilteredLogbooks();
      this.totalPages = Math.ceil(this.filteredLogbooksLength / this.perPage);
    },
    computeFilteredLogbooks() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      const filteredLogbooks = this.logbooks.filter((logbook) => {
        const traineeMatch = this.traineeSearch === '' || logbook.trainee_id.toString() === this.traineeSearch;
        const logbookName = logbook.name.toLowerCase();
        const logbookMatch = logbookName.includes(this.logbookSearch.toLowerCase()) || this.logbookSearch === '';

        // const instructorMatch = this.instructors.some(instructor => {
        //   const fullName = `${instructor.first_name} ${instructor.last_name}`;
        //   const searchQuery = this.instructorSearch.toLowerCase();

        //   return fullName.toLowerCase().includes(searchQuery);
        // }) && logbook.instructor_id.toString().includes(this.instructors.find(instructor => {
        //   const fullName = `${instructor.first_name} ${instructor.last_name}`;
        //   return fullName.toLowerCase().startsWith(this.instructorSearch.toLowerCase());
        // }).user_id) || this.instructorSearch === '';

        const projectMatch = this.projectFilter === '' || logbook.project_id === this.projectFilter;
        const productMatch = this.productFilter === '' || logbook.product_id === this.productFilter;

        return traineeMatch && logbookMatch && projectMatch && productMatch;

      });
      this.filteredLogbooksLength = filteredLogbooks.length
      return filteredLogbooks.slice(startIndex, startIndex + this.perPage);
    },
    clearFilters() {
      // Clear all filter-related data properties
      this.productFilter = '';
      this.projectFilter = '';
      this.traineeSearch = '';
      this.instructorSearch = '';
      this.logbookSearch = '';

      // Recalculate the total pages and update the data
      this.calculateTotalPages();
    },
  },

  computed: {
    startIndex() {
      return (this.currentPage - 1) * this.perPage;
    },
    endIndex() {
      const end = this.startIndex + this.perPage;
      return end < this.filteredLogbooksLength ? end : this.filteredLogbooksLength;
    },
    filteredProjects() {
      if (this.productFilter === '') {
        // If no product is selected, return all projects
        return this.projects;
      } else {
        // Filter projects based on the selected prod_id
        return this.projects.filter(project => project.prod_id === this.productFilter);
      }
    },
    filteredLogbooks() {
      return this.computeFilteredLogbooks();
    },
  },

}
</script>
  
  
<style scoped>
h2 .trainees-header,
.logbooks-header {
  margin-left: 5%;
  text-align: left;
}

span:hover {
  cursor: pointer;
  color: blue;
}

.container-fluid-logbook {
  padding-top: 30px;
  margin-left: 1%;
  margin-right: 1%;
}

input[type="text"] {
  width: 100%;
  height: 35px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  margin-bottom: 1px;
}



h1 {
  padding-top: 30px;
  margin-right: 100%;
  padding-left: 3%;
}

.filter-container {
  margin: 20% 0;
  position: absolute;
  width: 200px;
  top: 10px;
}

label {
  margin-top: 8px;
}

.filter-select#productFilter {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
  position: absolute;
  right: 210px;
  top: 6px;
}

.filter-select#projectFilter {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
  position: absolute;
  right: 210px;
  top: 50px;
}

button.btn#addlogbookButton {
  padding: 10px 10px;
  font-size: 16px;
  width: 180px;
  height: 40px;
  margin-top: 0;
  margin-bottom: 15px;
  margin-right: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  float: right;
}

button.btn#addlogbookButton:hover {
  background-color: #0c1a61;
  transition: transform 0.2s, box-shadow 0.2s;
}

.current-time-container {
  position: absolute;
  top: 90px;
  right: 210px;
  display: flex;
  align-items: center;
}

.data-table-trainee {
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-collapse: collapse;
  margin-left: 0;
  align-items: left;
  margin-bottom: 30px;
}

.data-table-trainee th,
.data-table-trainee td {
  text-align: left;
  /* Align content to the left */
  padding-left: 10px;
}

.data-table-trainee td.center-icons {
  text-align: center;
}

.pagination-container {
  margin-top: 40px;
  margin-bottom: 0;
  display: flex;
  margin-left: 3%;
}

.edit-icon {
  font-size: 16px;
  /* Adjust the size as needed */
  color: #615f5f;
  /* Change the color to your preference */
  cursor: pointer;
  transition: color 0.2s, transform 0.2s, box-shadow 0.2s;
}

.edit-icon:hover {
  color: #2b2a2a;
  /* Change the color to your preference */
  cursor: pointer;
  transform: scale(1.1);
  /* Example transform for scaling on hover */

}

span {
  border: 0px;
}

span:hover {
  border: 0px;
  color: rgb(17, 17, 17);
  cursor: auto;
}

.delete-icon {
  font-size: 16px;
  color: #FF0000;
  /* Red color */
  cursor: pointer;
  transition: color 0.2s, transform 0.2s, box-shadow 0.2s;
  padding-left: 15px;
}

.delete-icon:hover {
  color: #a82222;
  /* Lighter red color on hover */
  cursor: pointer;
  transform: scale(1.1);
  /* Scale the icon on hover */
  /* You can also add a box-shadow or any other styles you prefer */
}

.skinny-column {
  width: 10%;
}

.id-column {
  width: 7%;
}

.data-table-trainee th.skinny-column-select {
  width: 10%;
  text-align: center;
}

.wider-column {
  width: 42%;
}


.filter-select#projectFilter:disabled {
  cursor: not-allowed;
}

.clear-filter-button {
  position: absolute;
  right: 250px;
  top: 92px;
  width: 120px;
  border-radius: 5px;
  background-color: #dee2e6;
  font-size: 14px;
  height: 30px;
  border: 2px solid black;
  line-height: 0px;
}

.clear-filter-button:hover {
  background-color: #cecece;
  cursor: pointer;
}

.pagination-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}


.pagination-info {
  font-size: 14px;
  color: #666;
  /* Adjust the color as needed */
  position: absolute;
  top: 250px;
  /* Adjust the top position as needed */
  /* Center horizontally within the container */
  left: 50%;
  /* Center horizontally */
  transform: translateX(-50%);
  /* Center horizontally */
}

button#generatePDFButton {
  border-radius: 5px;
  background-color: #c8e1fa;
  margin-left: 10px;
  font-size: 13px;
  padding: 3px;
  max-width: 40px;
  border: 2px solid black;
}

button#generatePDFButton:hover {
  background-color: #afc5db;
  cursor: pointer;
}
</style>
  