<template>
  <div class="Task-modal-overlay" @click="close">
    <div class="Task-modal-content" @click.stop>
      <div class="Task-modal-header">
        <div class="Task-modal-dialog">
          <h5 style="padding: 10px;" class="Task-modal-title">Progress of Trainee (License No: {{ traineeUID }})</h5>
        </div>
      </div>
      <div>
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link" :class="{ active: currentTab === 'chapters' }"
              @click.stop="currentTab = 'chapters'">Chapters</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{ active: currentTab === 'scope' }" @click.stop="currentTab = 'scope'">Scope</a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" :class="{ active: currentTab === 'chapterScope' }"
              @click.stop="currentTab = 'chapterScope'">Chapter-Scope</a>
          </li> -->
        </ul>
        <table class="data-table" v-if="currentTab === 'chapters'">
          <thead>
            <tr>
              <th style="width: 700px;">Chapter</th>
              <th>Total Tasks</th>
              <th>Total Complete</th>
              <th>Mandatory</th>
              <th>Mandatory Complete</th>
              <th>Enabled</th>
              <th>Outstanding</th>
              <th>% Total Complete</th>
              <th>% Mandatory Complete</th>
            </tr>
          </thead>
          <tbody>
            <!-- Table rows for chapters -->
          </tbody>
          <tr v-for="chapter in filteredChapters" :key="chapter.id">
            <td class="first_column"><strong>{{ chapter.chapt_no }}: {{ chapter.name }}</strong></td>
            <td>{{ chapter.tasks.all.length }}</td>
            <td>{{ chapter.tasks.completed.length }}</td>
            <td>{{ chapter.tasks.manob.length }}</td>
            <td>{{ chapter.tasks.main_completed.length }}</td>
            <td>{{ chapter.tasks.enabled.length }}</td>
            <td>{{ chapter.tasks.enabled.length - chapter.tasks.completed.length }}</td>
            <td :class="{
              'highlight-green': chapter.tasks.all.length === 0 || (chapter.tasks.all.length && (chapter.tasks.main_completed.length / chapter.tasks.all.length * 100) >= 50),
              'highlight-orange': chapter.tasks.all.length && (chapter.tasks.main_completed.length / chapter.tasks.all.length * 100) < 50
            }">
              {{ chapter.tasks.all.length === 0 ? '100.0%' : (chapter.tasks.main_completed.length /
                chapter.tasks.all.length * 100).toFixed(1) + '%' }}
            </td>
            <td :class="{
              'highlight-green': chapter.tasks.manob.length === 0 || (chapter.tasks.manob.length && (chapter.tasks.main_completed.length / chapter.tasks.manob.length * 100) >= 50),
              'highlight-orange': chapter.tasks.manob.length && (chapter.tasks.main_completed.length / chapter.tasks.manob.length * 100) < 50
            }">
              {{ chapter.tasks.manob.length === 0 ? '100.0%' : (chapter.tasks.main_completed.length /
                chapter.tasks.manob.length * 100).toFixed(1) + '%' }}
            </td>
          </tr>
          <tr class="total-row">
            <td class="first_column"><strong>Overall</strong></td>
            <td>{{ totalTasks }}</td>
            <td>{{ totalCompleted }}</td>
            <td>{{ totalMandatory }}</td>
            <td>{{ totalMainCompleted }}</td>
            <td>{{ totalEnabled }}</td>
            <td>{{ totalOutstanding }}</td>
            <td :class="{
                'highlight-green': parseFloat(totalPercentage) >= 50,
                'highlight-orange': parseFloat(totalPercentage) < 50
              }">{{ totalPercentage }}</td>
            <td :class="{
                'highlight-green': parseFloat(totalManobPercentage) >= 50,
                'highlight-orange': parseFloat(totalManobPercentage) < 50
              }">{{ totalManobPercentage }}</td>
          </tr>
          <tr class="mandatory-tasks-row">
            <td colspan="9" class="grey-column"><strong>The percentage of mandatory tasks completed must be > 50% (This also applies for each individual chapter)</strong></td>
          </tr>
        </table>
        <table class="data-table" v-if="currentTab === 'scope'">
          <thead>
            <th style="width: 400px;">Scope</th>
            <th>Total Tasks</th>
            <th>Total Completed</th>
            <th>Mandatory</th>
            <th>Mandatory Completed</th>
            <th>Enabled</th>
            <th>Outstanding</th>
            <th>% Total Complete</th>
            <th>% Mandatory Complete</th>
          </thead>
          <tbody>

            <tr v-for="(tasks, scope) in groupedTasksByScope" :key="scope">
              <td class="first_column"><strong>{{ scope }}</strong></td>
              <td>{{ tasks.all.length }}</td>
              <td>{{ tasks.completed.length }}</td>
              <td>{{ tasks.manob.length }}</td>
              <td>{{ tasks.main_completed.length }}</td>
              <td>{{ tasks.enabled.length }}</td>
              <td>{{ tasks.enabled.length - tasks.completed.length }}</td>
              <td :class="{
                'highlight-green': tasks.all.length && (tasks.main_completed.length / tasks.all.length * 100) >= (scopePercentages[scope] || 0),
                'highlight-orange': tasks.all.length && (tasks.main_completed.length / tasks.all.length * 100) < (scopePercentages[scope] || 0)
              }">
                {{ tasks.all.length ? (tasks.main_completed.length / tasks.all.length * 100).toFixed(1) + '%' : '0.0%' }}
              </td>
              <td :class="{
                'highlight-green': tasks.manob.length && (tasks.main_completed.length / tasks.manob.length * 100) >= (scopePercentages[scope] || 0),
                'highlight-orange': tasks.manob.length && (tasks.main_completed.length / tasks.manob.length * 100) < (scopePercentages[scope] || 0)
              }">
                {{ tasks.manob.length ? (tasks.main_completed.length / tasks.manob.length * 100).toFixed(1) + '%' : '0.0%' }}
              </td>
            </tr>
            <tr class="total-row">
              <td class="first_column"><strong>Overall</strong></td>
              <td>{{ totalScopeTasks }}</td>
              <td>{{ totalScopeCompleted }}</td>
              <td>{{ totalScopeMandatory }}</td>
              <td>{{ totalScopeMainCompleted }}</td>
              <td>{{ totalScopeEnabled }}</td>
              <td>{{ totalScopeOutstanding }}</td>
              <td :class="{
                'highlight-green': parseFloat(totalScopePercentage) >= 50,
                'highlight-orange': parseFloat(totalScopePercentage) < 50
              }">{{ totalScopePercentage }}</td>
              <td :class="{
                'highlight-green': parseFloat(totalManobScopePercentage) >= 50,
                'highlight-orange': parseFloat(totalManobScopePercentage) < 50
              }">{{ totalManobScopePercentage }}</td>
            </tr>
            <tr>
              <td colspan="9" class="grey-column"><strong>The percentage of mandatory tasks completed must be > 50% (This also applies for each individual task type)</strong></td>
            </tr>
          </tbody>
        </table>
        <!-- <table class="data-table" v-if="currentTab === 'chapterScope'">
          <thead>
            <tr>
              <th style="width: 700px;">Chapter / Scope</th>
              <th v-for="scope in availableScopes" :key="scope">
                {{ scope }} ({{ getScopePercentage.find(item => item.scope === scope)?.percentage || '0' }}%)
              </th>
              <th>% Completed</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="chapter in filteredChapters" :key="chapter.chapt_no">
              <td><strong>{{ chapter.chapt_no }}: {{ chapter.name }}</strong></td>
              <td v-for="scope in availableScopes" :key="scope">
                {{ chapterScopeTaskCounts[chapter.chapt_no]?.[scope]?.total || '' }}
                <span style="border: none;" v-if="chapterScopeTaskCounts[chapter.chapt_no]?.[scope]?.total">
                  ({{ chapterScopeTaskCounts[chapter.chapt_no]?.[scope]?.completed }})
                </span>
              </td>
              <td :class="{
                'highlight-green': chapter.tasks.all.length && (chapter.tasks.completed.length / chapter.tasks.all.length * 100) >= 50,
                'highlight-orange': chapter.tasks.all.length && (chapter.tasks.completed.length / chapter.tasks.all.length * 100) < 50
              }">
                {{ chapter.tasks.all.length ? (chapter.tasks.completed.length / chapter.tasks.all.length *
                  100).toFixed(1) + '%' : '0.0%' }}
              </td>
            </tr>
            <tr class="total-row">
              <td><strong>Total</strong></td>
              <td v-for="scope in availableScopes" :key="scope">
                {{ getScopeTotal(scope).total }}
              </td>
              <td>
                {{ totalScopeTasks }}
              </td>
            </tr>
            <tr class="total-row">
              <td><strong>Total Required to Pass (% Required)</strong></td>
              <td v-for="scope in availableScopes" :key="scope">
                {{ totalRequiredToPass(scope) }} ({{ getScopePercentage.find(item => item.scope === scope)?.percentage
                  || '0' }}%)
              </td>
              <td>{{ overallTotalRequiredToPass }}</td>
            </tr>
            <tr class="total-row">
              <td><strong>Total Completed (% Complete)</strong></td>
              <td v-for="(scope, index) in availableScopes" :key="scope" :class="{
                'highlight-green': getScopeTotal(scope).completed >= totalRequiredToPass(scope),
                'highlight-orange': getScopeTotal(scope).completed < totalRequiredToPass(scope)
              }">
                {{ getScopeTotal(scope).completed }} ({{ percentageCompletePerScope[index] }})
              </td>
              <td :class="{
                'highlight-green': parseFloat(totalCompletePercentage) >= 50,
                'highlight-orange': parseFloat(totalCompletePercentage) < 50
              }">
                {{ totalScopeCompleted }} ({{ totalCompletePercentage }})
              </td>
            </tr>
          </tbody>
        </table> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    mergedTasks: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      showTaskLongFormModal: true,
      chapters: [],
      currentTab: 'chapters',
      traineeUID: '',
      scopePercentages: {
        INS: 50,
        FOT: 50,
        SGH: 50,
        'R/I': 50,
        MEL: 50,
        TS: 50, 
        LOC: 50
      }
    };
  },
  computed: {
    filteredChapters() {
      return this.chapters.filter(chapter => chapter.tasks.all.length > 0);
    },
    totalTasks() {
      return this.filteredChapters.reduce((acc, chapter) => acc + chapter.tasks.all.length, 0);
    },
    totalMandatory() {
      return this.filteredChapters.reduce((acc, chapter) => acc + chapter.tasks.manob.length, 0);
    },
    totalCompleted() {
      return this.filteredChapters.reduce((acc, chapter) => acc + chapter.tasks.completed.length, 0);
    },
    totalMainCompleted() {
      return this.filteredChapters.reduce((acc, chapter) => acc + chapter.tasks.main_completed.length, 0);
    },
    totalEnabled() {
      return this.filteredChapters.reduce((acc, chapter) => acc + chapter.tasks.enabled.length, 0);
    },
    totalOutstanding() {
      return this.filteredChapters.reduce((acc, chapter) => acc + (chapter.tasks.enabled.length - chapter.tasks.completed.length), 0);
    },
    totalCompletePercentage() {
      const totalCompleted = this.filteredChapters.reduce((acc, chapter) => acc + chapter.tasks.completed.length, 0);
      const total = this.filteredChapters.reduce((acc, chapter) => acc + chapter.tasks.all.length, 0);
      return ((totalCompleted / total) * 100).toFixed(1) + '%';
    },
    totalManobPercentage() {
      const totalMainCompleted = this.filteredChapters.reduce((acc, chapter) => acc + chapter.tasks.main_completed.length, 0);
      const totalMandatory = this.filteredChapters.reduce((acc, chapter) => acc + chapter.tasks.manob.length, 0);
      return ((totalMainCompleted / totalMandatory) * 100).toFixed(1) + '%';
    },
    totalPercentage() {
      const totalCompleted = this.filteredChapters.reduce((acc, chapter) => acc + chapter.tasks.completed.length, 0);
      const total = this.filteredChapters.reduce((acc, chapter) => acc + chapter.tasks.all.length, 0);
      return ((totalCompleted / total) * 100).toFixed(1) + '%';
    },
    groupedTasksByScope() {
      // Group mergedTasks based on scope
      const groupedScopeTasks = {};
      this.mergedTasks.forEach(task => {
        if (!groupedScopeTasks[task.scope]) {
          groupedScopeTasks[task.scope] = {
            all: [],
            manob: [],
            completed: [],
            enabled: [],
            main_completed: [],
          };
        }
        groupedScopeTasks[task.scope].all.push(task);
        if (task.manob === 'MT' && task.is_exam === 0) { // Checking if the task is manob
          groupedScopeTasks[task.scope].manob.push(task);
          if (task.completed) {
            groupedScopeTasks[task.scope].main_completed.push(task);
          }
        }

        if (task.enabled) {
          groupedScopeTasks[task.scope].enabled.push(task);
        }
        if (task.completed) {
          groupedScopeTasks[task.scope].completed.push(task);
        }
      });

      return groupedScopeTasks;
    },
    totalScopeTasks() {
      return Object.values(this.groupedTasksByScope).reduce((acc, tasks) => acc + tasks.all.length, 0);
    },
    totalScopeMandatory() {
      return Object.values(this.groupedTasksByScope).reduce((acc, tasks) => acc + tasks.manob.length, 0);
    },
    totalScopeCompleted() {
      return Object.values(this.groupedTasksByScope).reduce((acc, tasks) => acc + tasks.completed.length, 0);
    },
    totalScopeMainCompleted() {
      return Object.values(this.groupedTasksByScope).reduce((acc, tasks) => acc + tasks.main_completed.length, 0);
    },
    totalScopeEnabled() {
      return Object.values(this.groupedTasksByScope).reduce((acc, tasks) => acc + tasks.enabled.length, 0);
    },
    totalScopeOutstanding() {
      return Object.values(this.groupedTasksByScope).reduce((acc, tasks) => {
        const totalCompleted = tasks.completed.length;
        const totalEnabled = tasks.enabled.length;
        return acc + (totalEnabled - totalCompleted);
      }, 0);
    },
    totalManobScopePercentage() {
      const totalMainCompleted = Object.values(this.groupedTasksByScope).reduce((acc, tasks) => { return acc + tasks.main_completed.length; }, 0);
      const totalMandatory = Object.values(this.groupedTasksByScope).reduce((acc, tasks) => { return acc + tasks.manob.length; }, 0);
      return ((totalMainCompleted / totalMandatory) * 100 || 0).toFixed(1) + '%';
    },
    totalScopePercentage() {
      const totalCompleted = Object.values(this.groupedTasksByScope).reduce((acc, tasks) => { return acc + tasks.completed.length; }, 0);
      const total = Object.values(this.groupedTasksByScope).reduce((acc, tasks) => { return acc + tasks.all.length; }, 0);
      return ((totalCompleted / total) * 100 || 0).toFixed(1) + '%';
    },
    chapterScopeTaskCounts() {
      const taskCounts = {};

      // Initialize task counts per chapter-scope combination
      this.mergedTasks.forEach(task => {
        const { chapt_no, scope } = task;

        // Initialize chapter if it doesn't exist
        if (!taskCounts[chapt_no]) {
          taskCounts[chapt_no] = {};
        }

        // Initialize scope if it doesn't exist within the chapter
        if (!taskCounts[chapt_no][scope]) {
          taskCounts[chapt_no][scope] = {
            total: 0,
            completed: 0,
            mandatory: 0, // Mandatory task count (manob === 'MT')
            main_completed: 0 // Mandatory completed task count
          };
        }

        // Count total tasks per scope
        taskCounts[chapt_no][scope].total++;

        // Count completed tasks per scope
        if (task.completed) {
          taskCounts[chapt_no][scope].completed++;
        }

        // Count mandatory tasks where 'manob' is 'MT'
        if (task.manob === 'MT') {
          taskCounts[chapt_no][scope].mandatory++;

          // Count mandatory tasks that are completed
          if (task.completed) {
            taskCounts[chapt_no][scope].main_completed++;
          }
        }
      });

      return taskCounts;
    },
    // Collect unique scopes for column headers
    availableScopes() {
      const scopes = new Set();
      this.mergedTasks.forEach(task => {
        if (task.scope) {
          scopes.add(task.scope);
        }
      });
      return Array.from(scopes);
    },
    // New computed property to get the percentage for each scope
    getScopePercentage() {
      return this.availableScopes.map(scope => ({
        scope,
        percentage: this.scopePercentages[scope] || 0
      }));
    },
    getScopeTotal() {
      return (scope) => {
        let total = 0;
        let completed = 0;
        let mandatory = 0;
        let mandatory_complete = 0;

        // Loop through chapters and sum up tasks per scope
        this.filteredChapters.forEach(chapter => {
          if (this.chapterScopeTaskCounts[chapter.chapt_no] && this.chapterScopeTaskCounts[chapter.chapt_no][scope]) {
            total += this.chapterScopeTaskCounts[chapter.chapt_no][scope].total;
            completed += this.chapterScopeTaskCounts[chapter.chapt_no][scope].completed;
            mandatory += this.chapterScopeTaskCounts[chapter.chapt_no][scope].mandatory;
            mandatory_complete += this.chapterScopeTaskCounts[chapter.chapt_no][scope].main_completed;
          }
        });

        return { total, completed, mandatory, mandatory_complete };
      };
    },
    totalRequiredToPass() {
      return (scope) => {
        // Get the total tasks for the scope using the `getScopeTotal` method
        const { total } = this.getScopeTotal(scope);
        // Calculate the required total based on the scope percentage
        const percentage = this.scopePercentages[scope] || 0;
        return Math.ceil(total * (percentage / 100));
      };
    },
    overallTotalRequiredToPass() {
      return this.availableScopes.reduce((accumulator, scope) => {
        // Add up the total required to pass for each scope
        return accumulator + this.totalRequiredToPass(scope);
      }, 0);
    },
    percentageCompletePerScope() {
      return this.availableScopes.map(scope => {
        const { total, completed } = this.getScopeTotal(scope);
        // Calculate the completion percentage for each scope
        return total ? ((completed / total) * 100).toFixed(1) + '%' : '0.0%';
      });
    },
  },
  watch: {
    mergedTasks(newVal) {
      console.log('New value of mergedTasks:', newVal);
    }
  },
  methods: {
    async fetchTrainee() {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      const traineeId = localStorage.getItem('trainee_id');

      console.log('id');
      console.log(traineeId);
      try {
        // Assuming you have an API endpoint to fetch task data
        const response = await axios.get(`/api/users/${traineeId}`, { headers });
        const traineeData = response.data;

        console.log('data');
        console.log(traineeData);

        this.traineeUID = traineeData.license_no;

      } catch (error) {
        console.error('Error fetching trainee data:', error);
        // Handle errors as needed
      }
    },
    close() {
      this.$emit('close');
    },
    async fetchChapters() {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      try {
        // Assuming you have an API endpoint to fetch task data
        const response = await axios.get(`/api/chapters`, { headers });
        const chaptersData = response.data;

        // Group mergedTasks by chapter based on chapt_no
        const groupedTasks = {};
        this.mergedTasks.forEach(task => {
          if (!groupedTasks[task.chapt_no]) {
            groupedTasks[task.chapt_no] = {
              all: [],
              manob: [],
              completed: [],
              enabled: [],
              main_completed: [],
            };
          }
          groupedTasks[task.chapt_no].all.push(task);
          if (task.manob === 'MT' && task.is_exam === 0) { // Checking if the task is manob
            groupedTasks[task.chapt_no].manob.push(task);
            if (task.completed) {
              groupedTasks[task.chapt_no].main_completed.push(task);
            }
          }

          if (task.enabled) {
            groupedTasks[task.chapt_no].enabled.push(task);
          }
          if (task.completed) {
            groupedTasks[task.chapt_no].completed.push(task);
          }
        });

        // Merge chaptersData with groupedTasks
        this.chapters = chaptersData.map(chapter => {
          const tasks = groupedTasks[chapter.chapt_no] || { all: [], manob: [], completed: [], enabled: [], main_completed: [], };
          return {
            ...chapter,
            tasks: {
              all: tasks.all,
              manob: tasks.manob,
              completed: tasks.completed,
              enabled: tasks.enabled,
              main_completed: tasks.main_completed,
            }
          };
        });

        console.log(this.chapters);
      } catch (error) {
        console.error('Error fetching task data:', error);
        // Handle errors as needed
      }
    },
  },
  async mounted() {
    this.fetchChapters();
    this.fetchTrainee();
    console.log('Value of mergedTasks:', this.mergedTasks);
  },
}


</script>

<style scoped>
.Task-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(146, 146, 146, 0.5);
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.Task-modal-content {
  margin-top: 40px;
  background-color: rgb(239, 244, 255);
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  width: min(95%, 1600px);
  max-height: 89vh;
  /* You can adjust the maximum height as needed */
  overflow-y: auto;
  border: 2px solid rgb(83, 83, 83);
  margin-top: 60px;
}

.Task-modal-title {
  margin-bottom: 10px;
  float: left;
  margin-left: 20px;
  color: black;
}

.data-table {
  border-collapse: collapse;
  background-color: rgb(253, 253, 255);
  border: 2px solid rgb(0, 0, 0);
  box-shadow: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}

.highlight-green {
  background-color: #b7f8c6;
  color: #155724;
}

.highlight-orange {
  background-color: #ffedb2;
  /* Light orange background */
  color: #6e5405;
}

.data-table td,
.data-table th {
  border: 1px solid grey;
  padding: 10px;
  width: 250px;
  font-size: 16px;
}

.data-table th,
td.grey-column {
  background-color: rgb(233, 233, 233)
}

.total-row,
.mandatory-tasks-row {
  border-top: 2px solid black;
  /* Adjust the border color and size as needed */
}
</style>