<template>
    <div></div>
</template>

<script>
import axios from "axios";
//import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { font as regularFont } from './Roboto-Regular-normal.js'; // Import the font from the JS file
import { font as blackFont } from './Roboto-Bold-normal.js'; // Import the font from the JS file

const callAddFont = function () {
    this.addFileToVFS('Roboto-Regular-normal.js', regularFont);
    this.addFont('Roboto-Regular-normal.js', 'Roboto-Regular', 'normal');
};

const callAddFont2 = function () {
    this.addFileToVFS('Roboto-Bold-normal.js', blackFont);
    this.addFont('Roboto-Bold-normal.js', 'Roboto-Bold', 'normal');
};

export default {
    props: {
        filteredTasks: {
            type: Array, // Assuming filteredTasks is an array
            required: true // If it's mandatory for this component to have filteredTasks
        },
        logbookName: {
            type: String,
            required: true
        },
        logbookId: {
            type: [String, Number],
            required: true
        },
        selectedTrainee: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            traineeName: '',
            traineeCategory: '',
            traineeDOB: '',
            traineeLicenseNo: '',
            CMCS_fault: '',
            extra_info: '',
            flight_deck: '',
            logbook_entry: '',
            observation: '',
            questions: '',
            formattedQuestions: '', // Declare formattedQuestions
            formattedQuestionsText: '',
            filteredTask: { questions: '' },
            criteria: '',
            result: '',
            trainee_signature: '',
            assessor_signature: '',
            evaluation_date: '',
            instructorSignatures: {},
            traineeSignatures: {},
            signatures: [],
            taskAssessments: [],
            project: [],
            tasks: [],
            mergedTasks: [],
        };
    },
    methods: {
        loadCustomFonts() {
            return new Promise((resolve) => {
                jsPDF.API.events.push(['addFonts', callAddFont]);
                jsPDF.API.events.push(['addFonts', callAddFont2]);
                resolve(); // Resolve immediately after adding the font
            });
        },
        getInstructorData() {
            const token = localStorage.getItem('token');
            const headers = { Authorization: `Bearer ${token}` };
            console.log(this.logbookId);

            axios.get(`/api/logbooks/${this.logbookId}/users`, { headers })
                .then(response => {
                    const instructorData = response.data;
                    console.log(instructorData);

                    this.instructors = instructorData;
                    console.log('instructors: ', this.instructors); // Log the trainee data to see if it's fetched correctly

                    this.getCourseData();
                })
                .catch(error => {
                    console.error('Error fetching trainee data:', error);
                    // Handle error accordingly
                });
        },
        getCourseData() {
            const token = localStorage.getItem('token');
            const headers = { Authorization: `Bearer ${token}` };
            console.log(this.logbookId);
            const projectId = parseInt(localStorage.getItem('project_id'));

            axios.get(`/api/projects/${projectId}`, { headers })
                .then(response => {
                    const projectData = response.data;
                    console.log(projectData);

                    this.project = response.data.project;
                    console.log('project: ', this.project); // Log the trainee data to see if it's fetched correctly
                    
                    this.getEvaluationData();
                })
                .catch(error => {
                    console.error('Error fetching project data:', error);
                    // Handle error accordingly
                });
        },
getEvaluationData() {
            const token = localStorage.getItem('token');
            const headers = { Authorization: `Bearer ${token}` };

            axios.get(`/api/evaluations/logbooks/${this.logbookId}`, { headers })
                .then(response => {
                    const logbookEvaluation = response.data;
                    console.log("logbook evaluation", logbookEvaluation)
                    this.criteria = logbookEvaluation.criteria;
                    this.result = logbookEvaluation.result;
                    this.trainee_signature = logbookEvaluation.trainee_signature;
                    this.assessor_signature = logbookEvaluation.assessor_signature;
                    this.evaluation_date = logbookEvaluation.date;

                                // Load the custom font
            this.loadCustomFonts().then(() => {
                // Generate the PDF after the font is loaded
                this.generatePDF();
            });
                })
                .catch(error => {
                    console.error('Error fetching Evaluation data:', error);
                    alert('An evaluation must be completed before generating a PDF.');
                });
        },
        getImageBase64(imagePath) {
            return new Promise((resolve, reject) => {
                const image = new Image();
                image.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = image.width;
                    canvas.height = image.height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(image, 0, 0);
                    resolve(canvas.toDataURL('image/png'));
                };
                image.onerror = reject;
                image.src = imagePath; // Use the path directly
            });
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        async generatePDF() {
            await this.fetchSignaturesForTasks();

            this.instructorName = localStorage.getItem('username');
            this.traineeId = localStorage.getItem('trainee_id');
            this.instructorId = localStorage.getItem('user_id');
            this.productName = localStorage.getItem('product_name');

            const doc = new jsPDF({
                orientation: 'landscape'
            });


            const pageWidth = doc.internal.pageSize.width;
            const pageHeight = doc.internal.pageSize.height;

            // Title and Horizontal Line
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(14);
            doc.text(this.logbookName, 15, 12);
            doc.line(10, 20, pageWidth - 10, 20);

            doc.setFont('helvetica', 'bold');
            doc.setFontSize(12); // Adjust the font size if needed
            doc.text(`Trainee Information:`, 10, 30);
            doc.setFont('helvetica', 'normal'); // Reset the font weight to normal

            // Personal Information Section
            const currentDate = new Date();
            const day = currentDate.getDate();
            const month = currentDate.getMonth() + 1; // Months are 0-based, so add 1
            const year = currentDate.getFullYear();
            const formattedDate = `${String(day).padStart(2, '0')}/${String(month).padStart(2, '0')}/${year}`;

            // Left Table: Basic Information
            doc.autoTable({
                startY: 35,
                startX: 10, // Left side (adjust this for the left column)
                body: [
                    ['Name', `${this.selectedTrainee.first_name} ${this.selectedTrainee.last_name}`],
                    ['UIN', this.selectedTrainee.license_no],
                    ['Date', formattedDate]
                ],
                tableWidth: 'wrap', // Automatically wrap to the content width
                padding: 2,
                styles: {
                    font: 'Roboto-Regular',
                    lineWidth: 0.05, // Table border width
                    lineColor: [169, 169, 169], // Border color
                    fontSize: 10, // Font size
                },
                headStyles: {
                    fillColor: [41, 128, 185],
                    textColor: [255, 255, 255],
                    fontStyle: 'bold'
                },
                columnStyles: {
                    0: { cellWidth: 50 }, // Field column
                    1: { cellWidth: 85 }  // Information column
                },
                didParseCell: function (data) {
                    // Check if the cell is in the first column (index 0), which contains the field names
                    if (data.column.index === 0) {
                        // Set the font style for the field names to bold
                        data.cell.styles.font = 'Roboto-Bold';
                    }
                }
            });

            // Right Table: Additional Information
            doc.autoTable({
                startY: 35, // Ensure both tables are on the same Y-axis
                startX: 110, // Right side (adjust this for the right column)
                body: [
                    ['Category', this.selectedTrainee.category],
                    ['Date of Birth', this.selectedTrainee.date_of_birth],
                    ['Place of Birth', this.selectedTrainee.place_of_birth]
                ],
                margin: { left: 149 },
                padding: 2,
                tableWidth: 'wrap', // Automatically wrap to the content width
                styles: {
                    font: 'Roboto-Regular',
                    lineWidth: 0.05, // Table border width
                    lineColor: [169, 169, 169], // Border color
                    fontSize: 10, // Font size
                },
                headStyles: {
                    fillColor: [41, 128, 185],
                    textColor: [255, 255, 255],
                    fontStyle: 'bold'
                },
                columnStyles: {
                    0: { cellWidth: 50 }, // Field column
                    1: { cellWidth: 85 }  // Information column
                },
                didParseCell: function (data) {
                    // Check if the cell is in the first column (index 0), which contains the field names
                    if (data.column.index === 0) {
                        // Set the font style for the field names to bold
                        data.cell.styles.font = 'Roboto-Bold';
                    }
                }
            });


            // Instructors Table
            const instructorStartY = doc.autoTable.previous.finalY + 20; // Start below previous table
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(12);
            doc.text('Instructor(s) Information:', 10, instructorStartY);

            this.instructors.forEach((instructor, index) => {
                const instructorBody = [
                    ['Name', `${instructor.first_name} ${instructor.last_name}`],
                    ['License No', instructor.license_no],
                ];

                doc.autoTable({
                    startY: instructorStartY + (index * 25) + 5, // Adjust Y position for each instructor table
                    startX: 10,
                    body: instructorBody,
                    tableWidth: 'wrap',
                    padding: 2,
                    styles: {
                        font: 'Roboto-Regular',
                        lineWidth: 0.05,
                        lineColor: [169, 169, 169],
                        fontSize: 10,
                    },
                    headStyles: {
                        fillColor: [41, 128, 185],
                        textColor: [255, 255, 255],
                        fontStyle: 'bold'
                    },
                    columnStyles: {
                        0: { cellWidth: 50 },
                        1: { cellWidth: 85 }
                    },
                    didParseCell: function (data) {
                        if (data.column.index === 0) {
                            data.cell.styles.font = 'Roboto-Bold';
                        }
                    }
                });
                // Right Table: Additional Information
                doc.autoTable({
                    startY: instructorStartY + (index * 25) + 5, // Ensure both tables are on the same Y-axis
                    startX: 110, // Right side (adjust this for the right column)
                    body: [
                        ['Date of Birth', instructor.date_of_birth],
                        ['Place of Birth', instructor.place_of_birth]
                    ],
                    margin: { left: 149 },
                    padding: 2,
                    tableWidth: 'wrap', // Automatically wrap to the content width
                    styles: {
                        font: 'Roboto-Regular',
                        lineWidth: 0.05, // Table border width
                        lineColor: [169, 169, 169], // Border color
                        fontSize: 10, // Font size
                    },
                    headStyles: {
                        fillColor: [41, 128, 185],
                        textColor: [255, 255, 255],
                        fontStyle: 'bold'
                    },
                    columnStyles: {
                        0: { cellWidth: 50 }, // Field column
                        1: { cellWidth: 85 }  // Information column
                    },
                    didParseCell: function (data) {
                        // Check if the cell is in the first column (index 0), which contains the field names
                        if (data.column.index === 0) {
                            // Set the font style for the field names to bold
                            data.cell.styles.font = 'Roboto-Bold';
                        }
                    }
                });
            });

            const courseStartY = doc.autoTable.previous.finalY + 20;

            doc.setFont('helvetica', 'bold');
            doc.setFontSize(12);
            doc.text('Course Information:', 10, courseStartY);

            // Left Table: Basic Information
            doc.autoTable({
                startY: courseStartY + 5,
                startX: 10, // Left side (adjust this for the left column)
                body: [
                    ['Course ID', this.project.prod_id],
                    ['Name', this.project.product_name],
                    //['Location', this.project.location],
                ],
                tableWidth: 'wrap', // Automatically wrap to the content width
                padding: 2,
                styles: {
                    font: 'Roboto-Regular',
                    lineWidth: 0.05, // Table border width
                    lineColor: [169, 169, 169], // Border color
                    fontSize: 10, // Font size
                },
                headStyles: {
                    fillColor: [41, 128, 185],
                    textColor: [255, 255, 255],
                    fontStyle: 'bold'
                },
                columnStyles: {
                    0: { cellWidth: 40 }, // Field column
                    1: { cellWidth: 130 }  // Information column
                },
                didParseCell: function (data) {
                    // Check if the cell is in the first column (index 0), which contains the field names
                    if (data.column.index === 0) {
                        // Set the font style for the field names to bold
                        data.cell.styles.font = 'Roboto-Bold';
                    }
                }
            });

            // Right Table: Additional Information
            doc.autoTable({
                startY: courseStartY + 5, // Ensure both tables are on the same Y-axis
                startX: 160, // Right side (adjust this for the right column)
                body: [
                    ['Start Date', this.project.start_date],
                    ['End Date', this.project.end_date]
                ],
                margin: { left: 184 },
                padding: 2,
                tableWidth: 'wrap', // Automatically wrap to the content width
                styles: {
                    font: 'Roboto-Regular',
                    lineWidth: 0.05, // Table border width
                    lineColor: [169, 169, 169], // Border color
                    fontSize: 10, // Font size
                },
                headStyles: {
                    fillColor: [41, 128, 185],
                    textColor: [255, 255, 255],
                    fontStyle: 'bold'
                },
                columnStyles: {
                    0: { cellWidth: 50 }, // Field column
                    1: { cellWidth: 50 }  // Information column
                },
                didParseCell: function (data) {
                    // Check if the cell is in the first column (index 0), which contains the field names
                    if (data.column.index === 0) {
                        // Set the font style for the field names to bold
                        data.cell.styles.font = 'Roboto-Bold';
                    }
                }
            });

            doc.addPage();

            doc.setFont('helvetica', 'bold');
            doc.setFontSize(12); // Adjust the font size if needed
            doc.text(`Tasks:`, 10, 12);
            doc.setFont('helvetica', 'normal'); // Reset the font weight to normal

            // Remove the image-related code from the existing for loop
            const columns = ['Chapt No', 'Task No', 'Subject', 'Manual Reference', 'Task Code', 'Manob', 'Skill', 'Category', 'Completed',  { title: "Trainee Signature", dataKey: "t_signature" }, 'Instructor SO', { title: "Inst Signature", dataKey: "i_signature" }];
            const tableData = [];

            for (const task of this.filteredTasks) {
                if (task.completed) {

                    const taskData = [
                        task.chapt_no,
                        task.task_no,
                        task.description,
                        task.reference,
                        task.scope,
                        task.manob,
                        task.skill,
                        task.category,
                        task.completed,
                        task.t_signature_id || '',
                        task.ins_so,
                        task.ins_signature_id || '', // Instructor signature ID
                    ];
                    // Add the row to the table data
                    tableData.push(taskData);

                }
            }

            // Add the table to the pdf document
            doc.autoTable({
                startY: 18,
                columns: columns,
                body: tableData,
                margin: { horizontal: 10 },
                styles: {
                        fontSize: 9, // Font size
                    },
                    columnStyles: {
        // Add other column widths as needed
        6: { cellWidth: 24 }, // Custom width for 'Inst Signature' column
        8: { cellWidth: 24 }, // Custom width for 'Inst Signature' column
    },
    didDrawCell: (data) => {
                    // Instructor Signature
                    if (data.column.dataKey === "i_signature" && data.row.raw[11]) {
                        const signatureId = data.row.raw[11]; // Adjust index for instructor signature ID
                        const selectedSignature = this.signatures.find(signature => signature.id === signatureId);
                        const selectedImageDataInst = selectedSignature ? selectedSignature.user_image : null;

                        if (selectedImageDataInst) {
                            // Set image placement
                            const imageX = data.cell.x + 2;
                            const imageY = data.cell.y + 2;
                            const imageWidth = 18;
                            const imageHeight = data.cell.height - 4;

                            // Add the image to the cell
                            doc.addImage(selectedImageDataInst, 'PNG', imageX, imageY, imageWidth, imageHeight);

                            // Set the border color and thickness
                            doc.setLineWidth(0.25); // Adjust thickness as needed
                            doc.setDrawColor(0, 0, 0); // Set the color (black in this case)

                            // Draw the border
                            doc.rect(imageX, imageY, imageWidth, imageHeight);
                        }

                    }
                    if (data.column.dataKey === "t_signature" && data.row.raw[9]) {
                        const signatureId = data.row.raw[9]; // Adjust index for instructor signature ID
                        const selectedSignature = this.signatures.find(signature => signature.id === signatureId);
                        const selectedImageDataInst = selectedSignature ? selectedSignature.user_image : null;

                        if (selectedImageDataInst) {
                            // Set image placement
                            const imageX = data.cell.x + 2;
                            const imageY = data.cell.y + 2;
                            const imageWidth = 18;
                            const imageHeight = data.cell.height - 4;

                            // Add the image to the cell
                            doc.addImage(selectedImageDataInst, 'PNG', imageX, imageY, imageWidth, imageHeight);

                            // Set the border color and thickness
                            doc.setLineWidth(0.25); // Adjust thickness as needed
                            doc.setDrawColor(0, 0, 0); // Set the color (black in this case)

                            // Draw the border
                            doc.rect(imageX, imageY, imageWidth, imageHeight);
                        }

                    }
                }
            });

            doc.addPage();


            doc.setFont('helvetica', 'bold');
            doc.setFontSize(12); // Adjust the font size if needed
            doc.text(`Continuous Evaluation:`, 10, 10);
            doc.setFont('helvetica', 'normal'); // Reset the font weight to normal
            const evaluationColumns = ['Competence Criteria', 'Performance Result'];
            const evaluationTableData = [
                // Rows for "Flight Deck Effect"
                [
                    { content: `Consults the correct documentation:` },
                    { content: this.capitalizeFirstLetter(this.criteria['1']) }, // Replace 'Successful' with criteria value
                ],
                [
                    { content: `Scans the environment before starting and during the task to ensure safety:` },
                    { content: this.capitalizeFirstLetter(this.criteria['2']) }, // Replace 'Successful' with criteria value
                ],
                [
                    { content: `Reads/interprets the safety warning correctly:` },
                    { content: this.capitalizeFirstLetter(this.criteria['3']) }, // Replace 'Successful' with criteria value
                ],
                [
                    { content: `Informs/communicates with appropriate people correctly:` },
                    { content: this.capitalizeFirstLetter(this.criteria['4']) }, // Replace 'Successful' with criteria value
                ],
                [
                    { content: `Interprets the reports, indications and documentation correctly:` },
                    { content: this.capitalizeFirstLetter(this.criteria['5']) }, // Replace 'Successful' with criteria value
                ],
                [
                    { content: `Follows the procedure steps:` },
                    { content: this.capitalizeFirstLetter(this.criteria['6']) }, // Replace 'Successful' with criteria value
                ],
                [
                    { content: `Takes into account interactions between systems:` },
                    { content: this.capitalizeFirstLetter(this.criteria['7']) }, // Replace 'Successful' with criteria value
                ],
                [
                    { content: `Restores the aircraft back to initial condition (or appropriate condition depending on the circumstances):` },
                    { content: this.capitalizeFirstLetter(this.criteria['8']) }, // Replace 'Successful' with criteria value
                ],
                [
                    { content: `RESULT OF THE ASSESSMENT`, styles: { fillColor: [211, 211, 211], fontStyle: 'bold', lineColor: [0, 0, 0] } }, // Adjust the width automatically
                    { content: `${this.result.toUpperCase()}`, styles: { fillColor: [211, 211, 211], fontStyle: 'bold', lineColor: [0, 0, 0] } }, // Adjust the width automatically
                ],
            ];
            const evaluationColumnStyles = {
                0: { cellWidth: 'auto' }, // Adjust the width automatically
                1: { cellWidth: 90 }, // Adjust the width automatically
            };
            doc.autoTable({
                head: [evaluationColumns],
                body: evaluationTableData,
                startY: 15,
                margin: { horizontal: 10 },
                styles: {
                    lineWidth: 0.05, // Set the border line width
                    lineColor: [169, 169, 169], // Set the border color 
                    fontSize: 8, // Set the font size for the table
                },
                columnStyles: evaluationColumnStyles // Apply column styles
            });

            const mainTasksCompletedCount = this.filteredTasks.filter(task => task.manob === 'MT' && task.completed !== null).length;
            // Define the data for the tables
            const leftTableData = [
                [
                    { content: `Number of Main Tasks in this practical training element:` }, // Adjust the width automatically
                    { content: `218` }, // Adjust the width automatically
                ],
                [
                    { content: `Number of Main Tasks performed by the Trainee (109 mini to PASS):` }, // Adjust the width automatically
                    { content: mainTasksCompletedCount }, // Adjust the width automatically
                ],
            ];
            const rightTableData = [
                [
                    { content: `Trainee Signature` }, // Adjust the width automatically
                    ' trainee_sig', // Adjust the width automatically
                ],
                [
                    { content: `Assessor Signature` }, // Adjust the width automatically
                    ' assessor_sig', // Adjust the width automatically
                ],
                [
                    { content: `Date:` }, // Adjust the width automatically
                    { content: `${this.evaluation_date}` }, // Adjust the width automatically
                ],
            ];
            const leftTableColumnStyles = {
                0: { cellWidth: 120 }, // Adjust the width automatically
                1: { cellWidth: 20 }, // Adjust the width automatically
            };
            const rightTableColumnStyles = {
                0: { cellWidth: 40 }, // Adjust the width automatically
                1: { cellWidth: 40 }, // Adjust the width automatically
            };
            // Call autoTable for the left table
            doc.autoTable({
                body: leftTableData,
                startY: doc.previousAutoTable.finalY + 5, // Adjust the Y position where the table starts
                margin: { horizontal: 10 }, // Adjust the margins if needed
                styles: {
                    lineWidth: 0.05, // Set the border line width
                    lineColor: [169, 169, 169], // Set the border color
                    fontSize: 8, // Set the font size for the table
                },
                columnStyles: leftTableColumnStyles, // Apply column styles
            });

                       // Call autoTable for the right table
                       doc.autoTable({
                body: rightTableData,
                startY: doc.autoTable.previous.finalY + 5, // Adjust the Y position where the table starts
                margin: { horizontal: 10 }, // Adjust the margins if needed
                styles: {
                    lineWidth: 0.05, // Set the border line width
                    lineColor: [169, 169, 169], // Set the border color
                    fontSize: 8, // Set the font size for the table
                },
                columnStyles: rightTableColumnStyles, // Apply column styles
                didDrawCell: (data) => {
                    // Check for trainee signature column and non-empty sign-off
                    if (data.cell.raw === ' trainee_sig') {
                        const trainee_sig = this.trainee_signature;
                        const imageX = data.cell.x + 1; // Calculate X position
                        const imageY = data.cell.y + 0.5; // Calculate Y position
                        const imageWidth = 28; // The width of the image
                        const imageHeight = data.cell.height - 1; // The height of the cell - padding
                        doc.addImage(trainee_sig, 'PNG', imageX, imageY, imageWidth, imageHeight);
                    }
                    // Check for assessor signature column and non-empty sign-off
                    if (data.cell.raw === ' assessor_sig') {
                        const assessor_sig = this.assessor_signature;
                        const imageX = data.cell.x + 1; // Calculate X position
                        const imageY = data.cell.y + 0.5; // Calculate Y position
                        const imageWidth = 28; // The width of the image
                        const imageHeight = data.cell.height - 1; // The height of the cell - padding
                        doc.addImage(assessor_sig, 'PNG', imageX, imageY, imageWidth, imageHeight);
                    }
                }
            });
            doc.addPage();

            doc.setFontSize(11);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor(255, 0, 0); // Set text color to red (RGB: 255, 0, 0)
            const note_text = 'Note: Providing trainee and instructor declarations is optional. Additionally, the declarations provided are samples and can be easily changed to ones provided by the company.'
            doc.text(note_text, pageWidth / 2, 20, { align: 'center', maxWidth: pageWidth - 40 });
            doc.setTextColor(0, 0, 0); // Reset text color to black

            doc.setFontSize(16);

            doc.text(`Declarations`, pageWidth / 2, 60, 'center');

            doc.setFontSize(11);
            doc.setFont('helvetica', 'normal');

            doc.setFont('helvetica', 'bold');
            doc.text(`Trainee Declaration:`, 20, 75);
            const text = `I acknowledge that I have successfully completed the assigned tasks in accordance with the provided instructions on the dates and times indicated in the logbook. I have made a diligent effort to meet the objectives and standards set for each task and acknowledge that each task is completed through my own work alone.`;
            doc.setFont('helvetica', 'normal');
            doc.text(text, 20, 81, { maxWidth: pageWidth - 30 });

            doc.setFont('helvetica', 'bold');
            doc.text(`Instructor Declaration:`, 20, 105);
            const text_inst = `I am signing off on behalf of all instructors that have contributed to this logbook. We have thoruoghly assessed the trainee throughout their training. I ensured that each task was completed up to the required standard and in accordance with the provided instructions. If there were any discrepancies or issues, they were promptly addressed and resolved. The trainee has demonstrated competence and diligence throughout the training period.`;
            doc.setFont('helvetica', 'normal');
            doc.text(text_inst, 20, 111, { maxWidth: pageWidth - 30 });


            // const text_assess = `I hereby acknowledge that I have thoroughly assessed the tasks assigned to the trainee. After careful evaluation, I confirm that the trainee has successfully completed the assessment tasks up to the required standards and expectations outlined for each. The trainee demonstrated competence, understanding, and proficiency in the assigned responsibilities.`;
            // doc.setFont('helvetica', 'bold');
            // doc.text(`Assessor Declaration:`, 20, 180);

            // doc.setFont('helvetica', 'normal');
            // doc.text(text_assess, 20, 186, { maxWidth: 160 });


            // Add Trainee Signature
            doc.text(`Trainee Signature:`, 15, pageHeight - 50);
            doc.line(47, pageHeight - 50, pageWidth / 2, pageHeight - 50); // Horizontal line for Trainee Signature

            doc.text(`Date:`, pageWidth - 85, pageHeight - 50)
            // Add a horizontal line 
            doc.line(pageWidth - 75, pageHeight - 50, pageWidth - 15, pageHeight - 50);

            // Add Instructor Signature
            doc.text(`Instructor Signature:`, 15, pageHeight - 30); // Adjust the vertical position as needed
            doc.line(50, pageHeight - 30, pageWidth / 2, pageHeight - 30);

            doc.text(`Date:`, pageWidth - 85, pageHeight - 30)
            // Add a horizontal line 
            doc.line(pageWidth - 75, pageHeight - 30, pageWidth - 15, pageHeight - 30);

            doc.text(`Assessor Signature:`, 15, 280)
            // Add a horizontal line 
            doc.line(50, 280, 125, 280);

            doc.text(`Date:`, 150, 280)
            // Add a horizontal line 
            doc.line(160, 280, 195, 280);

            // Calculate the total number of pages after rendering the table
            const totalPages = doc.internal.getNumberOfPages();
            // Add the footer to each page
            for (let i = 1; i <= totalPages; i++) {
                // Add a horizontal line 
                doc.setPage(i);
                doc.setDrawColor(0); // Set line color to black
                doc.line(10, pageHeight - 12, pageWidth - 10, pageHeight - 12);
                doc.setFontSize(8);

                doc.text(`Author: ${this.instructorName}`, 10, pageHeight - 6);

                const currentDate = new Date();
                const formattedDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`;
                // Calculate the width of the "Printed by" text and the formatted date
                const text = `Printed by: ${this.instructorName} on ${formattedDate}`;
                const textWidth = (doc.getStringUnitWidth(text) * 8) / 3;

                // Calculate the X position to center the text
                const x = (pageWidth - textWidth) / 2;

                doc.text(text, x, pageHeight - 6);

                doc.text(`Page ${i} of ${totalPages}`, pageWidth - 27, pageHeight - 6); // Adjust the coordinates as needed
            }


            const blob = doc.output('blob');
            const url = URL.createObjectURL(blob);
            window.open(url, '_blank');

            //       // Create a link element
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', 'evaluation.pdf'); // Set the file name for download

            // // Trigger a click event on the link to start the download
            // link.click();

            // window.location.reload();
        },
        async fetchSignaturesForTasks() {

            try {
                // Fetch all signatures in a single API call
                const token = localStorage.getItem('token');
                const headers = { Authorization: `Bearer ${token}` };

                const response = await axios.get('/api/books', { headers });

                this.signatures = response.data;

                console.log(this.signatures);

            } catch (error) {
                console.error('Error fetching signatures in bulk:', error);
            }
        }

    },

    async mounted() {
        this.getInstructorData();
        console.log('filtered');
        console.log(this.filteredTasks);

        console.log('trainee', this.selectedTrainee);
    }
}
</script>



<style scoped>
h1.page-title {
    text-align: left;
    padding-top: 100px;
    font-size: 22px;
    margin-left: 3%;
    padding-bottom: 10px;
}
</style>