import { createApp } from 'vue';
import App from './App.vue';
import { quillEditor } from 'vue3-quill';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import router from './routers';
import store from './store';
import axios from 'axios';
import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
import 'v-calendar/style.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fas)

const app = createApp(App);
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
app.component('font-awesome-icon', FontAwesomeIcon);

app.use(router);
app.use(store);
app.use(setupCalendar, {});
app.component('VCalendar', Calendar);
app.component('VDatePicker', DatePicker);

app.config.globalProperties.$http = axios;
app.use(quillEditor);

// Set the inactivity timeout duration in milliseconds
const inactivityTimeout = 15 * 60 * 1000; // 15 minutes (adjust as needed)

// Initialize a timer variable
let inactivityTimer = null;

// Function to reset the inactivity timer
function resetInactivityTimer() {
  if (inactivityTimer) {
    clearTimeout(inactivityTimer);
  }
  inactivityTimer = setTimeout(logout, inactivityTimeout);
}

// Function to perform the logout action
function logout() {
  localStorage.clear();
  router.replace('/timeout');
}

// Register a global navigation guard
router.beforeEach((to, from, next) => {
  resetInactivityTimer();
  next();
});

router.beforeEach((to, from, next) => {
    if (to.path !== '/timeout') {
      resetInactivityTimer();
    }
    next();
  });

// Add a click event listener to the entire document
document.addEventListener('click', resetInactivityTimer);

// Add a keydown event listener to the entire document
document.addEventListener('keydown', resetInactivityTimer);

// Optionally add more listeners (e.g., mouse movement)
document.addEventListener('mousemove', resetInactivityTimer);
// Function to add event listeners to modals (or specific elements)
function addModalEventListeners() {
  const modals = document.querySelectorAll('.modal');
  modals.forEach((modal) => {
    modal.addEventListener('click', resetInactivityTimer);
    modal.addEventListener('keydown', resetInactivityTimer);
  });
}
// Add a MutationObserver to detect when modals are added to the DOM
const observer = new MutationObserver(() => {
  addModalEventListeners(); // Add event listeners when modals are added
});
// Observe the document body for changes (modals being added)
observer.observe(document.body, { childList: true, subtree: true });

app.mount('#app');
