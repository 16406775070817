<template>
    <div class="modal-overlay" @click="close">
      <div class="products-modal" @click.stop>
        <div class="modal-content" @click.stop>
          <h2>Select Role</h2>
          <table>
            <tr>
              <p style="margin-top: 20px;">You have multiple roles. Please select the role you want to log in as:</p>
            </tr>
            <tr>
              <button class="role-btn" @click="selectRole(primaryRole)">{{ readablePrimaryRole }}</button>
            </tr>
            <tr>
              <button class="role-btn" @click="selectRole(secondaryRole)">{{ readableSecondaryRole }}</button>
            </tr>
            <div v-if="showAlert" class="alert alert-danger error-message" role="alert">
              <div v-if="errorMessage">{{ errorMessage }}</div>
            </div>
            <div>
            <button @click="close()" class="btn btn-secondary" style="margin-top: 15px; margin-bottom: 10px; border: 1px solid #5A5A5A;">Cancel</button>
            </div>
          </table>
        </div>
      </div>
    </div>
  </template>  

<script>
export default {
  props: {
    primaryRole: {
      type: String,
      required: true,
    },
    secondaryRole: {
      type: String,
      required: true,
    },
  },
  computed: {
    readablePrimaryRole() {
      return this.getReadableRole(this.primaryRole);
    },
    readableSecondaryRole() {
      return this.getReadableRole(this.secondaryRole);
    },
  },
  methods: {
    getReadableRole(role) {
      const roleMappings = {
        admin: 'Administrator',
        examiner: 'Examiner',
        practical_manager: 'Practical Element Manager',
        session_manager: 'Classroom Session Manager',
        instructor: 'Instructor',
        trainee: 'Trainee',
        assessor: 'Assessor',
      };
      return roleMappings[role] || role; // Default to original role if not found
    },
    selectRole(role) {
      this.$emit('role-selected', role); // Emit the selected role to the parent
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.products-modal {
    background-color: #fff;
    max-width: 90%;
    min-width: 35%;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    position: relative;
    display: block;
    z-index: 1001;
}

.chapterdropdown,
.productsearch,
.chapterdropdown {
    height: 40px;
    /* adjust this value as needed */
    margin-bottom: 10px;
    width: 80%;
}


.modal-content {
    border: white;
}

.saveButton {
    background-color: #0a407a;
    color: #ffffff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    min-width: 120px;
    cursor: pointer;
    margin-bottom: auto;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    align-self: center;
}

.viewChaptersButton {
    background-color: rgb(129, 129, 129);
    color: #ffffff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    min-width: 150px;
    height: 45px;
    margin-right: 30px;
    float: right;
}

.saveButton:hover {
    background-color: rgb(1, 40, 82);
}

.viewChaptersButton:hover {
    background-color: rgb(92, 92, 92);
}

h2 {
    text-align: center;
    margin-bottom: 10px;
    margin-left: 0px;
    font-size: 1.5rem;
}

.alert-danger {
    color: #ff0019;
    background-color: #f8d7da;
    border: 1px solid #e6b0b6;
}

.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 0rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}

.role-btn {
    width: 300px;
  /* Increase the width to 200px for better visibility */
  background-color: rgb(51, 165, 165);
  color: white;
  padding: 10px 10px;
  margin: 10px 0;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
  border-radius: 3px;
  border: 1px solid rgb(29, 83, 83);
}

.role-btn:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  /* More pronounced shadow on hover */
  transform: translateY(-2px);
  /* Slight lift effect on hover */
  background-color: rgb(30, 99, 99);
}
</style>