<template>
  <div>
    <Header />
    <h1 class="page-title">Trainee Logbook Report Card</h1>

    <fieldset class="logbook-details-box">
      <legend class="cell-header">Logbook Details</legend>
      <table class="data-table">
        <tbody>
          <tr>
            <td class="fieldset-column"><strong>ID:</strong></td>
            <td>{{ logbookDetails.logbook_id }}</td>
          </tr>
          <tr>
            <td><strong>Name:</strong></td>
            <td>{{ logbookDetails.name }}</td>
          </tr>
          <tr>
            <td><strong>Practical Element:</strong></td>
            <td>{{ logbookDetails.product }}</td>
          </tr>
          <tr>
            <td><strong>Session:</strong></td>
            <td>{{ logbookDetails.project }}</td>
          </tr>
          <tr>
            <td><strong>Instructor(s):</strong></td>
            <td>
              {{ logbookDetails.instructor1 }}
              <template v-if="logbookDetails.instructor2">, {{ logbookDetails.instructor2 }}</template>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="logbookError" class="logbook-not-found-message">
        <p>{{ logbookError }}</p>
      </div>
    </fieldset>



 <!-- Trainee Details Table -->
 <fieldset class="logbook-details-box">
      <legend class="cell-header">Trainee Details</legend>
      <table class="data-table">
        <tbody>
          <tr>
            <td class="fieldset-column"><strong>ID:</strong></td>
            <td>{{ traineeDetails.trainee_id }}</td>
          </tr>
          <tr>
            <td><strong>Name:</strong></td>
            <td>{{ traineeDetails.name }}</td>
          </tr>
          <tr>
            <td><strong>Email:</strong></td>
            <td>{{ traineeDetails.email }}</td>
          </tr>
          <tr>
            <td><strong>Date of Birth:</strong></td>
            <td>{{ traineeDetails.date_of_birth }}</td>
          </tr>
          <tr>
            <td><strong>Place of Birth:</strong></td>
            <td>{{ traineeDetails.place_of_birth }}</td>
          </tr>
          <tr>
            <td><strong>License No:</strong></td>
            <td>{{ traineeDetails.license_no }}</td>
          </tr>
        </tbody>
      </table>
      <div v-if="traineeError" class="logbook-not-found-message">
        <p>{{ traineeError }}</p>
      </div>
    </fieldset>

    <fieldset class="logbook-details-box">
      <legend class="cell-header">Tasks</legend>

      <table class="data-table-trainee">
        <thead>
          <tr>
            <th class="skinny-column">
              Task ID
            </th>
            <th class="skinny-column">
              Task No
            </th>
            <th class="skinny-column">Section ID</th>
            <th class="skinny-column">TType</th>
            <th class="skinny-column">Manob</th>
            <th class="skinny-column">Level</th>
            <th class="skinny-column">Est. Time</th>
            <th class="wider-column">Description</th>
            <th >Completed</th>
            <th >Instructor SO</th>

          </tr>
        </thead>
        <tbody>
          <tr v-for="item in this.tasks" :key="item.logbook_id">
            <td>{{ item.task_id }} </td>
            <td>{{ item.task_no }}</td>
            <td>{{ item.sect_id }} </td>
            <td>{{ item.ttype }} </td>
            <td>{{ item.manob }} </td>
            <td>{{ item.level }} </td>
            <td>{{ item.est_time }} </td>
            <td>{{ item.description }} </td>
            <td>{{ item.completed }} </td>
            <td>{{ item.ins_so }} </td>
          </tr>
        </tbody>
      </table>
      <div v-if="logbookError" class="logbook-not-found-message">
      <p>{{ logbookError }}</p>
    </div>

    </fieldset>

    <button class="back-button" @click="goBack">Back</button>
  </div>
</template>



  
<script>
import Header from '../Header.vue';
import axios from 'axios';


export default {
  name: 'TraineeReportsHTML',

  components: {
    Header,
  },

  data() {
    return {
      products: [],
      projects: [],
      tasks: [],
      trainees: [],
      logbookDetails: {
      logbook_id: null,
      name: '',
      product: '',
      project: '',
      instructor1: '',
      instructor2: '',
    },
    traineeDetails: {
      trainee_id: null,
      name: '',
      email: '',
      date_of_birth: '',
      place_of_birth: '',
      license_no: '',
    },
    logbookExists: true,
    logbookError: null,
    traineeError: null,
    };
  },

  async mounted() {
  try {
    const token = localStorage.getItem('token');
    const traineeId = this.$route.params.trainee_id;
    const headers = { Authorization: `Bearer ${token}` };
    const projectId = localStorage.getItem('project_id');

    // Fetch the logbook details for the given trainee
    const logbookResult = await axios.get(`/api/trainee/${traineeId}/logbooks/${projectId}`, { headers });
    const logbook = logbookResult.data.logbook;

    console.log('logbookResult.data.logbook');
    console.log(logbookResult.data.logbook);

    // Set the logbook details in your data
    this.logbookDetails.logbook_id = logbook.logbook_id;
    this.logbookDetails.name = logbook.name;


    if (logbook.product_id && logbook.project_id) {
    // Fetch the product and project details (you may need to adjust your API routes)
    const productResult = await axios.get(`/api/products/${logbook.product_id}`, { headers });
    this.logbookDetails.product = productResult.data.name;

    const projectResult = await axios.get(`/api/projects/${logbook.project_id}`, { headers });
    this.logbookDetails.project = projectResult.data.project.name;
    }
    if (logbook.instructor_id) {
  const inst1Result = await axios.get(`/api/users/${logbook.instructor_id}`, { headers });
  this.logbookDetails.instructor1 = inst1Result.data.name;
}

if (logbook.instructor2_id) {
  const inst2Result = await axios.get(`/api/users/${logbook.instructor2_id}`, { headers });
  this.logbookDetails.instructor2 = inst2Result.data.name;
}
    // Fetch the logbook details for the given trainee
    const taskResult = await axios.get(`/api/tasks/products/${logbook.product_id}`, { headers });
    console.log('Task Result');
    console.log(taskResult.data);
    const logbookEntryResult =  await axios.get(`/api/logbookentry/${logbook.logbook_id}`, { headers });
    console.log('Logbook Entry Result');
    console.log(logbookEntryResult.data.tasks);

        // Combine the results where task_id matches
        this.tasks = taskResult.data.map(task => {
      const logbookEntry = logbookEntryResult.data.tasks.find(entry => entry.task_id === task.task_id);
      return { ...task, ...logbookEntry };
    });


    console.log(this.tasks);
} catch (error) {
      this.logbookError = 'There is no logbook assigned to this Trainee in this Session: ' + error.message;
      console.error('An error occurred while fetching data:', error);
  }
  try {
    const token = localStorage.getItem('token');
    const headers = { Authorization: `Bearer ${token}` };

    // Fetch the logbook details for the given trainee
    const traineeResult = await axios.get(`/api/users/${this.$route.params.trainee_id}`, { headers });
    const trainee = traineeResult.data;

    // Set the logbook details in your data
    this.traineeDetails.trainee_id = trainee.user_id;
    this.traineeDetails.name = trainee.first_name + ' ' + trainee.last_name;
    this.traineeDetails.email = trainee.email;
    this.traineeDetails.date_of_birth = trainee.date_of_birth;
    this.traineeDetails.place_of_birth = trainee.place_of_birth;
    this.traineeDetails.license_no = trainee.license_no;

} catch (error) {
  this.traineeError = 'An error occurred while fetching data: ' + error.message;
      console.error('An error occurred while fetching data:', error);
  }



},
methods: {
  goBack() {
            this.$router.push({ name: 'TraineeReports' });
        },
}

}
</script>
  
  
<style scoped>
h1.page-title {
  text-align: left;
  padding-top: 30px;
  font-size: 2rem;
  margin-left: 3%;
  padding-bottom: 10px;
}

.logbook-details-box {
  border: 2px solid #858585; /* Border color can be adjusted */
  border-radius: 5px;
  margin: 20px auto; /* Center the box horizontally */
  max-width: 95%; /* Adjust the maximum width as needed */
  padding: 20px;
}


.cell-header {
  font-weight: bold;
  font-size: 14px;
  background-color: #707070;
  padding: 2px 5px;
  color: white;
  border-radius: 2px;
  margin-right: 20px; /* Set margin-right */
  text-align: left;
  display: inline-block; 
  width: auto;
}

.logbook-details {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.logbook-detail-item {
  margin-bottom: 10px;
  font-size: 14px;
}

.logbook-detail-item strong {
  font-weight: bold;
  margin-right: 5px;
  margin-left: 10px;
}
.logbook-detail-item span#align {
  flex-direction: column;
  border: 0px;
  font-weight: 100;
  
}

.wider-column {
  width: 30%;
}

.back-button {
    width: 100px;
    border-radius: 5px;
    background-color: #c1e0bf;
    font-size: 14px;
    margin-bottom: 20px;
    border: 2px solid black;
  padding: 3px;
}


.back-button:hover {
  background-color: #abc7a9;
  cursor: pointer;
}

p {
  color: red;
  font-size: 14px;
}

.data-table {
  font-size: 14px;
  border-collapse: collapse; /* Remove table borders */
  box-shadow: none;
}

.data-table th, .data-table td {
  border: none; /* Remove cell borders */
  padding: 4px; /* Add padding to cells for spacing */
  text-align: left; /* Align content to the left */
}

.fieldset-column {
  width: 150px;
}
</style>
