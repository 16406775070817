<template>
  <Header />
  <div>
    <h1>Assessors in Session ID: {{ $route.params.project_id }}</h1>
  </div>

  <div class="current-time-container">
    <div class="filter-container">
      <input type="text" v-model="searchQuery" placeholder="Search by Assessor Name" />
      <input type="text" id="productFilter" v-model="categorySearchQuery" placeholder="Search by Category" />
      <input type="text" id="projectFilter" v-model="uinSearchQuery" placeholder="Search by UIN" />

      <button class="clear-filter-button" @click="clearFilters">Clear Filters</button>

    </div>
  </div>

  <div class="pagination-button-container">
    <div class="pagination-container">
      <Pagination :totalPages="totalPages" :perPage="perPage" :currentPage="currentPage" @page-change="onPageChange" />
    </div>
    <div class="pagination-info">
      Showing {{ startIndex + 1 }}-{{ endIndex }} of {{ filteredAssessorsLength }} Assessors
    </div>
  </div>

  

  <div class="container-fluid-trainee">
    <table class="data-table-trainee">
      <thead>
        <tr>
          <th @click="sortBy('user_id')" class="skinny-column">
            ID
            <span v-if="sortField === 'user_id'" v-html="getFieldArrow()"></span>
          </th>
          <th @click="sortBy('first_name')">
            First Name
            <span v-if="sortField === 'first_name'" v-html="getFieldArrow('first_name')"></span>
          </th>
          <th>Last Name</th>
          <th>Email</th>
          <th>UIN</th>
          <th class="skinny-column">Remove</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in filteredAssessors" :key="item.user_id">
          <td>{{ item.user_id }}</td>
          <td>{{ item.first_name }}</td>
          <td>{{ item.last_name }}</td>
          <td>{{ item.email }} </td>
          <td> {{ item.UIN }}</td>
          <td class="center-icons">
            <i class="fas fa-trash-alt delete-icon" @click="removeAssessor(item.user_id)"></i>
          </td>
        </tr>
        <tr v-if="filteredAssessors.length === 0">
          <td colspan="100" style="text-align: center;">No Matching Assessors</td>
        </tr>
      </tbody>
    </table>
    <button class="back-button" @click="goBack">Back</button>
    <button class="btn btn-primary rounded" id=addlogbookButton @click="goToAssignAssessor">Assign New Assessors</button>
  </div>

</template>
  
<script>
import Header from '../Header.vue';
import axios from 'axios';
import Pagination from '../TablePagination.vue';

export default {
  name: 'ViewAssessors',
  data() {
    return {
      assessors: [],
      sortField: 'user_id',
      sortDirection: 'asc',
      searchQuery: '',
      categorySearchQuery: '',
      uinSearchQuery: '',
      currentPage: 1,
      perPage: 10,
      totalPages: 0,
      newProject: {
        name: '',
        product_id: '',
        user_id: '',
      },
      filteredAssessorsLength: 0,
    };
  },
  watch: {
    searchQuery(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
      }
    },
    categorySearchQuery(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    uinSearchQuery(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
  },
  components: {
    Pagination,
    Header
  },
  async mounted() {

    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      let result = await axios.get(`/api/projects/${this.$route.params.project_id}/users`, { headers });


      if (result.status === 200) {
        // Filter the response data to include only users with the role 'instructor'
        this.assessors = result.data.filter(user => user.role === 'assessor'  || user.second_role === 'assessor');

        for (let i = 0; i < this.assessors.length; i++) {
          let result_project = await axios.get(`/api/users/${this.assessors[i].user_id}/projects`, { headers });
          this.assessors[i].projects = result_project.data;
        }
      }

      this.totalPages = Math.ceil(this.assessors.length / this.perPage);

    } catch (error) {
      console.error('An error occurred while fetching data:', error);
    }
  },
  methods: {
    editAssessor(user_id) {
      window.location.href = `/assessors/${user_id}`;
    },

    async removeAssessor(user_id) {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        // You may want to show a confirmation dialog before removing the assessor
        const confirmRemove = confirm('Are you sure you want to remove this assessor from the Session?');

        if (!confirmRemove) {
          return;
        }

        // Send a DELETE request to your API to remove the assessor from the project
        const response = await axios.delete(`/api/users/${user_id}/projects/${this.$route.params.project_id}`, { headers });

        if (response.status === 200) {
          // If the removal was successful, you can remove the assessor from the local data
          this.assessors = this.assessors.filter(assessor => assessor.user_id !== user_id);
          window.alert('Assessor successfully removed from Session.');
        } else {
          // Handle errors here
          console.error('An error occurred while removing the assessor:', response);
        }
      } catch (error) {
        console.error('An error occurred while removing the assessor:', error);
      }
    },
    sortBy(field) {
      if (this.sortField === field) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortField = field;
        this.sortDirection = 'asc';
      }

      this.assessors.sort((a, b) => {
        const valueA = a[field];
        const valueB = b[field];

        if (typeof valueA === 'number' && typeof valueB === 'number') {
          return (this.sortDirection === 'asc' ? 1 : -1) * (valueA - valueB);
        } else if (typeof valueA === 'string' && typeof valueB === 'string') {
          return (this.sortDirection === 'asc' ? 1 : -1) * valueA.localeCompare(valueB);
        } else {
          return 0;
        }
      });
    },
    getFieldArrow(field = 'user_id') {
      if (this.sortField === field) {
        return this.sortDirection === 'asc' ? '▲' : '▼';
      } else {
        return '';
      }
    },
    onPageChange(page) {
      this.currentPage = page;
    },
    goToAssignAssessor() {
      const project_id = localStorage.getItem('project_id');
      this.$router.push({ name: 'AssignAssessors', params: { project_id } });
    },
    goBack() {
      this.$router.push({ name: 'ViewProjects' });
    },
    onCategorySearch() {
      this.currentPage = 1;
    },
    onUINSearch() {
      this.currentPage = 1;
    },
    calculateTotalPages() {
      this.computeFilteredAssessors();
      this.totalPages = Math.ceil(this.filteredAssessorsLength / this.perPage);
    },
    computeFilteredAssessors() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;

      const filteredAssessors = this.assessors.filter((assessor) => {
        const fullName = (assessor.first_name + assessor.last_name || "").toLowerCase(); // Fallback to empty string if null or undefined
        const assessorMatch = fullName.includes(this.searchQuery.toLowerCase()) || this.searchQuery === '';

        const category = (assessor.category || "").toLowerCase(); // Fallback to empty string if null or undefined
        const categoryMatch = category.includes(this.categorySearchQuery.toLowerCase()) || this.categorySearchQuery === '';

        const uin = (assessor.UIN || "").toString(); // Fallback to empty string if null or undefined
        const uinMatch = uin.includes(this.uinSearchQuery.toLowerCase()) || this.uinSearchQuery === '';

        return assessorMatch && categoryMatch && uinMatch;
      });

      console.log(filteredAssessors);
      this.filteredAssessorsLength = filteredAssessors.length;
      console.log(this.filteredAssessorsLength);
      return filteredAssessors.slice(startIndex, endIndex);
    },
    clearFilters() {
      // Clear all filter-related data properties
      this.searchQuery = '',
        this.categorySearchQuery = '',
        this.uinSearchQuery = '',

        // Recalculate the total pages and update the data
        this.calculateTotalPages();
    },
  },
  computed: {
    startIndex() {
      return (this.currentPage - 1) * this.perPage;
    },
    endIndex() {
      const end = this.startIndex + this.perPage;
      return end < this.filteredAssessorsLength ? end : this.filteredAssessorsLength;
    },
    filteredAssessors() {
      return this.computeFilteredAssessors();
    },
  },
};
</script>
  
  
<style scoped>
span:hover {
  cursor: pointer;
  color: blue;
}

.container-fluid-trainee {
  padding-top: 30px;
  margin-left: 1%;
  margin-right: 1%;
}

button.btn#addlogbookButton {
  padding: 10px 10px;
  font-size: 16px;
  width: 180px;
  height: 40px;
  margin-top: 0;
  margin-bottom: 15px;
  margin-right: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  float: right;
}

button.btn#addlogbookButton:hover {
  background-color: #0c1a61;
  transition: transform 0.2s, box-shadow 0.2s;
}

.current-time-container {
  position: absolute;
  top: 80px;
  right: 10px;


}

input[type="text"] {
  width: 100%;
  height: 35px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  margin-bottom: 1px;
  margin-right: 20px;
}

input[type="text"]#productFilter {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
  position: absolute;
  right: 210px;
  top: 0px;
}

input[type="text"]#projectFilter {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
  position: absolute;
  right: 210px;
  top: 41px;
}

h1 {
  padding-top: 30px;
  text-align: left;
  padding-left: 3%;
}

.filter-container {
  margin: 20px 0;
  position: absolute;
  width: 200px;
  top: 0px;
  right: 20px;
}

label {
  margin-top: 8px;
}


.data-table-trainee {
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-collapse: collapse;
  margin-left: 0;
  align-items: left;
}

.data-table-trainee th,
.data-table-trainee td {
  text-align: left;
  /* Align content to the left */
  padding-left: 10px;
}

.data-table-trainee td.center-icons {
  text-align: center;
}

.back-button {
  /* Adjust the right position as needed */
  width: 100px;
  border-radius: 5px;
  background-color: #c1e0bf;
  float: left;
  margin-left: 30px;
  border: 2px solid black;
  padding: 3px;
}

.back-button:hover {
  background-color: #abc7a9;
  cursor: pointer;
}

button:hover {
  background-color: #7c927a;
  transition: transform 0.2s, box-shadow 0.2s;
}

.pagination-container {
  margin-top: 40px;
  margin-bottom: 0;
  display: flex;
  margin-left: 3%;
}

.delete-icon {
  font-size: 16px;
  color: #FF0000;
  /* Red color */
  cursor: pointer;
  transition: color 0.2s, transform 0.2s, box-shadow 0.2s;
}

.delete-icon:hover {
  color: #a82222;
  /* Lighter red color on hover */
  cursor: pointer;
  transform: scale(1.1);
  /* Scale the icon on hover */
  /* You can also add a box-shadow or any other styles you prefer */
}

.skinny-column {
  width: 10%;
}

.pagination-info {
  font-size: 14px;
  color: #666;
  /* Adjust the color as needed */
  position: absolute;
  top: 250px;
  left: 50%;
  /* Center horizontally */
  transform: translateX(-50%);
  /* Center horizontally */
}


.clear-filter-button {
  position: absolute;
  right: 50px;
  top: 92px;
  width: 120px;
  border-radius: 5px;
  background-color: #dee2e6;
  font-size: 14px;
  height: 30px;
  border: 2px solid black;
  line-height: 0px;
}

.clear-filter-button:hover {
  background-color: #cecece;
  cursor: pointer;
}

.pagination-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}</style>