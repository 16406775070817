
<head>  
  <meta name="viewport" content="width=device-width, initial-scale=1">
</head>
<template>
<div class="Evaluation-modal-overlay">
  <div class="Evaluation-modal">
    <h3 class="Evaluation-modal-title">CONTINUOUS EVALUATION SHEET</h3>
    <table class="assessment-table">
      <thead>
        <tr>
          <th rowspan="2">Competence Criteria</th>
          <th colspan="2">Performance result</th>
        </tr>
        <tr>
          <th>
            Satisfactory
            <div style="border:1px solid grey; font-weight:100;border-radius: 5px;">
              <input type="checkbox" id="selectAllSatisfactory" @change="toggleAllSatisfactory"
                :disabled="isCheckboxDisabled">Select All
            </div>
          </th>
          <th>Unsatisfactory</th>
        </tr>
      </thead>
      <tbody>
        <!-- Inside the <tbody> tag -->
        <tr v-for="(item) in criteriaResults" :key="item.criterionId">
          <td>{{ criteria[item.criterionId - 1] }}</td>
          <td>
            <input type="radio" :id="`satisfactory${item.criterionId}`" :name="`result${item.criterionId}`"
              value="satisfactory" v-model="item.result" :disabled="isSubmitted > 0">
            <label :for="`satisfactory${item.criterionId}`"></label>
          </td>
          <td>
            <input type="radio" :id="`unsatisfactory${item.criterionId}`" :name="`result${item.criterionId}`"
              value="unsatisfactory" v-model="item.result" :disabled="isSubmitted > 0">
            <label :for="`unsatisfactory${item.criterionId}`"></label>
          </td>
        </tr>
      </tbody>
    </table>
    <div style="background-color:#f2f2f2">
      <div class="assessment-result">
        <table>
          <td style="width:50%; font-weight: bold;">
            RESULT OF THE ASSESSMENT
          </td>
          <td style="width:20%; border:none;" :style="assessmentResultStyle">
            <span v-if="assessmentResult" style="border:none">{{ assessmentResult.toUpperCase() }}</span>
            <span v-else style="border:none">-</span>
          </td>
        </table>
      </div>
      <div class="assessment-container">
        <table class="task-performance">
          <tr>
            <th style="font-size:14px">Number of Main Tasks in this practical training element</th>
            <td style="font-weight: bold; text-align: center; width:10%;">{{ tasksCount }}</td>
          </tr>
          <tr>
            <td style="width:40%; font-size:14px">Number of Main Tasks performed by the Trainee (mini {{ Math.round(tasksCount/2) }} to pass)</td>
            <td style="text-align: center;">{{ completedTasks }}</td>
          </tr>
        </table>
        <div class="signature-block">
          <table class="signature-table" style="margin-left: 30px;">
            <tr class="trainee-signature">
  <td style="width:40%; font-weight:bold;">TRAINEE SIGNATURE:</td>
  <td>
    <div class="signature-image-wrapper" :class="{ 'disabled': isSubmitted > 0 }">
      <img v-if="traineeSignature" :src="traineeSignature" alt="Trainee Signature" class="signature-image" width="280" height="60"/>
      <!-- Transparent div to overlay on top of the image -->
      <div class="signature-overlay" v-if="isSubmitted > 0"></div>
    </div>
  </td>
</tr>

            <tr class="signature">
              <td style="font-weight:bold">INSTRUCTOR SIGNATURE:</td>
              <td>
                <canvas ref="signaturePadCanvas" id="signature-pad" class="signature-pad" width="280"
                  height="60"></canvas>
              </td>
            </tr>
            <tr class="signature">
              <td style="font-weight:bold">DATE:</td>
              <td><input type="date" id="date" name="date" v-model="date" :disabled="isSubmitted > 0" /></td>
            </tr>
          </table>
        </div>
      </div>
      <!-- ... other signature inputs ... -->
    </div>
    <div class="button-container">
      <button class="ins-close-button" @click="close">Close</button>
      <div class="right">
      <button class="ins-clear-button" @click="clearSignature" :disabled="isSubmitted > 0">Clear Signature</button>
      <button class="ins-save-button" @click="save(false)" :disabled="isSubmitted > 0">Save</button>
      <button class="ins-submit-button" @click="submitEvaluation" :disabled="isSubmitted > 0">Submit</button>
    </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios';
import SignaturePad from 'signature_pad';

export default {
props: {
  tasksCount: {
    type: Number,
    required: true
  },
  completedTasks: {
    type: Number,
  },
  fetchedData: Object,
  evaluationId: Number,
},
watch: {
  fetchedData(newVal) {
    console.log('Received fetched data:', newVal);
    this.populateEvaluationData(newVal);
  },
  criteriaResults: {
    immediate: true,
    handler() {
      const result = this.assessmentResult;
      this.successfulChecked = result === 'successful';
      this.remedialChecked = result === 'remedial';
    }
  },
  isSubmitted(newValue, oldValue) {
    if (newValue > 0) {
      // Disable the signature pad
      if (this.signaturePad) {
        this.signaturePad.off();
      }
      this.$refs.signaturePadCanvas.style.opacity = '0.5';
    } else if (oldValue > 0 && newValue === 0) {
      // If going from disabled to enabled
      this.initializeSignaturePad();
    }
  },
},
data() {
  return {
    criteriaResults: new Array(8).fill(null).map((_, index) => {
      return { criterionId: index + 1, result: null };
    }),
    isSubmitted: 0,
    tasksPerformed: '',
    traineeSignature: '',
    assessorSignature: '',
    successfulChecked: false,
    remedialChecked: false,
    date: '',
    traineeId: localStorage.getItem('trainee_id'),
    logbookId: localStorage.getItem('logbook_id'),
    instructorId: localStorage.getItem('user_id'),
    evallogbookId: null,
    signaturePad: null,
    criteria: [
      'Consults the correct documentation',
      'Scans the environment before starting and during the task to ensure safety',
      'Reads/interprets the safety warning correctly',
      'Informs/communicates with appropriate people correctly',
      'Interprets the reports, indications and documentation correctly',
      'Follows the procedure steps',
      'Reacts properly to changes during task performance to ensure safety',
      'Takes into account interactions between systems',
      'Restores the aircraft back to initial condition (or appropriate condition depending on the circumstances)'
    ],
  }
},
computed: {
  assessmentResult() {
    // Check if all criteria are marked as 'satisfactory'
    const allSatisfactory = this.criteriaResults.every(item => item.result === 'satisfactory');

    // Check if any criterion is marked as 'unsatisfactory'
    const anyUnsatisfactory = this.criteriaResults.some(item => item.result === 'unsatisfactory');

    if (allSatisfactory) {
      return 'successful';
    } else if (anyUnsatisfactory) {
      return 'remedial';
    }
    return null;
  },
  assessmentResultStyle() {
    switch (this.assessmentResult) {
      case 'successful':
        return { color: 'green', fontWeight: 'bold', border: 'none' };
      case 'remedial':
        return { color: 'red', fontWeight: 'bold', border: 'none' };
      default:
        return {};
    }
  },
  isReadOnly() {
    return this.fetchedData && this.fetchedData.is_submitted;
  },
  isCheckboxDisabled() {
    return this.isSubmitted > 0;
  },
},
methods: {
  close() {
    this.$emit('close');
  },
  clearSignature() {
    if (this.isSubmitted === 0 && this.signaturePad) {
      this.signaturePad.clear();
    }
  },
  initializeSignaturePad() {
    const canvas = this.$refs.signaturePadCanvas;
    if (canvas) {
      this.signaturePad = new SignaturePad(canvas, {
        backgroundColor: 'rgb(255, 255, 255)',
        minWidth: 0.5, // Adjust this value for thinner lines
      maxWidth: 1.5, // Adjust this value for thinner lines
      });

      if (this.assessorSignature) {
        this.signaturePad.fromDataURL(this.assessorSignature);
      }

      if (this.isSubmitted > 0) {
        this.signaturePad.off();
        canvas.style.opacity = '0.5';
      } else {
        canvas.style.opacity = '1';
      }
    }
  },
  destroySignaturePad() {
    if (this.signaturePad) {
      this.signaturePad.off();  // Turn off the signature pad
      this.signaturePad = null; // Clean up the reference
    }
  },

  toggleAllSatisfactory(event) {
    const isSatisfactory = event.target.checked;
    this.criteriaResults.forEach((criteria) => {
      criteria.result = isSatisfactory ? 'satisfactory' : null;
    });
  },
  submitEvaluation() {
    const assessorSign = this.signaturePad.toDataURL();
    const isComplete = this.criteriaResults.every(criterion => criterion.result) &&
      assessorSign && this.date && this.assessmentResult;

    if (!isComplete) {
      alert("Please fill all the fields before submitting.");
      return;
    }

    // Confirm submission
    const confirmSubmission = confirm("Once submitted, the form cannot be edited. Are you sure you want to submit?");
    if (confirmSubmission && this.isSubmitted === 0) {
      this.isSubmitted = 1; // Set form to readonly
      this.save(1); // Pass true to indicate submission
    }
  },
  save(isSubmitting = 0) {
    //eslint-disable-next-line
    debugger;
    const evaluationData = {
      logbook_id: Number(this.logbookId),
      trainee_id: Number(this.traineeId),
      instructor_id: Number(this.instructorId),
      criteria: this.criteriaResults.map(result => ({
        criterionId: result.criterionId,
        result: result.result
      })),
      result: this.assessmentResult,
      date: this.date,
      assessor_signature: this.signaturePad.toDataURL(),
      is_submitted: Number(isSubmitting),
    };
    const token = localStorage.getItem('token');
    const headers = { Authorization: `Bearer ${token}` };

    if (this.evaluationId) {
      axios.put(`/api/evaluations/${this.evaluationId}`, evaluationData, { headers })
        .then(response => {
          alert('Evaluation updated successfully.', response);
          this.close();
          //this.populateEvaluationData(response.data);
        })
        .catch(error => {
          console.error('There was an error updating the evaluation:', error);
          alert('Failed to update the evaluation.');
        });
    } else {
      // POST request if this is a new evaluation
      axios.post('/api/evaluations', evaluationData, { headers })
        .then(response => {
          alert('Evaluation saved successfully.', response);
          this.close();
          //this.populateEvaluationData(response.data);
        })
        .catch(error => {
          console.error('There was an error saving the evaluation:', error);
          alert('Failed to save the evaluation.');
        });
    }
  },
  populateEvaluationData(evaluationData) {
    if (!evaluationData) {
      console.error('No evaluation data found');
      return;
    }

    // Convert criteria object to an array if necessary
    if (evaluationData.criteria && typeof evaluationData.criteria === 'object') {
      // Object.entries will give you an array of [key, value] pairs
      this.criteriaResults = Object.entries(evaluationData.criteria).map(([criterionId, result]) => ({
        criterionId: parseInt(criterionId), // Make sure to parse the criterionId to number if necessary
        result: result
      }));
    }
    if (this.signaturePad && evaluationData.assessor_signature) {
      const image = new Image();
      image.onload = () => {
        this.signaturePad.fromDataURL(evaluationData.assessor_signature, {width: 280, height: 55});
      };
      image.src = evaluationData.assessor_signature;
    } else if (this.signaturePad) {
      this.signaturePad.clear();
    }

    // Assign other values
    this.successfulChecked = evaluationData.result === 'successful';
    this.remedialChecked = evaluationData.result === 'remedial';
    this.traineeSignature = evaluationData.trainee_signature;
    this.assessorSignature = evaluationData.assessor_signature;
    this.date = evaluationData.date;
    this.isSubmitted = evaluationData.is_submitted;
  },
},
mounted() {
  this.$nextTick(() => {
    this.initializeSignaturePad();
  });
  if (this.fetchedData) {
    this.populateEvaluationData(this.fetchedData);
  }
},
beforeUnmount() { // or beforeDestroy() for Vue 2
  this.destroySignaturePad();
}

}
</script>


<style scoped>
.Evaluation-modal-overlay {
  position: absolute;
    top: 0px;
    left: 0;
    background-color: rgba(97, 94, 94, 0.5);
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.Evaluation-modal {
background-color: #ffffff;
overflow-y: auto;
/* Enables vertical scrolling if needed */
margin-top: 2px;
max-height: 90vh;
max-width: 90vw;
padding: 10px;
border-radius: 8px;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
position: fixed;
display: flex;
flex-direction: column;
overflow-wrap: break-word;
padding: 20px;
}

.modal-title {
text-align: center;
margin-bottom: 20px;
}

.assessment-table {
width: 100%;
border-collapse: collapse;
margin-bottom: 20px;
align-items: center;
}

.assessment-table th,
.assessment-table td {
border: 1px solid #ddd;
padding: 8px;
text-align: left;
}

.assessment-table thead th {
background-color: #f2f2f2;
}

.signature-section {
margin-top: 20px;
display: grid;
grid-template-columns: repeat(2, 1fr);
gap: 10px;
}

.signature-item label {
width: 40%;
display: block;
margin-bottom: 5px;
}

.signature-placeholder,
.date-placeholder {
border: 1px solid #ddd;
padding: 5px;
min-height: 30px;
/* Adjust as needed */
}

.button-container {
display: flex;
justify-content: space-between;
/* Aligns items to the right */
align-items: center;
margin-top: 20px;
/* Adjust as needed */
}

.ins-save-button {
background-color: #007bff;
border-color: #007bff;
color: white;
border: none;
cursor: pointer;
border-radius: 5px;
padding: 5px 10px;
margin-left: 20px;
width: 100%;
min-width: 90px;
max-width: 100px;
}

.ins-submit-button {
background-color: brown;
color: white;
border: none;
cursor: pointer;
border-radius: 5px;
padding: 5px 10px;
margin-left: 20px;
min-width: 100px;
max-width: 120px;
}

.ins-close-button {
background-color: #6c757d;
border-color: #6c757d;
color:white;
padding: 5px 20px;
border-radius: 5px;
}

.ins-clear-button {
background-color: #f0ac9c;
color:white;
padding: 5px 10px;
border-radius: 5px;
margin-right: 0;
width: 100%;
min-width: 140px;
max-width: 150px;
}

.ins-save-button:disabled,
.ins-submit-button:disabled,
.ins-clear-button:disabled {
background-color: rgb(216, 216, 216);
cursor: not-allowed;
color:grey
}
.ins-submit-button:hover {
  background-color: rgb(129, 33, 33);
}

.assessment-result {
margin-left: 25%;
}

.task-performance {
width: 50%;
border-collapse: collapse;
text-align: left;
background-color: #f2f2f2;
margin-left: 20px;
}

.task-performance th,
.task-performance td {
border: 1px solid black;

}

.task-performance th {
/* Adjust the color to match your header background */
font-weight: bold;
}


.task-performance input[type="text"] {
width: 100%;
border: none;
padding: 4px;
text-align: center;
background-color: #f2f2f2;
}

.assessment-container {
display: flex;
justify-content: space-between;
align-items: flex-start;
margin-top: 20px;
}


.signature-block {
display: flex;
flex-direction: column;
margin-right: 70px;
}


.signature-table {
width: 100%;
}

.signature-table td {
padding: 4px;
/* Adjust padding as needed */
vertical-align: middle;
/* Aligns content of <td> vertically in the middle */
}

.signature-table .signature {
text-align: left;
/* Aligns the text to the left */
}

.trainee-signature {
text-align: left;
}

.signature-table input[type="text"],
.signature-table input[type="date"] {
width: 100%;
border: none;
border-bottom: 1px solid black;
border-radius: 0px;
background-color: transparent;
outline: none;
height: 20px;
}

@media (max-width: 768px) {

/* Adjust the max-width as needed for iPad or other tablet sizes */
.Evaluation-modal {
  padding: 5px;
  font-size: 0.9em;
  /* Reduce font size */
}

/* Further adjustments for other elements */
}


#selectAllSatisfactory {
margin-left: 5px;
border: 1px solid rgb(206, 13, 13);
}

.signature-pad {
border-bottom: 1px solid #000;
border-radius: 5px;
}

.right {
    display: flex;
  justify-content: flex-end;
}

/* Add this CSS to your stylesheet */
.signature-image-wrapper {
  position: relative;
  display: inline-block;
}

.signature-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  pointer-events: none; /* Prevents interaction with the overlay */
}

.disabled {
  opacity: 0.5; /* Optionally reduce opacity to indicate disabled state */
}

</style>
