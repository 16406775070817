<template>
  <Header />
  <h1>Revisions</h1>

  <div class="current-time-container">
    <div class="filter-container">
      <input type="text" v-model="userSearchQuery" placeholder="Search by User ID" />
      <input type="text" v-model="keySearchQuery" placeholder="Search by Key" />
      <input type="text" id="oldValueFilter" v-model="oldValueSearchQuery" placeholder="Search by Old Value" />
      <input type="text" v-model="newValueSearchQuery" placeholder="Search by New Value" />
      <input type="text" id="productFilter" v-model="revisionIDSearchQuery" placeholder="Search by Revision ID" />
      <input type="text" id="projectFilter" v-model="revisionNameSearchQuery" placeholder="Search by Revision Name" />

      <button class="clear-filter-button" @click="clearFilters">Clear Filters</button>
    </div>
  </div>

  <div class="pagination-button-container">
    <div class="pagination-container">
      <Pagination :totalPages="totalPages" :perPage="perPage" :currentPage="currentPage" @page-change="onPageChange" />
    </div>
    <div class="pagination-info">
      Showing {{ startIndex + 1 }}-{{ endIndex }} of {{ filteredRevisionsLength }} revisions
    </div>
  </div>

  <div class="container-fluid-trainee">
    <table class="data-table-trainee">
      <thead>
        <tr>
          <th class="skinny-column" @click="sortBy('revisionable_id')">
            Revision ID
            <span v-if="sortField === 'first_name'" v-html="getFieldArrow('first_name')"></span>
          </th>
          <th>Revision Name</th>
          <th class="skinny-column">User ID</th>
          <th class="skinny-column">Key</th>
          <th>Old Value</th>
          <th>New Value</th>
          <th>Updated At</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in filteredRevisions" :key="item.id">
          <td>{{ item.revisionable_id }}</td>
          <td>{{ item.revisionable_type }}</td>
          <td>{{ item.user_id }} </td>
          <td>{{ item.key }} </td>
          <td>
      <span v-if="item.key === 'password'">{{ shortenString(item.old_value) }}</span>
      <span v-else>{{ item.old_value }}</span>
    </td>
    <td>
      <span v-if="item.key === 'password'">{{ shortenString(item.new_value) }}</span>
      <span v-else>{{ item.new_value }}</span>
    </td>
          <td>{{ getTimestamp(item.updated_at) }} </td>
          <!-- <td class="center-icons">
            <i class="fas fa-edit edit-icon" @click="viewEditTrainee(item.user_id)"></i>
            <i class="fas fa-trash-alt delete-icon" @click="deleteTrainee(item.user_id)"></i>
          </td> -->
        </tr>
        <tr v-if="filteredRevisions.length === 0">
          <td colspan="100" style="text-align: center;">No Matching Revisions</td>
        </tr>
      </tbody>
    </table>
  </div>
<div>
  </div>
</template>
    
<script>
import Header from './Header.vue';
import axios from 'axios';
import Pagination from './TablePagination.vue';

export default {
  name: 'ViewRevisions',
  components: {
    Pagination,
    Header,
  },

  data() {
    return {
      revisions: [],
      sortField: 'user_id',
      sortDirection: 'asc',
      userSearchQuery: '',
      keySearchQuery: '',
      oldValueSearchQuery: '', 
      newValueSearchQuery: '', 
      revisionIDSearchQuery: '',
      revisionNameSearchQuery: '', 
      currentPage: 1,
      perPage: 10,
      totalPages: 0,
      filteredRevisionsLength: 0,
    };
  },
  watch: {
    userSearchQuery(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    revisionIDSearchQuery(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    revisionNameSearchQuery(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    keySearchQuery(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    oldValueSearchQuery(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    newValueSearchQuery(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
  },

  async mounted() {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      // Fetch all Revisions
      let result = await axios.get('/api/revisions', { headers });

      if (result.status === 200) {
        this.revisions = result.data;
      }

      this.totalPages = Math.ceil(this.revisions.length / this.perPage);

    } catch (error) {
      console.error('An error occurred while fetching data:', error);
    }
  },


  methods: {
    // Function to get a timestamp in a specific format
getTimestamp(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based in JavaScript
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Format the date and time
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
},
shortenString(value, maxLength = 40) {
      if (value && value.length > maxLength) {
        return value.substring(0, maxLength) + '...';
      }
      return value;
    },
    // deleteTrainee(user_id) {
    //   if (confirm("Are you sure you want to delete this trainee?")) {
    //     if (confirm("This action will PERMANENTLY delete the user from the database. Do you wish to continue?")) {
    //       // If the user confirms the deletion, send a request to delete the trainee.
    //       const token = localStorage.getItem('token');
    //       const headers = { Authorization: `Bearer ${token}` };

    //       axios
    //         .delete(`/api/users/${user_id}`, { headers })
    //         .then(response => {
    //           if (response.status === 200) {
    //             // Remove the deleted trainee from the local data
    //             this.revisions = this.revisions.filter(trainee => trainee.user_id !== user_id);

    //             window.alert('User deleted Successfully');
    //           }
    //         })
    //         .catch(error => {
    //           console.error('An error occurred while deleting the trainee:', error);
    //         });
    //     }
    //   }
    // },
    sortBy(field) {
      if (this.sortField === field) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortField = field;
        this.sortDirection = 'asc';
      }

      this.revisions.sort((a, b) => {
        const valueA = a[field];
        const valueB = b[field];

        if (typeof valueA === 'number' && typeof valueB === 'number') {
          return (this.sortDirection === 'asc' ? 1 : -1) * (valueA - valueB);
        } else if (typeof valueA === 'string' && typeof valueB === 'string') {
          return (this.sortDirection === 'asc' ? 1 : -1) * valueA.localeCompare(valueB);
        } else {
          return 0;
        }
      });
    },
    getFieldArrow(field = 'user_id') {
      if (this.sortField === field) {
        return this.sortDirection === 'asc' ? '▲' : '▼';
      } else {
        return '';
      }
    },
    onPageChange(page) {
      this.currentPage = page;
      this.calculateTotalPages();
    },
    onTraineeSearch() {
      this.currentPage = 1;
    },
    onLogbookSearch() {
      this.currentPage = 1;
    },
    onCategorySearch() {
      this.currentPage = 1;
    },
    onLicenseSearch() {
      this.currentPage = 1;
    },
    calculateTotalPages() {
      this.computeFilteredRevisions();
      this.totalPages = Math.ceil(this.filteredRevisionsLength / this.perPage);
    },
    computeFilteredRevisions() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;

      const filteredRevisions = this.revisions.filter((revision) => {

        const revisionIDMatch = revision.revisionable_id.toString().includes(this.revisionIDSearchQuery) || this.revisionIDSearchQuery === '';
        
        const revisionName = (revision.revisionable_type || "").toLowerCase(); // Fallback to empty string if null or undefined
        const revisionNameMatch = revisionName.includes(this.revisionNameSearchQuery.toLowerCase()) || this.revisionNameSearchQuery === '';

        const userMatch = revision.user_id.toString().includes(this.userSearchQuery) || this.userSearchQuery === '';

        const key = (revision.key || "").toLowerCase(); // Fallback to empty string if null or undefined
        const keyMatch = key.includes(this.keySearchQuery.toLowerCase()) || this.keySearchQuery === '';

        const old_value = (revision.old_value || "").toLowerCase(); // Fallback to empty string if null or undefined
        const oldValueMatch = old_value.includes(this.oldValueSearchQuery.toLowerCase()) || this.oldValueSearchQuery === '';

        const new_value = (revision.new_value || "").toLowerCase(); // Fallback to empty string if null or undefined
        const newValueMatch = new_value.includes(this.newValueSearchQuery.toLowerCase()) || this.newValueSearchQuery === '';

        return revisionIDMatch && revisionNameMatch && userMatch && keyMatch && oldValueMatch && newValueMatch;
      });
      console.log(filteredRevisions);
      this.filteredRevisionsLength = filteredRevisions.length;
      console.log(this.filteredRevisionsLength);
      return filteredRevisions.slice(startIndex, endIndex);
    },
    clearFilters() {
      // Clear all filter-related data properties
      this.searchQuery = '',
        this.logbookSearchQuery = '',
        this.categorySearchQuery = '',
        this.licenseSearchQuery = '',

        // Recalculate the total pages and update the data
        this.calculateTotalPages();
    },
  },
  computed: {
    startIndex() {
      return (this.currentPage - 1) * this.perPage;
    },
    endIndex() {
      const end = this.startIndex + this.perPage;
      return end < this.filteredRevisionsLength ? end : this.filteredRevisionsLength;
    },
    filteredRevisions() {
      return this.computeFilteredRevisions();
    },
  },
};
</script>
    
    
<style scoped>
span:hover {
  cursor: pointer;
  color: blue;
}

.container-fluid-trainee {
  padding-top: 30px;
  margin-left: 1%;
  margin-right: 1%;
}

button.btn#addlogbookButton {
  padding: 10px 10px;
  font-size: 16px;
  width: 180px;
  height: 40px;
  margin-top: 0;
  margin-bottom: 15px;
  margin-right: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  float: right;
}

button.btn#addlogbookButton:hover {
  background-color: #0c1a61;
  transition: transform 0.2s, box-shadow 0.2s;
}

button.btn#assignTraineeButton {
  padding: 10px 10px;
  font-size: 16px;
  width: 200px;
  height: 40px;
  margin-top: 0;
  margin-bottom: 15px;
  margin-right: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  float: right;
}

button.btn#assignTraineeButton:hover {
  background-color: #0c1a61;
  transition: transform 0.2s, box-shadow 0.2s;
}

button.btn#importTraineeButton {
  padding: 10px 10px;
  font-size: 16px;
  width: 180px;
  height: 40px;
  margin-top: 0;
  margin-bottom: 15px;
  margin-right: 20px;
  border: 1px solid rgb(17, 80, 20);
  background: #2c752c;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  float: right;
}

button.btn#importTraineeButton:hover {
  background-color: #184218;
  transition: transform 0.2s, box-shadow 0.2s;
}

input[type="text"] {
  width: 100%;
  height: 35px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  margin-bottom: 1px;
  margin-right: 20px;
}

input[type="text"]#productFilter {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
  position: absolute;
  right: 210px;
  top: 0px;
}

input[type="text"]#projectFilter {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
  position: absolute;
  right: 210px;
  top: 41px;
}

input[type="text"]#oldValueFilter {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
  position: absolute;
  right: 210px;
  top: 82px;
}




h1 {
  padding-top: 30px;
  margin-right: 100%;
  padding-left: 3%;
}

.filter-container {
  margin: 20px 0;
  position: absolute;
  width: 200px;
  top: 0px;
  right: 20px;
}

label {
  margin-top: 8px;
}

.current-time-container {
  position: absolute;
  top: 80px;
  right: 10px;

}

.data-table-trainee {
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-collapse: collapse;
  margin-left: 0;
  align-items: left;
  margin-bottom: 30px;
}

.data-table-trainee th,
.data-table-trainee td {
  text-align: left;
  /* Align content to the left */
  padding-left: 10px;
}

.data-table-trainee td.center-icons {
  text-align: center;
}

.pagination-container {
  margin-top: 40px;
  margin-bottom: 0;
  display: flex;
  margin-left: 3%;
}

.edit-icon {
  font-size: 16px;
  /* Adjust the size as needed */
  color: #615f5f;
  /* Change the color to your preference */
  cursor: pointer;
  transition: color 0.2s, transform 0.2s, box-shadow 0.2s;
}

.edit-icon:hover {
  color: #2b2a2a;
  /* Change the color to your preference */
  cursor: pointer;
  transform: scale(1.1);
  /* Example transform for scaling on hover */

}

.delete-icon {
  font-size: 16px;
  color: #FF0000;
  /* Red color */
  cursor: pointer;
  transition: color 0.2s, transform 0.2s, box-shadow 0.2s;
  padding-left: 15px;
}

.delete-icon:hover {
  color: #a82222;
  /* Lighter red color on hover */
  cursor: pointer;
  transform: scale(1.1);
  /* Scale the icon on hover */
  /* You can also add a box-shadow or any other styles you prefer */
}

.clear-filter-button {
  position: absolute;
  right: 50px;
  top: 142px;
  width: 120px;
  border-radius: 5px;
  background-color: #dee2e6;
  font-size: 14px;
  height: 30px;
  border: 2px solid black;
  line-height: 0px;
}

.clear-filter-button:hover {
  background-color: #cecece;
  cursor: pointer;
}

.pagination-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}


.pagination-info {
  font-size: 14px;
  color: #666;
  /* Adjust the color as needed */
  position: absolute;
  top:250px;
  left: 50%;
  /* Center horizontally */
  transform: translateX(-50%);
  /* Center horizontally */
}

.wider-column {
  width: 42%;
}

.td-logbook {
  font-size: 13px;
}


.id-column {
  width: 5%;
}

.skinny-column {
  width: 7%;
}

span {
  border: none;
}

span:hover {
  border: none;
  cursor: auto;
  color: rgb(29, 29, 29);
}

</style>