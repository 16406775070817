<template>
  <div>
    <page-header></page-header>
    <h1>Multi Enable:</h1>

    <div class="signoff-buttons">
      <button @click="toggleSelectAllTasks" class="multi-sign-off-button">
        {{ isAllTasksSelected ? 'Deselect All' : 'Select All' }}
      </button>
      <button @click="enableAll" class="multi-sign-off-button" :disabled="processing">{{ processing ? 'Processing...' : 'Enable Selected' }}</button>
    </div>

    <div class="container-fluid-trainee">
      <table class="data-table-trainee">
        <thead>
          <tr>
            <th style="width: 10%;">Chapter No
              <select v-model="selectedChapter">
                <option value="All">All</option>
                <option v-for="chapter in uniqueChapters" :key="chapter" :value="chapter">{{ chapter }}</option>
              </select>
            </th>
            <th style="width: 10%;">Task No</th>
            <th style="width: 40%;">Description</th>
            <th style="width: 10%;">M/O
              <select v-model="selectedMO">
                <option value="All">All</option>
                <option v-for="mo in uniqueMOs" :key="mo" :value="mo">{{ mo }}</option>
              </select>
            </th>
            <th style="width: 10%;">Scope
              <select v-model="selectedScope">
                <option value="All">All</option>
                <option v-for="scope in uniquescopes" :key="scope" :value="scope">{{ scope }}</option>
              </select>
            </th>
            <th style="width: 5%;">Enable
              <select id="completed-filter" v-model="selectedCompleted" style="width:100%">
                <option value="All tasks">All tasks</option>
                <option value="not enabled tasks">Not enabled tasks</option>
                <option value="enabled tasks">Enabled tasks</option>
              </select>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="task in filteredTasks" :key="task.task_id"
            :class="{ 'enabled-task': isTaskEnabledInAllLogbooks(task.task_id) }">
            <td>{{ task.chapt_no }}</td>
            <td>{{ task.task_no }}</td>
            <td>{{ task.description }}
              <!-- <i class="fas fa-edit edit-icon" @click="viewEditTask(task.task_id)"></i> -->
            </td>
            <td>{{ task.manob }}</td>
            <td>{{ task.scope }}</td>
            <td>
              <input type="checkbox" :value="task.task_id" v-model="selectedTasks"
                :checked="isTaskEnabledInAllLogbooks(task.task_id)" :disabled="isTaskEnabledInAllLogbooks(task.task_id)"
                :class="{ 'disabled-checkbox': isTaskEnabledInAllLogbooks(task.task_id) }" />
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <edit-task-modal v-if="showEditTask" @close="closeEditTask"></edit-task-modal> -->
    </div>
  </div>
</template>

<script>
import PageHeader from './InstructorHeader.vue';
// import EditTaskModal from './EditTaskDescriptionModal.vue';
import axios from 'axios';

export default {
  name: 'MultiEnable',
  data() {
    return {
      loggedInUser: '',
      logbooks: [],
      tasks: [],
      projects: [],
      traineeNames: [],
      entries: [],
      selectedTasks: [],
      isAllTasksSelected: false,
      selectedTask: '',
      selectedProject: '',
      trainees: [],
      projectId: this.$route.params.projectId,
      filteredEntries: [],
      // showEditTask: false,
      processing: false,
      selectedMO: 'All',
      selectedCompleted: 'All tasks',
      selectedScope: 'All',
      selectedChapter: 'All',
    };
  },
  components: {
    PageHeader,
    // EditTaskModal,
  },
  computed: {
    isTaskEnabledInAllLogbooks() {
      return (taskId) => {
        // Check if the task is enabled in all logbooks
        return this.logbooks.every(logbook =>
          this.entries.some(entry =>
            entry.logbook_id === logbook.logbook_id && entry.task_id === taskId && entry.enabled
          )
        );
      };
    },
    filteredTasks() {
      return this.tasks.filter(task => {
        const moMatches = this.selectedMO === 'All' || task.manob === this.selectedMO;
        const completedMatches =
          this.selectedCompleted === 'All tasks' ||
          (this.selectedCompleted === 'not completed tasks' && !task.enabled) ||
          (this.selectedCompleted === 'enabled tasks' && task.enabled)
        //const levelMatches = this.selectedLevel === 'All' || task.level === parseInt(this.selectedLevel);
        const scopeMatches = this.selectedScope === 'All' || task.scope === this.selectedScope;
        const chapterMatches = this.selectedChapter === 'All' || task.chapt_no === this.selectedChapter;
 
        return moMatches && completedMatches && scopeMatches && chapterMatches;
      });
    },
    uniqueChapters() {
      const chapters = this.tasks.map(task => task.chapt_no);
      return [...new Set(chapters)]; // This will remove any duplicates and return unique values.
    },
    uniqueMOs() {
      const MOs = this.tasks.map(task => task.manob);
      return [...new Set(MOs)];
    },
    uniquescopes() {
      const scopes = this.tasks.map(task => task.scope);
      return [...new Set(scopes)];
    },
  },
  async mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        // Include the trainee_id parameter in the URL
        this.processing = true;
        const result = await axios.get(`/api/projects/${this.projectId}/logbooks`, { headers });
        this.logbooks = result.data;
        console.log(this.logbooks)

        const projectResult = await axios.get(`/api/projects/${this.projectId}`, { headers });
        this.projects = projectResult.data.project;
        console.log(this.projects);

        const taskResult = await axios.get(`/api/tasks/products/${this.projects.prod_id}`, { headers });
        this.tasks = taskResult.data;
        console.log(this.tasks);

        const entryResult = await axios.get(`/api/logbookentry`, { headers });
        this.entries = entryResult.data;
        console.log(this.entries);

        // Filter entries based on logbook_id
        this.filteredEntries = this.entries.filter(entry =>
          this.logbooks.some(logbook => logbook.logbook_id === entry.logbook_id)
        );
        console.log(this.filteredEntries);

        this.selectedTasks = this.tasks
          .filter(task => this.isTaskEnabledInAllLogbooks(task.task_id))
          .map(task => task.task_id);
          this.processing = false;
      } catch (error) {
        console.error('Error fetching data:', error);
        this.processing = false;
      }
    },
    toggleSelectAllTasks() {
      if (this.isAllTasksSelected) {
        // Deselect all tasks
        this.selectedTasks = [];
      } else {
        // Select all tasks
        this.selectedTasks = this.tasks.map(task => task.task_id);
      }

      // Toggle the state
      this.isAllTasksSelected = !this.isAllTasksSelected;
    },
    async enableAll() {
      try {
        if (this.selectedTasks.length === 0) {
          alert('Please select at least one task.');
          return;
        }
        this.processing = true;

        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        // Gather all logbook_ids and selected task_ids
        const logbookIds = this.logbooks.map(logbook => logbook.logbook_id);
        console.log('logbook_ids', logbookIds);

        // Construct the payload for the batch update
        const logbookEntries = [];
        logbookIds.forEach(logbook_id => {
          this.selectedTasks.forEach(task_id => {
            logbookEntries.push({
              logbook_id,
              task_id,
              enabled: true, // Assuming you're enabling these tasks
            });
          });
        });

        console.log('logbookEntries', logbookEntries);

        // Send the batch update request
        const response = await axios.post('/api/logbookentry/batch-update', { logbook_entries: logbookEntries }, { headers });

        if (response.status === 200) {
          alert('Tasks successfully enabled!');
          // Optionally refresh data or update UI
          console.log('response', response);
          this.selectedTasks = [];
          this.isAllTasksSelected = false;
          this.fetchData();
        }
      } catch (error) {
        console.error('Error enabling tasks:', error);
        alert('An error occurred while enabling tasks.');
        this.processing = false;
      }
    },
    // viewEditTask(task_id) {
    //   localStorage.setItem('task_id', task_id);
    //   this.showEditTask = true; // Show the modal when the button is clicked
    // },
    // closeEditTask() {
    //   this.showEditTask = false; // Close the modal
    // },
  },

}

</script>

<style scoped>
.select-project-container {
  float: left;
  /* Adjust this margin as needed */
  margin-left: 50px;
}

.select-project-container select {
  max-width: 250px;
}

span {
  border: 0px;
}

.container-fluid-trainee {
  padding-top: 10px;
  margin-left: 2%;
  margin-right: 2%;
}

h2 {
  font-size: 1.3rem;
  margin-bottom: 25px;
}

h1 {
  font-size: 2rem;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-top: 0px;
  top: 15px;
}

.completed-task {
  background-color: #a2f5a1;
  /* or any other color you want for completed tasks */
}

.signoff-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: -50px;
  margin-right: 10px;
}

.select-task-container {
  float: left;
  /* Adjust this margin as needed */
  margin-left: 50px;
}

.select-task-container select {
  max-width: 250px;
}

.enabled-task {
  background-color: #c8f5c8 !important;
  /* Light green background for enabled tasks */
}

.edit-icon {
  font-size: 16px;
  color: #615f5f;
  cursor: pointer;
  transition: color 0.2s, transform 0.2s, box-shadow 0.2s;
  float: right;
  margin-right: 10px;
}

.edit-icon:hover {
  color: #2b2a2a;
  cursor: pointer;
  transform: scale(1.1);

}

.multi-sign-off-button {
  width: 140px;
}
</style>
