<template>
    <div class="login-box">
      <div class="att-login-header">
        <h2 id="login-title">Attendance Login</h2>
      </div>
      <div class="login">
        <div class="input-container">
          <input id="email" type="text" v-model="username" placeholder="Enter Email" />
        </div>
        <div class="input-container">
          <input id="password" type="password" v-model="password" placeholder="Enter Password" @keyup.enter="performLogin" />
        </div>
        <div class="input-container">
          <button id="user-login" @click="performLogin">Login</button>
        </div>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        username: '',
        password: '',
        errorMessage: ''
      };
    },
    methods: {
      async performLogin() {
        try {
          const response = await axios.post('/api/login', {
            email: this.username,
            password: this.password,
          });
          
          const userRole = response.data.data.role;
          if (userRole === 'examiner') {
            this.$router.push('/dashboard');
          } else if (userRole === 'trainee') {
            this.$router.push('/trainee-home');
            alert(`Timestamp recorded for ${response.data.data.trainee_id}`);
          }
        } catch (error) {
          this.errorMessage = error.response ? error.response.data.message : error.message;
        }
      }
    }
  }
  </script>
  
  <style scoped>
  .login-box {
    width: 30%;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .input-container {
    margin-bottom: 10px;
    margin-top:10px;
  }
  
  .error-message {
    color: red;
  }

  .att-login-header {
  background-color: #b3c5f7;
  text-align: left;
  height: 70px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  justify-content: left;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
}

  </style>
  