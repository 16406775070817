<template>

  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
  </head>
  <Header />
  <div v-if="isOffline" class="offline-box">
    <p>Offline Mode</p>
  </div>
  <div v-if="isSyncing" class="syncing-box">
    <p>You're online! Syncing data...</p>
  </div>
  <div class="table-section-trainee">
    <table class="logbook-name">
      <td class="logbookName">{{ logbookName }}</td>
      <td> <button @click="openTraineeProgressModal" style="font-size: 18px; margin-right: 20px; "
          class="pdfButton">Progress</button></td>

      <td style="border:none;"><button class="signatureButton" @click="openSignatureModal">Signature</button>
      </td>
      <!-- <td> <button @click="createPDFReport" style="font-size: 18px;" class="pdfButton">Download PDF</button></td> -->


    </table>
    <div class="tee-pagination-button-container">
      <div class="tee-pagination-container">
        <Pagination :total-pages="totalPages" :per-page="perPage" :current-page="currentPage"
          @page-change="onPageChange" />
      </div>
      <div class="tee-pagination-info">
        Showing {{ startIndex + 1 }}-{{ endIndex }} of {{ filteredTasks.length }} Tasks
      </div>
    </div>
    <table class="data-table-trainee">
      <thead>
        <tr>
          <th class="task-no">Task No</th>
          <!-- <th class="reference">Reference</th> -->
          <th id="scope-filter" class="scope">Scope
            <select v-model="selectedscope">
              <option value="All">All</option>
              <option v-for="scope in uniquescopes" :key="scope" :value="scope">{{ scope }}</option>
            </select>
          </th>
          <!-- <th class="category">Category
            <select v-model="selectedCategory">
              <option value="All">All</option>
              <option v-for="category in uniquecategories" :key="category" :value="category">{{ category }}</option>
            </select>
          </th> -->
          <th class="MO">MT/AT <br>
            <select id="manob-filter" v-model="selectedMO">
              <option value="All">All</option>
              <option v-for="mo in uniqueMOs" :key="mo" :value="mo">{{ mo }}</option>
            </select>
          </th>
          <!--<th class="sect-id">Sect ID</th>-->
          <th class="chapt-id">ATA
            <select id="chapter-filter" v-model="selectedChapter">
              <option value="All">All</option>
              <option v-for="chapter in uniqueChapters" :key="chapter" :value="chapter">{{ chapter }}</option>
            </select>
          </th>
          <th class="description">Description</th>
          <!--<th class="level"> Level
            <select id="level-filter" v-model="selectedLevel">
              <option value="All">All</option>
              <option v-for="level in uniqueLevels" :key="level" :value="level">{{ level }}</option>
            </select>
          </th>
          <th class="isexam">Is Exam</th>
         <th class="isexam">Task Enabled</th> -->
          <th class="completed">Completed
            <select id="completed-filter" v-model="selectedCompleted" style="width:100%">
              <option value="All tasks">All tasks</option>
              <option value="completed tasks">Completed tasks</option>
              <option value="not completed tasks">Not completed tasks</option>
              <option value="enabled tasks">Enabled tasks</option>
              <option id="examinable" value="examinable tasks">Examinable tasks</option>
            </select>
          </th>
          <th class="inssign">Inst. Sign Off
            <select id="ins-so-filter" v-model="selectedSignOff" style="width:100%">
              <option value="All tasks">All tasks</option>
              <option value="signed off tasks">Signed Off</option>
              <option value="not signed off tasks">Not Signed Off</option>
            </select>
          </th>
          <th class="trainee_notes">My Notes</th>
          <th class="trainee_insnotes">Inst Notes</th>
          <!-- <th class="assess" style="width: 1%;">Assess</th> -->
        </tr>
      </thead>

      <tbody>
        <template v-for="(group, chaptNo) in paginatedTasks" :key="chaptNo">
    <!-- Row for the Chapter Name -->
    <tr class="chapter-row">
      <td colspan="100%" class="chapter-name-cell">{{ group.name }}</td>
    </tr>
        <tr v-for="(task, index) in group.tasks" :key="index"
          :style="{ background: task.P1 === 1 ? customColor : 'inherit' }">
          <td @click="openTaskLongFormModal(task)" style="color: blue; text-decoration: underline;">{{ task.task_no }}
          </td>
          <!-- <td>{{ task.reference }}</td> -->
          <td>{{ task.scope }}</td>
          <!-- <td>{{ task.category }}</td> -->
          <td>{{ task.manob }}</td>
          <td>{{ task.chapt_no }}</td>
          <!-- <td @click="openTaskDescriptionModal(task)"
            :class="{ 'Description-data': task.is_exam, 'Other-description': !(task.is_exam) }">
            {{ task.activity }}
          </td> -->
          <td v-if="!task.is_exam">
              <div :style="{ color: task.is_submitted === 3 ? 'green' : 'blue' }">
                {{ task.description }}
              </div>
            </td>
            <td id="description" v-else @click="openTaskAssessmentModal(task)">
              <div :style="{ color: task.is_submitted === 3 ? 'green' : 'blue' }">
                {{ task.description }}
              </div>
              <div :style="{ color: task.is_submitted === 3 ? 'green' : 'red' }">
                <b>
                  {{ task.is_submitted === 3 ? '*Assessed' :
                    task.is_submitted === 2 ? '*Trainee Signature Required' :
                      (task.is_selected ? '*Selected for Assessment' : '*Examinable') }}
                </b>
              </div>
            </td>
          <!--<td>{{ task.sect_id }}</td>
          <td>{{ task.level }}</td>
          <td>{{ task.is_exam }}</td>
          <td>{{ task.enabled }}</td> -->
          <td>
            <div v-if="task.completed" style="padding-right: 20px;">
              {{ task.completed }}
              <div v-if="task.t_signature_id" class="signature-container" style="max-height: 40px;">
      <img :src="findSignatureUrl(task.t_signature_id)" class="signature-image" alt="Signature">
    </div>
            </div>
            <div v-else>
              <button class="trainee_signoff" @click.prevent="confirmSignOff(task)" :disabled="!task.enabled">SIGN
                OFF</button>
            </div>
          </td>
          <td>
            <div v-if="task.ins_so">
              {{ task.ins_so }}
              <div v-if="task.ins_signature_id" class="signature-container" style="max-height: 40px;">
      <img :src="findInstSignatureUrl(task.ins_signature_id)" class="signature-image" alt="Signature">
    </div>
            </div>

          </td>
          <td>
            <div v-if="!task.is_exam">
              <button class="notesButton" @click="openEditor(task.task_id)">
                <i :class="{
    'fas fa-sticky-note': true, 'icon-green': allTraineeNotes[task.task_id] && allTraineeNotes[task.task_id].trim() !== '',
    'icon-grey': !allTraineeNotes[task.task_id] || allTraineeNotes[task.task_id].trim() === ''
  }"></i>
              </button>
            </div>
            <div v-else>
              <button class="notesButton" @click="openEditor(task.task_id)">
                <i :class="{
    'fas fa-sticky-note': true, 'icon-green': allTraineeNotes[task.task_id] && allTraineeNotes[task.task_id].trim() !== '',
    'icon-grey': !allTraineeNotes[task.task_id] || allTraineeNotes[task.task_id].trim() === ''
  }"></i>
              </button>
            </div>
          </td>
          <td>
            <button class="notesButton" @click="openNotesModal(task.task_id)">
              <i
                :class="{ 'fas fa-eye': true, 'icon-green': allPublicNotes[task.task_id], 'icon-grey': !allPublicNotes[task.task_id] }"></i>
            </button>
          </td>
          <!-- <td>
            <div v-if="task.is_assessed">
              <a style="text-decoration: underline; color: blue; cursor: pointer;">View Results</a>
            </div>
          </td> -->
        </tr>
      </template>
      </tbody>
    </table>
    <div class="evaluationSection">
      <button class="evaluationButton" @click="openEvaluationDisplayModal()" :disabled="!evaluationIsSubmitted">
        Evaluation Sheet</button>
    </div>
    <QuillEditor v-if="showQuillEditor" :task-id="currentTaskId" :initialContent="getCurrentTaskNotes"
      :displayTaskId="currentTaskId" @saveNotes="closeQuillEditor">
    </QuillEditor>
    <troubleshootModal v-if="showTroubleshootModal" :task-id="currentTaskId" :logbook-id="logbookId"
      :troubleshoot="currentTroubleshoot" :task-explanation="currentTaskExplanation" :chapt-no="currentChaptNo"
      @close="closeTroubleshootModal">
    </troubleshootModal>
    <TaskDescriptionModal v-if="showTaskDescriptionModal" :task-id="currentTaskId" :logbook-id="logbookId"
      :task-explanation="currentTaskExplanation" @close="closeTaskDescriptionModal"></TaskDescriptionModal>
      <task-assessment-modal v-if="showTaskAssessmentModal" :trainee="trainee" :tasks-count="MTCount" :completed-tasks="completedMTCount || 0"
        :taskAssessmentId="taskAssessmentId" :logbook-id="logbookId" :fetched-data="fetchedTaskAssessmentData" :attemptNo="attemptNo" :selectedTask="selectedTask"
        @close="closeTaskAssessmentModal"></task-assessment-modal>
    <TaskLongFormModal v-if="showTaskLongFormModal" :task-id="currentTaskId" :logbook-id="logbookId"
      :merged-tasks="mergedTasks" @close="closeTaskLongFormModal"></TaskLongFormModal>
    <TraineeProgressModal v-if="showTraineeProgressModal" :logbook-id="logbookId" :merged-tasks="mergedTasks"
      @close="closeTraineeProgressModal"></TraineeProgressModal>
    <EvaluationDisplay v-if="showEvaluationDisplayModal" :tasks-count="tasksCount" :completed-tasks="tasksCompleted"
      :evaluation-id="evaluationId" :logbook-id="logbookId" :fetched-data="fetchedEvaluationData"
      @close="closeEvaluationDisplayModal"></EvaluationDisplay>
    <signature-modal v-if="showSignatureModal" @close="closeSignatureModal"></signature-modal>
    <div v-if="showNotesModal" class="notes-modal-overlay" @click="closeNotesModal">
      <div class="notesModal" @click.stop>
        <div class="notes-modal-content">
          <h5>Task ID: {{ currentTaskId }}</h5>
          <hr>
          <p>{{ currentNote }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './TraineeHeader.vue';
import axios from 'axios';
import './TraineeLogbook.css';
import QuillEditor from '@/components/notes/quill.vue';
import Pagination from '@/components/Examiner/TablePagination.vue';
import LogbookReport from './TraineeLogbookReport.vue';
import troubleshootModal from '@/components/Trainee/troubleshootModal.vue';
import TaskDescriptionModal from '@/components/Trainee/TaskDescriptionModal.vue';
import TaskAssessmentModal from '@/components/Trainee/TraineeTaskAssessmentModal.vue';
import TaskLongFormModal from '@/components/Trainee/TaskLongFormModal.vue';
import TraineeProgressModal from '@/components/Trainee/TraineeProgressModal.vue';
import EvaluationDisplay from './EvaluationDisplayModal.vue';
import SignatureModal from '@/components/Examiner/SignatureModal.vue';
import Localbase from 'localbase';
let db = new Localbase('myDatabase');


export default {
  name: 'TraineeLogbook',
  components: {
    Header,
    QuillEditor,
    troubleshootModal,
    TaskDescriptionModal,
    TaskAssessmentModal,
    TaskLongFormModal,
    TraineeProgressModal,
    EvaluationDisplay,
    Pagination,
    SignatureModal,
  },
  data() {
    return {
      user_id: null,
      logbooks: [],
      tasks: [],
      trainee: [],
      logbookName: '',
      productId: localStorage.getItem('productId') || null,
      logbookId: localStorage.getItem('logbook_id'),
      mergedTasks: [],
      editorContent: '',
      showQuillEditor: false,
      showTroubleshootModal: false,
      showTaskDescriptionModal: false,
      showTaskAssessmentModal: false,
      showTaskLongFormModal: false,
      showTraineeProgressModal: false,
      showEvaluationDisplayModal: false,
      showSignatureModal: false,
      evaluationIsSubmitted: false,
      fetchedEvaluationData: null,
      allTraineeNotes: {},
      notes: {},
      currentTaskId: null,
      notesContent: '',
      isLoadingNote: false,
      allPublicNotes: {},
      showNotesModal: false,
      currentNote: '',
      notesExist: false,
      selectedMO: 'All',
      selectedCompleted: 'All tasks',
      selectedLevel: 'All',
      selectedscope: 'All',
      selectedChapter: 'All',
      selectedCategory: 'All',
      selectedisexam: 'All',
      selectedSignOff: 'All tasks',
      offlineTasks: [],
      isSyncing: false,
      isOffline: !navigator.onLine,
      currentTroubleshoot: '',
      filteredTasksLength: 0,
      currentPage: 1,
      perPage: 10,
      customColor: 'rgb(218, 248, 252, 0.7)',
      MTCount: '',
      completedMTCount: '',
      signatureId: '',
      instSignatureId: '',
      signatureUrl: null,
      instructorSignatureUrl: null,
      instructorSignatures: {},
      signatures: [],
      fetchedTaskAssessmentData: [],
      taskAssessmentId: null,
      task_assessments: [],
      chapters: [],
    };
  },
  computed: {
    getCurrentTaskNotes() {
      if (!this.currentTaskId) return '';
      return this.allTraineeNotes[this.currentTaskId] || '';
    },
    filteredTasks() {
      return this.mergedTasks.filter(task => {
        const moMatches = this.selectedMO === 'All' || task.manob === this.selectedMO;
        const completedMatches =
          this.selectedCompleted === 'All tasks' ||
          (this.selectedCompleted === 'completed tasks' && task.completed) ||
          (this.selectedCompleted === 'not completed tasks' && !task.completed) ||
          (this.selectedCompleted === 'enabled tasks' && task.enabled) ||
          (this.selectedCompleted === 'examinable tasks' && task.is_exam);
        const SignedOffMatches =
          this.selectedSignOff === 'All tasks' ||
          (this.selectedSignOff === 'signed off tasks' && task.ins_so) ||
          (this.selectedSignOff === 'not signed off tasks' && !task.ins_so);
        const levelMatches = this.selectedLevel === 'All' || task.level === parseInt(this.selectedLevel);
        const scopeMatches = this.selectedscope === 'All' || task.scope === this.selectedscope;
        const chapterMatches = this.selectedChapter === 'All' || task.chapt_no === this.selectedChapter;
        const categoryMatches = this.selectedCategory === 'All' || task.category === this.selectedCategory;
        const isexamMatches = this.selectedisexam === 'All' || task.is_exam === this.selectedisexam;
        return moMatches && completedMatches && levelMatches && SignedOffMatches && scopeMatches && chapterMatches && categoryMatches && isexamMatches;
      });
    },
    groupedTasks() {
  return Object.entries(
    this.filteredTasks.reduce((groups, task) => {
      const chapterName = this.getChapterName(task.chapt_no);
      if (!groups[task.chapt_no]) {
        groups[task.chapt_no] = { name: chapterName, tasks: [] };
      }
      groups[task.chapt_no].tasks.push(task);
      return groups;
    }, {})
  )
    .map(([chaptNo, group]) => ({
      chaptNo,
      ...group,
    }))
    .sort((a, b) => parseInt(a.chaptNo, 10) - parseInt(b.chaptNo, 10)); // Sort numerically by chapter number
},

paginatedTasks() {
  // Paginate the filtered tasks before grouping them by chapter
  const start = this.startIndex;
  const end = this.endIndex;

  // Get the paginated filtered tasks
  const paginatedFilteredTasks = this.filteredTasks.slice(start, end);

  // Now, group the tasks by chapter
  return Object.entries(
    paginatedFilteredTasks.reduce((groups, task) => {
      const chapterName = this.getChapterName(task.chapt_no);
      if (!groups[task.chapt_no]) {
        groups[task.chapt_no] = { name: chapterName, tasks: [] };
      }
      groups[task.chapt_no].tasks.push(task);
      return groups;
    }, {})
  )
    .map(([chaptNo, group]) => ({
      chaptNo,
      ...group,
    }))
    .sort((a, b) => parseInt(a.chaptNo, 10) - parseInt(b.chaptNo, 10)); // Sort numerically by chapter number
},
    uniqueChapters() {
      const chapters = this.tasks.map(task => task.chapt_no);
      return [...new Set(chapters)]; // This will remove any duplicates and return unique values.
    },
    uniqueMOs() {
      const MOs = this.tasks.map(task => task.manob);
      return [...new Set(MOs)];
    },
    uniqueLevels() {
      const levels = this.tasks.map(task => task.level);
      return [...new Set(levels)];
    },
    uniquescopes() {
      const scopes = this.tasks.map(task => task.scope);
      return [...new Set(scopes)];
    },
    uniquecategories() {
      const categories = this.tasks.map(task => task.category);
      return [...new Set(categories)];
    },
    uniqueisexam() {
      const isexams = this.tasks.map(task => task.is_exam);
      return [...new Set(isexams)];
    },
    totalPages() {
      return Math.ceil(this.filteredTasks.length / this.perPage);
    },
    startIndex() {
      return (this.currentPage - 1) * this.perPage;
    },
    endIndex() {
      const end = this.startIndex + this.perPage;
      return end < this.filteredTasks.length ? end : this.filteredTasks.length;
    },
  },
  ref: {
    logbookReport: LogbookReport
  },
  methods: {
    getChapterName(chapt_no) {
      const chapter = this.chapters.find((ch) => ch.chapt_no === chapt_no);
      return chapter ? `Chapter ${chapt_no}: ${chapter.name}` : `Chapter ${chapt_no}`;
    },
    async addSigToTasks() {
  try {
    const logbookId = this.$route.params.logbookId;
    const tasksToUpdate = [];

    // Filter tasks that need signature
    const filteredSigTasks = this.mergedTasks.filter(task => {
      return task.completed !== null && task.t_signature_id === null;
    });

    // Create an array of tasks to update
    for (const task of filteredSigTasks) {
      tasksToUpdate.push({
        logbook_id: parseInt(logbookId),
        task_id: parseInt(task.task_id),
        t_signature_id: parseInt(localStorage.getItem('sign_id'))
      });
    }

    console.log('sign_id');
    console.log(parseInt(localStorage.getItem('sign_id')));

    if (tasksToUpdate.length === 0) {
      console.log('No tasks require signature.');
      return;
    }

    const token = localStorage.getItem('token');
    const headers = { Authorization: `Bearer ${token}` };

    // Make an Axios POST request to the batchUpdate API endpoint
    const response = await axios.post('/api/logbookentry/batch-update', {
      logbook_entries: tasksToUpdate
    }, { headers });

    console.log("Success in updating logbook entries:", response);

    // Fetch updated data after successful batch update
    this.allSignatures();
    this.fetchData();

  } catch (error) {
    console.error('Error updating logbook entries:', error);
  }
},
    findSignatureUrl(signatureId) {
    const signature = this.signatures.find(sig => sig.id === signatureId);
    if (signature && signature.user_image) {
      return `data:image/jpeg;base64,${signature.user_image}`;
    } else {
      return null;
    }
  },
  findInstSignatureUrl(instSignatureId) {
    const signature = this.signatures.find(sig => sig.id === instSignatureId);
    if (signature && signature.user_image) {
      return `data:image/jpeg;base64,${signature.user_image}`;
    } else {
      return null;
    }
  },
    async setTimestamp(task) {
      const currentDate = new Date();
      const formattedDate = this.formatDate(currentDate);
      task.completed = formattedDate;
      task.isReadOnly = true;

      const logbookId = this.$route.params.logbookId;

      if (!navigator.onLine) {
        // Save to Localbase when offline
        db.collection('completedTasks').doc(String(task.task_id)).set({
          taskId: String(task.task_id),  // Convert task_id to string
          task_no: task.task_no,
          completed: formattedDate,
          logbookId: logbookId,
        }).then(() => {
          console.log('Task saved offline');
        });
      } else {
        try {
          const token = localStorage.getItem('token');
          const headers = { Authorization: `Bearer ${token}` };
          const response = await axios.put(`/api/logbookentry/${logbookId}/${task.task_id}`, {
            completed: formattedDate,
            t_signature_id: parseInt(localStorage.getItem('sign_id')),
          }, { headers });
          console.log("success in updating logbook entry:", response);
          // Handle the response if needed (e.g. check if the update was successful)
          if (parseInt(localStorage.getItem('sign_id'))) {
          this.allSignatures();
          const entryResult = await axios.get(`/api/logbookentry/${logbookId}`, { headers });
          this.logbookEntries = entryResult.data.tasks; 
          this.mergeData();
          }
        } catch (error) {
          console.error("Error updating logbook entry:", error);
        }
      }
    },
    onPageChange(newPage) {
      this.currentPage = newPage;
    },
    async syncData() {
      // Check if there's an internet connection
      if (navigator.onLine) {
        this.isSyncing = true;

        const tasksToSync = await db.collection('completedTasks').get();
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        if (tasksToSync.length === 0) {
          this.isSyncing = false;
          return; // exit function if there are no tasks to sync
        }

        let allTasksSynced = true;

        // Synchronize each task's completed status with the backend
        for (const task of tasksToSync) {
          try {
            const response = await axios.put(`/api/logbookentry/${task.logbookId}/${task.taskId}`, {
              completed: task.completed
            }, { headers });

            console.log("success in syncing task:", response);

            // If sync is successful, remove task from Localbase
            await db.collection('completedTasks').doc(task.taskId).delete();

          } catch (error) {
            console.error('Error syncing task:', error);
            allTasksSynced = false;
          }
        }

        this.isSyncing = false;

        if (allTasksSynced) {
          location.reload();
        }
      } else {
        console.log("No internet connection. Data sync postponed.");
      }
    },
    async fetchOfflineTasks() {
      if (!navigator.onLine) {
        try {
          this.offlineTasks = await db.collection('completedTasks').get();
          console.log(this.offlineTasks); // Log the offline tasks
        } catch (error) {
          console.error("Error fetching offline tasks:", error);
        }
      }
    },

    formatDate(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    async fetchData() {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        const logbookId = localStorage.getItem('logbook_id');

        const [logbookResponse, inslogbookEntryResponse, tasksResponse, taskAssessmentResponse] = await Promise.all([
          axios.get(`/api/logbooks/${logbookId}`, { headers }),
          axios.get(`/api/logbookentry/${logbookId}`, { headers }),
          axios.get(`/api/${logbookId}/tasks`, { headers }),
          axios.get(`/api/task_assessments/logbooks/${logbookId}`, { headers })
        ]);

        this.logbookName = logbookResponse.data.name;
        this.tasks = tasksResponse.data.tasks;
        this.logbookEntries = inslogbookEntryResponse.data.tasks;
        this.MTCount = this.tasks.filter(task => task.manob === 'MT').length;
        this.task_assessments = taskAssessmentResponse.data;

        this.mergeData(); // Prepare mergedTasks for display
      } catch (error) {
        console.error('An error occurred while fetching data:', error);
      }
    },

    mergeData() {
      this.mergedTasks = this.tasks.map(task => {
        const matchingEntry = this.logbookEntries.find(entry => entry.task_id === task.task_id);
        const matchingAssessment = this.task_assessments.find(assessment => assessment.task_id === task.task_id);
        return {
          ...task,
          completed: matchingEntry ? matchingEntry.completed : null,
          ins_so: matchingEntry ? matchingEntry.ins_so : null,
          enabled: matchingEntry ? matchingEntry.enabled === 1 : 0,
          troubleshoot: matchingEntry ? matchingEntry.troubleshoot : null,
          instructor_id: matchingEntry ? matchingEntry.instructor_id : null,
          is_selected: matchingEntry ? matchingEntry.is_selected : null,
          is_submitted: matchingAssessment ? matchingAssessment.is_submitted : null,
          t_signature_id: matchingEntry ? matchingEntry.t_signature_id : null,
          ins_signature_id: matchingEntry ? matchingEntry.ins_signature_id : null,
        };
      });
      this.completedMTCount = this.mergedTasks.filter(task => task.manob === 'MT' && task.completed).length;
      console.log('merged', this.mergedTasks);
    },
    shouldShowSignOffButton(task) {
      return (
        !task.complete && // Check if complete is empty (not signed off)
        task.work_order_no !== null &&
        task.work_order_no.toString().trim() !== '' &&
        task.task_detail !== null &&
        task.task_detail.toString().trim() !== '' &&
        task.category !== null &&
        task.category.toString().trim() !== '' &&
        task.ATA !== null &&
        task.ATA.toString().trim() !== ''
      );
    },
    updateCurrentTime() {
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');
      this.currentTime = `${hours}:${minutes}:${seconds}`;
    },
    async fetchAllNotes() {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      axios.get(`/api/trainee-notes`, { headers })
        .then(response => {
          console.log('Fetched all notes:', response.data);

          // Assuming response.data is an array of notes
          response.data.forEach(note => {
            this.allTraineeNotes[note.task_id] = note.content;
          });
        })
        .catch(error => {
          console.error("Error fetching all notes:", error);
        });
    },
    async saveNotes(taskId, content) {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      };
      console.log("task, content", taskId, content)
      if (this.notes[taskId]) {
        // Update note
        axios.put(`/api/traineeNotes/${taskId}`, { content }, { headers })
          .then(response => {
            this.notes[taskId] = response.data.content; // Update the local notes object
          })
          .catch(error => {
            console.error('Error updating note:', error);
          });
      } else {
        // Create new note
        const userId = localStorage.getItem('trainee_id');
        axios.post('/api/trainee-notes', { task_id: taskId, content, user_id: userId }, { headers })
          .then(response => {
            this.notes[taskId] = response.data.content; // Update the local notes object
            console.log("notes", this.notes[taskId])
          })
          .catch(error => {
            console.error('Error creating note:', error);
          });
      }
    },
    async openEditor(taskId) {
      this.currentTaskId = taskId;
      this.showQuillEditor = true;
    },
    closeQuillEditor(payload) {
      this.saveNotes(payload.taskId, payload.content);
      this.fetchAllNotes();
      this.showQuillEditor = false;
    },

    async fetchAllPublicNotes() {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      // Adjust the endpoint to fetch all public notes (not specific to a taskId)
      const endpoint = `/api/instructor-public-notes/`;
      console.log("Fetching all public notes");

      try {
        const response = await axios.get(endpoint, { headers });
        if (response.data && Array.isArray(response.data)) {
          response.data.forEach(note => {
            this.allPublicNotes[note.task_id] = note.content;
          });
        }
      } catch (error) {
        console.error("Error fetching all public notes:", error);
      }
    },
    openNotesModal(taskId) {
      this.currentTaskId = taskId;
      this.notesExist = !!this.allPublicNotes[taskId];
      this.currentNote = stripHtml(this.allPublicNotes[taskId]) || "No Instructor Notes for this task";
      this.showNotesModal = true;
      console.log("notes: ", this.allPublicNotes[taskId]);
    },
    closeNotesModal() {
      this.showNotesModal = false;
    },
    openTroubleshootModal(task) {
      if (!task.enabled) {
        alert('This task is not enabled.');
        return; // Exit the function early
      }
      this.showTroubleshootModal = true;
      const entry = this.logbookEntries.find(entry => entry.task_id === task.task_id);
      this.currentTaskExplanation = task.task_explanation;
      this.currentChaptNo = task.chapt_no;
      if (entry) {
        this.currentTaskId = task.task_id;  // Make sure 'task_id' is the correct property name
        this.currentTroubleshoot = entry.troubleshoot ? entry.troubleshoot.replace(/<br\s*\/?>/gi, '\n') : '';
      } else {
        console.error('No logbook entry found for task id:', task.task_id);
        this.currentTroubleshoot = '';  // Set to empty string if not found
      }
    },
    closeTroubleshootModal() {
      this.showTroubleshootModal = false;
      this.fetchData();
    },
    fetchTaskAssessmentData() {
  const token = localStorage.getItem('token');
  const headers = { Authorization: `Bearer ${token}` };
  const logbookId = parseInt(this.logbookId);

  axios.get(`/api/task_assessments/logbooks/${logbookId}`, { headers })
    .then(response => {
      this.task_assessments = response.data;
      console.log(this.task_assessments);
    })
    .catch(error => {
      console.error('There was an error fetching the task assessment:', error);
    });
},
async fetchTraineeTaskAssessment(task) {
  try {
    const token = localStorage.getItem('token');
    const headers = { Authorization: `Bearer ${token}` };
    const logbookId = this.logbookId;

    const response = await axios.get(`/api/task_assessments/${logbookId}/${task.task_id}`, { headers });
    const task_assessment = response.data;

    if (task_assessment) {
      this.taskAssessmentId = task_assessment.id;
      this.fetchedTaskAssessmentData = task_assessment;
      console.log('Task assessment fetched:', task_assessment);
    } else {
      console.warn('No task assessment data found');
    }
  } catch (error) {
    console.error('Failed to fetch task assessment:', error);
    this.showTaskAssessmentModal = false;
  }
},
    openTaskAssessmentModal(task) {
      this.fetchedTaskAssessmentData = null;
      this.tasksCompleted = this.completedMTCount || 0;
      this.selectedTask = task;
      console.log('Selected Task:', this.selectedTask);
      if (task.is_submitted === 2 || task.is_submitted === 3) {
        this.fetchTraineeTaskAssessment(task);
        this.showTaskAssessmentModal = true;
      } else if (task.is_selected && (task.is_submitted === null || task.is_submitted === 0 || task.is_submitted === 1)) {
        alert('Instructor has not submitted the assessment for this task, try again later.')
        return;
      } else {
        alert('The instructor has not selected this task for assessment.')
        return;
      }

    },
    closeTaskAssessmentModal() {
      this.showTaskAssessmentModal = false;
      this.fetchData();
    },
    openTaskDescriptionModal(task) {
      if (task.is_exam) {
        this.openTroubleshootModal(task);
      } else {
        this.currentTaskId = task.task_id;
        this.currentTaskExplanation = task.task_explanation;
        this.showTaskDescriptionModal = true;
      }
    },
    closeTaskDescriptionModal() {
      this.showTaskDescriptionModal = false;
    },
    openTaskLongFormModal(task) {
      this.currentTaskId = task.task_id;
      this.showTaskLongFormModal = true;
    },
    closeTaskLongFormModal() {
      this.showTaskLongFormModal = false;
    },
    openTraineeProgressModal() {
      this.showTraineeProgressModal = true;
    },
    closeTraineeProgressModal() {
      this.showTraineeProgressModal = false;
    },
    closeSignatureModal() {
      this.showSignatureModal = false;
      this.addSigToTasks();
    },
    openSignatureModal(event) {
      try {
        this.showSignatureModal = true;
      } catch (error) {
        console.error('Error opening signature modal:', error);
        // If `error` is actually an Event object, this will help surface more info.
        console.error('Event details:', event);
      }
    },
    goHome() {
      this.$router.push({ name: 'TraineeHome' }) // Go back to the home page
    },
    confirmSignOff(task) {
      if (!this.user_id) {
        console.error("Attempt to sign off without user_id.");
        return;  // Prevent signing off without a user_id
      }
       if (confirm('Are you sure you want to sign off?')) {
        this.setTimestamp(task);
        //window.location.reload();
      }
    },
    async createPDFReport() {
      const logbookName = this.logbookName;
      LogbookReport.methods.generatePDF(this.filteredTasks, logbookName);
    },
    updateOnlineStatus() {
      this.isOffline = !navigator.onLine;
    },
    fetchEvaluationData() {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      const logbookId = this.logbookId;

      axios.get(`/api/evaluations?logbook_id=${logbookId}`, { headers })
        .then(response => {
          const evaluation = response.data[0];
          if (evaluation) {
            this.evaluationId = evaluation.id; // Store the fetched evaluation ID
            this.evaluationIsSubmitted = evaluation.is_submitted > 0;
            this.fetchedEvaluationData = evaluation; // Pass the evaluation data directly
            console.log(this.fetchedEvaluationData)
          } else {
            this.evaluationIsSubmitted = false;
          }
        })
        .catch(error => {
          console.error('There was an error fetching the evaluation:', error);
        });
    },

    openEvaluationDisplayModal() {
      this.showEvaluationDisplayModal = true;
      this.tasksCount = this.MTCount;
      this.tasksCompleted = this.completedMTCount || 0;
      this.fetchEvaluationData();
    },
    closeEvaluationDisplayModal() {
      this.showEvaluationDisplayModal = false;
      this.fetchEvaluationData();
    },
    allSignatures() {
  const token = localStorage.getItem('token');
  const headers = { Authorization: `Bearer ${token}` };

  axios.get(`/api/books`, { headers })
    .then(response => {
      // Access the data from the response
      const responseData = response.data;
      
      // Do something with the data, for example, assign it to a variable or log it
      this.signatures = responseData;
      console.log('all');
      console.log(responseData);
    })
    .catch(error => {
      // Handle any errors that occurred during the request
      console.error('Error fetching signatures:', error);
    });
    this.fetchCurrentSignatureId();
},
async fetchCurrentSignatureId() {
  const token = localStorage.getItem('token');
  const headers = { Authorization: `Bearer ${token}` };

try {
  const response = await axios.get(`/api/current-signature/${this.user_id}`, { headers })

  localStorage.setItem('sign_id', response.data.id);
  this.signatureId = localStorage.getItem('sign_id');
} catch (error) {
        console.error(`Error fetching signature for trainee:`, error);
      }

},
    fetchSignature() {
      if (!this.user_id) {
        console.error("user_id is undefined when fetching signature.");
        return;
      }

      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      axios.get(`/api/store_image/fetch_image/${this.user_id}`, {
        responseType: 'blob',
        headers: headers
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          this.signatureUrl = url;
        })
        .catch(error => {
          if (error.response) {
            console.error('Error status:', error.response.status);
            console.error('Error data:', error.response.data);
          } else if (error.request) {
            console.error('Error request:', error.request);
          } else {
            console.error('Error message:', error.message);
          }
        });
    },
    fetchTraineeSignature(id) {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      axios.get(`/api/signatures/${id}`, {
        responseType: 'blob',
        headers: headers
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          this.signatureUrl = url;
        })
        .catch(error => {
          if (error.response) {
            console.error('Error status:', error.response.status);
            console.error('Error data:', error.response.data);
          } else if (error.request) {
            console.error('Error request:', error.request);
          } else {
            console.error('Error message:', error.message);
          }
        });
    },
    async fetchAllSignatures() {
      const idsRaw = localStorage.getItem('instructorIds');
      console.log("Raw instructor IDs from localStorage:", idsRaw);

      let instructorIds;
      try {
        instructorIds = JSON.parse(idsRaw);
      } catch (error) {
        console.error("Error parsing instructorIds from localStorage:", error);
        instructorIds = [];
      }

      if (!Array.isArray(instructorIds)) {
        console.error("Parsed instructorIds is not an array:", instructorIds);
        instructorIds = [];
      }

      console.log("Parsed instructor IDs:", instructorIds);
      instructorIds.forEach(instructorId => {
        if (instructorId === undefined) {
          console.error("Skipping fetch for undefined instructorId");
        } else {
          this.fetchInstructorSignature(instructorId);
        }
      });
    },

    async fetchInstructorSignature(instructorId) {
      // if (!this.user_id || this.instructorSignatures[instructorId]) {
      //   return; // Exit if no user_id or signature already fetched
      // }
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      try {
        const sigResponse = await axios.get(`/api/store_image/fetch_image/${instructorId}`, {
          responseType: 'blob',
          headers: headers
        });
        const url = window.URL.createObjectURL(new Blob([sigResponse.data]));
        this.instructorSignatures[instructorId] = url;
      } catch (error) {
        console.error(`Error fetching signature for instructor ${instructorId}:`, error);
        this.instructorSignatures[instructorId] = null;
      }
    },
    async fetchTrainee () {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      const traineeId = localStorage.getItem('user_id');

      try {
        const traineeResponse = await axios.get(`/api/users/${traineeId}`, { headers });
        this.trainee = traineeResponse.data;
        console.log('trainee', this.trainee);
      } catch (error) {
        console.error(`Error fetching data for trainee ${traineeId}:`, error);
      }
    },

    async initializeData() {
      try {
        await Promise.all([
          this.fetchData(),
          this.fetchAllNotes(),
          this.fetchAllPublicNotes(),
          this.fetchEvaluationData(),
          this.fetchTrainee()
        ]);

        await this.fetchSignature(),
          await this.fetchInstructorSignatures(),

          await this.fetchOfflineTasks(); // Assuming this needs to be run regardless of the above tasks' completion.
      } catch (error) {
        console.error("Error initializing data:", error);
      }
    },
    async fetchChapters () {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      try {
        const chapterResponse = await axios.get(`/api/chapters`, { headers });
        this.chapters = chapterResponse.data;
        console.log('chapters', this.chapters);
      } catch (error) {
        console.error(`Error fetching data for chapters:`, error);
      }
    },
  },
  async mounted() {
    this.user_id = localStorage.getItem('user_id');  // Retrieve and store user_id
    if (!this.user_id) {
      console.error("user_id not found");
      return;  // Handle the absence of user_id appropriately
    }

    this.updateCurrentTime();
    setInterval(this.updateCurrentTime, 1000);

    window.addEventListener('online', this.syncData);
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);

    await this.initializeData();
    this.fetchAllSignatures();
    this.allSignatures();
    this.fetchChapters();
  },
  beforeMount() {
    // Remove the event listener when the component is destroyed
    window.removeEventListener('online', this.syncData);
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
}

function stripHtml(html) {
  if (!html) return "";
  return html.replace(/<\/?[^>]+(>|$)/g, "");
}

</script>

<style scoped>
.data-table-trainee .module,
.data-table-trainee .level,
.data-table-trainee .location,
.data-table-trainee .scope {
  width: 2%;
  /* adjust as needed */
}

.tee-pagination-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  margin-bottom: -10px;
  z-index: 0;
  margin-top: 30px;
}

img {
  width: 100%;
  height: 30%;
  border: 1px solid lightgray;
}

.signature-container,
.ins-signature-container {
  width: 100px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
}

.ins-signature-image,
.signature-image {
  width: 100%;
  height: 100%;
}

.chapter-name-cell {
  background-color: rgba(38, 65, 116, 0.884);
  color: white;
  text-align: center;
  
}
</style>