<template>
    <div>
        <page-header></page-header>
        <!-- Heading and Filename -->
        <div>
            <table class="header-section">
                <td style="width:30%;">
                    IMPORT TASKS (DRY RUN)
                </td>
                <td style="color: grey;">
                    {{ fileName }}
                </td>
            </table>
        </div>
        <button class="back-button" @click="goBack">Back</button>
        <!-- Dry Run Information -->
        <div class="info-box">
            <span class="dryrun-span" title="dry run is a simulation where the database stays unaffected">Dry run</span> has
            been completed
            <li>Total records: {{ importedTasks.length }}</li>
            <li>Invalid records: {{ invalidTaskCount }}</li>
            <li>Valid tasks: {{ importedTasks.length - invalidTaskCount }}</li>
            <p></p>
            <li>Tasks added to the database: 0</li>
        </div>
        <div class="issuesDisplay">
            <div v-if="Object.keys(groupedIssues).length">
                <div v-for="(issues, index) in groupedIssues" :key="index" class="grey-line">
                    <!-- Display all issues for this index -->
                    <ul>
                        <br>
                        <li style="font-weight:bold">
                            Task (Row: {{ parseInt(index) + 1 }})
                        </li>
                        <ul>
                            <li style="font-weight:bold" v-for="(issue, issueIndex) in issues" :key="issueIndex">
                                {{ issue.message }}
                            </li>
                        </ul>
                    </ul>
                    <i style="color:grey; margin-left:20px;">Provided fields</i>
                    <div class="issue-list">
                            <!-- Check if there is an error in importedTasks -->
                                <ul v-if="importedTasks[parseInt(index - 1)] && importedTasks[parseInt(index - 1 )].error">
                                    <li v-for="(field, fieldIndex) in importedTasks[parseInt(index - 1)].row" :key="fieldIndex">
                                        {{ field }}
                                    </li>
                                </ul>
                            <!-- If no error, display the regular fields -->
                        <ol v-else>
                            <li><strong>Chapter No: &nbsp;</strong> {{ importedTasks[parseInt(index - 1)]?.chapt_no || '' }}</li>
                            <li ><strong>Task no: &nbsp;</strong> {{ importedTasks[parseInt(index - 1)]?.task_no || '' }}</li>
                            <li><strong>M/O: &nbsp;</strong> {{ importedTasks[parseInt(index - 1)]?.manob || '' }}</li>
                            <li><strong>Scope: &nbsp;</strong> {{ importedTasks[parseInt(index - 1)]?.scope || '' }}</li>
                            <li><strong>Description: &nbsp;</strong> {{ importedTasks[parseInt(index - 1)]?.description || '' }}</li>
                            <li><strong>Reference: &nbsp;</strong> {{ importedTasks[parseInt(index - 1)]?.reference || '' }}</li>
                            <li><strong>Skill: &nbsp;</strong> {{ importedTasks[parseInt(index - 1)]?.skill || '' }}</li>
                            <li><strong>P1: &nbsp;</strong> {{ importedTasks[parseInt(index - 1)]?.P1 || '' }}</li>
                            <li><strong>P2: &nbsp;</strong> {{ importedTasks[parseInt(index - 1)]?.P2 || '' }}</li>
                            <li><strong>Category: &nbsp;</strong> {{ importedTasks[parseInt(index - 1)]?.category || '' }}</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import pageHeader from '@/components/Examiner/Header.vue';

export default {
    data() {
        return {
            productId: this.$route.params.productId,
            csvFileName: "",  // Initialized as an empty string
            entireFileRows: [],
            previewRows: [],
        };
    },
    components: {
        pageHeader,
    },
    computed: {
        issues() {
            return this.$store.state.issues;
        },
        importedTasks() {
            return this.$store.state.importedTasks;
        },
        invalidTaskCount() {
            const uniqueIssueTasks = new Set(this.issues.map(issue => issue.index));
            return uniqueIssueTasks.size;
        },
        validTaskCount() {
            const uniqueImportedTasks = new Set(this.importedTasks.map(task => task.task_no + '-' + task.chapt_no));
            return uniqueImportedTasks.size - this.invalidTaskCount;
        },
        groupedIssues() {
            return this.issues.reduce((acc, issue) => {
                const index = issue.index; // Assume each issue has an index that refers to its position in importedTasks
                if (!acc[index]) {
                    acc[index] = [];
                }
                acc[index].push(issue);
                return acc;
            }, {});
        },
        fileName() {
            return this.$store.state.fileName;
        }
    },
    methods: {
        goBack() {
      this.$router.push({ name: 'importTasks' });
    },
        displayIssues() {
            console.log("Import Issues:", this.issues);
        },
    },
    mounted() {
        console.log("importedTasks:", this.importedTasks);
        console.log("issues from Vuex:", this.$store.state.issues);
    }
}
</script>


 
<style scoped>
    .grey-line {
        border-top: 1px solid rgb(179, 179, 179); /* Add a grey border on the top to create the line effect */
        margin-top: 10px; /* Adjust the margin as needed */
        margin-bottom: 10px; /* Adjust the margin as needed */
        max-width: 100%;
    }
.header-section {
    width: 99%;
    text-align: left;
    height: 90%;
    margin-left: 1%;
}
.header-section td {
    font-size: 28px;
}
.info-box {
    border: 1px solid #ecb1b1;
    padding: 20px;
    margin: 0px 20px;
    color: #ca3f4b;
    font-weight: bold;
    background-color: #F8D7DA;
    width: 60%;
    height: 180px;
    text-align: left;
}
.issuesDisplay {
    text-align: left;
    margin-top: 20px;
}
.issue-list {
    margin-left: 1%;
    background-color: rgb(250, 248, 248);
    width: 98%;
}
.dryrun-span {
    border: none;
}
.back-button {
  position: absolute;
  /* Adjust the top position as needed */
  right: 60px;
  /* Adjust the right position as needed */
  width: 100px;
  border-radius: 5px;
  background-color: #c1e0bf;
  font-size: 14px;
  border: 2px solid black;
  padding: 3px;
}

.back-button:hover {
  background-color: #abc7a9;
  cursor: pointer;
}
</style>
  