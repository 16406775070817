<template>
    <div class="Task-modal-overlay" @click="close">
        <div class="Task-modal-content" @click.stop>
                <div class="Task-modal-header">
        <div class="Task-modal-dialog" @click.stop>
                    <h5 class="Task-modal-title">Task ID: {{ taskId }}</h5>
                </div>   
            </div>  
        <table class="data-table">
        <tbody>
          <tr>
            <td class="fieldset-column"><strong>Task No:</strong></td>
            <td>{{ taskData.task_no  }}</td>
          </tr>
          <tr>
            <td><strong>Chapter No:</strong></td>
            <td>{{ taskData.chapt_no }}</td>
          </tr>
          <tr>
            <td><strong>Description:</strong></td>
            <td>{{ taskData.description}}</td>
          </tr>
          <tr>
            <td><strong>Skill:</strong></td>
            <td>{{ taskData.skill }}</td>
          </tr>
          <tr>
            <td><strong>Examinable:</strong></td>
        <td>{{ taskData.is_exam ? 'True' : 'False' }}</td>
          </tr>
          <tr>
            <td><strong>Mandatory / Obligatory:</strong></td>
            <td>{{ taskData.manob }}</td>
          </tr>
          <tr>
            <td><strong>Practical Element ID:</strong></td>
            <td>{{ taskData.prod_id }}</td>
          </tr>
          <tr>
            <td><strong>Reference:</strong></td>
            <td>{{ taskData.reference }}</td>
          </tr>
          <tr>
            <td><strong>Scope:</strong></td>
            <td>{{ taskData.scope }}</td>
          </tr>
          <tr>
            <td><strong>Category:</strong></td>
            <td>{{ taskData.category }}</td>
          </tr>
          <tr>
            <td><strong>Enabled:</strong></td>
            <td>{{ taskData.enabled }}</td>
          </tr>
          <tr>
            <td><strong>P1:</strong></td>
            <td>{{ taskData.P1 }}</td>
          </tr>
          <tr>
            <td><strong>P2:</strong></td>
            <td>{{ taskData.P2 }}</td>
          </tr>
          <tr>
            <td><strong>STD:</strong></td>
            <td>{{ taskData.STD }}</td>
          </tr>
          <tr>
            <td><strong>Completed:</strong></td>
            <td>{{ taskData.completed != null ? taskData.completed : 'False' }}</td>
          </tr>
          <tr>
            <td><strong>Instructor Sign Off:</strong></td>
            <td>{{ taskData.ins_so != null ? taskData.ins_so : 'False' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
        </div>
</template>

<script>
export default {
    props: {
        taskId: {
            type: [String, Number],
            required: true
        },
        logbookId: {
            type: [String, Number],
            required: true
        },
        mergedTasks: {
    type: Array,
    required: true
  }
    },
    data() {
        return {
            showTaskLongFormModal: true,
            taskData: [],
        };
    },

    methods: {
        close() {
            this.$emit('close');
        }, 
        async getTaskData(taskId) {
            console.log(taskId);
    // Find the task in mergedTasks that matches the taskId
    const task = this.mergedTasks.find(task => task.task_id === taskId);

    if (task) {
        // If the task is found, assign its data to taskData
        this.taskData = task;
        console.log(this.taskData);
    } else {
        // Handle the case where task is not found
        console.error(`Task with ID ${taskId} not found.`);
    }
  },

    },
    async mounted() {
    this.getTaskData(this.taskId);

    console.log('merged');
    console.log(this.mergedTasks);
  },
}


</script>

<style scoped>
.Task-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(146, 146, 146, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.Task-modal-content {
    margin-top: 40px;
    background-color: rgb(239, 244, 255);
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    max-width: 50%; /* You can adjust the maximum width as needed */
    max-height: 87vh; /* You can adjust the maximum height as needed */
    overflow-y: auto;
    border: 2px solid rgb(83, 83, 83);
    margin-top: 80px;
}

.Task-modal-title {
    margin-bottom: 10px;
    float: left;
    margin-left: 20px;
    color: black;
}

.data-table {
    border-collapse: collapse;
    background-color: rgb(253, 253, 255);
    border: 1px solid grey;
    box-shadow: 0;
}

.data-table td, .data-table th {
    border: none;
    padding: 8px;
    width: 250px;
}
</style>