<template>
    <div class="Description-modal-overlay" @click="close">
        <div class="Description-modal-dialog" @click.stop>
            <div class="Description-modal-content">
                <div class="Description-modal-header">
                    <h5 class="Description-modal-title">Task ID: {{ taskId }}</h5>
                    <h5>{{ taskExplanation }} </h5>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TaskDescriptionModal',
    props: {
        taskId: {
            type: [String, Number],
            required: true
        },
        logbookId: {
            type: [String, Number],
            required: true
        },
        taskExplanation: String
    },
    data() {
        return {
            showTaskDescriptionModal: true,
        };
    },

    methods: {
        close() {
            this.$emit('close');
        },        
    }
}


</script>

<style>
.Description-modal-overlay {
    position: fixed;
    padding-top: 1%;
    left: 0;
    background-color: rgba(97, 94, 94, 0.5);
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.Description-modal-content {
    background-color: #ffffff;
    height: 30%;
    width: 90%;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    flex-direction: column;
}


</style>