<template>
      <head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
  </head>
  <div>
    <Header />

    <div class="current-time-container">
      <div class="filter-container">
        <select v-model="productFilter" id="productFilter" class="filter-select">
          <option value="">All Practical Elements</option>
          <option v-for="product in products" :key="product.product_id" :value="product">{{ product.name }}</option>
        </select>

        <input v-model="projectSearch" type="text" placeholder="Search Project Name" />
        <input v-model="startDateSearch" type="text" placeholder="Search Start Date" />
        <input v-model="endDateSearch" type="text" placeholder="Search End Date" />

        <button class="clear-filter-button" @click="clearFilters">Clear Filters</button>


      </div>
      
    </div>

    <h1>Sessions</h1>

    <div class="pagination-button-container">
      <div class="pagination-container">
        <Pagination :totalPages="totalPages" :perPage="perPage" :currentPage="currentPage" @page-change="onPageChange" />        
        <div class="pagination-info">
        Showing {{ startIndex + 1 }}-{{ endIndex }} of {{ filteredProjectsLength }} Sessions
      </div>
      </div>
    </div>

    <edit-project-modal v-if="showEditProject" @close="closeEditProject"></edit-project-modal>


    <div class="container-fluid-project">
      <table class="data-table-trainee">
        <thead>
          <tr>
            <th @click="sortBy('project_id')" class="projectID">
              ID
              <span v-if="sortField === 'project_id'" v-html="getFieldArrow()"></span>
            </th>
            <th @click="sortBy('name')" class="wider-column">
              Name
              <span v-if="sortField === 'name'" v-html="getFieldArrow('name')"></span>
            </th>
            <th class="projectID">P.E. ID</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th class="skinny-column">Edit</th>
            <th class="skinny-column-edit">Select</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in filteredProjects" :key="item.project_id">
            <td>{{ item.project_id }} </td>
            <td>{{ item.name }}</td>
            <td>{{ item.prod_id }} </td>
            <td>{{ item.start_date }}</td>
            <td>{{ item.end_date }}</td>
            <td class="center-icons">
              <i class="fas fa-edit edit-icon" @click="viewEditProject(item.project_id)"></i>
              <i class="fas fa-trash-alt delete-icon" @click="deleteProject(item.project_id)"></i>
            </td>
              <td class="center-icons" @click="selectRadioButton">
              <input type="radio" name="selectedProject" v-model="selectedProjectId" :value="item.project_id"
                >
            </td>
          </tr>
          <tr v-if="projects.length === 0">
          <td colspan="100" style="text-align: center;">No Matching Sessions</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
<div>
  <button class="btn btn-primary rounded" id=addProjectButton @click="goAddProject">Add Session</button>
  <button class="btn btn-primary rounded" id=addTraineeButton @click="assignTrainees(selectedProjectId)">Assign Trainee(s)</button>
  <button class="btn btn-primary rounded" id=addInstructorButton @click="assignInstructors(selectedProjectId)">Assign Instructor(s)</button>
  <!-- <button class="btn btn-primary rounded" id=addAssessorButton @click="assignAssessors(selectedProjectId)">Assign Assessor(s)</button> -->
    </div>
</template>
  
<script>
import Header from '../Header.vue';
import axios from 'axios';
import Pagination from '../TablePagination.vue';
import EditProjectModal from './EditProjectModal.vue';

export default {
  name: 'ViewProjects',

  components: {
    Pagination,
    Header,
    EditProjectModal,
  },

  data() {
    return {
      projects: [],
      products: [],
      sortField: 'project_id',
      sortDirection: 'asc',
      currentPage: 1,
      perPage: 10,
      totalPages: 0,
      productFilter: '', 
      projectSearch: '',
      startDateSearch: '',
      endDateSearch: '',
      courses: [],
      showEditProject: false,
      filteredProjectsLength: 0,
      selectedProjectId: '',
      showSelectProjectError: false,
    };
  },

  watch: {
    productFilter(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    projectSearch(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    startDateSearch(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    endDateSearch(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
  },

  async mounted() {

    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      let result = await axios.get('/api/projects', { headers });
      this.projects = result.data;

      console.log(this.projects)

      // Extract and populate unique course names
      let productResult = await axios.get('/api/products', { headers });
      this.products = productResult.data;

      this.totalPages = Math.ceil(this.projects.length / this.perPage);
    } catch (error) {
      console.error('An error occurred while fetching data:', error);
    }
  },

  methods: {
    selectRadioButton() {
      // Find the radio button inside the clicked <td>
      var radioButton = event.currentTarget.querySelector('input[type="radio"]');

      // Check the radio button if it exists
      if (radioButton) {
        radioButton.click();
      }
    },
    sortBy(field) {
      if (this.sortField === field) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortField = field;
        this.sortDirection = 'asc';
      }

      this.projects.sort((a, b) => {
        const valueA = a[field];
        const valueB = b[field];

        if (typeof valueA === 'number' && typeof valueB === 'number') {
          return (this.sortDirection === 'asc' ? 1 : -1) * (valueA - valueB);
        } else if (typeof valueA === 'string' && typeof valueB === 'string') {
          return (this.sortDirection === 'asc' ? 1 : -1) * valueA.localeCompare(valueB);
        } else {
          return 0;
        }
      });
    },
    goAddProject() {
      this.$router.push({ name: 'AddProject' })
    },
    assignTrainees(project_id) {
      if (!this.selectedProjectId) {
        window.alert('You must select a Session');
        console.log(`No Session selected ${this.showSelectProjectError}`)
      } else {
        this.showSelectProjectError = false;
      localStorage.setItem('project_id', project_id);
      this.$router.push({ name: 'AssignTrainees', params: { project_id } });
      }
    },
    assignInstructors(project_id) {
      if (!this.selectedProjectId) {
        window.alert('You must select a Session');
        console.log(`No Session selected ${this.showSelectProjectError}`)
      } else {
        this.showSelectProjectError = false;
      localStorage.setItem('project_id', project_id);
      this.$router.push({ name: 'AssignInstructors', params: { project_id } });
      }
    },
    assignAssessors(project_id) {
      if (!this.selectedProjectId) {
        window.alert('You must select a Session');
        console.log(`No Session selected ${this.showSelectProjectError}`)
      } else {
        this.showSelectProjectError = false;
      localStorage.setItem('project_id', project_id);
      this.$router.push({ name: 'AssignAssessors', params: { project_id } });
      }
    },
    getFieldArrow(field = 'project_id') {
      if (this.sortField === field) {
        return this.sortDirection === 'asc' ? '▲' : '▼';
      } else {
        return '';
      }
    },
    onPageChange(page) {
      this.currentPage = page;
      this.calculateTotalPages();
    },
        // Add a method to handle changes in the search input field
    onProjectSearch() {
      this.currentPage = 1; // Reset currentPage to 1 when search query changes
    },
    onStartDateSearch() {
      this.currentPage = 1; // Reset currentPage to 1 when search query changes
    },
    onEndDateSearch() {
      this.currentPage = 1; // Reset currentPage to 1 when search query changes
    },
    viewEditProject(project_id) {
      localStorage.setItem('project_id', project_id);
            this.showEditProject = true; // Show the modal when the button is clicked
        },
        closeEditProject() {
            this.showEditProject = false; // Close the modal
        },
        calculateTotalPages() {
      this.computeFilteredProjects();
      this.totalPages = Math.ceil(this.filteredProjectsLength / this.perPage);
    },
    computeFilteredProjects() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      const filteredProjects = this.projects.filter((project) => {

        const projectName = project.name.toLowerCase();
        const projectNameMatch = projectName.includes(this.projectSearch.toLowerCase()) || this.projectSearch === '';

        const startDate = project.start_date.toLowerCase();
        const startDateMatch = startDate.includes(this.startDateSearch.toLowerCase()) || this.startDateSearch === '';

        const endDate = project.end_date.toLowerCase();
        const endDateMatch = endDate.includes(this.endDateSearch.toLowerCase()) || this.endDateSearch === '';

        const productMatch = this.productFilter === '' || project.prod_id === this.productFilter.product_id;

        return projectNameMatch && startDateMatch && endDateMatch && productMatch;

      });
      this.filteredProjectsLength = filteredProjects.length
      return filteredProjects.slice(startIndex, startIndex + this.perPage);
    },

    clearFilters() {
      // Clear all filter-related data properties
      this.productFilter = '';
      this.projectSearch = '';
      this.startDateSearch = '';
      this.endDateSearch = '';

      // Recalculate the total pages and update the data
      this.calculateTotalPages();
    },
    deleteProject(project_id) {
  if (confirm("Are you sure you want to delete this Session?")) {
    if (confirm("This action will PERMANENTLY delete the Session from the database. Do you wish to continue?")) {
      // If the user confirms the deletion, send a request to delete the Session.
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      axios
        .delete(`/api/projects/${project_id}`, { headers })
        .then(response => {
          if (response.status === 200) {
            // Remove the deleted project from the local data
            this.projects = this.projects.filter(project => project.project_id !== project_id);

            window.alert('Session deleted Successfully');
          }
        })
        .catch(error => {
          // Check if the error is due to a foreign key constraint
          if (error.response && error.response.status === 500) {
            const errorMessage = error.response.data.message;
            const isForeignKeyConstraintError = errorMessage.includes('SQLSTATE[23000]') && errorMessage.includes('Integrity constraint violation: 1451');

            if (isForeignKeyConstraintError) {
              window.alert('This Session cannot be deleted because it contains one or more logbooks.');
            } else {
              window.alert('An error occurred while deleting the Session. Please try again.');
            }
          } else {
            console.error('An unexpected error occurred:', error);
            window.alert('An unexpected error occurred. Please try again.');
          }
        });
    }
  }
},

  },
  computed: {
    startIndex() {
      return (this.currentPage - 1) * this.perPage;
    },
    endIndex() {
      const end = this.startIndex + this.perPage;
      return end < this.filteredProjectsLength ? end : this.filteredProjectsLength;
    },
    filteredProjects() {
      return this.computeFilteredProjects();
    },

  },


}
</script>
  
  
<style scoped>

span:hover {
  cursor: pointer;
  color: #374380;
}

button.btn#addProjectButton {
  padding: 10px 10px;
  font-size: 16px;
  width: 180px;
  height: 40px;
  margin-top: 0;
  margin-bottom: 15px;
  margin-right: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  float: right;
}

button.btn#addProjectButton:hover {
  background-color: #0c1a61;
  transition: transform 0.2s, box-shadow 0.2s;
}

button.btn#addTraineeButton {
  padding: 10px 10px;
  font-size: 16px;
  width: 160px;
  height: 40px;
  margin-top: 0;
  margin-bottom: 15px;
  margin-right: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  float: right;
}

button.btn#addTraineeButton:hover {
  background-color: #0c1a61;
  transition: transform 0.2s, box-shadow 0.2s;
}

button.btn#addInstructorButton {
  padding: 10px 10px;
  font-size: 16px;
  width: 160px;
  height: 40px;
  margin-top: 0;
  margin-bottom: 15px;
  margin-right: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  float: right;
}

button.btn#addInstructorButton:hover {
  background-color: #0c1a61;
  transition: transform 0.2s, box-shadow 0.2s;
}

button.btn#addAssessorButton {
  padding: 10px 10px;
  font-size: 16px;
  width: 160px;
  height: 40px;
  margin-top: 0;
  margin-bottom: 15px;
  margin-right: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  float: right;
}

button.btn#addAssessorButton:hover {
  background-color: #0c1a61;
  transition: transform 0.2s, box-shadow 0.2s;
}

.current-time-container {
    position: absolute;
    top: 60px;
    right: 210px;
    display: flex;
    align-items: center;
}

h1 {
  padding-top: 30px;
  margin-right: 100%;
  padding-left: 3%;
}



.container-fluid-project {
  padding-top: 30px;
  margin-left: 1%;
  margin-right: 1%;
}

label {
  margin-top: 8px;
}


.filter-select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-left: 0;
  position: absolute;
  margin-bottom: 50px;
  width: 200px;
}


.edit-icon {
  font-size: 16px; /* Adjust the size as needed */
  color: #615f5f; /* Change the color to your preference */
  cursor: pointer;
  transition: color 0.2s, transform 0.2s, box-shadow 0.2s;
}

.edit-icon:hover {
  color: #2b2a2a; /* Change the color to your preference */
  cursor: pointer;
  transform: scale(1.1); /* Example transform for scaling on hover */

}

input[type="text"] {
  width: 100%;
  height: 35px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  margin-bottom: 1px;
}

.filter-container {
  margin: 40px 0;
  position: absolute;
  width: 200px;
  top: 0px;
}

.filter-select#productFilter {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
  position: absolute;
  right: 210px;
  top: 6px;
}


.data-table-trainee {
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-collapse: collapse;
  margin-left: 0;
  align-items: left;
  margin-bottom: 30px;
}
.data-table-trainee .projectID{
  width:8%;
}

.data-table-trainee th, .data-table-trainee td {
  text-align: left; /* Align content to the left */
  padding-left: 10px;
}

.data-table-trainee td.center-icons {
  text-align: center;
}

.pagination-container {
  margin-top: 40px;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  margin-left: 3%;
}

.filter-select#projectFilter:disabled {
  cursor: not-allowed;
}

.clear-filter-button {
  position: absolute;
  right: 250px;
  top: 90px;
  width: 120px;
  border-radius: 5px;
  background-color: #dee2e6;
  font-size: 14px;
  height: 30px;
  border: 2px solid black;
  line-height: 0px;
}

.clear-filter-button:hover {
  background-color: #cecece;
  cursor: pointer;
}

.pagination-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}


.pagination-info {
  font-size: 14px;
  color: #666;
  /* Adjust the color as needed */
  position: absolute;
  top: 250px;
  /* Adjust the top position as needed */
  /* Center horizontally within the container */
  left: 50%;
  /* Center horizontally */
  transform: translateX(-50%);
  /* Center horizontally */
}

.skinny-column-edit {
  width: 85px;
  justify-content: left;
}


.delete-icon {
  font-size: 16px;
  color: #FF0000;
  /* Red color */
  cursor: pointer;
  transition: color 0.2s, transform 0.2s, box-shadow 0.2s;
  margin-left: 15px;
}

.delete-icon:hover {
  color: #a82222;
  cursor: pointer;
  transform: scale(1.1);
}
</style>
  