<template>
    <div class="modal-overlay" @click="closeModal">
        <div class="products-modal" @click.stop>
            <div class="modal-content" @click.stop> <!-- Stop the click event from propagating to the overlay -->
                <h2>Add a New Chapter</h2>
                <table>
                    <tr>
                        <input class="productsearch" v-model="chapterNo" type="text" style="width: 30%; margin-top: 5px; margin-bottom: 20px; margin-right: 2%;"
                        placeholder="Chapter No" />
                        <input class="productsearch" v-model="chapterName" type="text" style="width: 65%; margin-top: 5px; margin-bottom: 20px;"
                            placeholder="Chapter Name" />
                    </tr>
                    <div v-if="showAlert" class="alert alert-danger error-message" role="alert">
              <div v-if="errorMessage">{{ errorMessage }}</div>
            </div>
            <tr>
        <button class="saveButton" @click="saveButton">Save</button>
</tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            chapters: [],
            showAlert: false,
            errorMessage: '',
        };
    },

    mounted() {
        this.fetchData();
        window.addEventListener('show-alert', this.handleShowAlert);
    },
    beforeUnmount() {
        window.removeEventListener('show-alert', this.handleShowAlert);
    },


    methods: {
        async fetchData() {
            try {
                const token = localStorage.getItem('token');
                const headers = { Authorization: `Bearer ${token}` };
                let response = await axios.get('/api/chapters', { headers });
                this.chapters = response.data;
                console.log(response);

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        },
        viewChapters () {
            this.$router.push({name: 'ViewChapters'});
        },
        closeModal() {
            this.$emit('close');
        },
        async saveButton() {
    // Check if values are set before making the API call
    if (this.chapterNo && this.chapterName) {
        // Check for duplicate chapter number
        const isNumberDuplicate = this.chapters.some(chapter => chapter.chapt_no === this.chapterNo);
        // Check for duplicate chapter name
        const isNameDuplicate = this.chapters.some(chapter => chapter.name === this.chapterName);

        if (isNumberDuplicate) {
            console.error("Chapter number already exists. Please choose a different number.");
            window.alert('Chapter number already exists');
        } else if (isNameDuplicate) {
            console.error("Chapter name already exists. Please choose a different name.");
            window.alert('Chapter name already exists');
        } else {
            try {
                const token = localStorage.getItem('token');
                const headers = { Authorization: `Bearer ${token}` };
                const data = {
                    chapt_no: this.chapterNo, // Include the chapter number in the data being sent
                    name: this.chapterName
                };
                console.log("data", data);
                const response = await axios.post('/api/chapters', data, { headers });

                if (response.status === 201) {
                    window.alert('Chapter added successfully');
                    this.closeModal();
                } else {
                    window.dispatchEvent(new CustomEvent('show-alert', { detail: "Chapter addition failed" }));
                }
            } catch (error) {
                this.errorMessage = error.response.data.message;
                this.showAlert = true;
                console.error("There was an error with the fetch operation:", error);
            }
        }
    } else {
        console.error("Please enter both a chapter number and name.");
    }
}
    }
};

</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.products-modal {
    background-color: #fff;
    max-width: 90%;
    min-width: 35%;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    position: relative;
    display: block;
    z-index: 1001;
}

.chapterdropdown,
.productsearch,
.chapterdropdown {
    height: 40px;
    /* adjust this value as needed */
    margin-bottom: 10px;
    width: 80%;
}


.modal-content {
    border: white;
}

.saveButton {
    background-color: #0a407a;
    color: #ffffff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    min-width: 120px;
    cursor: pointer;
    margin-bottom: auto;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    align-self: center;
}

.viewChaptersButton {
    background-color: rgb(129, 129, 129);
    color: #ffffff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    min-width: 150px;
    height: 45px;
    margin-right: 30px;
    float: right;
}

.saveButton:hover {
    background-color: rgb(1, 40, 82);
}

.viewChaptersButton:hover {
    background-color: rgb(92, 92, 92);
}

h2 {
    margin-bottom: 10px;
    margin-left: 15px;
}

.alert-danger {
    color: #ff0019;
    background-color: #f8d7da;
    border: 1px solid #e6b0b6;
}

.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 0rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}
</style>