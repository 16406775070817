<template>
  <Header />
  <h1>Trainees</h1>

  <div class="current-time-container">
    <div class="filter-container">
      <input type="text" v-model="searchQuery" placeholder="Search by Trainee Name" />
      <input type="text" v-model="logbookSearchQuery" placeholder="Search by Logbook Name" />
      <input type="text" id="productFilter" v-model="categorySearchQuery" placeholder="Search by Category" />
      <input type="text" id="projectFilter" v-model="licenseSearchQuery" placeholder="Search by License No" />

      <button class="clear-filter-button" @click="clearFilters">Clear Filters</button>
    </div>
  </div>

  <div class="pagination-button-container">
    <div class="pagination-container">
      <Pagination :totalPages="totalPages" :perPage="perPage" :currentPage="currentPage" @page-change="onPageChange" />
    </div>
    <div class="pagination-info">
      Showing {{ startIndex + 1 }}-{{ endIndex }} of {{ filteredTraineesLength }} trainees
    </div>
  </div>

  <edit-trainee-modal v-if="showEditTrainee" @close="closeEditTrainee"></edit-trainee-modal>

  <div class="container-fluid-trainee">
    <table class="data-table-trainee">
      <thead>
        <tr>
          <th class="id-column" @click="sortBy('user_id')">
            ID
            <span v-if="sortField === 'user_id'" v-html="getFieldArrow()"></span>
          </th>
          <th @click="sortBy('first_name')">
            First Name
            <span v-if="sortField === 'first_name'" v-html="getFieldArrow('first_name')"></span>
          </th>
          <th>Last Name</th>
          <th>Email</th>
          <th class="wider-column">Logbooks</th>
          <th class="skinny-column">Category</th>
          <th class="skinny-column">License No</th>
          <th class="skinny-column">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in filteredTrainees" :key="item.user_id">
          <td>{{ item.user_id }}</td>
          <td>{{ item.first_name }}</td>
          <td>{{ item.last_name }}</td>
          <td>{{ item.email }} </td>
          <td class="td-logbook">
            <div v-for="logbookItem in item.logbooks" :key="logbookItem.logbook_id">
              {{ logbookItem.name }}
            </div>
          </td>
          <td>{{ item.category }} </td>
          <td>{{ item.license_no }} </td>
          <td class="center-icons">
            <i class="fas fa-edit edit-icon" @click="viewEditTrainee(item.user_id)"></i>
            <i class="fas fa-trash-alt delete-icon" @click="deleteTrainee(item.user_id)"></i>
          </td>
        </tr>
        <tr v-if="filteredTrainees.length === 0">
          <td colspan="100" style="text-align: center;">No Matching Trainees</td>
        </tr>
      </tbody>
    </table>
  </div>
<div>
    <button class="btn btn-primary rounded" id=addlogbookButton @click="goToAddTrainee">Add Trainee</button>
    <button class="btn btn-primary rounded"  id=importTraineeButton @click="goToImportTrainee">Import Trainees</button>
    <button class="btn btn-primary rounded" id="assignTraineeButton" @click="openTraineeProjectModal">Assign Trainee To Session</button>
    <trainee-project-modal v-if="showTraineeProject" @close="closeTraineeProject"></trainee-project-modal>

  </div>
</template>
    
<script>
import Header from '../Header.vue';
import axios from 'axios';
import Pagination from '../TablePagination.vue';
import EditTraineeModal from './EditTraineeModal.vue';
import TraineeProjectModal from './TraineeProjectModal.vue';

export default {
  name: 'ViewTraineeTable',
  components: {
    Pagination,
    Header,
    EditTraineeModal,
    TraineeProjectModal,
  },

  data() {
    return {
      trainees: [],
      sortField: 'user_id',
      sortDirection: 'asc',
      searchQuery: '',
      logbookSearchQuery: '',
      categorySearchQuery: '',
      licenseSearchQuery: '',
      currentPage: 1,
      perPage: 10,
      totalPages: 0,
      newLogbook: {
        name: '',
        logbook_id: '',
        logbook_name: '',
        user_id: '',
      },
      showEditTrainee: false,
      showTraineeProject: false,
      filteredTraineesLength: 0,
    };
  },
  watch: {
    searchQuery(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    logbookSearchQuery(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    categorySearchQuery(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    licenseSearchQuery(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
  },

  async mounted() {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      // Fetch all trainees
      let result = await axios.get('/api/users', { headers });

      // Fetch all logbooks
      let result_logbooks = await axios.get('/api/logbooks', { headers });

      if (result.status === 200) {
        this.trainees = result.data.filter(user => user.role === 'trainee' || user.second_role === 'trainee');

        for (let i = 0; i < this.trainees.length; i++) {
          const userId = this.trainees[i].user_id;
          // Find the logbooks for the current user by matching the user_id
          const userLogbooks = result_logbooks.data.filter(logbook => logbook.trainee_id === userId);
          this.trainees[i].logbooks = userLogbooks;
        }
      }

      this.totalPages = Math.ceil(this.trainees.length / this.perPage);

    } catch (error) {
      console.error('An error occurred while fetching data:', error);
    }
  },


  methods: {
    editTrainee(user_id) {
      window.location.href = `/trainees/${user_id}`;
    },
    openTraineeProjectModal() {
    this.showTraineeProject = true;
  },
    deleteTrainee(user_id) {
      if (confirm("Are you sure you want to delete this trainee?")) {
        if (confirm("This action will PERMANENTLY delete the user from the database. Do you wish to continue?")) {
          // If the user confirms the deletion, send a request to delete the trainee.
          const token = localStorage.getItem('token');
          const headers = { Authorization: `Bearer ${token}` };

          axios
            .delete(`/api/users/${user_id}`, { headers })
            .then(response => {
              if (response.status === 200) {
                // Remove the deleted trainee from the local data
                this.trainees = this.trainees.filter(trainee => trainee.user_id !== user_id);

                window.alert('User deleted Successfully');
              }
            })
            .catch(error => {
              console.error('An error occurred while deleting the trainee:', error);
            });
        }
      }
    },
    sortBy(field) {
      if (this.sortField === field) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortField = field;
        this.sortDirection = 'asc';
      }

      this.trainees.sort((a, b) => {
        const valueA = a[field];
        const valueB = b[field];

        if (typeof valueA === 'number' && typeof valueB === 'number') {
          return (this.sortDirection === 'asc' ? 1 : -1) * (valueA - valueB);
        } else if (typeof valueA === 'string' && typeof valueB === 'string') {
          return (this.sortDirection === 'asc' ? 1 : -1) * valueA.localeCompare(valueB);
        } else {
          return 0;
        }
      });
    },
    getFieldArrow(field = 'user_id') {
      if (this.sortField === field) {
        return this.sortDirection === 'asc' ? '▲' : '▼';
      } else {
        return '';
      }
    },
    onPageChange(page) {
      this.currentPage = page;
      this.calculateTotalPages();
    },
    goToAddTrainee() {
      this.$router.push({ name: 'AddTrainee' });
    },
    goToImportTrainee() {
      this.$router.push({ name: 'ImportTrainees' });
    },
    onTraineeSearch() {
      this.currentPage = 1;
    },
    onLogbookSearch() {
      this.currentPage = 1;
    },
    onCategorySearch() {
      this.currentPage = 1;
    },
    onLicenseSearch() {
      this.currentPage = 1;
    },
    viewEditTrainee(user_id) {
      localStorage.setItem('user_id', user_id);
      this.showEditTrainee = true; // Show the modal when the button is clicked
    },
    closeEditTrainee() {
      this.showEditTrainee = false; // Close the modal
    },
    closeTraineeProject() {
      this.showTraineeProject = false; // Close the modal
    },
    calculateTotalPages() {
      this.computeFilteredTrainees();
      this.totalPages = Math.ceil(this.filteredTraineesLength / this.perPage);
    },
    computeFilteredTrainees() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;

      const filteredTrainees = this.trainees.filter((trainee) => {
        const fullName = (trainee.first_name + ' ' + trainee.last_name || "").toLowerCase(); // Fallback to empty string if null or undefined
        const traineeMatch = fullName.includes(this.searchQuery.toLowerCase()) || this.searchQuery === '';

        const category = (trainee.category || "").toLowerCase(); // Fallback to empty string if null or undefined
        const categoryMatch = category.includes(this.categorySearchQuery.toLowerCase()) || this.categorySearchQuery === '';

        const license_no = (trainee.license_no || "").toString(); // Fallback to empty string if null or undefined
        const licenseMatch = license_no.includes(this.licenseSearchQuery.toLowerCase()) || this.licenseSearchQuery === '';

        const logbooks = trainee.logbooks || [];
        const logbookMatch = logbooks.length === 0 || logbooks.some((logbookItem) =>
          (logbookItem.name || "").toLowerCase().includes(this.logbookSearchQuery.toLowerCase()) // Fallback to empty string if null or undefined
        );

        return traineeMatch && logbookMatch && categoryMatch && licenseMatch;
      });
      console.log(filteredTrainees);
      this.filteredTraineesLength = filteredTrainees.length;
      console.log(this.filteredTraineesLength);
      return filteredTrainees.slice(startIndex, endIndex);
    },
    clearFilters() {
      // Clear all filter-related data properties
      this.searchQuery = '',
        this.logbookSearchQuery = '',
        this.categorySearchQuery = '',
        this.licenseSearchQuery = '',

        // Recalculate the total pages and update the data
        this.calculateTotalPages();
    },
  },
  computed: {
    startIndex() {
      return (this.currentPage - 1) * this.perPage;
    },
    endIndex() {
      const end = this.startIndex + this.perPage;
      return end < this.filteredTraineesLength ? end : this.filteredTraineesLength;
    },
    filteredTrainees() {
      return this.computeFilteredTrainees();
    },
  },
};
</script>
    
    
<style scoped>
span:hover {
  cursor: pointer;
  color: blue;
}

.container-fluid-trainee {
  padding-top: 30px;
  margin-left: 1%;
  margin-right: 1%;
}

button.btn#addlogbookButton {
  padding: 10px 10px;
  font-size: 16px;
  width: 180px;
  height: 40px;
  margin-top: 0;
  margin-bottom: 15px;
  margin-right: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  float: right;
}

button.btn#addlogbookButton:hover {
  background-color: #0c1a61;
  transition: transform 0.2s, box-shadow 0.2s;
}

button.btn#assignTraineeButton {
  padding: 10px 10px;
  font-size: 16px;
  width: 210px;
  height: 40px;
  margin-top: 0;
  margin-bottom: 15px;
  margin-right: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  float: right;
}

button.btn#assignTraineeButton:hover {
  background-color: #0c1a61;
  transition: transform 0.2s, box-shadow 0.2s;
}

button.btn#importTraineeButton {
  padding: 10px 10px;
  font-size: 16px;
  width: 180px;
  height: 40px;
  margin-top: 0;
  margin-bottom: 15px;
  margin-right: 20px;
  border: 1px solid rgb(17, 80, 20);
  background: #2c752c;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  float: right;
}

button.btn#importTraineeButton:hover {
  background-color: #184218;
  transition: transform 0.2s, box-shadow 0.2s;
}

input[type="text"] {
  width: 100%;
  height: 35px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  margin-bottom: 1px;
}

input[type="text"]#productFilter {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
  position: absolute;
  right: 210px;
  top: 0px;
}

input[type="text"]#projectFilter {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
  position: absolute;
  right: 210px;
  top: 41px;
}




h1 {
  padding-top: 30px;
  margin-right: 100%;
  padding-left: 3%;
}

.filter-container {
  margin: 20px 0;
  position: absolute;
  width: 200px;
  top: 0px;
  right: 0px;
}

label {
  margin-top: 8px;
}

.current-time-container {
  position: absolute;
  top: 80px;
  right: 10px;

}

.data-table-trainee {
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-collapse: collapse;
  margin-left: 0;
  align-items: left;
  margin-bottom: 30px;
}

.data-table-trainee th,
.data-table-trainee td {
  text-align: left;
  /* Align content to the left */
  padding-left: 10px;
}

.data-table-trainee td.center-icons {
  text-align: center;
}

.pagination-container {
  margin-top: 40px;
  margin-bottom: 0;
  display: flex;
  margin-left: 3%;
}

.edit-icon {
  font-size: 16px;
  /* Adjust the size as needed */
  color: #615f5f;
  /* Change the color to your preference */
  cursor: pointer;
  transition: color 0.2s, transform 0.2s, box-shadow 0.2s;
}

.edit-icon:hover {
  color: #2b2a2a;
  /* Change the color to your preference */
  cursor: pointer;
  transform: scale(1.1);
  /* Example transform for scaling on hover */

}

.delete-icon {
  font-size: 16px;
  color: #FF0000;
  /* Red color */
  cursor: pointer;
  transition: color 0.2s, transform 0.2s, box-shadow 0.2s;
  padding-left: 15px;
}

.delete-icon:hover {
  color: #a82222;
  /* Lighter red color on hover */
  cursor: pointer;
  transform: scale(1.1);
  /* Scale the icon on hover */
  /* You can also add a box-shadow or any other styles you prefer */
}

.clear-filter-button {
  position: absolute;
  right: 50px;
  top: 112px;
  width: 120px;
  border-radius: 5px;
  background-color: #dee2e6;
  font-size: 14px;
  height: 30px;
  border: 2px solid black;
  line-height: 0px;
}

.clear-filter-button:hover {
  background-color: #cecece;
  cursor: pointer;
}

.pagination-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}


.pagination-info {
  font-size: 14px;
  color: #666;
  /* Adjust the color as needed */
  position: absolute;
  top:250px;
  left: 50%;
  /* Center horizontally */
  transform: translateX(-50%);
  /* Center horizontally */
}

.wider-column {
  width: 42%;
}

.td-logbook {
  font-size: 13px;
}


.id-column {
  width: 8%;
}

.skinny-column {
  width: 7%;
}
</style>