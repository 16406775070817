<template>
  <div class="trainee-list-modal-overlay" @click="closeModalOutside">
    <div class="trainee-list-modal" @click.stop>
      <div class="modal-content">
        <h2>Trainee List</h2>
  <div class="search-modal">
    <input type="text" v-model="searchQuery" placeholder="Search by trainee name" />
    <input type="text" v-model="projectSearchQuery" placeholder="Search by Session ID" />

    <div class="pagination-container">
      <Pagination :totalPages="totalPages" :perPage="perPage" :currentPage="currentPage" @page-change="onPageChange" />
    </div>
  </div>

  <div class="container-fluid">
    <table class="data-table-trainee">
      <thead>
        <tr>
          <th @click="sortBy('user_id')">
            Trainee ID
            <span v-if="sortField === 'user_id'" v-html="getFieldArrow()"></span>
          </th>
          <th @click="sortBy('first_name')">
            First Name
            <span v-if="sortField === 'first_name'" v-html="getFieldArrow('first_name')"></span>
          </th>
          <th>Last Name</th>
          <th>Email</th>
          <th>Session IDs</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in filteredData" :key="item.user_id">
          <td>{{ item.user_id }}</td>
          <td>{{ item.first_name }}</td>
          <td>{{ item.last_name }}</td>
          <td>{{ item.email }} </td>
          <td>
            {{ concatenatedProjectIDs[filteredData.indexOf(item)] }}
          </td>
        </tr>
      </tbody>
    </table>
    <span v-if="filteredData.length === 0">No Matching Trainees</span>
  </div>
  </div>
  </div>
  </div>
  </template>
  
  <script>

import axios from 'axios';
import Pagination from '../TablePagination.vue';

  export default {
  data() {
    return {
        trainees: [],
      sortField: 'user_id',
      sortDirection: 'asc',
      searchQuery: '',
      projectSearchQuery: '',
      currentPage: 1,
      perPage: 10,
      totalPages: 0,
      newProject: {
        name: '',
        product_id: '',
        product_name: '',
        user_id: '',
      },
    };
  },
  watch: {
    searchQuery(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
      }
    },
    projectSearchQuery(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
      }
    },
    currentPage() {
    this.projectSearchQuery = ''; // Reset projectSearchQuery when changing the page.
  }
  },
  components: {
    Pagination,
  },
  async mounted() {

    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      let result = await axios.get('/api/users', { headers });

      if (result.status === 200) {
        // Filter the response data to include only users with the role 'instructor'
        this.trainees = result.data.filter(user => user.role === 'trainee' || user.second_role === 'trainee');
        console.log(this.trainees);

        for (let i = 0; i < this.trainees.length; i++) {
          let result_project = await axios.get(`/api/trainees/${this.trainees[i].user_id}/projects`, { headers });
          this.trainees[i].projects = result_project.data;
        }
      }

      this.totalPages = Math.ceil(this.trainees.length / this.perPage);

    } catch (error) {
      console.error('An error occurred while fetching data:', error);
    }
  },
  methods: {
    editTrainee(user_id) {
      window.location.href = `/trainees/${user_id}`;
    },
    sortBy(field) {
      if (this.sortField === field) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortField = field;
        this.sortDirection = 'asc';
      }

      this.trainees.sort((a, b) => {
        const valueA = a[field];
        const valueB = b[field];

        if (typeof valueA === 'number' && typeof valueB === 'number') {
          return (this.sortDirection === 'asc' ? 1 : -1) * (valueA - valueB);
        } else if (typeof valueA === 'string' && typeof valueB === 'string') {
          return (this.sortDirection === 'asc' ? 1 : -1) * valueA.localeCompare(valueB);
        } else {
          return 0;
        }
      });
    },
    getFieldArrow(field = 'user_id') {
      if (this.sortField === field) {
        return this.sortDirection === 'asc' ? '▲' : '▼';
      } else {
        return '';
      }
    },
    onPageChange(page) {
      this.currentPage = page;
    },
    goToAddTrainee() {
      this.$router.push({ name: 'AddTrainee' });
    },
    closeModalOutside() {
      this.$emit('close'); // Emit an event to indicate the modal should be closed
    },
  },
  computed: {
    filteredData() {
  const startIndex = (this.currentPage - 1) * this.perPage;
  const endIndex = startIndex + this.perPage;

  return this.trainees.filter((trainee) => {
    const fullName = `${trainee.first_name} ${trainee.last_name}`.toLowerCase();
    const traineeMatch =
      fullName.includes(this.searchQuery.toLowerCase()) || this.searchQuery === '';

    const projectMatch =
      trainee.projects && trainee.projects.some((projectItem) =>
        projectItem.project_id.toString().includes(this.projectSearchQuery)
      ) || this.projectSearchQuery === '';

    return traineeMatch && projectMatch;
  }).slice(startIndex, endIndex);
},

    concatenatedProjectIDs() {
  return this.filteredData.map((trainee) => {
    if (Array.isArray(trainee.projects)) {
      return trainee.projects.map((projectItem) => projectItem.project_id).join(', ');
    } else {
      return '';
    }
  });
},

  },
};
  </script>
  
  <style scoped>
.trainee-list-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the modal is on top of other content */
}

h2 {
  font-size: 30px;
}

/* Style your modal content */
.trainee-list-modal {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 800px; /* Limit the maximum width of the modal */
  padding: 20px;
  position: relative;
  max-height: 95%;
}

input[type="text"], input[type="password"] {
    width: 40%;
    height: 30px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 0px 20px;
}

button {
  /* Add your button styles here */
  background-color: #007BFF;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.data-table-trainee {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  max-height: 50vh; /* Adjust the maximum height as needed */
  overflow-y: auto; /* Enable vertical scrolling when content exceeds the maximum height */
}

table th, table td {
  padding: 10px;
  border: 1px solid #ccc;
}

.modal-content {
  height: 90vh; /* Adjust the maximum height as needed */
  overflow-y: auto; /* Enable scrolling within the modal content */
}
  
  </style>
  